import { appbarHeight } from '@/common/constants';
import { AppBar, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AppHeaderGreeting } from './AppHeaderGreeting';
import { Avatar } from './Avatar';

const drawerWidth = 256;

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background.default,
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        height: `${appbarHeight}px`,
    },
    title: {
        flexGrow: 1,
    },
}));

export function AppHeaderDesktop() {
    const classes = useStyles();

    return (
        <AppBar position="fixed" className={classes.root} elevation={0} color="transparent">
            <Toolbar>
                <AppHeaderGreeting />
                <Avatar />
            </Toolbar>
        </AppBar>
    );
}
