import { useAccountId } from '@/atoms/session';
import { useFetchServiceLocations } from '@/common/hooks/query/useQueryServiceLocations';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { formatAddress } from '@/common/utils/formatAddress';
import { setLocationId, useSelectQuestionCommentState } from '../../store';
import { useTexts } from '../../useTexts';

export function ServiceLocationSelect() {
    const accountId = useAccountId();
    const { data: locations } = useFetchServiceLocations(accountId || '');

    const texts = useTexts();
    const { serviceLocationId } = useSelectQuestionCommentState();

    const onChangeLocation = useCallback((event: SelectChangeEvent<string>) => {
        setLocationId(event.target.value);
    }, []);

    useEffect(() => {
        if (!!locations && locations.length == 1) {
            setLocationId(locations[0].serviceLocationId);
        } else {
            setLocationId('');
        }
    }, [locations]);

    return (
        <>
            {!!locations && (
                <FormControl fullWidth>
                    <InputLabel id="service-location-label">Service Location</InputLabel>
                    <Select
                        defaultValue=""
                        id="service-location"
                        label={texts.serviceLocation}
                        labelId="service-location-label"
                        onChange={onChangeLocation}
                        required
                        value={serviceLocationId}
                    >
                        {locations.map((l, i) => (
                            <MenuItem key={i} value={l.serviceLocationId}>
                                {formatAddress(l)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </>
    );
}
