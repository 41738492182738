import type { ScrollIntoView } from '../../SmoothScrollbar';

import { createContext } from 'react';

export interface Context {
    scrollTo: (id: string) => void;
    scrollIntoView?: ScrollIntoView;
}

export const ScrollContext = createContext<Context>({
    scrollTo: () => {
        if (process.env.NODE_ENV === 'development') {
            console.warn('Missing provider for scrollTo');
        }
    },
});
