import { Alert, Box, CircularProgress } from '@mui/material';
import { useAddServicesState } from '../store';
import { useRequestServiceState } from '../../../store';
import { useData } from '../useData';
import { useTexts } from './useTexts';
import { ServiceList } from './ServiceList';

export function DrawerContent() {
    const { serviceLocation } = useRequestServiceState();
    const {
        all: { page },
        status,
    } = useAddServicesState();

    const texts = useTexts();

    useData(serviceLocation?.businessUnitId || '', page);

    return (
        <Box>
            {status == 'loading' && (
                <Box display="flex" p={2} justifyContent="center">
                    <CircularProgress />
                </Box>
            )}
            {status == 'success' && <ServiceList />}
            {status == 'error' && <Alert severity="error">{texts.error}</Alert>}
        </Box>
    );
}
