import { PageWrapper } from '@/components/PageWrapper';
import { useAccountId } from '@/atoms/session';
import { PageErrorState } from '@/components/PageErrorState/PageErrorState';
import { useQueryUpcomingServices } from '@/common/hooks/query/useQueryUpcomingServices';
import { useEffect } from 'react';
import { UpcomingServicesList } from './UpcomingServicesList';
import { resetStore, useListState } from './UpcomingServicesList/store';
import { LoadingSkeleton } from './LoadingSkeleton';

export function UpcomingServicesPage() {
    const { page, take, search, serviceLocations } = useListState();
    const accountId = useAccountId();
    const query = useQueryUpcomingServices(accountId || '', page, take, search, serviceLocations);

    useEffect(() => {
        resetStore();
    }, [accountId]);

    return (
        <PageWrapper disableScroll>
            {query.status === 'loading' && <LoadingSkeleton />}
            {query.status === 'success' && <UpcomingServicesList items={query.data.items} total={query.data.total} />}
            {query.status === 'error' && <PageErrorState />}
        </PageWrapper>
    );
}
