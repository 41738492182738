import type { ReactElement } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useIsUserAuthorized } from '@/atoms/session';

interface Props {
    element: ReactElement;
}

export function PrivateRoute(props: Props): ReactElement<Props> {
    const { element } = props;

    const location = useLocation();
    const authorized = useIsUserAuthorized();

    if (!authorized) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return element;
}
