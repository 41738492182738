import { useCallback } from 'react';
import { Button, Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { useAccountId } from '@/atoms/session';
import { PageWrapper } from '@/components/PageWrapper';
import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { AccountSummary } from './widgets/AccountSummary/AccountSummary';
import { YourNextService } from './widgets/YourNextService/YourNextService';
import { YourLastService } from './widgets/YourLastService/YourLastService';
import { useTexts } from './useTexts';

export function AccountOverview() {
    const accountId = useAccountId();
    const navigate = useNavigate();
    const texts = useTexts();
    const serviceHistoryEnabled = useFeatureEnabled('enableServiceHistory');
    const upcomingServicesEnabled = useFeatureEnabled('enableUpcomingServices');
    const requestServiceEnabled = useFeatureEnabled('enableRequestNewService');

    const goToRequestService = useCallback(() => {
        navigate('/your-services/request-service');
    }, [navigate]);

    return (
        <PageWrapper>
            <Stack direction="column" spacing={2}>
                {requestServiceEnabled && (
                    <Box>
                        <Button color="primary" variant="contained" onClick={goToRequestService}>
                            {texts.requestServices}
                        </Button>
                    </Box>
                )}

                {!!accountId && (
                    <>
                        <AccountSummary accountId={accountId} />

                        {upcomingServicesEnabled && <YourNextService accountId={accountId} />}
                        {serviceHistoryEnabled && <YourLastService accountId={accountId} />}
                    </>
                )}
            </Stack>
        </PageWrapper>
    );
}
