import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            loading: translate({ id: `intl.loading-screen.loading` }),
            error: translate({ id: `intl.loading-screen.error` }),
        };
    }, [translate]);
}
