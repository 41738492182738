import makeStyles from '@mui/styles/makeStyles';
import Paper, { PaperProps } from '@mui/material/Paper';

import { toggleCss } from '@/utils/toggleCss';

const useStyles = makeStyles(theme => ({
    contentPaper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fullWidth: {
        width: '100%',
    },
    fullHeight: {
        height: '100%',
    },
}));

type Props = PaperProps & {
    fullWidth?: boolean;
    fullHeight?: boolean;
};

export function ContentPaper(props: Props) {
    const { className, fullHeight, fullWidth, ...other } = props;
    const classes = useStyles();

    return (
        <Paper
            className={toggleCss(
                classes.contentPaper,
                fullWidth && classes.fullWidth,
                fullHeight && classes.fullHeight,
                className,
            )}
            {...other}
        >
            {props.children}
        </Paper>
    );
}
