import { List as MuiList } from '@mui/material';

import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { ListItem, useTexts } from '../../index';

export function YourServicesSubList() {
    const texts = useTexts();
    const requestServiceEnabled = useFeatureEnabled('enableRequestNewService');
    const upcomingServicesEnabled = useFeatureEnabled('enableUpcomingServices');
    const viewAgreementsEnabled = useFeatureEnabled('enableViewAgreements');

    return (
        <MuiList>
            <ListItem
                data-testid="btn-request-service"
                hidden={!requestServiceEnabled}
                label={texts.requestService}
                path="/your-services/request-service"
            />
            <ListItem
                data-testid="btn-your-requests"
                hidden={!requestServiceEnabled}
                label={texts.yourRequests}
                path="/your-services/your-requests"
            />
            <ListItem
                data-testid="btn-upcoming-services"
                hidden={!upcomingServicesEnabled}
                label={texts.upcomingServices}
                path="/your-services/upcoming-services"
            />
            <ListItem
                data-testid="btn-agreements"
                hidden={!viewAgreementsEnabled}
                label={texts.agreements}
                path="/your-services/agreements"
            />
        </MuiList>
    );
}
