import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, IconButton, Popover, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useState } from 'react';
import { useNavigate } from 'react-router';
import { getDisplayName } from '@/atoms/session/store';
import { useTexts } from './useTexts';
import { useLogoutActions } from './useLogoutActions';

const useStyles = makeStyles({
    name: {
        flexGrow: 1,
    },
    popover: {
        minWidth: '300px',
    },
});

export const Avatar = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const navigate = useNavigate();
    const texts = useTexts();
    const logout = useLogoutActions();
    const displayName = getDisplayName();

    const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const goToProfileSettings = (_event: React.MouseEvent<HTMLButtonElement>) => {
        navigate('/profile');
        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton color="primary" onClick={handleMenu} size="medium" data-testid="btn-avatar">
                <AccountCircleIcon color="primary" />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={2} className={classes.popover}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Box display="flex">
                                <Box display="flex" flexGrow={1} alignItems="center">
                                    <Typography variant="h4">{displayName}</Typography>
                                </Box>
                                <Box>
                                    <IconButton onClick={handleClose} size="small">
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Button
                                data-testid="btn-view-profile-settings"
                                onClick={goToProfileSettings}
                                variant="text"
                            >
                                <Typography variant="caption">{texts.viewProfileSettings}</Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button data-testid="btn-log-out" onClick={logout} variant="contained">
                                {texts.logout}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Popover>
        </div>
    );
};
