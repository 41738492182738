import { useQuery } from 'react-query';
import { AgreementsResponse, getAgreements } from '@/api/agreements';
import { AgreementStatus } from '@/common/types/agreements';

export const useQueryActiveAgreements = (accountId: string, page: number, take: number, serviceLocations: string[]) =>
    useQuery<AgreementsResponse, Error>(
        ['activeAgreements', accountId, page, take, serviceLocations, AgreementStatus.ACTIVE],
        () => getAgreements(accountId, page, take, serviceLocations, AgreementStatus.ACTIVE),
        {
            keepPreviousData: true,
        },
    );
