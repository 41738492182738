import { ListHeader } from '@/components/cardList';
import { useTexts } from '../../useTexts';

interface Props {
    openDrawer?: () => void;
}

export function YourRequestsListHeader(props: Props) {
    const { openDrawer } = props;
    const texts = useTexts();

    return <ListHeader title={texts.pageTitle} openDrawer={openDrawer} hideFilters singleLineOnMobile />;
}
