import * as _ from 'lodash';
import { proxy, useSnapshot } from 'valtio';
import { InvoiceListItem } from '@/common/types/invoices';
import { PaymentAccount } from '@/common/types/payments';

export enum InvoiceWizardStep {
    SelectSavedPaymentMethod = 1,
    NewCardSave = 2,
    NewCardOneTime = 3,
}
export interface PayInvoicesState {
    invoicePagination: {
        page: number;
        take: number;
    };
    paymentMethod: PaymentAccount | null;
    invoicesSelected: InvoiceListItem[];
    startingBalancePayment: number;
    status: 'error' | 'loading' | 'success';
    wizardStep: InvoiceWizardStep;
}

export const initialState: PayInvoicesState = {
    invoicePagination: {
        page: 0,
        take: 10,
    },
    paymentMethod: null,
    invoicesSelected: [],
    startingBalancePayment: 0,
    status: 'loading',
    wizardStep: InvoiceWizardStep.SelectSavedPaymentMethod,
};

export function resetPayInvoicesState() {
    state.invoicePagination = {
        page: 0,
        take: 10,
    };
    state.paymentMethod = null;
    state.invoicesSelected = [];
    state.startingBalancePayment = 0;
    state.status = 'loading';
    state.wizardStep = InvoiceWizardStep.SelectSavedPaymentMethod;
}

// Valtio store initialization
const state = proxy<PayInvoicesState>(initialState);

// Setter functions
export function addInvoice(invoice: InvoiceListItem) {
    state.invoicesSelected.push(invoice);
}

export function clearSelectedInvoices() {
    state.invoicesSelected = [];
}

export function removeInvoice(invoice: InvoiceListItem) {
    const updatedInvoices = _.cloneDeep(state.invoicesSelected.filter(i => i.invoiceId != invoice.invoiceId));
    state.invoicesSelected = updatedInvoices;
}

export function selectUnappliedStartingBalance(balance: number) {
    state.startingBalancePayment = balance;
}

export function unSelectUnappliedStartingBalance() {
    state.startingBalancePayment = 0;
}

export function resetInvoicePaymentDetails() {
    state.paymentMethod = null;
    state.wizardStep = InvoiceWizardStep.SelectSavedPaymentMethod;
}

export function setPaymentMethod(paymentAccount: PaymentAccount) {
    state.paymentMethod = { ...paymentAccount };
}

export function setPage(pageNumber: number) {
    state.invoicePagination.page = pageNumber;
}

export function setWizardStep(step: InvoiceWizardStep) {
    state.wizardStep = step;
}

// Hooks
export function usePayInvoicesState() {
    return useSnapshot(state);
}

export function usePaymentTotal() {
    const snapshot = useSnapshot(state);
    return snapshot.invoicesSelected.reduce((a, b) => a + b.balance || 0, 0) + snapshot.startingBalancePayment;
}

export function useIsInvoiceSelected(invoice: InvoiceListItem) {
    return useSnapshot(state).invoicesSelected.filter(i => i.invoiceId == invoice.invoiceId).length > 0;
}

export function useIsPaymentMethodSelected(paymentMethod: PaymentAccount) {
    return useSnapshot(state).paymentMethod?.paymentAccountId == paymentMethod.paymentAccountId;
}
