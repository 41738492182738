import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            active: translate({ id: 'sections.agreements.drawer.status.active' }),
            addNewPaymentMethod: translate({ id: 'sections.agreements.drawer.addNewPaymentMethod' }),
            agreement: translate({ id: 'global.agreement' }),
            agreementName: translate({ id: 'sections.agreements.drawer.agreementName' }),
            autopayIsDisabled: translate({ id: 'sections.agreements.drawer.autopayIsDisabled' }),
            autopayIsEnabled: translate({ id: 'sections.agreements.drawer.autopayIsEnabled' }),
            billingContact: translate({ id: 'global.billingContact' }),
            billingLocation: translate({ id: 'global.billingLocation' }),
            disableAutopay: translate({ id: 'sections.agreements.drawer.disableAutopay' }),
            cancelled: translate({ id: 'sections.agreements.drawer.status.cancelled' }),
            completed: translate({ id: 'sections.agreements.drawer.status.completed' }),
            endDate: translate({ id: 'sections.agreements.drawer.endDate' }),
            error: translate({ id: 'sections.agreements.drawer.error' }),
            errorDisablingAutopay: translate({ id: 'sections.agreements.drawer.errorDisablingAutopay' }),
            failedToDownloadPDF: translate({ id: 'sections.agreements.drawer.failedToDownloadPDF' }),
            hasAutoPayInstance: translate({ id: 'sections.payInvoices.drawer.paymentMethod.hasAutoPayInstance' }),
            invoicingType: translate({ id: 'sections.agreements.drawer.invoicingType' }),
            locationHasErrors: translate({ id: 'sections.agreements.locationHasErrors' }),
            manageAutopay: translate({ id: 'sections.agreements.drawer.manageAutopay' }),
            pdf: translate({ id: 'global.pdf' }),
            serviceContact: translate({ id: 'global.serviceContact' }),
            serviceLocation: translate({ id: 'global.serviceLocation' }),
            services: translate({ id: 'sections.agreements.drawer.services' }),
            startDate: translate({ id: 'sections.agreements.drawer.startDate' }),
            status: translate({ id: 'sections.agreements.drawer.status' }),
            successfullyDisabledAutopay: translate({ id: 'sections.agreements.drawer.successfullyDisabledAutopay' }),
            successfullyEnabledAutopay: translate({ id: 'sections.agreements.drawer.successfullyEnabledAutopay' }),
            title: translate({ id: 'sections.agreements.drawer.title' }),
            useSavedPaymentMethod: translate({ id: 'sections.payInvoices.drawer.paymentMethod.useSavedPaymentMethod' }),
        };
    }, [translate]);
}
