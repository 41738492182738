import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            sunday: translate({ id: 'global.daysOfWeek.sunday' }),
            monday: translate({ id: 'global.daysOfWeek.monday' }),
            tuesday: translate({ id: 'global.daysOfWeek.tuesday' }),
            wednesday: translate({ id: 'global.daysOfWeek.wednesday' }),
            thursday: translate({ id: 'global.daysOfWeek.thursday' }),
            friday: translate({ id: 'global.daysOfWeek.friday' }),
            saturday: translate({ id: 'global.daysOfWeek.saturday' }),
        };
    }, [translate]);
}

export function useOrderedDaysOfWeek() {
    const texts = useTexts();

    return useMemo(() => {
        return [
            texts.sunday,
            texts.monday,
            texts.tuesday,
            texts.wednesday,
            texts.thursday,
            texts.friday,
            texts.saturday,
        ];
    }, [texts]);
}
