import { palette } from '../palette';

export const MuiTableRow = {
    styleOverrides: {
        root: {
            '&$selected': {
                backgroundColor: palette.background.default,
            },
            '&$hover': {
                '&:hover': {
                    backgroundColor: palette.background.default,
                },
            },
        },
    },
};
