import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useMemo } from 'react';
import { useTexts } from '../useTexts';

interface Props {
    expMonth: string | number;
    expYear: string | number;
    friendly: string;
    isCreditCard: boolean;
    isExpired: boolean;
    isSelected?: boolean;
    styles?: Pick<React.CSSProperties, 'color'>;
}

const useStyles = (props: Props) =>
    makeStyles(theme => ({
        subtitle: {
            color: props.isSelected ? theme.palette.white : props.styles?.color || '#9E9E9E',
        },
        title: {
            color: props.isExpired
                ? theme.palette.error.main
                : props.isSelected
                ? theme.palette.white
                : props.styles?.color || '#9E9E9E',
            fontSize: '10px',
            textTransform: 'uppercase',
        },
    }));

export function PaymentAccountDetails(props: Props) {
    const { expMonth, expYear, friendly, isCreditCard, isExpired } = props;
    const classes = useStyles(props)();
    const texts = useTexts();

    const friendlyName = useMemo(() => {
        return isCreditCard ? texts.cardName : texts.achName;
    }, [isCreditCard, texts]);

    return (
        <Box display="flex" flexDirection="row">
            <Box display="flex" flexGrow={1}>
                <Box>
                    <Typography className={classes.title}>{friendlyName}</Typography>
                    <Typography className={classes.subtitle}>{friendly}</Typography>
                </Box>
            </Box>

            {isCreditCard && (
                <Box display="flex" justifyContent="flex-end">
                    <Box>
                        <Typography className={classes.title}>{isExpired ? texts.expired : texts.expires}</Typography>
                        <Typography align="right" className={classes.subtitle}>
                            {expMonth.toString().padStart(2, '0')}/{expYear}
                        </Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );
}
