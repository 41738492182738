import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            amount: translate({ id: 'sections.accountHistory.paymentCard.drawer.amount' }),
            associatedInvoiceNumber: translate({
                id: 'sections.accountHistory.paymentCard.drawer.associatedInvoiceNumber',
            }),
            associatedInvoiceNumbers: translate({
                id: 'sections.accountHistory.paymentCard.drawer.associatedInvoiceNumbers',
            }),
            balance: translate({ id: 'sections.accountHistory.paymentCard.drawer.balance' }),
            error: translate({ id: 'sections.accountHistory.paymentCard.drawer.error' }),
            none: translate({ id: 'global.none' }),
            paymentDate: translate({ id: 'sections.accountHistory.paymentCard.drawer.paymentDate' }),
            paymentReceiptReportFailed: translate({
                id: 'sections.accountHistory.paymentCard.drawer.paymentReceiptReportFailed',
            }),
            paymentType: translate({ id: 'sections.accountHistory.paymentCard.drawer.paymentType' }),
            refundReceiptReportFailed: translate({
                id: 'sections.accountHistory.paymentCard.drawer.refundReceiptReportFailed',
            }),
            status: translate({ id: 'sections.accountHistory.paymentCard.drawer.status' }),
            checkNumber: translate({ id: 'sections.accountHistory.paymentCard.drawer.checkNumber' }),
            title: translate({ id: 'sections.accountHistory.paymentCard.drawer.title' }),
            viewReceipt: translate({ id: 'sections.accountHistory.paymentCard.drawer.viewReceipt' }),
            viewRefundReceipt: translate({
                id: 'sections.accountHistory.paymentCard.drawer.viewRefundReceipt',
            }),
            refundAmount: translate({ id: 'sections.accountHistory.paymentCard.drawer.refundAmount' }),
            refundDate: translate({ id: 'sections.accountHistory.paymentCard.drawer.refundDate' }),
            refundDetailsTitle: translate({ id: 'sections.accountHistory.paymentCard.drawer.refundDetailsTitle' }),
            refundPaymentType: translate({ id: 'sections.accountHistory.paymentCard.drawer.refundPaymentType' }),
        };
    }, [translate]);
}
