import { theme } from '@/theme';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';

const useStyles = makeStyles({
    root: {
        borderRadius: '4px',
        padding: '4px',
        display: 'inline',
        fontSize: '10px',
        fontWeight: 'bold',
        color: theme.palette.black,
    },
    error: {
        backgroundColor: theme.palette.badges.error,
    },
    info: {
        backgroundColor: theme.palette.badges.info,
    },
    success: {
        backgroundColor: theme.palette.badges.success,
    },
    warning: {
        backgroundColor: theme.palette.badges.warning,
    },
});

interface Props {
    severity: 'error' | 'info' | 'success' | 'warning';
    text: string;
}

export function CardBadge(props: Props) {
    const { severity, text } = props;
    const classes = useStyles();

    return <Box className={`${classes.root} ${classes[severity]}`}>{text}</Box>;
}
