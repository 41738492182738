import { Drawer } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useGetUILayout } from '@/atoms/UILayout';
import { Sidebar } from './Sidebar';

import { FooterContent, HeaderContent } from './content';
import { List } from './List/List';
import { closeSidebar, useIsOpen } from './store';

const drawerWidth = 256;

const useStyles = makeStyles({
    drawer: {
        flexShrink: 0,
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
});

export const SidebarDrawer = () => {
    const classes = useStyles();
    const isOpen = useIsOpen();
    const layout = useGetUILayout();

    return (
        <Drawer
            anchor="left"
            className={classes.drawer}
            classes={{
                paper: classes.drawerPaper,
            }}
            data-testid="sidebar-drawer"
            onClose={closeSidebar}
            open={isOpen}
            variant={layout == 'mobile' ? 'temporary' : 'permanent'}
        >
            <Sidebar
                data-testid="sidebar"
                list={List}
                footerContent={<FooterContent />}
                headerContent={<HeaderContent />}
            />
        </Drawer>
    );
};

SidebarDrawer.displayName = 'SidebarDrawer';
