import { SchedulingPlan } from '@/api/services';
import { useHumanizedSchedulingPlan } from '@/common/hooks/schedulingPlan/useHumanizedSchedulingPlan';

interface Props {
    frequency: SchedulingPlan;
}

export function FrequencyMenuItemText(props: Props) {
    const { frequency } = props;
    const humanizedSchedulingPlan = useHumanizedSchedulingPlan(frequency);

    return <>{humanizedSchedulingPlan}</>;
}
