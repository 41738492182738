import { useAccountId } from '@/atoms/session';
import { useFetchDefaultBillingLocation } from '@/common/hooks/query/useQueryDefaultBillingLocation';
import { PageHeader } from '@/components/PageHeader';
import { PageWrapper } from '@/components/PageWrapper';
import { Alert, Stack } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { AccountSummary } from '../AccountOverview/widgets/AccountSummary/AccountSummary';
import { InvoiceList } from './InvoiceList';
import { InvoiceTotal } from './InvoiceTotal';
import { resetPayInvoicesState } from './store';
import { useTexts } from './useTexts';

export function PayInvoicesPage() {
    const texts = useTexts();
    const accountId = useAccountId();
    const { data: billingLocation } = useFetchDefaultBillingLocation(accountId || '');

    const hasLocationErrors = useMemo(() => {
        return !!billingLocation?.validationErrors;
    }, [billingLocation?.validationErrors]);

    useEffect(() => {
        return () => {
            resetPayInvoicesState();
        };
    }, []);

    return (
        <PageWrapper>
            <Stack direction="column" spacing={2}>
                <PageHeader title={texts.pageTitle} description={texts.pageDescription} />

                {!!accountId && (
                    <>
                        <AccountSummary accountId={accountId} hidePrimaryAction />
                        <InvoiceList accountId={accountId} disableSelection={hasLocationErrors} />
                        <InvoiceTotal disableSelection={hasLocationErrors} />
                        {hasLocationErrors && <Alert severity="error">{texts.locationHasErrors}</Alert>}
                    </>
                )}
            </Stack>
        </PageWrapper>
    );
}
