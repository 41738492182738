import { CultureCode } from './localization';

export enum CurrencyCode {
    AUD = 'AUD',
    CAD = 'CAD',
    GBP = 'GBP',
    EUR = 'EUR',
    USD = 'USD',
    NZD = 'NZD',
}

export const DefaultCurrencyCode: Record<CultureCode, CurrencyCode> = {
    [CultureCode.en_AU]: CurrencyCode.AUD,
    [CultureCode.en_CA]: CurrencyCode.CAD,
    [CultureCode.en_GB]: CurrencyCode.GBP,
    [CultureCode.en_IE]: CurrencyCode.EUR,
    [CultureCode.en_US]: CurrencyCode.USD,
    [CultureCode.en_NZ]: CurrencyCode.NZD,
};
