export enum PaymentAccountTypes {
    ACH = 'ACH',
    CREDIT_CARD = 'CreditCard',
}

export enum PaymentStatus {
    CANCELLED = 'Cancelled',
    FAILED = 'Failed',
    PARTIALLY_REFUNDED = 'PartiallyRefunded',
    PENDING = 'Pending',
    REFUNDED = 'Refunded',
    SUCCEEDED = 'Succeeded',
}

export enum HostedPaymentStatus {
    SUCCESS = 'Success',
    CANCELLED = 'Cancelled',
}

export enum PaymentSource {
    EPAY = 3,
    MOBILE = 2,
    NONE = 0,
    OFFICE = 1,
    PORTAL = 4,
}

export enum PaymentMethodMapping {
    ACH = 4,
    CASH = 1,
    CHECK = 2,
    CREDIT_CARD = 3,
    NONE = 0,
}

export enum TransactionSetupMethod {
    CreditCardSale = 1,
    PaymentAccountCreate = 7,
    PaymentAccountUpdate = 8,
}
export interface PaymentAccount {
    accountId: string;
    accountType: PaymentAccountTypes;
    businessUnitId: string;
    createdOn: string;
    ending: number;
    friendly: string;
    isAutoPayment: boolean;
    hasAutoPaymentInstance: boolean;
    isExpired: boolean;
    month: number;
    nameOnCard: string;
    paymentAccountId: string;
    processorPaymentAccountId: string;
    routingNumber: string;
    servicesId: string;
    year: number;
}

export interface AddCreditCardCallback {
    account: string | null;
    accountId: string;
    approvalNumber: string | null;
    approvedAmount: number;
    cardLogo: string;
    expirationMonth: string;
    expirationYear: string;
    expressResponseCode: null;
    expressResponseMessage: string;
    hostedPaymentStatus: HostedPaymentStatus;
    hostedPaymentTransaction: string | null;
    hostedPaymentTransactionId: string;
    hostResponseCode: string;
    hostResponseMessage: string | null;
    lastFour: string;
    paymentAccountId: string;
    tenantId: string;
    transactionId: string;
    processorTransactionLogId: string;
    partnerRefId: string;
}

export const isSuccessfulCreditCardCallback = (response: unknown): response is AddCreditCardCallback => {
    const r = response as AddCreditCardCallback;
    return r.hostedPaymentStatus == HostedPaymentStatus.SUCCESS;
};

export const isCancelledCreditCardCallback = (response: unknown): response is AddCreditCardCallback => {
    const r = response as AddCreditCardCallback;
    return r.hostedPaymentStatus == HostedPaymentStatus.CANCELLED;
};

export interface AddCreditCardPaymentAccountInput {
    accountId: string;
    businessUnitId: string;
    cardType: string;
    lastFour: string;
    month: string;
    processorPaymentAccountId: string;
    year: string;
}

export interface AddCreditCardPaymentAccountResponse {
    accountId: string;
    businessUnitId: string;
    cardType: string;
    friendly: string;
    lastFour: string;
    month: string;
    nameOnCard: string;
    processorPaymentAccountId: string;
    securityCode: string;
    year: string;
}

export interface AddAchPaymentAccountInput {
    accountId: string;
    accountNumber: string;
    businessUnitId: string;
    processorPaymentAccountId: string;
    routingNumber: string;
}

export interface AddAchPaymentAccountResponse {
    accountId: string;
    businessUnitId: string;
    cardType: string;
    friendly: string;
    lastFour: string;
    month: string;
    nameOnCard: string;
    processorPaymentAccountId: string;
    securityCode: string;
    year: string;
}

export interface ProcessPaymentInput {
    accountId: string;
    accountType?: string;
    agreementPaymentLinks?: string[];
    amount: number;
    businessUnitId: string;
    emailReceiptTo?: string[];
    employeeId?: string;
    friendlyValue: string;
    invoicePayments: InvoicePayment[];
    isBulkPayment?: boolean;
    nameOnCard?: string;
    partnerRefId?: string;
    paymentAccountId?: string;
    paymentDate?: Date;
    paymentId?: string;
    paymentMethod: PaymentMethodMapping;
    paymentReference?: string;
    paymentSource: PaymentSource;
    paymentStatus?: PaymentStatus;
    processorTransactionId?: string;
    startingBalancePayment?: number;
    submittedOnDateTime?: Date;
    transactionId?: string;
    transactionReferenceNumber?: string;
    workOrderId?: string;
}

export interface InvoicePayment {
    amount: number;
    invoiceId: string;
    paymentDate: Date;
    paymentStatus?: PaymentStatus;
}
export interface ChargePaymentAccountInput {
    accountId: string;
    amount: number;
    businessUnitId: string;
    employeeId: string;
    invoicePayments: InvoicePayment[];
    startingBalancePayment: number;
    paymentAccountId: string;
    paymentId?: string;
}
