import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    title: {
        fontWeight: 'bold',
    },
});

interface Props {
    title: string;
    value: string | React.ReactNode;
}

export const SummaryValue = (props: Props) => {
    const classes = useStyles();
    const { title, value } = props;

    return (
        <Box display="flex" width={1}>
            <Box display="flex" flexGrow={1}>
                <Typography className={classes.title}>{title}</Typography>
            </Box>
            <Box>
                <Typography>{value}</Typography>
            </Box>
        </Box>
    );
};

SummaryValue.displayName = 'SummaryValue';
