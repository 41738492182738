import type { FormField, FormError, FormMeta, FormApi } from '../types';
import { useEffect, useState } from 'react';

import { useFormInternalContext } from '../utils/formContext';

export type UseFormMeta<FIELDS extends Record<string, FormField>, ERROR extends FormError<keyof FIELDS>> = {
    meta: Readonly<FormMeta>;
    api: Readonly<FormApi<FIELDS, ERROR>>;
};

/**
 * React Hook connecting to a Form Meta state and exposing its API
 * @returns [formMeta, formApi]
 */
export function useFormMeta<
    FIELDS extends Record<string, FormField>,
    ERROR extends FormError<keyof FIELDS>,
>(): UseFormMeta<FIELDS, ERROR> {
    const { api } = useFormInternalContext<FIELDS, ERROR>();

    type State = ReturnType<typeof api.getMeta>;
    const [meta, setState] = useState<State>(() => {
        return api.getMeta();
    });

    useEffect(() => {
        let effectCleared = false;

        const unsubscribe = api.subscribeToMeta(meta => {
            if (effectCleared) return;

            setState(meta);
        });

        return () => {
            effectCleared = true;
            unsubscribe();
        };
    }, [api]);

    return { meta, api };
}
