import { Button, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { createQuestionOrComment } from '@/api/questionsAndComments';
import { useTexts } from '../useTexts';
import { resetQuestionCommentState, useSelectQuestionCommentState } from '../store';

export function FormFooter() {
    const texts = useTexts();
    const { comment, serviceLocationId } = useSelectQuestionCommentState();

    const saveDisabled = useMemo(() => {
        return !comment || !serviceLocationId;
    }, [comment, serviceLocationId]);

    const onSave = useCallback(async () => {
        try {
            await createQuestionOrComment(serviceLocationId, comment);
            toast.success(texts.successToast);
            resetQuestionCommentState();
        } catch {
            toast.error(texts.error);
        }
    }, [comment, serviceLocationId, texts]);

    return (
        <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="outlined" onClick={resetQuestionCommentState}>
                {texts.clear}
            </Button>
            <Button color="primary" disabled={saveDisabled} onClick={onSave} variant="contained">
                {texts.send}
            </Button>
        </Stack>
    );
}
