export function isCardExpired(mm: number, yy: number) {
    const today = new Date();

    const expirationDate = new Date();
    expirationDate.setFullYear(Number(20 + yy), mm, 0);

    if (expirationDate < today) {
        return true;
    }

    return false;
}
