import { ServiceOffering } from '@/api/services';
import { Box, Checkbox, Typography } from '@mui/material';
import { addService, removeService, useIsServiceSelected } from '../store';
interface Props {
    service: ServiceOffering;
}

export const ServiceCheckbox = (props: Props) => {
    const { service } = props;
    const isSelected = useIsServiceSelected(service);

    const handleClick = () => {
        isSelected ? removeService(service) : addService(service);
    };

    return (
        <Box display="flex" alignItems="center">
            <Box display="flex" flexGrow={1} flexDirection="column" onClick={handleClick} style={{ cursor: 'pointer' }}>
                <Typography variant="h5">
                    <b>{service.serviceName}</b>
                </Typography>
                <Typography>{service.serviceDescription}</Typography>
            </Box>
            <Box>
                <Checkbox checked={isSelected} color="primary" onChange={handleClick} />
            </Box>
        </Box>
    );
};
