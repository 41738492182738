import { post } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';

const baseUrl = '/api/QuestionsAndComments';

export const createQuestionOrComment = async (serviceLocationId: string, questionOrComment: string) => {
    try {
        const { data, status } = await post<string>(`${baseUrl}`, {
            serviceLocationId: serviceLocationId,
            questionOrComment: questionOrComment,
        });

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to submit question or comment.`, status);
    } catch (e) {
        throw e;
    }
};
