import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getUser, login as loginAction, setAccountId } from '@/atoms/session';
import { useToast } from '@/utils/useToast';
import { useFormApi } from '@/formState';
import { fetchAccountsByContactId } from '@/services';
import { useFetchCustomerPortalSettings } from '@/common/hooks/query/useFetchCustomerPortalSettings';
import { FormErrors, FormFields } from '../form/formFields';
import { setAccountSelect, setError } from '../store';
import { useTexts } from './useTexts';

export function useLoginActions() {
    const toast = useToast();
    const navigate = useNavigate();
    const api = useFormApi<FormFields, FormErrors>();
    const texts = useTexts();
    const [selectedBusinessUnitId, setSelectedBusinessUnitId] = useState<string>('');
    const { refetch } = useFetchCustomerPortalSettings(selectedBusinessUnitId);

    // --------------------------------------
    // Mounted state ref for async callbacks

    const unmounted = useRef(false);
    useEffect(() => () => void (unmounted.current = true), []);

    // --------------------------------------
    // callbacks
    const afterLoginRedirect = useCallback(() => {
        navigate('/account-overview');
        document.body.scrollIntoView();
    }, [navigate]);

    const notifyError = useCallback((message: string) => toast.error(message), [toast]);

    const handleOnAccountSelect = useCallback(
        async (account: Account.AccountSelect) => {
            if (!!account) {
                setAccountId(account);
                setSelectedBusinessUnitId(account.businessUnitId);
                refetch();
                afterLoginRedirect();
            }
        },
        [afterLoginRedirect, refetch],
    );

    const login = useCallback(async () => {
        try {
            if (!unmounted.current) {
                setError('');
            }
            api.setSubmitting(true);

            const { email, password } = api.getValues();
            const loginRequest = await loginAction(email, password);

            if (loginRequest === true) {
                const user = getUser();
                if (!!user) {
                    const accounts = await fetchAccountsByContactId(user.contactId);

                    if (accounts?.length == 1) {
                        handleOnAccountSelect(accounts[0]);
                    } else if (!!accounts?.length && accounts.length > 1) {
                        setAccountSelect(accounts);
                    } else {
                        if (!unmounted.current) {
                            setError('');
                        }
                    }
                }
                return true;
            } else {
                setError(texts.loginFailed);
                console.log(`Error during login`);
                return false;
            }
        } catch (e) {
            return false;
        } finally {
            api.setSubmitting(false);
        }
    }, [api, texts, handleOnAccountSelect]);

    const handleOnForgotPasswordClick = useCallback(() => {
        navigate('/forgot-password');
    }, [navigate]);

    return {
        login,
        notifyError,
        afterLoginRedirect,
        handleOnAccountSelect,
        handleOnForgotPasswordClick,
    } as const;
}
