import { useHumanizedSchedulingPlan } from '@/common/hooks/schedulingPlan/useHumanizedSchedulingPlan';
import { LongDate } from '@/components/localization';
import Typography from '@mui/material/Typography';
import * as _ from 'lodash';
import { ServiceDetails as ServiceDetailsModel } from '../SetServiceDetails/store';

interface Props {
    details: ServiceDetailsModel;
}

export function ServiceDetails(props: Props) {
    const { details } = props;
    const humanizedSchedulingPlan = useHumanizedSchedulingPlan(details.schedulingPlan);

    return (
        <>
            {details.date && (
                <Typography>
                    <LongDate value={details.date} />
                    {details.timeFrame && <span> &bull; {_.startCase(_.camelCase(details.timeFrame))}</span>}
                </Typography>
            )}
            {details.schedulingPlan && <Typography>{humanizedSchedulingPlan}</Typography>}
            {details.propertyTraits && (
                <>
                    {details.propertyTraits.map((p, i) => (
                        <Typography key={i} display="inline">{`${p.amount} ${p.unitOfMeasure}${
                            i == details.propertyTraits.length - 1 ? '' : ', '
                        }`}</Typography>
                    ))}
                </>
            )}
        </>
    );
}
