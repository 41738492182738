import { CommitmentWindowCustomerResponse } from '@/api/workOrderDetails';
import { Box, Button, Stack } from '@mui/material';
import { useTexts } from './useTexts';

interface Props {
    commitmentWindowCustomerResponse: CommitmentWindowCustomerResponse;
    onConfirm: () => void;
    onDecline: () => void;
}

export function ConfirmationActions(props: Props) {
    const { commitmentWindowCustomerResponse, onConfirm, onDecline } = props;
    const texts = useTexts();

    return (
        <Stack spacing={1}>
            {commitmentWindowCustomerResponse !== CommitmentWindowCustomerResponse.DECLINED && (
                <Box>
                    <Button color="error" onClick={onDecline} variant="outlined">
                        {texts.declineVisit}
                    </Button>
                </Box>
            )}
            {commitmentWindowCustomerResponse !== CommitmentWindowCustomerResponse.CONFIRMED && (
                <Box>
                    <Button onClick={onConfirm} variant="contained">
                        {texts.confirmVisit}
                    </Button>
                </Box>
            )}
        </Stack>
    );
}
