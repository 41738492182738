import { getAccount } from '@/atoms/session/store';
import { emptyGuid } from '@/common/constants';
import { useFetchCustomerPortalSettings } from '@/common/hooks/query/useFetchCustomerPortalSettings';
import { useMemo } from 'react';

export const useCompanyLogoExists = () => {
    const account = getAccount();
    const { data: settings } = useFetchCustomerPortalSettings(account?.businessUnitId || '');

    return useMemo(() => {
        return !!settings?.companyLogoImageId && settings?.companyLogoImageId != emptyGuid;
    }, [settings?.companyLogoImageId]);
};
