import { createTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { palette } from './palette';
import { components } from './components';
import { typography } from './typography';

declare module '@mui/styles' {
    interface DefaultTheme extends Theme {}
}

export const theme = createTheme({
    components,
    palette,
    typography,
    zIndex: {
        appBar: 1100,
        drawer: 1200,
    },
});
