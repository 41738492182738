import { get } from '@/utils/http';
import { openReport } from '@/utils/openReport';
import { ServerError } from '@/utils/ServerError';

export interface WorkOrderDetailsResource {
    resourceId: string;
    resourceType: string;
    name: string;
    color: string;
    imageId: string;
    isCustomImage: boolean;
}

export interface WorkOrderDetailsServiceLocation {
    name?: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    stateAbbreviation: string;
    postalCode: string;
    country: string;
    countryCode: string;
}

export interface WorkOrderDetailsService {
    name: string;
    products: string[];
}

export enum CommitmentWindowCustomerResponse {
    CONFIRMED = 'Confirmed',
    DECLINED = 'Declined',
    UNKNOWN = 'Unknown',
}

export enum WorkOrderStatus {
    NOT_STARTED = 'NotStarted',
    IN_PROGRESS = 'InProgress',
    COMPLETED = 'Completed',
    CANCELLED = 'Cancelled',
}

export interface WorkOrderDetails {
    actualCompletedTime: Date | null;
    actualStartTime: Date | null;
    commitmentWindowConfirmationRequested: boolean;
    commitmentWindowCustomerResponse: CommitmentWindowCustomerResponse;
    completedByResource: WorkOrderDetailsResource;
    endOfCommitmentWindow: Date | null;
    estimatedCompletedTime: Date | null;
    estimatedStartTime: Date | null;
    startingEligibleDate: Date | null;
    endingEligibleDate: Date | null;
    number: string;
    productSubTotal: number;
    projectSequence: number | null;
    resource: WorkOrderDetailsResource | null;
    serviceLocation: WorkOrderDetailsServiceLocation;
    services: WorkOrderDetailsService[];
    serviceSubTotal: number;
    startOfCommitmentWindow: Date | null;
    status: string;
    subNumber: number | null;
    subTotal: number;
    totals: {
        discountTotal: number;
        productAfterServiceDiscount: number;
        productDiscount: number;
        productSubTotal: number;
        productTaxableSubtotal: number;
        roundedTax: number;
        serviceSubTotal: number;
        serviceTaxableSubtotal: number;
        subTotal: number;
        taxableProductAfterServiceDiscount: number;
        taxableServiceAfterServiceDiscount: number;
    };
    workOrderId: string;
}

const baseUrl = '/api/WorkOrders';

export const getWorkOrderDetails = async (workOrderId: string) => {
    try {
        const { data, status } = await get<WorkOrderDetails>(`${baseUrl}/${workOrderId}/details`);

        if (status === 200) {
            return data;
        }

        throw new ServerError(`Failed to get work order details.`, status);
    } catch (e) {
        throw e;
    }
};

export const getServiceReport = async (workOrderId: string) => {
    await openReport(`${baseUrl}/${workOrderId}/report`);
};
