import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
interface Props {
    primary: string;
    secondary?: string | number | JSX.Element;
}

const useStyles = makeStyles({
    primary: {
        fontWeight: 'bold',
    },
});

export const DefinitionListItem = (props: Props) => {
    const { primary, secondary } = props;
    const classes = useStyles();

    return (
        <Box>
            <Typography className={classes.primary}>{primary}</Typography>
            {!!secondary && <Box typography="body1">{secondary}</Box>}
        </Box>
    );
};
