import { emptyGuid } from '@/common/constants';
import { get } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';

const baseUrl = '/api/Image';

export const getLogoUrl = async (imageId: string | undefined) => {
    try {
        if (!imageId || imageId === emptyGuid) {
            return '';
        }
        const response = await get<string>(`${baseUrl}/${imageId}`, {
            responseType: 'arraybuffer',
        });
        if (response.status === 200) {
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            return URL.createObjectURL(blob);
        }

        throw new ServerError(`Failed to get logo.`, response.status);
    } catch (e) {
        throw e;
    }
};
