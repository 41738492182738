import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/pro-regular-svg-icons';
import { Currency } from '@/components/localization';
import { DesktopListCard } from '@/components/cardList/DesktopListCard';
import { MobileListCard } from '@/components/cardList/MobileListCard';
import { useGetUILayout } from '@/atoms/UILayout';
import { Box, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTexts } from './useTexts';
import { selectUnappliedStartingBalance, unSelectUnappliedStartingBalance, usePayInvoicesState } from './store';

function Title() {
    const texts = useTexts();

    return (
        <Box display="flex" flexDirection="row">
            <Typography noWrap>{texts.unappliedStartingBalance}</Typography>
        </Box>
    );
}

interface Props {
    balance: number;
}

export function UnappliedStartingBalanceCard(props: Props) {
    const { balance } = props;
    const texts = useTexts();
    const layout = useGetUILayout();

    const { startingBalancePayment } = usePayInvoicesState();

    const isSelected = useMemo(() => startingBalancePayment > 0, [startingBalancePayment]);

    const handleClick = useCallback(() => {
        isSelected ? unSelectUnappliedStartingBalance() : selectUnappliedStartingBalance(balance);
    }, [isSelected, balance]);

    return (
        <>
            {layout == 'desktop' ? (
                <DesktopListCard
                    actionBtnText={isSelected ? texts.deselect : texts.select}
                    icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
                    onClick={handleClick}
                    selected={isSelected}
                    subTitle={<Currency value={balance} />}
                    title={<Title />}
                />
            ) : (
                <MobileListCard
                    actionBtnText={isSelected ? texts.deselect : texts.select}
                    icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
                    onClick={handleClick}
                    selected={isSelected}
                    subTitle={<Currency value={balance} />}
                    title={<Title />}
                />
            )}
        </>
    );
}
