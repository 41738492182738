import { SearchBox } from '@/components/SearchBox/SearchBox';
import { Box } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useRequestServiceState } from '../../../store';
import { setLocation, setSearchQuery, useSelectLocationState } from '../store';

export const LocationSearch = () => {
    const { searchQuery } = useSelectLocationState();
    const request = useRequestServiceState();

    useEffect(() => {
        setLocation(request.serviceLocation);
    }, [request.serviceLocation]);

    const onChangeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    }, []);

    const clearSearch = useCallback(() => {
        setSearchQuery('');
    }, []);

    return (
        <Box p={2}>
            <SearchBox onChange={onChangeSearch} onClear={clearSearch} value={searchQuery} />
        </Box>
    );
};
