import type { FormFields } from './formFields';
import type { FormError } from '@/formState';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormMeta } from '@/formState';
import { useCallback, MouseEvent } from 'react';
import { useTexts } from '../useTexts';

interface Props {
    handleOnSubmit: () => Promise<void>;
}

export function Footer(props: Props) {
    const { handleOnSubmit } = props;
    const { meta } = useFormMeta<FormFields, FormError<keyof FormFields>>();
    const texts = useTexts();

    const handleOnClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            handleOnSubmit();
        },
        [handleOnSubmit],
    );

    return (
        <Box width="100%">
            {meta.validating && <LinearProgress />}

            <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleOnClick}
                disabled={meta.submitting || meta.status !== 'valid'}
                size="small"
                type="submit"
                data-testid="btn-register"
            >
                {meta.submitting && <CircularProgress size={16} />}
                <Box ml={1}>{texts.createAccount}</Box>
            </Button>
        </Box>
    );
}
