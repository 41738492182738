import { mobileAppbarHeight } from '@/common/constants';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu';

import { useMobileGreeting } from '@/utils/hooks/useMobileGreeting';
import { SidebarDrawer } from '../sidebar/SidebarDrawer';
import { toggleSidebar } from '../sidebar/store';
import { Avatar } from './Avatar';

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.white,
        height: `${mobileAppbarHeight}px`,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export function AppHeaderMobile() {
    const classes = useStyles();
    const greeting = useMobileGreeting();

    return (
        <>
            <AppBar className={classes.root} position="fixed">
                <Toolbar>
                    <IconButton
                        className={classes.menuButton}
                        data-testid="btn-toggle-sidebar"
                        edge="start"
                        onClick={toggleSidebar}
                        size="large"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography className={classes.title} variant="h6" noWrap>
                        {greeting}
                    </Typography>
                    <Avatar />
                </Toolbar>
            </AppBar>
            <SidebarDrawer />
        </>
    );
}
