import { Box } from '@mui/material';

interface Props {
    content: React.ReactNode;
}

export const Section = (props: Props) => {
    const { content } = props;
    return <Box p={2}>{content}</Box>;
};

Section.displayName = 'Section';
