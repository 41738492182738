import {
    HostedPaymentStatus,
    PaymentMethodMapping,
    PaymentSource,
    PaymentStatus,
    TransactionSetupMethod,
} from '@/common/types/payments';
import { useToast } from '@/utils/useToast';
import { Alert, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { Currency } from '@/components/localization';
import { useQueryPaymentAccounts } from '@/common/hooks/query/useQueryPaymentAccounts';
import { useAccountId } from '@/atoms/session';
import { processPayment } from '@/api/payments';
import { getAccount, getUser } from '@/atoms/session/store';
import { isServerError } from '@/utils/ServerError';
import { useFetchOpenInvoices } from '@/common/hooks/query/useFetchOpenInvoices';
import { useFetchAccountSummary } from '@/common/hooks/query/useFetchAccountSummary';
import { useFetchUnappliedStartingBalance } from '@/common/hooks/query/useFetchUnappliedStartingBalance';
import { CreditCardEntry } from '../../PaymentMethods/drawers/AddPaymentMethod/CreditCardEntry';
import { queryClient } from '../../../services/queryClient';
import {
    InvoiceWizardStep,
    resetPayInvoicesState,
    setPaymentMethod,
    setWizardStep,
    usePaymentTotal,
    usePayInvoicesState,
} from '../store';
import { useTexts } from './useTexts';

interface Props {
    onClose: () => void;
}

export function AddNewCreditCard(props: Props) {
    const { onClose } = props;
    const accountId = useAccountId();
    const user = getUser();
    const account = getAccount();
    const [selection, setSelection] = useState<TransactionSetupMethod>(TransactionSetupMethod.PaymentAccountCreate);
    const toast = useToast();
    const total = usePaymentTotal();
    const { addCard: texts } = useTexts();
    const { refetch } = useQueryPaymentAccounts(accountId || '');
    const { invoicesSelected, startingBalancePayment } = usePayInvoicesState();
    const {
        invoicePagination: { page, take },
    } = usePayInvoicesState();
    const { refetch: refetchOpenInvoices } = useFetchOpenInvoices(accountId || '', page, take);
    const { refetch: refetchAccountSummary } = useFetchAccountSummary(accountId || '');
    const { refetch: refetchUnappliedStartingBalance } = useFetchUnappliedStartingBalance(accountId || '');

    const handleSelection = (event: React.ChangeEvent<{ value: unknown }>) => {
        const value = event.target.value as TransactionSetupMethod;
        setSelection(value);
    };

    const handleCancel = useCallback(() => {
        setWizardStep(InvoiceWizardStep.SelectSavedPaymentMethod);
    }, []);

    const onOneTimePaymentSuccess = useCallback(
        async (
            _paymentAccountId: string,
            paymentStatus: HostedPaymentStatus,
            friendlyValue: string,
            transactionId: string,
            transactionReferenceNumber?: string,
            processorTransactionLogId?: string,
            partnerRefId?: string,
        ) => {
            if (!account || !user) return;

            try {
                const response = await processPayment({
                    accountId: account.accountId,
                    amount: total,
                    businessUnitId: account.businessUnitId,
                    employeeId: user.identityUserId,
                    friendlyValue: friendlyValue,
                    invoicePayments: invoicesSelected.map(i => ({
                        amount: i.balance,
                        invoiceId: i.invoiceId,
                        paymentDate: new Date(),
                    })),
                    paymentMethod: PaymentMethodMapping.CREDIT_CARD,
                    paymentSource: PaymentSource.PORTAL,
                    paymentStatus:
                        paymentStatus == HostedPaymentStatus.SUCCESS
                            ? PaymentStatus.SUCCEEDED
                            : PaymentStatus.CANCELLED,
                    processorTransactionId: processorTransactionLogId,
                    startingBalancePayment: startingBalancePayment || 0,
                    transactionId: transactionId,
                    transactionReferenceNumber: transactionReferenceNumber,
                    partnerRefId: partnerRefId,
                });

                if (isServerError(response)) {
                    toast.error(texts.paymentFailed);
                }

                toast.success(texts.paymentSuccessful);
                queryClient.invalidateQueries(['account-summary', 'open-invoices', 'unapplied-starting-balance']);
                await Promise.all([refetchOpenInvoices(), refetchAccountSummary(), refetchUnappliedStartingBalance()]);
                resetPayInvoicesState();
                onClose();
            } catch (e) {
                toast.error(texts.paymentFailed);
            }
        },
        [
            account,
            onClose,
            refetchAccountSummary,
            refetchOpenInvoices,
            refetchUnappliedStartingBalance,
            startingBalancePayment,
            invoicesSelected,
            texts,
            toast,
            total,
            user,
        ],
    );

    const onSaveCreditCardSuccess = useCallback(
        async (processorPaymentAccountId: string) => {
            try {
                const response = await refetch();

                if (!!response.data && response.data.length) {
                    const newPaymentMethod = response.data.find(
                        p => p.processorPaymentAccountId == processorPaymentAccountId,
                    );
                    if (newPaymentMethod) {
                        setPaymentMethod(newPaymentMethod);
                    }
                }
                setWizardStep(InvoiceWizardStep.SelectSavedPaymentMethod);
            } catch {
                setWizardStep(InvoiceWizardStep.SelectSavedPaymentMethod);
            }
        },
        [refetch],
    );

    return (
        <>
            <Typography>{texts.prompt}</Typography>
            <FormControl component="fieldset">
                <RadioGroup onChange={handleSelection} value={selection}>
                    <FormControlLabel
                        checked={selection == TransactionSetupMethod.PaymentAccountCreate}
                        control={<Radio />}
                        label={texts.yesOption}
                        value={TransactionSetupMethod.PaymentAccountCreate}
                    />
                    <FormControlLabel
                        checked={selection == TransactionSetupMethod.CreditCardSale}
                        control={<Radio />}
                        label={texts.noOption}
                        value={TransactionSetupMethod.CreditCardSale}
                    />
                </RadioGroup>
            </FormControl>

            {selection == TransactionSetupMethod.CreditCardSale && (
                <>
                    <Alert severity="info">
                        {texts.infoBox} <Currency value={total} /> {texts.infoBoxPayment}
                    </Alert>
                    <CreditCardEntry
                        amount={total}
                        onCancel={handleCancel}
                        onSuccess={onOneTimePaymentSuccess}
                        transactionSetupMethod={TransactionSetupMethod.CreditCardSale}
                    />
                </>
            )}

            {selection == TransactionSetupMethod.PaymentAccountCreate && (
                <CreditCardEntry
                    onCancel={handleCancel}
                    onSuccess={onSaveCreditCardSuccess}
                    transactionSetupMethod={TransactionSetupMethod.PaymentAccountCreate}
                />
            )}
        </>
    );
}
