import { InvoiceDetail } from '@/api/invoices';
import { DefinitionListItem } from '@/components/DefinitionListItem';
import { Currency, ShortDate } from '@/components/localization';
import { Typography } from '@mui/material';
import { useTexts } from '../../useTexts';

interface Props {
    invoiceDetails: InvoiceDetail;
}

export function Payments(props: Props) {
    const texts = useTexts();

    const {
        invoiceDetails: { payments },
    } = props;

    return (
        <DefinitionListItem
            primary={texts.payments}
            secondary={
                !!payments && payments.length > 0 ? (
                    <>
                        {payments.map((p, i) => (
                            <div key={i}>
                                <div>
                                    <Currency value={p.amount} /> {`${texts.paymentMadeOn} `}
                                    <ShortDate value={p.paymentDate} />
                                </div>
                                {p.refunds.map((r, index) => (
                                    <Typography key={index}>
                                        &nbsp;&nbsp;{<Currency value={r.amount} />} of this payment was refunded on{' '}
                                        <ShortDate value={r.refundTime} />.
                                    </Typography>
                                ))}
                            </div>
                        ))}
                    </>
                ) : (
                    texts.none
                )
            }
        />
    );
}
