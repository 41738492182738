import { GetOpenInvoicesResponse } from '@/common/types/invoices';
import { get } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';
import { BillingLocation } from './billingLocations';

export interface AccountSummary {
    accountBalance: number;
    credits: number;
    openBalance: number;
    prePayments: number;
}

const baseUrl = '/api/accounts';

export const getBalance = async (accountId: string) => {
    try {
        const { data, status } = await get<number>(`${baseUrl}/balance?accountId=${accountId}`);

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get account balance.`, status);
    } catch (e) {
        throw e;
    }
};

export const getUnappliedStartingBalance = async (accountId: string) => {
    try {
        const { data, status } = await get<number>(`${baseUrl}/${accountId}/unappliedStartingBalance`);

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get unapplied starting balance.`, status);
    } catch (e) {
        throw e;
    }
};

export const getAccountSummary = async (accountId: string) => {
    try {
        const { data, status } = await get<AccountSummary>(`${baseUrl}/balanceBreakdown?accountId=${accountId}`);

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get account balance breakdown.`, status);
    } catch (e) {
        throw e;
    }
};

export const getDefaultBillingLocation = async (accountId: string) => {
    try {
        const { data, status } = await get<BillingLocation>(`${baseUrl}/${accountId}/defaultBillingLocation`);

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get default billing location.`, status);
    } catch (e) {
        throw e;
    }
};

export const getDefaultServiceLocationId = async (accountId: string) => {
    try {
        const { data, status } = await get<string>(`${baseUrl}/${accountId}/defaultServiceLocationId`);

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get default service location.`, status);
    } catch (e) {
        throw e;
    }
};

export const getOpenInvoices = async (accountId: string, page: number, take: number, skip: number) => {
    try {
        const { data, status } = await get<GetOpenInvoicesResponse>(
            `${baseUrl}/${accountId}/invoices?page=${page}&take=${take}&skip=${skip}`,
        );

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get open invoices.`, status);
    } catch (e) {
        throw e;
    }
};
