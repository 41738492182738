import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            agreements: translate({ id: `navigation.pages.agreements` }),
            accountHistory: translate({ id: `navigation.pages.accountHistory` }),
            accountOverview: translate({ id: `navigation.pages.accountOverview` }),
            makePayments: translate({ id: `navigation.pages.makePayments` }),
            manageAutopay: translate({ id: `navigation.pages.manageAutopay` }),
            payInvoices: translate({ id: `navigation.pages.payInvoices` }),
            paymentMethods: translate({ id: `navigation.pages.paymentMethods` }),
            questionsAndComments: translate({ id: `navigation.pages.questionsAndComments` }),
            requestService: translate({ id: `navigation.pages.requestService` }),
            upcomingServices: translate({ id: `navigation.pages.upcomingServices` }),
            viewPaymentHistory: translate({ id: `navigation.pages.viewPaymentHistory` }),
            yourRequests: translate({ id: 'navigation.pages.yourRequests' }),
            yourServices: translate({ id: 'navigation.pages.yourServices' }),
        };
    }, [translate]);
}
