import { Alert, Stack } from '@mui/material';
import { useAddServicesState } from '../store';
import { useTexts } from './useTexts';
import { ServiceCheckbox } from './ServiceCheckbox';

export function ServiceList() {
    const texts = useTexts();

    const {
        all: { items: services },
    } = useAddServicesState();

    return (
        <>
            {services.length == 0 && <Alert severity="info">{texts.noServices}</Alert>}

            {services.length > 0 && (
                <Stack direction="column" spacing={2}>
                    {services.map((service, i) => (
                        <ServiceCheckbox key={i} service={service} />
                    ))}
                </Stack>
            )}
        </>
    );
}
