import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useEffect, useState } from 'react';
import { useFormMeta, FormError, FormValidator } from '@/formState';
import { Section } from '@/components/Section';
import { SectionHeader } from '@/components/SectionHeader';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router';
import { TextField } from '@/formUI';
import { getEmailErrors } from '@/components/PasswordRequirements/utils';
import { useTexts } from '../useTexts';
import { useForgotPasswordActions } from '../useForgotPasswordActions';
import { Footer } from './Footer';
import { FormErrors, FormFields } from './formFields';

const useStyles = makeStyles({
    section: {
        width: '350px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
});

enum formStates {
    ForgotPassword,
    ResetRequested,
}

export const Form = () => {
    const texts = useTexts();
    const [formState, setFormState] = useState<formStates>(formStates.ForgotPassword);
    const { resetPassword } = useForgotPasswordActions();
    const { api, meta } = useFormMeta<FormFields, FormError<keyof FormFields>>();
    const classes = useStyles();
    const navigate = useNavigate();

    const handleOnResetPassword = useCallback(async () => {
        api.setSubmitting(true);
        const values = api.getValues();
        await resetPassword(values.email);
        setFormState(formStates.ResetRequested);
        api.setSubmitting(false);
    }, [api, resetPassword]);

    const validateForm: FormValidator<FormFields, FormErrors> = formApi => {
        const email = formApi.getField('email');

        const errors = getEmailErrors(email, texts.mustBeValidEmail);
        return errors;
    };

    useEffect(() => {
        validateForm;
        if (!api.getValidator('validEmail')) {
            api.addValidation('validEmail', validateForm);
        }
    });

    return (
        <Box className={classes.container}>
            <Section className={classes.section}>
                {formState === formStates.ForgotPassword && (
                    <form>
                        <SectionHeader title={texts.resetPassword} />
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography>{texts.enterEmail}</Typography>
                            </Grid>
                            <Grid item>
                                <TextField<'email', FormFields, FormErrors>
                                    name="email"
                                    textField={{
                                        type: 'email',
                                        fullWidth: true,
                                    }}
                                    validateOn="change"
                                    label={texts.emailLabel}
                                />
                            </Grid>
                            <Grid item>
                                <Footer
                                    handleOnSubmit={handleOnResetPassword}
                                    buttonText={texts.sendEmail}
                                    disabled={meta.status !== 'valid'}
                                />
                            </Grid>
                        </Grid>
                    </form>
                )}

                {formState === formStates.ResetRequested && (
                    <>
                        <SectionHeader title={texts.emailHasBeenSent} />
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography>{texts.resetRequested}</Typography>
                            </Grid>
                            <Grid item>
                                <Footer
                                    handleOnSubmit={async () => {
                                        navigate('/login');
                                    }}
                                    buttonText={texts.close}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Section>
        </Box>
    );
};
