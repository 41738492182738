import { useAccountId } from '@/atoms/session';
import { useFetchServiceLocations } from '@/common/hooks/query/useQueryServiceLocations';
import { ServiceLocationFilterChip } from '@/components/cardList/chips';
import { ClearFiltersButton } from '@/components/cardList/filters';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useListActions } from '../hooks';
import { useIsListFiltered, useListState } from '../store';

export function Chips() {
    const accountId = useAccountId();
    const { data: locations } = useFetchServiceLocations(accountId || '');
    const { serviceLocations } = useListState();
    const { handleResetFilters, handleDeleteServiceLocationFilter } = useListActions();
    const isListFiltered = useIsListFiltered();

    return (
        <>
            {!!locations?.length && (
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box flexGrow={1}>
                        <Stack direction="row-reverse" spacing={1} flexWrap="wrap" gap={1}>
                            {!!serviceLocations.length &&
                                serviceLocations.map(
                                    x =>
                                        !!x && (
                                            <ServiceLocationFilterChip
                                                key={x}
                                                id={x}
                                                onDelete={handleDeleteServiceLocationFilter(x, serviceLocations)}
                                                serviceLocations={locations}
                                            />
                                        ),
                                )}
                        </Stack>
                    </Box>

                    <Box ml={2} flexGrow={0}>
                        {isListFiltered && <ClearFiltersButton onClick={handleResetFilters} />}
                    </Box>
                </Box>
            )}
        </>
    );
}
