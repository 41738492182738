import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            deselect: translate({ id: 'global.deselect' }),
            invoice: translate({ id: 'global.invoice' }),
            invoiceTotal: {
                invoicePlural: translate({ id: 'sections.payInvoices.invoiceTotal.invoicePlural' }),
                invoiceSingular: translate({ id: 'sections.payInvoices.invoiceTotal.invoiceSingular' }),
                paymentTotalFor: translate({ id: 'sections.payInvoices.invoiceTotal.paymentTotalFor' }),
                selectPaymentMethod: translate({ id: 'sections.payInvoices.invoiceTotal.selectPaymentMethod' }),
            },
            locationHasErrors: translate({ id: 'sections.payInvoices.locationHasErrors' }),
            noInvoicesToDisplay: translate({ id: 'sections.payInvoices.list.noInvoicesToDisplay' }),
            notImplementedYet: translate({ id: 'global.notImplementedYet' }),
            open: translate({ id: 'global.open' }),
            pageDescription: translate({ id: 'sections.payInvoices.pageDescription' }),
            pageSubtitle: translate({ id: 'sections.payInvoices.pageSubtitle' }),
            pageTitle: translate({ id: 'sections.payInvoices.pageTitle' }),
            select: translate({ id: 'global.select' }),
            unappliedStartingBalance: translate({ id: 'sections.payInvoices.unappliedStartingBalance' }),
        };
    }, [translate]);
}
