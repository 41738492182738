import { ChargePaymentAccountInput, PaymentStatus, ProcessPaymentInput } from '@/common/types/payments';
import { get, post } from '@/utils/http';
import { prepareServerError, ServerError } from '@/utils/ServerError';
import { openReport } from '@/utils/openReport';

export interface PaymentRefund {
    amount: number;
    refundMethod: string;
    refundTime: string;
}

export interface PaymentDetail {
    paymentId: string;
    accountType: string;
    amount: number;
    associatedInvoiceNumbers: number[];
    balance: number;
    paymentDate: string;
    status: PaymentStatus;
    reference: string;
    refunds: PaymentRefund[];
}

export interface CreditCardPaymentResult {
    payment: PaymentDetail;
    failureReason: string;
}

const baseUrl = '/api/payments';

export const getPaymentDetails = async (paymentId: string) => {
    try {
        const { data, status } = await get<PaymentDetail>(`${baseUrl}/${paymentId}/BasicPaymentDetails`);

        if (status === 200) {
            return data;
        }

        throw new ServerError(`Failed to get payment details.`, status);
    } catch (e) {
        throw e;
    }
};

export const getPaymentReceipt = async (paymentId: string) => {
    await openReport(`${baseUrl}/${paymentId}/receipt`);
};

export const getRefundReceipt = async (paymentId: string) => {
    await openReport(`${baseUrl}/${paymentId}/refundreceipt`);
};

export const processPayment = async (input: ProcessPaymentInput) => {
    try {
        const { data, status } = await post<PaymentDetail>(baseUrl, input);

        if (status === 200) {
            return data;
        }

        throw new ServerError(`Failed to process payment.`, status);
    } catch (e) {
        throw e;
    }
};

export const chargePaymentAccount = async (input: ChargePaymentAccountInput) => {
    try {
        const { data, status } = await post<CreditCardPaymentResult>(
            `${baseUrl}/paymentAccount/${input.paymentAccountId}`,
            input,
        );

        if (status === 200) {
            return data;
        }

        throw new ServerError(`Failed to make payment.`, status);
    } catch (e) {
        throw prepareServerError(e);
    }
};
