import { useMemo } from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCardOutlined';
import { ACH, AmericanExpress, Discover, Mastercard, Visa } from '@/components/icons';

interface Props {
    accountName: string;
    size?: 'small' | 'medium' | 'large';
}

export function CardIcon(props: Props) {
    const { accountName, size } = props;

    const icon = useMemo(() => {
        if (accountName.startsWith('ACH')) {
            return <ACH fontSize={size ? size : 'small'} />;
        }

        if (accountName.startsWith('AmericanExpress') || accountName.startsWith('Amex')) {
            return <AmericanExpress fontSize={size ? size : 'large'} />;
        }

        if (accountName.startsWith('Discover')) {
            return <Discover fontSize={size ? size : 'large'} />;
        }

        if (accountName.startsWith('Mastercard')) {
            return <Mastercard fontSize={size ? size : 'large'} />;
        }

        if (accountName.startsWith('Visa')) {
            return <Visa fontSize={size ? size : 'large'} />;
        }

        return <CreditCardIcon />;
    }, [accountName, size]);

    return <>{icon}</>;
}
