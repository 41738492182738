import { useCallback, useEffect, useState } from 'react';
import { isServerError } from '@/utils/ServerError';
import { WorkOrderWidgetData, getLastServicePerformedWidget } from '@/api/workOrders';

interface ErrorData {
    error: Error;
    status: 'error';
}

interface LoadingData {
    status: 'loading';
}

interface SuccessData {
    payload: WorkOrderWidgetData | null;
    status: 'success';
}

type Data = ErrorData | LoadingData | SuccessData;

export const useData = (accountId: string) => {
    const [data, setData] = useState<Data>({ status: 'loading' });
    const [isStale, setIsStale] = useState<boolean>(true);

    useEffect(() => {
        let mounted = true;

        const getSettings = async (accountId: string) => {
            try {
                setData({ status: 'loading' });
                const response = await getLastServicePerformedWidget(accountId);

                if (isServerError(response)) {
                    throw new Error(response.message);
                }

                if (mounted && response != undefined) {
                    setData({ status: 'success', payload: response });
                }
            } catch (e) {
                if (mounted) {
                    const error = e instanceof Error ? e : new Error('unknown error');
                    setData({ status: 'error', error });
                }
            } finally {
                setIsStale(false);
            }
        };

        if (isStale && !!accountId) {
            getSettings(accountId);
        }

        return () => {
            mounted = false;
        };
    }, [accountId, isStale]);

    const refreshData = useCallback(async () => {
        setIsStale(true);
    }, []);

    useEffect(() => {
        refreshData();
    }, [accountId, refreshData]);

    return [data, refreshData] as const;
};
