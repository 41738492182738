import { ContentContainer } from '@/components/ContentContainer';
import { ContentPaper } from '@/components/ContentPaper';
import { FormProvider } from './form/FormProvider';

export const ResetPassword = () => {
    return (
        <ContentContainer>
            <ContentPaper fullWidth>
                <FormProvider />
            </ContentPaper>
        </ContentContainer>
    );
};
