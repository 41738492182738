import { get } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';

const baseUrl = '/api/settings';

export enum PaymentProcessors {
    Vantiv = 'Vantiv',
    OpenEdge = 'OpenEdge',
    WorkWave = 'WorkWave',
}

export const getBusinessUnitPaymentProcessor = async (businessUnitId: string) => {
    try {
        const { data, status } = await get<string>(`${baseUrl}/paymentProcessor?businessUnitId=${businessUnitId}`);

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get payment processor information.`, status);
    } catch (e) {
        throw e;
    }
};
