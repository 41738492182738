import { useDesktopGreeting } from '@/utils/hooks/useDesktopGreeting';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTexts } from './useTexts';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        fontSize: 16,
        fontWeight: 600,
    },
});

export function AppHeaderGreeting() {
    const classes = useStyles();
    const greetingText = useDesktopGreeting();
    const { greeting: texts } = useTexts();

    return (
        <Typography component="div" className={classes.root}>
            {`${greetingText} ${texts.tagLine}`}
        </Typography>
    );
}
