import axios from 'axios';
import { post, put, get } from '@/utils/http';
import { prepareServerError, ServerError } from '@/utils/ServerError';

export const requestResetPasswordLink = async (email: string, abortSignal?: AbortSignal) => {
    const source = axios.CancelToken.source();

    const handleOnAbort = () => source.cancel();
    abortSignal?.addEventListener('abort', handleOnAbort);
    const options = abortSignal
        ? { cancelToken: source.token, headers: { 'Content-Type': 'application/json' } }
        : { headers: { 'Content-Type': 'application/json' } };

    try {
        const response = await post('/api/customerPortalUsers/password', JSON.stringify(email), options);
        if (response.status === 200 || response.status === 201) {
            return response.data;
        }

        throw new Error(`${response.status} - ${response.statusText}`);
    } catch (e) {
        throw e;
    } finally {
        abortSignal?.removeEventListener('abort', handleOnAbort);
    }
};

export const resetPassword = async (linkHash: string, newPassword: string, abortSignal?: AbortSignal) => {
    const source = axios.CancelToken.source();

    const handleOnAbort = () => source.cancel();
    abortSignal?.addEventListener('abort', handleOnAbort);
    const options = abortSignal ? { cancelToken: source.token } : undefined;

    try {
        const response = await put(
            '/api/customerPortalUsers/password',
            { linkHash: linkHash, newPassword: newPassword },
            options,
        );
        if (response.status === 200 || response.status === 201) {
            return response.data;
        }

        throw new Error(`${response.status} - ${response.statusText}`);
    } catch (e) {
        throw e;
    } finally {
        abortSignal?.removeEventListener('abort', handleOnAbort);
    }
};

export const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
        const { data, status } = await put<boolean>(`api/customerPortalUsers`, {
            oldPassword: oldPassword,
            newPassword: newPassword,
        });

        if (status === 200) {
            return data;
        }

        throw new ServerError(data + '', status);
    } catch (e) {
        throw prepareServerError(e);
    }
};

export const validateLinkHash = async (linkHash: string) => {
    try {
        const { data, status } = await get<boolean>(`api/customerPortalUsers/resetPasswordLinkHash/${linkHash}/`);

        if (status === 200) {
            return data;
        }

        throw new ServerError(data + '', status);
    } catch (e) {
        throw prepareServerError(e);
    }
};
