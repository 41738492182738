import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/pro-regular-svg-icons';
import { ShortDate } from '@/components/localization';
import Box from '@mui/material/Box';
import { useDrawerState } from '@/common/hooks/useDrawerState';
import { SideDrawer } from '@/components/SideDrawer';
import { DesktopListCard } from '@/components/cardList/DesktopListCard';
import { useFormattedWorkOrderNumber } from '@/common/hooks/useFormattedWorkOrderNumber';
import { useGetUILayout } from '@/atoms/UILayout';
import { MobileListCard } from '@/components/cardList/MobileListCard';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo } from 'react';
import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { CustomerPortalLastServiceFields } from '@/common/types/services';
import { useTexts } from '../../../useTexts';
import { CardProps } from '../typings';
import { setSelectedItem, useListState } from '../../store';
import { useTexts as useDrawerTexts } from './drawer/useTexts';
import { DrawerContent } from './drawer/DrawerContent';

const Title = (props: { date: Date }) => {
    const { date } = props;
    const texts = useTexts();

    const enableLastServiceScheduledDate = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServiceScheduledDate,
    );

    return (
        <Box display="flex" flexDirection="row">
            <Typography noWrap>
                {enableLastServiceScheduledDate && <ShortDate value={date} />}
                {` ${texts.serviceVisit}`}
            </Typography>
        </Box>
    );
};

export const ServiceCard = (props: CardProps) => {
    const { item } = props;
    const texts = useDrawerTexts();
    const { selected } = useListState();
    const layout = useGetUILayout();

    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();
    const formattedWorkOrderNumber = useFormattedWorkOrderNumber(
        item.workOrderNumber?.toString() || '',
        item.workOrderProjectSequence,
        item.workOrderSubNumber,
    );

    const handleOpen = useCallback(() => {
        setSelectedItem(item.workOrderId!);
        openDrawer();
    }, [openDrawer, item.workOrderId]);

    const handleClose = useCallback(() => {
        setSelectedItem('');
        closeDrawer();
    }, [closeDrawer]);

    const enableLastServiceWorkOrderNumber = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServiceWorkOrderNumber,
    );

    const subTitle = useMemo(() => {
        return enableLastServiceWorkOrderNumber ? formattedWorkOrderNumber : '';
    }, [enableLastServiceWorkOrderNumber, formattedWorkOrderNumber]);

    return (
        <>
            {layout == 'desktop' ? (
                <DesktopListCard
                    icon={<FontAwesomeIcon icon={faTools} />}
                    onClick={handleOpen}
                    selected={item.workOrderId === selected}
                    subTitle={subTitle}
                    title={<Title date={item.date} />}
                />
            ) : (
                <MobileListCard
                    icon={<FontAwesomeIcon icon={faTools} />}
                    onClick={handleOpen}
                    selected={item.workOrderId === selected}
                    subTitle={subTitle}
                    title={<Title date={item.date} />}
                />
            )}

            <SideDrawer isOpen={drawerOpen} onClose={handleClose} title={texts.title}>
                <DrawerContent workOrderId={item.workOrderId || ''} />
            </SideDrawer>
        </>
    );
};
