import Button from '@mui/material/Button';
import { useCallback } from 'react';
import { Section } from '@/components/Section';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import { SectionHeader } from '@/components/SectionHeader';
import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { useBusinessUnitDetails } from '@/common/hooks/useBusinessUnitDetails';
import { PhoneNumber } from '@/components/localization/PhoneNumber';
import { useTexts } from './useTexts';

export function NoUpcomingServicesSection() {
    const texts = useTexts();
    const navigate = useNavigate();
    const requestServiceEnabled = useFeatureEnabled('enableRequestNewService');
    const details = useBusinessUnitDetails();

    const goToRequestServicePage = useCallback(
        (_event: React.MouseEvent<HTMLButtonElement>) => {
            navigate('/your-services/request-service');
        },
        [navigate],
    );

    return (
        <Stack spacing={2} direction="column">
            <Typography variant="h2">{texts.title}</Typography>
            <Section>
                <SectionHeader title={texts.emptyState_getStarted} />
                <Stack direction="column" spacing={2}>
                    {requestServiceEnabled && (
                        <>
                            <Typography>
                                {texts.emptyState_noUpcomingServicesText} {texts.emptyState_newRequestsText}
                            </Typography>
                            <Box>
                                <Button variant="contained" onClick={goToRequestServicePage} startIcon={<AddIcon />}>
                                    {texts.emptyState_requestService}
                                </Button>
                            </Box>
                        </>
                    )}
                    {!requestServiceEnabled && !!details && (
                        <Typography>
                            {`${texts.emptyState_noUpcomingServicesText} ${texts.emptyState_pleaseContact} ${
                                !!details && details.name
                            } ${texts.emptyState_toSchedule} `}
                            <PhoneNumber phone={details.phone} phoneExt={details.phoneExt} />
                        </Typography>
                    )}
                </Stack>
            </Section>
        </Stack>
    );
}
