import { AgreementDetails, AgreementStatus } from '@/common/types/agreements';
import { get } from '@/utils/http';
import { openReport } from '@/utils/openReport';
import { ServerError } from '@/utils/ServerError';

const baseUrl = '/api/Agreements';
const accountsBaseUrl = '/api/Accounts';

export interface AgreementsResponse {
    total: number;
    page: number;
    take: number;
    items: AgreementModel[];
}

export interface AgreementModel {
    agreementDate: Date;
    agreementId: string;
    hasAutoPayment: boolean;
    hasExpiredPaymentMethod: boolean;
    hasValidationErrors: boolean;
    name: string;
    status: AgreementStatus;
}

function getQueryString(page: number, take: number, serviceLocations: string[], status: AgreementStatus | null) {
    const params = new URLSearchParams({
        page: page.toString(),
        take: take.toString(),
    });

    if (!!serviceLocations.length) {
        serviceLocations.map(x => !!x && params.append('serviceLocation', x));
    }

    if (!!status) {
        params.append('status', status);
    }

    return params;
}

export const getAgreements = async (
    accountId: string,
    page: number,
    take: number,
    serviceLocations: string[],
    agreementStatus: AgreementStatus | null,
) => {
    try {
        const queryString = getQueryString(page, take, serviceLocations, agreementStatus);
        const { status, data } = await get<AgreementsResponse>(
            `${accountsBaseUrl}/${accountId}/agreements?${queryString}`,
        );

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get agreements.`, status);
    } catch (e) {
        throw e;
    }
};

export const getAgreementDetails = async (agreementId: string) => {
    try {
        const { data, status } = await get<AgreementDetails>(`${baseUrl}/${agreementId}/Details`);

        if (status === 200) {
            return { ...data, agreementId: agreementId };
        }

        throw new ServerError(`Failed to get Agreement details.`, status);
    } catch (e) {
        throw e;
    }
};

export const getAgreementReport = async (agreementId: string) => {
    await openReport(`${baseUrl}/${agreementId}/report`);
};
