import { getCustomAvatarUrl, getStockAvatarUrl } from '@/api/avatar';
import { useQuery } from 'react-query';

export const useFetchAvatarUrl = (imageId: string, isCustom: boolean) =>
    useQuery<string, Error>(
        ['avatarUrl', imageId, isCustom],
        () => (isCustom ? getCustomAvatarUrl(imageId) : getStockAvatarUrl(imageId)),
        {
            staleTime: Infinity,
        },
    );
