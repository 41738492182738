import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
    popover: {
        width: '100%',
        pointerEvents: 'none',
    },
    menu: {
        maxHeight: 300,
        overflowX: 'hidden',
        overflowY: 'scroll',
        pointerEvents: 'all',

        '& .MuiMenu-paper': {
            marginTop: 25,
            width: '100%',
            transitionDuration: '0s !important',
        },
    },
});
