import { get } from '@/utils/http';
import { prepareServerError, ServerError } from '@/utils/ServerError';

export async function fetchAccountsByContactId(contactId: string): Promise<Account.AccountSelect[]> {
    try {
        const { data, status } = await get<Account.AccountSelect[]>(`api/accounts?contactId=${contactId}`);

        if (status === 200) {
            return data;
        }

        throw new ServerError(`Failed to retrieve Accounts.`, status);
    } catch (e) {
        throw prepareServerError(e);
    }
}
