import { Typography } from '@mui/material';
import { AgreementContact as IAgreementContact } from '@/common/types/agreements';
import { MethodOfContact } from '@/common/types/contacts';
import { PhoneNumber } from '@/components/localization/PhoneNumber';
import { EmailLink } from '@/common/components/EmailLink';

interface Props {
    contact: IAgreementContact;
}

export function AgreementContact(props: Props) {
    const {
        contact: { email, firstName, lastName, other, phone, preferredMethodOfContact },
    } = props;

    return (
        <>
            <Typography>
                {firstName} {lastName}
            </Typography>

            <Typography>
                {preferredMethodOfContact == MethodOfContact.Email && !!email && <EmailLink email={email.email} />}

                {preferredMethodOfContact == MethodOfContact.Phone && !!phone && (
                    <PhoneNumber callingCode={phone.callingCode} phone={phone.number} phoneExt={phone.extension} />
                )}

                {preferredMethodOfContact == MethodOfContact.Other && !!other && (
                    <Typography>{other.contactValue}</Typography>
                )}
            </Typography>
        </>
    );
}
