import { useCallback } from 'react';
import {
    clearDates,
    clearServiceLocations,
    clearType,
    resetFilters,
    setEndDate,
    setPage,
    setSearch,
    setTake,
    setType,
    setServiceLocations,
    setStartDate,
} from '../store';

export function useListActions() {
    const handleClearDates = useCallback(() => {
        setPage(0);
        clearDates();
    }, []);

    const handleClearServiceLocations = useCallback(() => {
        setPage(0);
        clearServiceLocations();
    }, []);

    const handleClearType = useCallback(() => {
        setPage(0);
        clearType();
    }, []);

    const handleDeleteServiceLocationFilter = useCallback(
        (id: string, serviceLocations: string[]) => (_e: React.MouseEvent<HTMLElement>) => {
            setPage(0);
            setServiceLocations(serviceLocations.filter(x => x !== id));
        },
        [],
    );

    const handleResetFilters = useCallback(() => {
        setPage(0);
        resetFilters();
    }, []);

    const handleSetEndDate = useCallback((date: Date | null, _keyboardInputValue?: string | undefined) => {
        if (!date) {
            setEndDate(null);
        }
        if (date?.toString() == 'Invalid Date') {
            return;
        }
        if (!!date) {
            setPage(0);
            setEndDate(new Date(date.toDateString()));
        }
    }, []);

    const handleSetPage = useCallback((_e, page: number) => {
        setPage(page - 1);
    }, []);

    const handleSetSearch = useCallback((search: string) => {
        setPage(0);
        setSearch(search);
    }, []);

    const handleSetServiceLocations = useCallback((locations: string[]) => {
        setPage(0);
        setServiceLocations(locations);
    }, []);

    const handleSetTake = useCallback((take: number) => {
        setTake(take);
    }, []);

    const handleSetType = useCallback(e => {
        setPage(0);
        setType(e.target.value);
    }, []);

    const handleSetStartDate = useCallback((date: Date | null, _keyboardInputValue?: string | undefined) => {
        if (!date) {
            setStartDate(null);
        }
        if (date?.toString() == 'Invalid Date') {
            return;
        }
        if (!!date) {
            setPage(0);
            setStartDate(new Date(date.toDateString()));
        }
    }, []);

    return {
        handleClearDates,
        handleClearServiceLocations,
        handleClearType,
        handleDeleteServiceLocationFilter,
        handleResetFilters,
        handleSetEndDate,
        handleSetPage,
        handleSetSearch,
        handleSetServiceLocations,
        handleSetTake,
        handleSetType,
        handleSetStartDate,
    } as const;
}
