import { useBusinessUnitDetails } from '@/common/hooks/useBusinessUnitDetails';
import { useMobileGreeting } from './useMobileGreeting';
import { useTexts } from './useTexts';

export function useDesktopGreeting() {
    const greeting = useMobileGreeting();
    const { useGreeting: texts } = useTexts();

    const buDetails = useBusinessUnitDetails();
    const companyName = buDetails ? buDetails.name : '';

    return `${greeting} ${!!companyName ? `${texts.thankYou} ${companyName}.` : ''}`;
}
