import { ContentContainer } from '@/components/ContentContainer';
import { Box } from '@mui/material';
import { FormProvider } from './form/FormProvider';
import { useCheckSubdomain } from './hooks/useCheckSubdomain';

export const Login = () => {
    const status = useCheckSubdomain();

    return (
        <>
            {status === 'success' && (
                <ContentContainer>
                    <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                        <FormProvider />
                    </Box>
                </ContentContainer>
            )}
        </>
    );
};
