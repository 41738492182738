import { AgreementModel } from '@/api/agreements';
import { useDrawerState } from '@/common/hooks/useDrawerState';
import { CardList, ListBody, ListFooter } from '@/components/cardList';
import { useTexts } from '../useTexts';
import { AgreementsListHeader } from './header/AgreementsListHeader';
import { AgreementCard } from './cards';
import { useListState } from './store';
import { useListActions } from './hooks';
import { FiltersDrawer } from './filters/FiltersDrawer';

interface Props {
    items: AgreementModel[];
    total: number;
}

export function AgreementsList(props: Props) {
    const { items, total } = props;
    const { page, take } = useListState();
    const texts = useTexts();
    const { handleSetPage } = useListActions();
    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();

    return (
        <>
            <CardList
                header={<AgreementsListHeader openDrawer={openDrawer} />}
                body={<ListBody card={AgreementCard} emptyListAlertText={texts.noAgreementsAlert} items={items} />}
                footer={<ListFooter count={Math.ceil(total / take)} page={page + 1} onChange={handleSetPage} />}
            />
            <FiltersDrawer open={drawerOpen} onClose={closeDrawer} />
        </>
    );
}
