import { setState } from '@/atoms/session/store';
import { useFetchTenantId } from '@/common/hooks/query/useQueryLoadTenant';

export const usePreload = () => {
    const storedStateString = localStorage.getItem('state');
    const initialState = storedStateString ? JSON.parse(storedStateString) : { authorized: false };
    setState(initialState);

    return useFetchTenantId();
};
