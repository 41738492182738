import { DebouncedSearchBox } from '@/components/cardList/DebouncedSearchBox';
import { ListHeader } from '@/components/cardList';
import { useTexts } from '../../useTexts';
import { useListActions } from '../hooks';
import { useListState } from '../store';
import { Chips } from './Chips';

interface Props {
    openDrawer: () => void;
}

export function UpcomingServicesListHeader(props: Props) {
    const { openDrawer } = props;
    const texts = useTexts();
    const { search } = useListState();
    const { handleSetSearch } = useListActions();

    return (
        <ListHeader
            title={texts.title}
            openDrawer={openDrawer}
            searchBox={
                <DebouncedSearchBox placeholder={texts.searchPlaceholder} value={search} setValue={handleSetSearch} />
            }
            chips={<Chips />}
        />
    );
}
