import { useGetUILayout } from '@/atoms/UILayout';
import { ClickAwayListener } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';

export interface Props extends ButtonProps {
    showTooltip?: boolean;
    spanClassName?: string;
    tooltipText?: any;
}

export function ButtonWithTooltip(props: Props) {
    const { tooltipText, showTooltip, spanClassName, ...buttonProps } = props;
    const [open, setOpen] = useState<boolean>(false);
    const layout = useGetUILayout();

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <>
            {showTooltip && (
                <>
                    {layout == 'desktop' && (
                        <Tooltip title={tooltipText}>
                            <span className={spanClassName}>
                                <Button {...buttonProps}>{props.children}</Button>
                            </span>
                        </Tooltip>
                    )}

                    {layout == 'mobile' && (
                        <>
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                <div>
                                    <Tooltip
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        onClose={handleTooltipClose}
                                        open={open}
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        title={tooltipText}
                                    >
                                        <span className={spanClassName} onClick={handleTooltipOpen}>
                                            <Button {...buttonProps}>{props.children}</Button>
                                        </span>
                                    </Tooltip>
                                </div>
                            </ClickAwayListener>
                        </>
                    )}
                </>
            )}

            {!showTooltip && <Button {...buttonProps}>{props.children}</Button>}
        </>
    );
}
