import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import { FormError, FormValidator, useForm } from '@/formState';
import makeStyles from '@mui/styles/makeStyles';
import { Section } from '@/components/Section';
import { SectionHeader } from '@/components/SectionHeader';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router';
import { TextField } from '@/formUI';
import { getPasswordErrors } from '@/components/PasswordRequirements/utils';
import { PasswordRequirements } from '@/components/PasswordRequirements/PasswordRequirements';
import { useTexts as useErrorsTexts } from '@/components/PasswordRequirements/useTexts';
import { useResetPasswordActions } from '../useResetPasswordActions';
import { useTexts } from '../useTexts';
import { Footer } from './Footer';
import { FormErrors, FormFields } from './formFields';

const useStyles = makeStyles({
    section: {
        width: '350px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
});

enum formStates {
    EnterPassword,
    ResetSuccesful,
}

export const Form = () => {
    const texts = useTexts();
    const [formState, setFormState] = useState<formStates>(formStates.EnterPassword);
    const [validLink, setValidLink] = useState<boolean>(false);
    const { api, meta } = useForm<FormFields, FormError<keyof FormFields>>();
    const { resetPassword, validateLinkHash, notifyError } = useResetPasswordActions();
    const classes = useStyles();
    const navigate = useNavigate();
    const errorsTexts = useErrorsTexts();
    const linkHash = new URL(location.href).searchParams.get('hash') ?? '';

    const validateForm: FormValidator<FormFields, FormErrors> = formApi => {
        const pwField = formApi.getField('password');
        const reEnterPwField = formApi.getField('reEnterPassword');

        const errors = getPasswordErrors(pwField, reEnterPwField, errorsTexts);
        return errors;
    };

    useEffect(() => {
        if (!api.getValidator('checkPassword')) {
            api.addValidation('checkPassword', validateForm);
        }
    });

    const validateLink = useCallback(async () => {
        try {
            const linkIsValid = await validateLinkHash(linkHash);
            if (linkIsValid) {
                setValidLink(true);
            } else {
                notifyError(texts.invalidLink);
            }
        } catch (e) {
            notifyError(texts.serverError);
        }
    }, [linkHash, validateLinkHash, setValidLink, notifyError, texts]);

    useEffect(() => {
        validateLink();
    }, [validateLink]);

    const handleOnUpdate = useCallback(async () => {
        let passwordUpdated = false;
        try {
            api.setSubmitting(true);
            const values = api.getValues();
            passwordUpdated = await resetPassword(linkHash, values.password);
        } catch (e) {
            notifyError(texts.error);
        } finally {
            api.setSubmitting(false);
            if (passwordUpdated) {
                setFormState(formStates.ResetSuccesful);
            } else {
                notifyError(texts.error);
            }
        }
    }, [api, notifyError, resetPassword, linkHash, texts.error]);

    return (
        <Box className={classes.container}>
            <Section className={classes.section}>
                {formState === formStates.EnterPassword && (
                    <form onChange={() => api.validate()}>
                        <SectionHeader title={texts.passwordReset} />
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography>{texts.enterNew}</Typography>
                            </Grid>
                            <Grid item>
                                <TextField<'password', FormFields, FormErrors>
                                    name="password"
                                    textField={{
                                        type: 'password',
                                    }}
                                    UNSTABLE_textField={{ 'autoComplete': 'new-password' }}
                                    validateOn="change"
                                    label={texts.passwordLabel}
                                />
                            </Grid>
                            <Grid item>
                                <TextField<'reEnterPassword', FormFields, FormErrors>
                                    name="reEnterPassword"
                                    textField={{
                                        type: 'password',
                                    }}
                                    UNSTABLE_textField={{ 'autoComplete': 'new-password' }}
                                    validateOn="change blur"
                                    label={texts.reEnterPasswordLabel}
                                />
                            </Grid>
                            <Grid item>
                                <Footer
                                    handleOnSubmit={handleOnUpdate}
                                    buttonText={texts.changePassword}
                                    disabled={meta.status !== 'valid' || !validLink}
                                />
                            </Grid>
                            <Grid item>
                                <PasswordRequirements errors={api.getErrors()} />
                            </Grid>
                        </Grid>
                    </form>
                )}

                {formState == formStates.ResetSuccesful && (
                    <>
                        <SectionHeader title={texts.success} />
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography>{texts.succesfullyReset}</Typography>
                            </Grid>
                            <Grid item>
                                <Footer
                                    handleOnSubmit={async () => {
                                        navigate('/login');
                                    }}
                                    buttonText={texts.signIn}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Section>
        </Box>
    );
};
