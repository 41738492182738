import { Fragment, useCallback, useState } from 'react';

import { Box, Divider, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Header, Section } from './index';
import { ListProps } from './List/typings';
import { SwitchAccountView } from './SwitchAccount/SwitchAccountView';

interface Props {
    footerContent: React.ReactNode;
    headerContent: React.ReactNode;
    list: React.ComponentType<ListProps>;
}

export const Sidebar = (props: Props) => {
    const { footerContent, headerContent, list: List } = props;
    const [selectedSubList, setSelectedSubList] = useState<React.ReactElement>();

    const setSubList = useCallback((list: React.ReactElement) => {
        setSelectedSubList(list);
    }, []);

    const clearSubList = useCallback(() => {
        setSelectedSubList(undefined);
    }, []);

    return (
        <Fragment>
            {!selectedSubList && (
                <Box data-testid="sidebar-list">
                    <Header content={headerContent} />
                    <Divider />
                    <SwitchAccountView setSubList={setSubList} clearSubList={clearSubList}></SwitchAccountView>
                    <List setSubList={setSubList} />
                    <Divider />
                    <Section content={footerContent} />
                </Box>
            )}

            {!!selectedSubList && (
                <Box data-testid="sidebar-sub-list">
                    <Box display="flex" justifyContent="flex-end">
                        <IconButton onClick={clearSubList} size="large">
                            <ArrowBackIcon />
                        </IconButton>
                    </Box>
                    {selectedSubList}
                </Box>
            )}
        </Fragment>
    );
};

Sidebar.displayName = 'Sidebar';
