import { get } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';
import { SchedulingPlan } from './services';

const baseUrl = '/api/opportunities';

export interface ServiceRequestsListReponse {
    total: number;
    page: number;
    take: number;
    items: ServiceRequestListItemModel[];
}
export enum ServiceRequestStatus {
    Declined = 'Declined',
    Pending = 'Pending',
    Processed = 'Processed',
}

export interface ServiceRequestListItemModel {
    protoServiceAgreementId: string;
    status: ServiceRequestStatus;
    servicesRequested: string[];
    dateTimeCreated: Date;
}

export interface PropertyTraitWithAmountModel {
    name: string;
    amount: number;
    unitOfMeasure: string;
}

export enum ReservationTimeFrame {
    Afternoon = 'afternoon',
    Evening = 'evening',
    Morning = 'morning',
}

export interface ReservationModel {
    workOrderId: string;
    serviceName: string;
    timeFrame: ReservationTimeFrame;
    schedulingPlan: SchedulingPlan;
    startingEligibleDate: Date | null;
    propertyTraits: PropertyTraitWithAmountModel[];
}

export interface ServiceRequestDetails {
    reservations: ReservationModel[];
    serviceLocationContactName: string;
    serviceLocationContactEmail: string;
    serviceLocationContactPhone: string;
    serviceLocationAddress: string;
    serviceLocationCity: string;
    serviceLocationState: string;
    serviceLocationStateAbbreviation: string;
    serviceLocationPostalCode: string;
    serviceLocationCountry: string;
    status: ServiceRequestStatus;
    issue: string;
}

export const getServiceRequests = async (accountId: string, page: number, take: number) => {
    try {
        const queryString = new URLSearchParams({
            page: page.toString(),
            take: take.toString(),
        });

        const { data, status } = await get<ServiceRequestsListReponse>(`${baseUrl}?${queryString}`, {
            params: { accountId },
        });

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError('Failed to get your service requests.', status);
    } catch (e) {
        throw e;
    }
};

export const getServiceRequestDetails = async (protoServiceAgreementId: string) => {
    try {
        const { data, status } = await get<ServiceRequestDetails>(`${baseUrl}/${protoServiceAgreementId}`);
        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError('Failed to get your service request details.', status);
    } catch (e) {
        throw e;
    }
};
