import { useMemo } from 'react';
import { useTranslate } from '@/intl';
import { Frequencies } from '@/common/types/schedulingPlan';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            daily: translate({ id: 'global.intervalUnits.daily' }),
            monthly: translate({ id: 'global.intervalUnits.monthly' }),
            multiDaysPerWeek: translate({ id: 'global.intervalUnits.multiDaysPerWeek' }),
            onDemand: translate({ id: 'global.intervalUnits.onDemand' }),
            oneTime: translate({ id: 'global.intervalUnits.oneTime' }),
            quarterly: translate({ id: 'global.intervalUnits.quarterly' }),
            weekly: translate({ id: 'global.intervalUnits.weekly' }),
        };
    }, [translate]);
}

export function useHumanizedIntervalUnits() {
    const texts = useTexts();
    return useMemo(() => {
        return {
            [Frequencies.Daily]: texts.daily,
            [Frequencies.Monthly]: texts.monthly,
            [Frequencies.MultiDaysPerWeek]: texts.multiDaysPerWeek,
            [Frequencies.OnDemand]: texts.onDemand,
            [Frequencies.OneTime]: texts.oneTime,
            [Frequencies.Quarterly]: texts.quarterly,
            [Frequencies.Weekly]: texts.weekly,
        };
    }, [texts]);
}
