import { ServiceLocation } from '@/api/serviceLocations';
import { proxy, useSnapshot } from 'valtio';

export interface SelectLocationState {
    all: ServiceLocation[];
    searchQuery: string;
    selected: ServiceLocation | null;
    status: 'error' | 'loading' | 'success';
}

export const initialState: SelectLocationState = {
    all: [],
    searchQuery: '',
    selected: null,
    status: 'loading',
};

// Valtio store initialization
const state = proxy<SelectLocationState>(initialState);

// Setter functions
export function setLocation(location: ServiceLocation | null) {
    if (location?.validationErrors) {
        return;
    }
    state.selected = location ? { ...location } : null;
}

export function setLocations(locations: ServiceLocation[]) {
    state.all = [...locations];
}

export function setSearchQuery(searchQuery: string) {
    state.searchQuery = searchQuery;
}

export function setStatus(status: 'error' | 'loading' | 'success') {
    state.status = status;
}

// Hooks
export function useSelectLocationState() {
    return useSnapshot(state, { sync: true });
}
