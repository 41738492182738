import { useQuery } from 'react-query';
import { getUpcomingServices, UpcomingServicesResponse } from '@/api/upcomingServices';

export const useQueryUpcomingServices = (
    accountId: string,
    page: number,
    take: number,
    search: string,
    serviceLocations: string[],
) =>
    useQuery<UpcomingServicesResponse, Error>(
        ['upcomingServices', accountId, page, take, search, serviceLocations],
        () => getUpcomingServices(accountId, page, take, search, serviceLocations),
        {
            keepPreviousData: true,
        },
    );
