import { SideDrawerForm } from '@/components/form/SideDrawerForm';
import { FormError, useForm } from '@/formState';
import { isServerError } from '@/utils/ServerError';
import { logout } from '@/atoms/session';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { changePassword } from '../../../services/services/userService';
import { Form } from './Form';
import { FormFields } from './formFields';
import { useTexts } from './useTexts';

interface Props {
    onClose: () => void;
    open: boolean;
}

export function FormWrapper(props: Props) {
    const { onClose, open } = props;
    const texts = useTexts();

    const { api, meta } = useForm<FormFields, FormError<keyof FormFields>>();

    const onSave = useCallback(async () => {
        if (meta.status == 'valid' && !meta.submitting) {
            const formValues = api.getValues();
            api.setSubmitting(true);
            const response = await changePassword(formValues.oldPassword, formValues.password);

            if (!isServerError(response) && response) {
                toast.success(texts.success);
                api.reset('force');
                onClose();
                logout();
            } else {
                toast.error(texts.error);
            }
            api.setSubmitting(false);
        } else {
            api.validate();
        }
    }, [api, meta, texts, onClose]);

    return (
        <>
            <SideDrawerForm
                onSave={onSave}
                onClose={onClose}
                isOpen={open}
                title={texts.heading}
                isSubmitting={meta.submitting}
            >
                <Form />
            </SideDrawerForm>
        </>
    );
}
