import type { ScrollbarPosition } from '../types';
import { CSSProperties, useMemo } from 'react';

export function useStyles(
    width: number | string = 'auto',
    height: number | string = 'auto',
    scrollbarPosition: ScrollbarPosition,
): CSSProperties {
    return useMemo(() => {
        const paddingRight = scrollbarPosition.position === 'asideContent' ? scrollbarPosition.gap ?? 20 : 0;

        return {
            width,
            height,
            paddingRight,
        };
    }, [width, height, scrollbarPosition]);
}
