import { DefinitionListItem } from '@/components/DefinitionListItem';
import { useFormattedAddress } from '@/common/hooks/useAddress';
import { useTexts } from '../useTexts';

interface Props {
    street: string;
    city: string;
    country: string;
    postalCode: string;
    state: string;
}

export function ServiceAddress(props: Props) {
    const { city, country, postalCode, state, street } = props;
    const texts = useTexts();
    const formattedAddress = useFormattedAddress(street, '', city, state, postalCode, country);

    return <DefinitionListItem primary={texts.serviceAddress} secondary={formattedAddress} />;
}
