import { useQuery } from 'react-query';
import { getServiceRequests, ServiceRequestsListReponse } from '@/api/serviceRequests';

export const useQueryServiceRequests = (accountId: string, page: number, take: number) =>
    useQuery<ServiceRequestsListReponse, Error>(
        ['your-requests', accountId, page, take],
        () => getServiceRequests(accountId, page, take),
        {
            keepPreviousData: true,
        },
    );
