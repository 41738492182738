export interface FormattablePhoneNumber {
    callingCode?: string;
    phone: string;
    phoneExt: string | null;
}

export function formatPhoneNumber(l: FormattablePhoneNumber, showCountryCode: boolean) {
    if (!l || !l.phone) {
        return '';
    }
    return (
        (showCountryCode && !!l.callingCode ? `+${l.callingCode} ` : '') +
        `${l.phone}` +
        (l.phoneExt ? ` ext. ${l.phoneExt}` : '')
    );
}

export function formatPhoneNumberTelLink(l: FormattablePhoneNumber) {
    if (!l || !l.phone) {
        return '';
    }
    return `tel:${l.callingCode || ''}${l.phone}` + (l.phoneExt ? `,${l.phoneExt}` : '');
}
