import { getOpenInvoices } from '@/api/accounts';
import { GetOpenInvoicesResponse } from '@/common/types/invoices';
import { staggerPagination } from '@/common/utils/staggerPagination';
import { useQuery } from 'react-query';

export const useFetchOpenInvoices = (accountId: string, page: number, take: number, stagger?: number) =>
    useQuery<GetOpenInvoicesResponse, Error>(
        ['open-invoices', accountId, page, take],
        () => {
            const { page: adjustedPage, take: adjustedTake, skip } = staggerPagination(page, take, stagger || 0)!;
            return getOpenInvoices(accountId, adjustedPage, adjustedTake, skip);
        },
        {
            staleTime: Infinity,
        },
    );
