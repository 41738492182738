import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            amount: translate({ id: 'sections.accountOverview.yourLastService.amount' }),
            date: translate({ id: 'sections.accountOverview.yourLastService.date' }),
            dateAndTime: translate({ id: 'sections.accountOverview.yourLastService.dateAndTime' }),
            error: translate({ id: 'sections.accountOverview.yourLastService.error' }),
            header: translate({ id: 'sections.accountOverview.yourLastService.header' }),
            noResults: translate({ id: 'sections.accountOverview.yourLastService.noResults' }),
            notSet: translate({ id: 'global.notSet' }),
            serviceLocation: translate({ id: 'sections.accountOverview.yourLastService.serviceLocation' }),
            servicesPerformed: translate({ id: 'sections.accountOverview.yourLastService.servicesPerformed' }),
            team: translate({ id: 'global.team' }),
            technician: translate({ id: 'global.technician' }),
            time: translate({ id: 'sections.accountOverview.yourLastService.time' }),
        };
    }, [translate]);
}
