import { useFetchOpenInvoices } from '@/common/hooks/query/useFetchOpenInvoices';
import { useFetchUnappliedStartingBalance } from '@/common/hooks/query/useFetchUnappliedStartingBalance';
import { PageErrorState } from '@/components/PageErrorState/PageErrorState';
import { Alert, Pagination, Stack } from '@mui/material';
import { useMemo } from 'react';
import { LoadingSkeleton } from '../AccountHistory/LoadingSkeleton';
import { InvoiceCard } from './InvoiceCard';
import { setPage, usePayInvoicesState } from './store';
import { UnappliedStartingBalanceCard } from './UnappliedStartingBalanceCard';
import { useTexts } from './useTexts';

interface Props {
    accountId: string;
    disableSelection: boolean;
}

export function InvoiceList(props: Props) {
    const { accountId, disableSelection } = props;
    const {
        invoicePagination: { page, take },
    } = usePayInvoicesState();

    const unappliedStartingBalance = useFetchUnappliedStartingBalance(accountId || '');
    const hasStartingBalance = useMemo(
        () => unappliedStartingBalance.status === 'success' && unappliedStartingBalance.data > 0,
        [unappliedStartingBalance],
    );
    const stagger = hasStartingBalance ? 1 : 0;
    const query = useFetchOpenInvoices(accountId, page, take, stagger);
    const texts = useTexts();

    return (
        <>
            {query.status === 'loading' && <LoadingSkeleton />}
            {query.status === 'success' && unappliedStartingBalance.status === 'success' && query.data && (
                <>
                    {(query.data.items.length > 0 || hasStartingBalance) && (
                        <Stack direction="column">
                            {hasStartingBalance && page == 0 && (
                                <UnappliedStartingBalanceCard balance={unappliedStartingBalance.data} />
                            )}
                            {query.data.items.map((invoice, i) => (
                                <InvoiceCard key={i} invoice={invoice} disableSelection={disableSelection} />
                            ))}
                            <Pagination
                                color="primary"
                                count={Math.ceil(
                                    (query.data.total + (unappliedStartingBalance.data > 0 ? 1 : 0)) / take,
                                )}
                                onChange={(_e, p) => {
                                    setPage(p - 1);
                                }}
                                page={page + 1}
                                sx={{ mb: 2 }}
                            />
                        </Stack>
                    )}

                    {query.data.items.length == 0 && !hasStartingBalance && (
                        <Alert severity="info">{texts.noInvoicesToDisplay}</Alert>
                    )}
                </>
            )}
            {query.status === 'error' && <PageErrorState />}
        </>
    );
}
