import { useState, useRef, useEffect, useCallback } from 'react';

import { requestResetPasswordLink } from '@/services';
import { useToast } from '@/utils/useToast';

export function useForgotPasswordActions() {
    const toast = useToast();

    // --------------------------------------
    // Mounted state ref for async callbacks

    const unmounted = useRef(false);
    useEffect(() => () => void (unmounted.current = true));

    // --------------------------------------
    // local state to track state of the login request

    const [submitting, setSubmitting] = useState(false);

    // --------------------------------------
    // callbacks
    const resetPassword = useCallback(async (email: string): Promise<null> => {
        try {
            setSubmitting(true);

            const resetPasswordRequest = await requestResetPasswordLink(email);

            if (!unmounted.current) setSubmitting(false);

            if (resetPasswordRequest === true) {
                return null;
            } else {
                console.log(`Error during request`);
                // TODO: navigate 404 page
                return null;
            }
        } catch (e) {
            // TODO: navigate 404 page
            return null;
        } finally {
            setSubmitting(false);
        }
    }, []);

    const notifyError = useCallback((message: string) => toast.error(message), [toast]);

    const notifySuccess = useCallback((message: string) => toast.success(message), [toast]);

    // --------------------------------------
    // --------------------------------------
    return {
        submitting,

        resetPassword,
        notifyError,
        notifySuccess,
    } as const;
}
