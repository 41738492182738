import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            actionBtn: translate({ id: 'sections.requestService.addServices.actionBtn' }),
            actionBtnDisabled: translate({ id: 'sections.requestService.addServices.actionBtnDisabled' }),
            header: translate({ id: 'sections.requestService.addServices.header' }),
        };
    }, [translate]);
}
