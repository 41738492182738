import * as React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, CircularProgress, IconButton, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { theme } from '@/theme';
import { useGetUILayout } from '@/atoms/UILayout';
import { LoadingButton } from '@mui/lab';
import { SideDrawer } from '../SideDrawer';
import { useTexts } from './useTexts';

type Props = {
    children?: React.ReactNode;
    disableSave?: boolean;
    footerSecondary?: React.ReactNode;
    headerSecondary?: React.ReactNode;
    hideFooter?: boolean;
    hidePrimary?: boolean;
    isOpen: boolean;
    isSubmitting?: boolean;
    onBackBtnClick?: () => void;
    onClose: () => void;
    onSave: () => void;
    onSecondaryAction?: () => void;
    primaryBtnLabel?: string;
    primaryBtnLoading?: boolean;
    secondaryBtnDisabled?: boolean;
    secondaryBtnLabel?: string;
    showBackBtn?: boolean;
    title: string;
};

export function SideDrawerForm(props: Props) {
    const layout = useGetUILayout();
    const texts = useTexts();

    const useFormStyles = makeStyles({
        footer: {
            display: 'flex',
            justifyContent: layout == 'mobile' ? 'space-between' : 'flex-end',
            padding: theme.spacing(2),
        },
        save: {
            marginLeft: theme.spacing(1.5),
        },
        close: {
            minWidth: 'unset',
            marginLeft: 'auto',
        },
    });

    const {
        children,
        disableSave = false,
        footerSecondary,
        headerSecondary,
        hideFooter,
        hidePrimary = false,
        isOpen,
        isSubmitting = false,
        onBackBtnClick,
        onClose,
        onSave,
        onSecondaryAction = onClose,
        primaryBtnLabel = texts.save,
        primaryBtnLoading = false,
        secondaryBtnDisabled = false,
        secondaryBtnLabel = texts.cancel,
        showBackBtn = false,
        title,
    } = props;
    const classes = useFormStyles();

    return (
        <SideDrawer
            onClose={onClose}
            isOpen={isOpen}
            title={title}
            headerSecondary={headerSecondary}
            footerSecondary={footerSecondary}
            footer={
                <>
                    {hideFooter ? (
                        <></>
                    ) : (
                        <div className={classes.footer}>
                            {showBackBtn && !!onBackBtnClick && (
                                <Box display="flex" flexGrow={1}>
                                    <IconButton size="small" onClick={onBackBtnClick}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Box>
                            )}

                            <Box display="flex" alignContent="center" alignItems="center">
                                {isSubmitting && (
                                    <Box mr={1.5}>
                                        <CircularProgress size={25} />
                                    </Box>
                                )}
                                <Button
                                    data-testid="btn-secondary"
                                    disabled={secondaryBtnDisabled}
                                    onClick={onSecondaryAction}
                                    variant="outlined"
                                >
                                    {secondaryBtnLabel}
                                </Button>
                                {!hidePrimary && (
                                    <LoadingButton
                                        data-testid="btn-primary"
                                        className={classes.save}
                                        loading={primaryBtnLoading}
                                        color="primary"
                                        variant="contained"
                                        onClick={onSave}
                                        disabled={disableSave || isSubmitting}
                                    >
                                        {primaryBtnLabel}
                                    </LoadingButton>
                                )}
                            </Box>
                        </div>
                    )}
                </>
            }
        >
            {children}
        </SideDrawer>
    );
}
