import { useDebounce } from '@/common/hooks/useDebounce';
import { SearchBox as CommonSearchBox } from '@/components/SearchBox/SearchBox';
import { useCallback, useEffect, useState } from 'react';

interface Props {
    placeholder: string;
    value: string;
    setValue: (val: string) => void;
}

export function DebouncedSearchBox(props: Props) {
    const { placeholder, value, setValue } = props;
    const [internalValue, setInternalValue] = useState<string>(value);
    const debouncedValue = useDebounce(internalValue, 500);

    const handleSearchChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(e.target.value);
    }, []);

    const handleSearchCleared = useCallback(() => {
        setInternalValue('');
    }, []);

    useEffect(() => {
        setValue(debouncedValue);
    }, [debouncedValue, setValue]);

    return (
        <CommonSearchBox
            onChange={handleSearchChanged}
            onClear={handleSearchCleared}
            placeholder={placeholder}
            value={internalValue}
        />
    );
}
