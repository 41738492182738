import { defaultLocalizationSettings } from '@/common/types/localization';
import { useMemo } from 'react';
import { useFetchLocalizationSettings } from './query/useFetchLocalizationSettings';

export const useLocalizationSettings = () => {
    const { data: settings } = useFetchLocalizationSettings();

    return useMemo(() => {
        return settings ? settings : defaultLocalizationSettings;
    }, [settings]);
};
