import { chargePaymentAccount } from '@/api/payments';
import { useAccountId, getUser } from '@/atoms/session';
import { getAccount } from '@/atoms/session/store';
import { useFetchAccountSummary } from '@/common/hooks/query/useFetchAccountSummary';
import { useFetchOpenInvoices } from '@/common/hooks/query/useFetchOpenInvoices';
import { useFetchUnappliedStartingBalance } from '@/common/hooks/query/useFetchUnappliedStartingBalance';
import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { InvoicePayment } from '@/common/types/payments';
import { SideDrawerForm } from '@/components/form/SideDrawerForm';
import { useToast } from '@/utils/useToast';
import { Alert, Link, Stack, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useMutation } from 'react-query';
import { ServerError } from '@/utils/ServerError';
import { useNavigate } from 'react-router';
import { AccountHistoryState } from 'src/sections/AccountHistory/AccountHistory';
import { queryClient } from '../../../services/queryClient';
import {
    InvoiceWizardStep,
    resetPayInvoicesState,
    setPaymentMethod,
    setWizardStep,
    usePaymentTotal,
    usePayInvoicesState,
} from '../store';
import { AddNewCreditCard } from './AddNewCreditCard';
import { PaymentConfirmationAlert } from './PaymentConfirmationAlert';
import { PaymentMethodSelect } from './PaymentMethodSelect';
import { useTexts } from './useTexts';

interface Props {
    onClose: () => void;
    open: boolean;
}

export const Drawer = (props: Props) => {
    const { onClose, open } = props;
    const accountId = useAccountId();
    const account = getAccount();
    const navigate = useNavigate();
    const texts = useTexts();
    const toast = useToast();
    const user = getUser();

    const creditCardEnabled = useFeatureEnabled('enableCreditCardPayment');

    const {
        invoicePagination: { page, take },
        paymentMethod,
        invoicesSelected,
        startingBalancePayment,
        wizardStep,
    } = usePayInvoicesState();

    const { refetch: refetchAccountSummary } = useFetchAccountSummary(accountId || '');
    const { refetch: refetchOpenInvoices } = useFetchOpenInvoices(accountId || '', page, take);
    const { refetch: refetchUnappliedStartingBalance } = useFetchUnappliedStartingBalance(accountId || '');

    const paymentTotal = usePaymentTotal();

    const mutation = useMutation(chargePaymentAccount, {
        onError: (error: ServerError) => {
            toast.error(error.errorCode == 400 ? texts.chargePaymentMethod.badRequest : texts.chargePaymentMethod.failed);
        },
        onSuccess: async result => {
            if (!result.failureReason) {
                toast.success(texts.chargePaymentMethod.success);
                resetPayInvoicesState();
                queryClient.invalidateQueries([
                    'account-summary',
                    'open-invoices',
                    'unapplied-starting-balance',
                    'accountHistory',
                ]);
                await Promise.all([refetchOpenInvoices(), refetchAccountSummary(), refetchUnappliedStartingBalance()]);
                onClose();
                navigate('/account-history', {
                    state: {
                        isStale: true,
                    } as AccountHistoryState,
                });
            } else {
                toast.error(texts.chargePaymentMethod.failed);
            }
        },
    });

    const onSave = useCallback(() => {
        if (
            !account?.accountId ||
            !account?.businessUnitId ||
            !paymentMethod?.paymentAccountId ||
            !user?.identityUserId
        )
            return;

        const invoicePayments: InvoicePayment[] = invoicesSelected.map(i => ({
            amount: i.balance,
            invoiceId: i.invoiceId,
            paymentDate: new Date(),
        }));

        mutation.mutate({
            accountId: account.accountId,
            amount: paymentTotal,
            businessUnitId: account.businessUnitId,
            employeeId: user.identityUserId,
            invoicePayments: invoicePayments,
            startingBalancePayment: startingBalancePayment || 0,
            paymentAccountId: paymentMethod.paymentAccountId,
        });
    }, [
        account,
        mutation,
        paymentTotal,
        paymentMethod?.paymentAccountId,
        invoicesSelected,
        user,
        startingBalancePayment,
    ]);

    const isProcessingPayment = useMemo(() => {
        return mutation.isLoading;
    }, [mutation.isLoading]);

    const isSaveDisabled = useMemo(() => {
        return !paymentMethod || (!invoicesSelected.length && !startingBalancePayment) || isProcessingPayment;
    }, [isProcessingPayment, paymentMethod, invoicesSelected, startingBalancePayment]);

    const hideSaveBtn = useMemo(() => {
        return wizardStep != InvoiceWizardStep.SelectSavedPaymentMethod;
    }, [wizardStep]);

    const goToAddCreditCardStep = useCallback(() => {
        setWizardStep(InvoiceWizardStep.NewCardSave);
    }, []);

    const showBackBtn = useMemo(() => {
        return wizardStep != InvoiceWizardStep.SelectSavedPaymentMethod;
    }, [wizardStep]);

    const title = useMemo(() => {
        if (wizardStep == InvoiceWizardStep.SelectSavedPaymentMethod) {
            return texts.header.selectPaymentMethod;
        } else {
            return texts.header.addCreditCard;
        }
    }, [texts.header.addCreditCard, texts.header.selectPaymentMethod, wizardStep]);

    const onBackBtnClick = useCallback(() => {
        setWizardStep(InvoiceWizardStep.SelectSavedPaymentMethod);
    }, []);

    return (
        <SideDrawerForm
            disableSave={isSaveDisabled}
            hidePrimary={hideSaveBtn}
            isOpen={open}
            onBackBtnClick={onBackBtnClick}
            onClose={onClose}
            onSave={onSave}
            primaryBtnLabel={texts.footer.processPayment}
            primaryBtnLoading={isProcessingPayment}
            secondaryBtnDisabled={isProcessingPayment}
            showBackBtn={showBackBtn}
            title={title}
        >
            <Stack direction="column" spacing={1}>
                {wizardStep == InvoiceWizardStep.SelectSavedPaymentMethod && (
                    <>
                        {!isProcessingPayment && (
                            <>
                                {creditCardEnabled && (
                                    <Typography gutterBottom>
                                        {texts.useSavedPaymentMethod}{' '}
                                        <Link onClick={goToAddCreditCardStep}>{texts.addNewPaymentMethod}</Link>
                                    </Typography>
                                )}
                                <PaymentMethodSelect onSelect={setPaymentMethod} selected={paymentMethod} />

                                {!!paymentMethod && <PaymentConfirmationAlert />}
                            </>
                        )}

                        {isProcessingPayment && <Alert severity="warning">{texts.processingPaymentAlert}</Alert>}
                    </>
                )}

                {wizardStep != InvoiceWizardStep.SelectSavedPaymentMethod && <AddNewCreditCard onClose={onClose} />}
            </Stack>
        </SideDrawerForm>
    );
};
