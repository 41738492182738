import { useCallback } from 'react';
import { useToast } from '@/utils/useToast';
import { enableAutopayment } from '@/api/autopayments';
import { addAchPaymentAccount } from '@/api/paymentAccounts';
import { AgreementModel } from '@/api/agreements';
import { getAccount } from '@/atoms/session/store';
import { useQueryPaymentAccounts } from '@/common/hooks/query/useQueryPaymentAccounts';
import { queryClient } from '../../../../../services/queryClient';
import { WizardStep, setWizardStep, useAgreementDrawerState, setPaymentAccount } from '../../../drawer/store';
import { useTexts } from '../../../useTexts';

export function useAgreementCardActions(item: AgreementModel) {
    const account = getAccount();
    const toast = useToast();
    const texts = useTexts();
    const { wizardStep, paymentAccount, achInput } = useAgreementDrawerState();
    const { refetch: refetchPaymentAccounts } = useQueryPaymentAccounts(account?.accountId || '');

    const handleEnableAutopayments = useCallback(async () => {
        const successful = await enableAutopayment(item.agreementId, paymentAccount?.paymentAccountId || '');
        if (successful) {
            toast.success(texts.successfullyEnabledAutopayments);
        } else {
            toast.error(texts.failedToEnableAutopay);
        }
        queryClient.invalidateQueries('activeAgreements');
        queryClient.invalidateQueries('agreements');
        queryClient.invalidateQueries('agreementDetails');
        queryClient.invalidateQueries('paymentAccounts');
        setWizardStep(WizardStep.Detail);
    }, [item.agreementId, paymentAccount?.paymentAccountId, toast, texts]);

    const handleAddAchAccount = useCallback(async () => {
        if (!account || !achInput) {
            return;
        }
        try {
            const response = await addAchPaymentAccount({
                accountId: account.accountId,
                accountNumber: achInput.accountNumber,
                businessUnitId: account.businessUnitId,
                processorPaymentAccountId: '',
                routingNumber: achInput.routingNumber,
            });

            const paymentAccounts = await refetchPaymentAccounts();
            if (!!paymentAccounts.data && paymentAccounts.data.length) {
                const newPaymentMethod = paymentAccounts.data.find(
                    p => p.processorPaymentAccountId == response.processorPaymentAccountId,
                );
                if (newPaymentMethod) {
                    setPaymentAccount(newPaymentMethod);
                }
            }

            toast.success(texts.successfullyAddedAch);
        } catch {
            toast.error(texts.errorAddingAch);
        }

        await refetchPaymentAccounts();
        setWizardStep(WizardStep.ManageAutopay);
    }, [account, achInput, texts, refetchPaymentAccounts, toast]);

    const handleOnSave = useCallback(async () => {
        if (wizardStep === WizardStep.ManageAutopay) {
            await handleEnableAutopayments();
            queryClient.invalidateQueries('paymentAccounts');
        }
        if (wizardStep === WizardStep.AddPaymentMethod) {
            await handleAddAchAccount();
        }
    }, [wizardStep, handleEnableAutopayments, handleAddAchAccount]);

    return { handleOnSave } as const;
}
