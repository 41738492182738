import { FormApi, FormError, FormField } from '@/formState';
import { useTranslate } from '@/intl';

export function RequiredFieldValidation<
    FIELDS extends Record<string, FormField>,
    ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
>(fieldName: keyof FIELDS, id: string) {
    const translate = useTranslate();

    const validate = (api: Readonly<FormApi<FIELDS, ERROR>>) => {
        const requiredField = api.getField(fieldName);
        const errors = {};

        errors[id] = !!requiredField.value
            ? null
            : {
                  id: id,
                  field: fieldName,
                  message: translate({ id: 'global.requiredField' }),
              };

        return errors;
    };

    return validate;
}
