import { useGetUILayout } from '@/atoms/UILayout';
import { appbarHeight } from '@/common/constants';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
    children: React.ReactNode;
    disableScroll?: boolean;
    fullWidth?: boolean;
}

const useStyles = (props: Props) => {
    return makeStyles(theme => ({
        desktop: {
            display: 'flex',
            flexDirection: 'column',
            height: `calc(100vh - ${appbarHeight}px)`,
            width: '100%',
            overflowY: props.disableScroll ? 'hidden' : 'auto',
            padding: theme.spacing(2),
        },
        desktopContainer: {
            height: '100%',
            marginLeft: 0,
            marginRight: 'auto',
        },
        mobile: {
            height: '100%',
            overflowY: props.disableScroll ? 'hidden' : 'auto',
            padding: theme.spacing(2),
        },
    }));
};

export function PageWrapper(props: Props) {
    const { children, fullWidth } = props;
    const layout = useGetUILayout();

    return layout == 'desktop' ? (
        <PageWrapperDesktop fullWidth={fullWidth}>{children}</PageWrapperDesktop>
    ) : (
        <PageWrapperMobile>{children}</PageWrapperMobile>
    );
}

export function PageWrapperDesktop(props: Props) {
    const { children, fullWidth } = props;
    const classes = useStyles(props)();

    return (
        <Box className={classes.desktop}>
            <Container disableGutters maxWidth={fullWidth ? false : 'md'} className={classes.desktopContainer}>
                {children}
            </Container>
        </Box>
    );
}

export function PageWrapperMobile(props: Props) {
    const { children } = props;
    const classes = useStyles(props)();

    return (
        <Container disableGutters maxWidth={false} className={classes.mobile}>
            {children}
        </Container>
    );
}
