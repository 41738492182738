import { useQuery } from 'react-query';
import { getLogoUrl } from '@/api/logo';
import { getAccount } from '@/atoms/session/store';
import { useFetchCustomerPortalSettings } from './useFetchCustomerPortalSettings';

export const useFetchLogoUrl = () => {
    const account = getAccount();
    const { data: settings } = useFetchCustomerPortalSettings(account?.businessUnitId || '');

    return useQuery<string, Error>(
        ['logo-url', account?.accountId, account?.businessUnitId, settings?.companyLogoImageId],
        () => getLogoUrl(settings?.companyLogoImageId),
        {
            staleTime: Infinity,
        },
    );
};
