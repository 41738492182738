import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            clearAll: translate({ id: 'sections.accountHistory.filters.clearAll' }),
            endDateLabel: translate({ id: 'sections.accountHistory.filters.endDateLabel' }),
            invoices: translate({ id: 'sections.accountHistory.filters.invoices' }),
            payments: translate({ id: 'sections.accountHistory.filters.payments' }),
            serviceVisits: translate({ id: 'sections.accountHistory.filters.serviceVisits' }),
            showAllServiceLocations: translate({ id: 'sections.accountHistory.filters.showAllServiceLocations' }),
            showCompleteAccountHistory: translate({
                id: 'sections.accountHistory.filters.drawer.showCompleteAccountHistory',
            }),
            startDateLabel: translate({ id: 'sections.accountHistory.filters.startDateLabel' }),
            title: translate({ id: 'sections.accountHistory.filters.drawer.title' }),
            workOrder: translate({ id: 'global.workOrder' }),
        };
    }, [translate]);
}
