import type { FormFields } from './formFields';
import type { FormError } from '@/formState';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useFormMeta } from '@/formState';
import { useCallback, MouseEvent } from 'react';
import { useTexts } from '../hooks/useTexts';

interface Props {
    handleOnSubmit: () => Promise<boolean>;
}

export function Footer(props: Props) {
    const { handleOnSubmit } = props;
    const { meta, api } = useFormMeta<FormFields, FormError<keyof FormFields>>();
    const texts = useTexts();

    const formInvalid = meta.status === 'invalid';
    const formIndeterminate = meta.status === 'indeterminate';
    const formHasError = formInvalid || (formIndeterminate && Object.keys(api.getErrors()).length > 0);

    const handleOnClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            handleOnSubmit();
        },
        [handleOnSubmit],
    );

    return (
        <Box width="100%">
            {meta.validating && <LinearProgress />}

            <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleOnClick}
                disabled={meta.submitting}
                size="small"
                type="submit"
                data-testid="btn-signIn"
            >
                {meta.submitting && <CircularProgress size={16} />}
                <Box ml={1}>{texts.login}</Box>
            </Button>

            {formHasError ? <Typography color="error">{texts.loginFailed}</Typography> : null}
        </Box>
    );
}
