import { FormField } from '@/formState';

const minCharacterLimit = (stringToTest: string, minChars: number) => {
    return stringToTest.length >= minChars;
};

const hasLowerCaseChar = (stringToTest: string) => /[a-z]/.test(stringToTest);

const hasUpperCaseChar = (stringToTest: string) => /[A-Z]/.test(stringToTest);

const hasNum = (stringToTest: string) => /[0-9]/.test(stringToTest);

const hasSpecialChar = (stringToTest: string) => /[!@#$%^&]/.test(stringToTest);

export const getPasswordErrors = (
    password: Readonly<FormField<string>>,
    confirmPassword: Readonly<FormField<string>>,
    texts: {
        passwordMust: string;
        minLength: string;
        includeLower: string;
        includeUpper: string;
        includeNumber: string;
        includeSpecial: string;
        mustMatch: string;
    },
) => {
    const errors = {
        minChar: minCharacterLimit(password.value, 8)
            ? null
            : {
                  id: 'minChar',
                  field: 'password' as const,
                  message: texts.minLength,
              },
        missingLower: hasLowerCaseChar(password.value)
            ? null
            : {
                  id: 'missingLower',
                  field: 'password' as const,
                  message: texts.includeLower,
              },
        missingUpper: hasUpperCaseChar(password.value)
            ? null
            : {
                  id: 'missingUpper',
                  field: 'password' as const,
                  message: texts.includeUpper,
              },
        missingNum: hasNum(password.value)
            ? null
            : {
                  id: 'missingNum',
                  field: 'password' as const,
                  message: texts.includeNumber,
              },
        missingSpecial: hasSpecialChar(password.value)
            ? null
            : {
                  id: 'missingSpecial',
                  field: 'password' as const,
                  message: texts.includeSpecial,
              },
        missingMatch:
            password.value === confirmPassword.value
                ? null
                : {
                      id: 'missingMatch',
                      field: 'password' as const,
                      message: texts.mustMatch,
                  },
    };

    return errors;
};

const isValidEmail = (stringToTest: string) =>
    /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/.test(
        stringToTest.toLowerCase(),
    );

export const getEmailErrors = (email: Readonly<FormField<string>>, mustBeValidEmail: string) => {
    const errors = {
        validEmail: isValidEmail(email.value)
            ? null
            : {
                  id: 'validEmail',
                  field: 'email' as const,
                  message: mustBeValidEmail,
              },
    };
    return errors;
};
