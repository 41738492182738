import { FormattedDate, FormattedTime } from 'react-intl';

interface Props {
    value: string | number | Date | undefined;
}

export function LongDate(props: Props) {
    const { value } = props;
    return <FormattedDate value={value} day="numeric" month="long" weekday="long" year="numeric" />;
}

export function LongDateTime(props: Props) {
    const { value } = props;
    return (
        <FormattedDate
            value={value}
            day="numeric"
            month="long"
            weekday="long"
            year="numeric"
            hour="numeric"
            minute="2-digit"
        />
    );
}

export function DateOnly(props: Props) {
    const { value } = props;
    return <FormattedDate value={value} day="numeric" month="long" weekday="long" year="numeric" />;
}

export function ShortDate(props: Props) {
    const { value } = props;
    return <FormattedDate value={value} day="2-digit" month="2-digit" year="2-digit" />;
}

export function Time(props: Props) {
    const { value } = props;
    return <FormattedTime value={value} />;
}
