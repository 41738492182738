export interface SplittableAddress {
    city: string;
    postalCode: string;
    state: string;
    street1: string;
    street2: string | null;
}

export function splitAddress(l: SplittableAddress | null) {
    if (!l) {
        return '';
    }

    const parts: string[] = [];

    parts.push(l.street1);
    if (l.street2) parts.push(l.street2);
    parts.push(`${l.city}, ${l.state} ${l.postalCode}`);

    return parts;
}
