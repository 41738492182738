import { getFirstName } from '@/atoms/session/store';
import { useTexts } from './useTexts';

export function useMobileGreeting() {
    const { useGreeting: texts } = useTexts();
    const firstName = getFirstName();

    const now: Date = new Date();
    const currentHour: number = now.getHours();
    let timeOfDay: string = '';

    if (currentHour < 12) {
        timeOfDay = texts.morning;
    } else if (currentHour < 18) {
        timeOfDay = texts.afternoon;
    } else {
        timeOfDay = texts.evening;
    }

    return `${texts.good} ${timeOfDay}, ${firstName}.`;
}
