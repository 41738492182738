import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { theme } from '@/theme';
import { useTexts } from './useTexts';
import { PasswordErrors } from './typings';

const useStyles = makeStyles({
    error: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    doneButton: {
        color: theme.palette.success.main,
        marginRight: theme.spacing(1),
    },
    closeButton: {
        color: theme.palette.error.main,
        marginRight: theme.spacing(1),
    },
});

type Props<T extends string> = {
    errors: PasswordErrors<T>;
};

export const PasswordRequirements = <T extends string>(props: Props<T>) => {
    const { errors } = props;
    const classes = useStyles();
    const texts = useTexts();

    return (
        <>
            <Typography>{texts.passwordMust}</Typography>
            <Typography className={classes.error}>
                {!errors.minChar ? (
                    <DoneIcon className={classes.doneButton} />
                ) : (
                    <CloseIcon className={classes.closeButton} />
                )}
                {texts.minLength}
            </Typography>
            <Typography className={classes.error}>
                {!errors.missingLower ? (
                    <DoneIcon className={classes.doneButton} />
                ) : (
                    <CloseIcon className={classes.closeButton} />
                )}
                {texts.includeLower}
            </Typography>
            <Typography className={classes.error}>
                {!errors.missingUpper ? (
                    <DoneIcon className={classes.doneButton} />
                ) : (
                    <CloseIcon className={classes.closeButton} />
                )}
                {texts.includeUpper}
            </Typography>
            <Typography className={classes.error}>
                {!errors.missingNum ? (
                    <DoneIcon className={classes.doneButton} />
                ) : (
                    <CloseIcon className={classes.closeButton} />
                )}
                {texts.includeNumber}
            </Typography>
            <Typography className={classes.error}>
                {!errors.missingSpecial ? (
                    <DoneIcon className={classes.doneButton} />
                ) : (
                    <CloseIcon className={classes.closeButton} />
                )}
                {texts.includeSpecial}
            </Typography>
            <Typography className={classes.error}>
                {!errors.missingMatch ? (
                    <DoneIcon className={classes.doneButton} />
                ) : (
                    <CloseIcon className={classes.closeButton} />
                )}
                {texts.mustMatch}
            </Typography>
        </>
    );
};
