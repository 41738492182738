import { useQuery } from 'react-query';
import { AgreementsResponse, getAgreements } from '@/api/agreements';
import { AgreementStatus } from '@/common/types/agreements';

export const useQueryAgreements = (
    accountId: string,
    page: number,
    take: number,
    serviceLocations: string[],
    status: AgreementStatus | null,
) =>
    useQuery<AgreementsResponse, Error>(
        ['agreements', accountId, page, take, serviceLocations, status],
        () => getAgreements(accountId, page, take, serviceLocations, status),
        {
            keepPreviousData: true,
        },
    );
