import { theme } from '@/theme';
import { createTheme } from '@mui/material';
import { useMemo } from 'react';
import { useThemingKey } from './useThemingKey';

export const useCustomTheme = () => {
    const themingKey = useThemingKey();

    const customTheme = useMemo(() => {
        return createTheme({
            ...theme,
            palette: {
                ...theme.palette,
                primary: {
                    main: themingKey || theme.palette.primary.main,
                },
            },
        });
    }, [themingKey]);

    return customTheme;
};
