import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            accountBalance: translate({ id: 'sections.accountOverview.accountSummary.accountBalance' }),
            credits: translate({ id: 'sections.accountOverview.accountSummary.credits' }),
            error: translate({ id: 'sections.accountOverview.accountSummary.error' }),
            header: translate({ id: 'sections.accountOverview.accountSummary.header' }),
            makePayment: translate({ id: 'sections.accountOverview.accountSummary.makePayment' }),
            openInvoices: translate({ id: 'sections.accountOverview.accountSummary.openInvoices' }),
            prepayments: translate({ id: 'sections.accountOverview.accountSummary.prepayments' }),
        };
    }, [translate]);
}
