import { Currency } from '@/components/localization';
import { Alert } from '@mui/material';
import { usePaymentTotal, usePayInvoicesState } from '../store';
import { useTexts } from './useTexts';

export function PaymentConfirmationAlert() {
    const { paymentMethod } = usePayInvoicesState();
    const { confirmationAlert: texts } = useTexts();
    const total = usePaymentTotal();

    return (
        <Alert severity="info">
            {texts.youAreUsing} &quot;{paymentMethod?.friendly}&quot; {texts.toMakeA} <Currency value={total} />{' '}
            {texts.payment}
        </Alert>
    );
}
