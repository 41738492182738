import type { FormField, FormApi, FormError, FormValidator, FormFieldMutation } from '../types';
import { useState, useCallback, useEffect, useLayoutEffect, useReducer, useRef } from 'react';

import { useFormInternalContext } from '../utils/formContext';
import { createFieldApi, FormFieldApi } from '../utils/createFieldApi';

export type UseFormField<
    FIELD_NAME extends keyof FIELDS,
    FIELDS extends Record<string, FormField>,
    ERROR extends FormError<keyof FIELDS>,
> = {
    field: Readonly<FIELDS[FIELD_NAME]>;
    errors: Readonly<FormError<keyof FIELDS>[]>;
    fieldApi: Readonly<FormFieldApi<FIELD_NAME, FIELDS, ERROR>>;
    formApi: Readonly<FormApi<FIELDS, ERROR>>;
};

export type UseFormFieldOptions<FIELDS extends Record<string, FormField>, ERROR extends FormError<keyof FIELDS>> = {
    // When dynamic mode is set
    dynamic?: {
        field: FormField;
        clearOnUnmount: 'never' | 'data-only' | 'all';
    };
    hidden?: boolean;
    disabled?: boolean;
    required?: boolean;
    mutation?: FormFieldMutation<FIELDS, ERROR>;
    validations?: Record<string, FormValidator<FIELDS, ERROR>>;
    fieldsOfInterest?: (keyof FormField | 'errors')[];
};

export type LazyUseFormFieldOptions<
    FIELDS extends Record<string, FormField>,
    ERROR extends FormError<keyof FIELDS>,
> = () => UseFormFieldOptions<FIELDS, ERROR>;

const emptyPlaceholder = {};

/**
 * React Hook connecting to a single Form Field and exposing its Field API.
 * TODO: Add documentation for lazy initialization
 *
 * @param fieldName Target Field name.
 * @param options.mutation Optional Mutation to register for the field
 * @param options.validations Optional Record of validation to register for the field
 * @param options.fieldsOfInterest Optional list of Form Fields that will trigger a re-render of the parent React Component. Defaults to All Fields.
 * @returns [field, fieldErrors, fieldApi, formApi]
 */
export function useFormField<
    FIELD_NAME extends keyof FIELDS,
    FIELDS extends Record<string, FormField>,
    ERROR extends FormError<keyof FIELDS>,
>(fieldName: FIELD_NAME, options?: UseFormFieldOptions<FIELDS, ERROR>): UseFormField<FIELD_NAME, FIELDS, ERROR>;
export function useFormField<
    FIELD_NAME extends keyof FIELDS,
    FIELDS extends Record<string, FormField>,
    ERROR extends FormError<keyof FIELDS>,
>(fieldName: FIELD_NAME, lazyOptions?: LazyUseFormFieldOptions<FIELDS, ERROR>): UseFormField<FIELD_NAME, FIELDS, ERROR>;
export function useFormField<
    FIELD_NAME extends keyof FIELDS,
    FIELDS extends Record<string, FormField>,
    ERROR extends FormError<keyof FIELDS>,
>(
    fieldName: FIELD_NAME,
    maybeLazyOptions?: UseFormFieldOptions<FIELDS, ERROR> | LazyUseFormFieldOptions<FIELDS, ERROR>,
): UseFormField<FIELD_NAME, FIELDS, ERROR> {
    // ------------------------------------------------------------------------
    // Core Form API through React context
    // ------------------------------------------------------------------------

    const { api: formApi } = useFormInternalContext<FIELDS, ERROR>();

    // ------------------------------------------------------------------------
    // Core Field API
    // ------------------------------------------------------------------------

    const [fieldApi, setFieldApi] = useState(() => {
        return createFieldApi<FIELD_NAME, FIELDS, ERROR>(formApi, fieldName);
    });

    useEffect(() => {
        setFieldApi(createFieldApi<FIELD_NAME, FIELDS, ERROR>(formApi, fieldName));
    }, [formApi, fieldName]);

    // ------------------------------------------------------------------------
    // Options initializations
    // ------------------------------------------------------------------------

    // Flag required to avoid unnecessary invocation to `maybeLazyOptions` when is a function
    const rOptionsInitialized = useRef(false);

    // initialize the Options' ref as first thing
    const rOptions = useRef<UseFormFieldOptions<FIELDS, ERROR>>(
        rOptionsInitialized.current
            ? // when already initialized a placeholder empty option object is used
              emptyPlaceholder
            : // the first render, when possible, initialize the ref value
              (typeof maybeLazyOptions === 'function' ? maybeLazyOptions() : maybeLazyOptions) ?? emptyPlaceholder,
    );

    // mark the Options' ref as initialized
    rOptionsInitialized.current = true;

    // Keep options updated when necessary
    useLayoutEffect(() => {
        if (typeof maybeLazyOptions === 'function') {
            // lazy options are read once and then forget
            if (Object.keys(rOptions.current).length === 0) {
                rOptions.current = maybeLazyOptions();
            }
        } else {
            // non-lazy options are updated
            rOptions.current = maybeLazyOptions ?? rOptions.current;
        }
    }, [maybeLazyOptions]);

    // expose computed options for effects usage
    const { mutation, validations, hidden, disabled, required } = rOptions.current;

    // ------------------------------------------------------------------------
    // Dynamic Field Cleanup
    // ------------------------------------------------------------------------

    // when dynamic, cleanup the field accordingly to the provided rules
    useEffect(() => {
        const { dynamic } = rOptions.current;

        if (!dynamic) return;

        const { clearOnUnmount } = dynamic;

        return () => {
            if (clearOnUnmount === 'never') return;

            // when `clearOnUnmount` is set to `all` all subscriptions, field-mutations and field-validations will be removed
            const completeFieldDestruction = clearOnUnmount === 'all';
            formApi.deregisterField(fieldName, completeFieldDestruction);
        };
    }, [formApi, fieldName]);

    // ------------------------------------------------------------------------
    // Local State
    // ------------------------------------------------------------------------

    type State = {
        field: Readonly<FIELDS[FIELD_NAME]>;
        errors: Readonly<FormError<keyof FIELDS>[]>;
    };

    const [, forceRender] = useReducer(s => s + 1, 0);

    // helper function used to initialize and maintain a local stored state
    const getState = useCallback((): State => {
        // Dynamic Field initialization
        const { dynamic } = rOptions.current;

        if (dynamic) {
            const { field } = dynamic;

            // it's safe to invoke multiple times `formApi.registerField`
            // after the first invocation the form simply ignore the request
            formApi.registerField(fieldName, field);
        }

        // return the
        return {
            field: formApi.getField(fieldName),
            errors: formApi.getFieldErrors(fieldName),
        };
    }, [formApi, fieldName]);

    // initialize the local state from the very begin, to ensure data consistency across different render passes
    const rState = useRef<State>(getState());

    // it ensures to recreate the field values when fieldName change
    // useLayoutEffect(() => void getState(true), [getState, fieldName])
    useLayoutEffect(() => void Object.assign(rState.current, getState()), [getState, fieldName]);

    // ------------------------------------------------------------------------
    // Form Field Subscription
    // ------------------------------------------------------------------------

    useEffect(() => {
        let effectCleared = false;

        const { fieldsOfInterest } = rOptions.current;

        const unsubscribe = formApi.subscribeToField(fieldName, (field, errors, _formApi) => {
            if (effectCleared) return;

            const changedFields = new Set<keyof FormField | 'errors'>();

            if (fieldsOfInterest?.length) {
                for (const key of Object.keys(field)) {
                    const fieldKey = key as keyof FormField;

                    if (field[fieldKey] !== rState.current.field[fieldKey]) {
                        changedFields.add(fieldKey);
                    }
                }
            }

            rState.current.field = field;
            rState.current.errors = errors;

            if (!fieldsOfInterest?.length) return forceRender();

            if (fieldsOfInterest.some(f => changedFields.has(f))) {
                forceRender();
            }
        });

        return () => {
            effectCleared = true;
            unsubscribe();
        };
    }, [formApi, fieldName]);

    // ------------------------------------------------------------------------
    // Add/Remove mutations
    // ------------------------------------------------------------------------

    useEffect(() => {
        if (mutation) {
            // add new mutation
            fieldApi.addMutation(mutation);
        }

        return () => {
            if (mutation) {
                // if it was previously registered remove it
                fieldApi.removeMutation();
            }
        };
    }, [fieldApi, mutation]);

    // ------------------------------------------------------------------------
    // Force Field meta states
    // ------------------------------------------------------------------------

    useEffect(() => {
        if (hidden !== undefined) {
            fieldApi.setVisible(!hidden);
        }
    }, [fieldApi, hidden]);

    useEffect(() => {
        if (required !== undefined) {
            fieldApi.setRequired(required);
        }
    }, [fieldApi, required]);

    useEffect(() => {
        if (disabled !== undefined) {
            fieldApi.setDisabled(disabled);
        }
    }, [fieldApi, disabled]);

    // ------------------------------------------------------------------------
    // Add/Remove validations
    // ------------------------------------------------------------------------

    useEffect(() => {
        if (validations) {
            // add new validations
            for (const [validationID, validator] of Object.entries(validations)) {
                fieldApi.addValidation(validationID, validator);
            }
        }

        return () => {
            if (validations) {
                // remove previously registered validation
                for (const validationID of Object.keys(validations)) {
                    fieldApi.removeValidation(validationID);
                }
            }
        };
    }, [fieldApi, validations]);

    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------

    return { field: rState.current.field, errors: rState.current.errors, fieldApi, formApi };
}
