import { useAccountId } from '@/atoms/session';
import { useQueryPaymentAccounts } from '@/common/hooks/query/useQueryPaymentAccounts';
import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { Alert, Box, CircularProgress, Grid } from '@mui/material';
import { useMemo } from 'react';
import { PaymentAccountCard } from './PaymentMethodCard/PaymentAccountCard';
import { useTexts } from './useTexts';

export function PaymentMethodList() {
    const texts = useTexts();
    const accountId = useAccountId();

    const { data: paymentMethods, status } = useQueryPaymentAccounts(accountId || '');

    const creditCardEnabled = useFeatureEnabled('enableCreditCardPayment');
    const achEnabled = useFeatureEnabled('enableACHPayment');

    const paymentAlertText = useMemo(() => {
        if (creditCardEnabled && achEnabled) {
            return texts.noPaymentMethodsAlert;
        } else if (creditCardEnabled && !achEnabled) {
            return texts.noPaymentMethodsAlertCreditCard;
        } else if (!creditCardEnabled && achEnabled) {
            return texts.noPaymentMethodsAlertACH;
        } else {
            return texts.noPaymentMethodsAlert;
        }
    }, [creditCardEnabled, achEnabled, texts]);

    return (
        <>
            {status == 'loading' && <CircularProgress />}
            {status == 'error' && <Alert severity="error">Failed to load payment methods on file.</Alert>}
            {status == 'success' && (
                <>
                    {!paymentMethods && <Alert severity="info">{paymentAlertText}</Alert>}

                    {!!paymentMethods && (
                        <>
                            {paymentMethods.length == 0 && <Alert severity="info">{paymentAlertText}</Alert>}

                            {paymentMethods.length > 0 && (
                                <Box sx={{ pb: 2, ml: -2 }}>
                                    <Grid container spacing={2}>
                                        {paymentMethods.map((p, i) => (
                                            <Grid item key={i} xs={12} sm={12} md={6} lg={4}>
                                                <PaymentAccountCard paymentAccount={p} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
}
