import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            emailLabel: translate({ id: `sections.forgotPassword.emailLabel` }),
            resetPassword: translate({ id: `sections.forgotPassword.resetPassword` }),
            enterEmail: translate({ id: `sections.forgotPassword.enterEmail` }),
            sendEmail: translate({ id: `sections.forgotPassword.sendEmail` }),
            failedToSendEmail: translate({ id: `sections.forgotPassword.failedToSendEmail` }),
            emailHasBeenSent: translate({ id: `sections.forgotPassword.emailHasBeenSent` }),
            resetRequested: translate({ id: `sections.forgotPassword.resetRequested` }),
            close: translate({ id: `sections.forgotPassword.close` }),
            mustBeValidEmail: translate({ id: `sections.emailRequirements.mustBeValidEmail` }),
        };
    }, [translate]);
}
