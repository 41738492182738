import { Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { PaymentAccountTypes, TransactionSetupMethod } from '@/common/types/payments';
import { useHumanizedPaymentAccountTypes } from '@/common/hooks/payments';
import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { AchEntry } from './AchEntry';
import { CreditCardEntry } from './CreditCardEntry';
import { useTexts } from './useTexts';

interface Props {
    achInput: AchInput;
    onAddCreditCardSuccess: (id: string) => Promise<void>;
    onCancelCreditCardEntry: () => void;
    paymentType: PaymentAccountTypes;
    setAchInput: (input: AchInput) => void;
    setPaymentType: (type: PaymentAccountTypes) => void;
}

export interface AchInput {
    accountNumber: string;
    routingNumber: string;
}

export function DrawerContent(props: Props) {
    const { achInput, onAddCreditCardSuccess, onCancelCreditCardEntry, paymentType, setAchInput, setPaymentType } =
        props;
    const texts = useTexts();
    const humanizedPaymentAccountTypes = useHumanizedPaymentAccountTypes();

    const handleChange = event => {
        setPaymentType(event.target.value);
    };

    const creditCardEnabled = useFeatureEnabled('enableCreditCardPayment');
    const achEnabled = useFeatureEnabled('enableACHPayment');

    if (creditCardEnabled && !achEnabled) {
        setPaymentType(PaymentAccountTypes.CREDIT_CARD);
    } else if (!creditCardEnabled && achEnabled) {
        setPaymentType(PaymentAccountTypes.ACH);
    }

    return (
        <Stack direction="column" spacing={2} divider={creditCardEnabled && achEnabled && <Divider />}>
            <FormControl component="fieldset">
                {creditCardEnabled && achEnabled && (
                    <Typography gutterBottom sx={{ fontWeight: 'bold' }}>
                        {texts.selectPaymentType}
                    </Typography>
                )}
                {creditCardEnabled && !achEnabled && <Typography gutterBottom>{texts.inputCreditCard}</Typography>}
                {!creditCardEnabled && achEnabled && <Typography gutterBottom>{texts.inputACH}</Typography>}
                <RadioGroup value={paymentType} onChange={handleChange}>
                    {creditCardEnabled && achEnabled && (
                        <>
                            <FormControlLabel
                                control={<Radio />}
                                label={humanizedPaymentAccountTypes[PaymentAccountTypes.CREDIT_CARD]}
                                value={PaymentAccountTypes.CREDIT_CARD}
                            />
                            <FormControlLabel
                                control={<Radio />}
                                label={humanizedPaymentAccountTypes[PaymentAccountTypes.ACH]}
                                value={PaymentAccountTypes.ACH}
                            />
                        </>
                    )}
                </RadioGroup>
            </FormControl>

            {((achEnabled && creditCardEnabled && paymentType === PaymentAccountTypes.ACH) ||
                (achEnabled && !creditCardEnabled)) && <AchEntry achInput={achInput} setAchInput={setAchInput} />}

            {((creditCardEnabled && achEnabled && paymentType === PaymentAccountTypes.CREDIT_CARD) ||
                (creditCardEnabled && !achEnabled)) && (
                <CreditCardEntry
                    onCancel={onCancelCreditCardEntry}
                    onSuccess={onAddCreditCardSuccess}
                    transactionSetupMethod={TransactionSetupMethod.PaymentAccountCreate}
                />
            )}
        </Stack>
    );
}
