import { useEffect, useState } from 'react';
import { isServerError } from '@/utils/ServerError';
import { getLocations } from '@/api/serviceLocations';
import { getDefaultServiceLocationId } from '@/api/accounts';
import { useRequestServiceState } from '../../store';
import { setLocation, setLocations, setStatus } from './store';

export const useData = (accountId: string) => {
    const [isStale, setIsStale] = useState<boolean>(true);
    const request = useRequestServiceState();

    useEffect(() => {
        let mounted = true;

        const getSettings = async (accountId: string) => {
            try {
                setStatus('loading');
                const response = await getLocations(accountId);
                const defaultLocationId = await getDefaultServiceLocationId(accountId);

                if (isServerError(response)) {
                    setStatus('error');
                }

                if (mounted && !!response) {
                    setStatus('success');
                    setLocations(response);

                    if (request.serviceLocation) {
                        setLocation(request.serviceLocation);
                    } else {
                        const matchingLocation = response.find(
                            l => l.serviceLocationId == defaultLocationId && !l.validationErrors,
                        );
                        const firstValidLocation = !matchingLocation ? response.find(l => !l.validationErrors) : null;
                        setLocation(matchingLocation || firstValidLocation || null);
                    }
                }
            } catch (error) {
                if (mounted) {
                    setStatus('error');
                }
            } finally {
                setIsStale(false);
            }
        };

        if (isStale && !!accountId) {
            getSettings(accountId);
        }

        return () => {
            mounted = false;
        };
    }, [accountId, isStale, request]);

    return;
};
