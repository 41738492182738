import { ServiceLocation } from '@/api/serviceLocations';
import { useMemo } from 'react';
import { formatAddress } from '../utils/formatAddress';

export const useServiceLocationAddress = (l: ServiceLocation | null) => {
    const address = useMemo(() => {
        return formatAddress(l);
    }, [l]);

    return address;
};

export const useFormattedAddress = (
    street1: string,
    street2: string,
    city: string,
    stateAbbreviation: string,
    postalCode: string,
    countryCode: string,
) => {
    const address = useMemo(() => {
        let address = '';
        if (street1) {
            address += `${street1},`;
        }
        if (street2) {
            address += ` ${street2},`;
        }
        if (city) {
            address += ` ${city},`;
        }
        if (stateAbbreviation) {
            address += ` ${stateAbbreviation}`;
        }
        if (postalCode) {
            address += ` ${postalCode},`;
        }
        if (countryCode) {
            address += ` ${countryCode}`;
        }

        return address
            .split(',')
            .map(x => x.trim())
            .filter(x => !!x)
            .join(', ');
    }, [city, countryCode, postalCode, stateAbbreviation, street1, street2]);

    return address;
};
