import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useCallback } from 'react';
import { AgreementStatus } from '@/common/types/agreements';
import { useTexts } from '../../../useTexts';

interface Props {
    status: AgreementStatus | null;
    setStatus: (status: AgreementStatus | null) => void;
}

export function StatusFilter(props: Props) {
    const { setStatus, status: statusProp } = props;
    const status = !!statusProp ? statusProp : '';
    const texts = useTexts();

    const handleChange = useCallback(
        e => {
            const val = e.target.value;

            if (!val) {
                setStatus(null);
            } else {
                setStatus(val);
            }
        },
        [setStatus],
    );

    return (
        <Select value={status} displayEmpty onChange={handleChange}>
            <MenuItem value="">{texts.showAllAgreements}</MenuItem>
            <MenuItem value={AgreementStatus.ACTIVE}>{texts.showActiveAgreements}</MenuItem>
            <MenuItem value={AgreementStatus.CANCELLED}>{texts.showCancelledAgreements}</MenuItem>
            <MenuItem value={AgreementStatus.COMPLETED}>{texts.showCompletedAgreements}</MenuItem>
        </Select>
    );
}
