import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AccountSummary } from '@/api/accounts';
import { Currency } from '@/components/localization';
import { SummaryValue } from './SummaryValue';
import { useTexts } from './useTexts';

const useStyles = makeStyles({
    columnContainer: {
        minInlineSize: 'fit-content',
    },
});

interface Props {
    data: AccountSummary;
}

export const Content = (props: Props) => {
    const { data } = props;
    const texts = useTexts();
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs className={classes.columnContainer}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item container>
                            <SummaryValue
                                title={texts.accountBalance}
                                value={<Currency value={data.accountBalance || 0} />}
                            />
                        </Grid>
                        <Grid item container>
                            <SummaryValue
                                title={texts.openInvoices}
                                value={<Currency value={data.openBalance || 0} />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs className={classes.columnContainer}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item container>
                            <SummaryValue title={texts.credits} value={<Currency value={data.credits || 0} />} />
                        </Grid>
                        <Grid item container>
                            <SummaryValue
                                title={texts.prepayments}
                                value={<Currency value={data.prePayments || 0} />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

Content.displayName = 'Content';
