import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            accountNumber: translate({ id: 'sections.paymentMethods.drawer.accountNumber' }),
            errorAddingAch: translate({
                id: 'sections.paymentMethods.drawer.errorAddingAch',
            }),
            errorAddingAchAccountNumber: translate({
                id: 'sections.paymentMethods.drawer.errorAddingAchAccountNumber',
            }),
            errorAddingCreditCard: translate({
                id: 'sections.paymentMethods.drawer.errorAddingCreditCard',
            }),
            errorLoadingIframe: translate({ id: 'sections.paymentMethods.drawer.errorLoadingIframe' }),
            errorMakingPayment: translate({
                id: 'sections.paymentMethods.drawer.errorMakingPayment',
            }),
            invalidAccountNumber: translate({ id: 'sections.paymentMethods.drawer.invalidAccountNumber' }),
            invalidRoutingNumber: translate({ id: 'sections.paymentMethods.drawer.invalidRoutingNumber' }),
            notImplementedYet: translate({ id: 'global.notImplementedYet' }),
            routingNumber: translate({ id: 'sections.paymentMethods.drawer.routingNumber' }),
            selectPaymentType: translate({ id: 'sections.paymentMethods.drawer.selectPaymentType' }),
            inputCreditCard: translate({ id: 'sections.paymentMethods.drawer.inputCreditCard' }),
            inputACH: translate({ id: 'sections.paymentMethods.drawer.inputACH' }),
            successfullyAddedAch: translate({
                id: 'sections.paymentMethods.drawer.successfullyAddedAch',
            }),
            successfullyAddedCreditCard: translate({
                id: 'sections.paymentMethods.drawer.successfullyAddedCreditCard',
            }),
            title: translate({ id: 'sections.paymentMethods.drawer.title' }),
        };
    }, [translate]);
}
