import type {
    FormField,
    FormApi,
    FormData,
    FormError,
    FormFieldMutation,
    FormMeta,
    FormMutation,
    FormState,
    FormValidator,
    FormValues,
    FormStatus,
    FormMode,
} from './types';
import { castDraft, Draft, produce } from 'immer';

export type FormConfig<FIELDS extends Record<string, FormField>, ERROR extends FormError<keyof FIELDS>> = {
    mode?: FormMode;
    overrideValues?: Partial<FormValues<FIELDS>>;
    formMutations?: { id: string; mutation: FormMutation<FIELDS, ERROR> }[];
    fieldMutations?: { field: keyof FIELDS; mutation: FormFieldMutation<FIELDS, ERROR> }[];
    validations?: {
        id: string;
        validator: FormValidator<FIELDS, ERROR>;
        fields?: keyof FIELDS;
    }[];
};

// ---------------------------------------------------------------------------
// Debug output styles
// ---------------------------------------------------------------------------

const prefixStyles = [
    'color: lightCoral',
    'font-size: 12px',
    'font-weight: bolder',
    'padding-left: 10px',
    'padding-right: 5px',
].join(';');

const contextStyles = ['color: goldenRod', 'font-size: 12px', 'font-weight: bolder', 'padding-right: 10px'].join(';');

const emptyErrorArray = [];

/**
 *
 * @param id Form unique identifier. Used for debug purposes and for React Context access.
 * @param baseFields Form Fields configuration.
 * @param config Optional Advanced configuration.
 * @returns A valid FormState object, exposing the Form public API and direct access to the internal `store`. The `store` is meant to be used only by provided `React hooks` or advanced usage. It must be considered `readonly`.
 */
export function createForm<FIELDS extends Record<string, FormField>, ERROR extends FormError<keyof FIELDS>>(
    id: string,
    baseFields?: FIELDS,
    config?: FormConfig<FIELDS, ERROR>,
): FormState<FIELDS, ERROR> {
    // ---------------------------------------------------
    // Form Mede
    // ---------------------------------------------------

    function isStrictMode() {
        return config?.mode === 'strict';
    }

    function isDynamicMode() {
        return config?.mode !== 'strict';
    }

    // ---------------------------------------------------
    // Debug API
    // ---------------------------------------------------

    let debugMode: 'silent' | 'verbose' = 'silent';

    /**
     * Debug helper used to print out detailed internal events in the browser console.
     * Disabled by default, can be enabled through the public form API.
     *
     * @param context Main identifier, rendered in a different color
     * @param args whatever must be printed to the browser console output
     */
    function log(context: string, ...args: any[]) {
        if (debugMode === 'verbose') {
            console.log(`%c[${id}] %c${context}`, prefixStyles, contextStyles, ...args);
        }
    }

    // ---------------------------------------------------
    // Store initialization
    // ---------------------------------------------------

    /**
     * Helper function used to initialize the Form internal store.
     * It takes care of initializing Fields values and their initial values.
     * It also adds sane defaults for provided Fields for missing keys.
     */
    function createStore(): FormData<FIELDS, ERROR> {
        const fields = baseFields
            ? produce(baseFields, draft => {
                  if (config?.overrideValues) {
                      for (const [key, value] of Object.entries(config.overrideValues)) {
                          draft[key].value = value;

                          // when a value override is provided also the `initialValue` will change
                          draft[key].initialValue = value;
                      }
                  }

                  // force sane default values for omitted Field properties
                  for (const key of Object.keys(draft)) {
                      draft[key].initialValue ??= draft[key].value;

                      draft[key].visible ??= true;
                      draft[key].disabled ??= false;
                      draft[key].required ??= false;
                      draft[key].validating ??= false;
                      draft[key].status ??= 'pristine';
                  }
              })
            : // If `baseFields` is missing set the form to an empty object
              ({} as FIELDS);

        const initialValues = {} as FormValues<FIELDS>;
        let initialStatus: FormStatus = 'pristine';

        for (const [key, field] of Object.entries(fields)) {
            initialValues[key as keyof FIELDS] = field.value;

            // TODO: should it properly reduce also to valid|invalid?
            initialStatus = field.status !== 'pristine' ? 'indeterminate' : initialStatus;
        }

        return {
            id,
            initialValues,
            values: { ...initialValues },

            fields,
            errors: {},

            meta: {
                disabled: false,
                submitting: false,
                validating: false,

                status: initialStatus,
            },
        };
    }

    /**
     * Form internal Store state.
     * This object should never been modified directly but always through `immer`,
     * and only it's properties. Never the object itself.
     */
    const store = createStore();

    // ---------------------------------------------------
    // Store maintenance
    // ---------------------------------------------------

    /**
     * Helper used by the Form `reset` API.
     *
     * @param mode Update fields mode. `preserve` updates the provided fields initial values but leaves untouched the current field values and states.
     * @param initialValues Partial object of new initial values for the Form Fields.
     */
    function updateFieldInitialValues(mode: 'force' | 'preserve', initialValues?: Partial<FormValues<FIELDS>>) {
        if (initialValues) {
            store.initialValues = produce(store.initialValues, draft => {
                for (const [fieldName, value] of Object.entries(initialValues)) {
                    draft[fieldName] = value;
                }
            });
        }

        store.fields = produce(store.fields, draft => {
            for (const [fieldName, field] of Object.entries(draft)) {
                if (mode === 'force') {
                    field.value = store.initialValues[fieldName as keyof FormValues<FIELDS>];
                    field.status = 'pristine';
                }
            }
        });

        store.values = produce(store.values, draft => {
            for (const fieldName of Object.keys(draft)) {
                draft[fieldName] = store.fields[fieldName as keyof FIELDS].value;
            }
        });
    }

    // ---------------------------------------------------
    // Private State
    // ---------------------------------------------------

    // Stable map of Errors grouped by Fields. The Map is updated every time any error change, is added, or deleted.
    const errorsByField: Map<keyof FIELDS, ERROR[]> = new Map();

    /**
     * Flag used to monitor any global validation running.
     * All Fields validations store their running status in the Field itself but there's non Field for global validations.
     * Hence this variable.
     */
    let globalValidationRunning = false;

    // Validator functions mapped by their unique ID
    const validatorsById: Map<string, FormValidator<FIELDS, ERROR>> = new Map();

    // Inverse Map connecting Validator functions to their IDs
    const validatorIdByFunction: Map<FormValidator<FIELDS, ERROR>, string> = new Map();

    // Validator functions mapped by their fields, or global.
    const validatorsByField: Map<keyof FIELDS, Set<FormValidator<FIELDS, ERROR>>> = new Map();

    /**
     * Map of running async validations. Every async validation register itself when it starts.
     * Only the latest ticket is kept. All the stale validations are simply ignored.
     */
    const activeAsyncValidation: Map<FormValidator<FIELDS, ERROR>, number> = new Map();

    /**
     * Map of all the running async validation grouped per Field.
     * This map is used to track when all async validations of a given Field have finished.
     */
    const runningValidationsByField: Map<keyof FIELDS, Set<string>> = new Map();

    // ---------------------------------------------------
    // Batched validation
    // ---------------------------------------------------

    /**
     * Active validation Promise.
     * Every time a new validation is requested the invoker receives a Promise.
     * The Form keep only a single validation Promise at time and it resolves it when all the running
     * async validations complete.
     */
    let validationPending: Promise<boolean> | undefined;

    /**
     * Helper function used to resolve the pending Promise for batched notification of running async validations.
     */
    let resolvePendingValidation: (value: boolean) => void = () => undefined;

    /**
     * Helper function used by `api.validate`.
     * @returns A batched Promise that will resolve when all pending async validations will complete. If no async validation is running it will resolve immediately.
     */
    function waitForValidation() {
        if (!store.meta.validating) {
            const isValid = store.meta.status === 'valid';

            log('resolvePendingValidation SYNC', isValid);

            if (validationPending) {
                resolvePendingValidation(isValid);
            }

            return Promise.resolve(isValid);
        }

        if (!validationPending) {
            validationPending = new Promise<boolean>(resolve => {
                resolvePendingValidation = (value: boolean) => {
                    log('resolvePendingValidation ASYNC', value);

                    resolve(value);

                    // reset the resolver
                    resolvePendingValidation = () => undefined;

                    // clear the pending promise
                    validationPending = undefined;
                };
            });
        }

        return validationPending;
    }

    // ---------------------------------------------------
    // Fields Mutation
    // ---------------------------------------------------

    /**
     * Map of all registered Form Mutations.
     */
    const formMutations: Map<string, FormMutation<FIELDS, ERROR>> = new Map();

    /**
     * Map of all registered Field Mutations. Only one Mutation per Field is allowed.
     */
    const fieldMutations: Map<keyof FIELDS, FormFieldMutation<FIELDS, ERROR>> = new Map();

    /**
     * Helper function used to process Fields mutations after one or more Form Fields change through the Form API.
     *
     * @param initialChangedValues Object containing changed field values
     * @param fieldToSkip Optional field that should not apply its registered Field Mutation. Required for Single Field changes to avoid self-induced mutations.
     * @returns Object of changed field values including applied field and form mutations.
     */
    function applyMutations<FIELD_NAME extends keyof FIELDS>(
        initialChangedValues: Partial<FormValues<FIELDS>>,
        fieldToSkip?: FIELD_NAME,
    ): Partial<FormValues<FIELDS>> {
        const changedValues = { ...initialChangedValues };

        // Apply all Fields mutations.
        // ATTENTION: The order doesn't matter unless there are chained Field mutations. That should be discouraged.
        // For dependant or chained Mutations a user should opt to create a Form Mutation.
        for (const [targetFieldName, fieldMutation] of fieldMutations) {
            // skip the mutation for the original changed field, if provided
            if (fieldToSkip && targetFieldName === fieldToSkip) continue;

            if (fieldMutation) {
                const mutatedValue = fieldMutation(api, changedValues, initialChangedValues, targetFieldName);

                if (store.fields[targetFieldName].value !== mutatedValue) {
                    // if the mutation output differs from the current Form values the Changes Map it's updated
                    changedValues[targetFieldName] = mutatedValue;
                } else {
                    // if the mutation output equals to the current Form values the field is removed from the Changes Map
                    delete changedValues[targetFieldName as string];
                }
            }
        }

        // Apply all Form Mutations.
        // ATTENTION: All Form Mutations are applied in their registration order.
        // Complex scenario should be resolved inside a single Form Mutation.
        for (const [, formMutation] of formMutations) {
            const mutatedValues = formMutation(api, changedValues, initialChangedValues);

            if (mutatedValues) {
                // shallow diff to prune out unmodified fields
                for (const [targetFieldName, mutatedValue] of Object.entries(mutatedValues)) {
                    if (store.fields[targetFieldName].value !== mutatedValue) {
                        // if the mutation output differs from the current Form values the Changes Map it's updated
                        changedValues[targetFieldName as keyof FIELDS] = mutatedValue;
                    } else {
                        // if the mutation output equals to the current Form values the field is removed from the Changes Map
                        delete changedValues[targetFieldName];
                    }
                }
            }
        }

        return changedValues;
    }

    // ---------------------------------------------------
    // Pub-Sub subscriptions
    // ---------------------------------------------------

    type FieldSubscription = [
        field: keyof FIELDS,
        subscriber: (
            field: Readonly<FIELDS[keyof FIELDS]>,
            errors: Readonly<FormError<keyof FIELDS>[]>,
            api: Readonly<FormApi<FIELDS, ERROR>>,
        ) => void,
    ];

    /**
     * Collection of subscribers to a Field's update.
     * A subscription is linked to a single Field.
     */
    const fieldSubscribers = new Set<FieldSubscription>();

    // ----------------------------

    type FormSubscription = (api: Readonly<FormApi<FIELDS, ERROR>>) => void;

    /**
     * Collection of subscribers to any From update.
     */
    const formSubscribers = new Set<FormSubscription>();

    // ----------------------------

    type FormMetaSubscription = (meta: Readonly<FormMeta>, api: Readonly<FormApi<FIELDS, ERROR>>) => void;

    /**
     * Collection of subscribers interested only when the From Meta states change.
     */
    const formMetaSubscribers = new Set<FormMetaSubscription>();

    // ---------------------------------------------------
    // Form notifications
    // ---------------------------------------------------

    /**
     * Batched Form Notification
     * All notification emitted to Form Subscriptions are batched.
     */
    let notifyFormSubscribersPending: Promise<void> | undefined;

    /**
     * Helper function used to notify Form Subscribers.
     * The notification is batched.
     */
    function notifyFormSubscribers() {
        if (!notifyFormSubscribersPending) {
            notifyFormSubscribersPending = Promise.resolve().then(() => {
                log('notifyFormSubscribers');

                formSubscribers.forEach(subscriber => {
                    subscriber(api);
                });

                notifyFormSubscribersPending = undefined;
            });
        }

        return notifyFormSubscribersPending;
    }

    // ---------------------------------------------------
    // Form META notifications
    // ---------------------------------------------------

    /**
     * Batched Form Meta Notification
     * All notification emitted to Form Meta Subscriptions are batched.
     */
    let notifyMetaSubscribersPending: Promise<void> | undefined;

    /**
     * Helper function used to notify Form Meta Subscribers.
     * The notification is batched.
     */
    function notifyMetaSubscribers() {
        if (!notifyMetaSubscribersPending) {
            notifyMetaSubscribersPending = Promise.resolve().then(() => {
                const meta = api.getMeta();

                log('notifyMetaSubscribers');

                formMetaSubscribers.forEach(subscriber => {
                    subscriber(meta, api);
                });

                notifyMetaSubscribersPending = undefined;
            });
        }

        return notifyMetaSubscribersPending;
    }

    // ---------------------------------------------------
    // Fields notifications
    // ---------------------------------------------------

    /**
     * Helper function used to notify all Field Subscribers for a specific Field.
     */
    function notifyFieldSubscriber<FIELD_NAME extends keyof FIELDS>(fieldName: FIELD_NAME) {
        if (store.fields[fieldName]) {
            const field = api.getField(fieldName);
            const errors = api.getFieldErrors(fieldName);

            log('notifyFieldSubscriber', fieldName);

            fieldSubscribers.forEach(([subName, subscriber]) => {
                if (fieldName === subName) {
                    subscriber(field, errors, api);
                }
            });
        }
    }

    /**
     * Helper function used to notify all Field Subscribers of a list of changed Fields.
     */
    function notifyFieldSubscribers(fieldNames: (keyof FIELDS)[]) {
        for (const fieldName of fieldNames) {
            notifyFieldSubscriber(fieldName);
        }
    }

    // ---------------------------------------------------
    // Store and internal State helpers
    // ---------------------------------------------------

    /**
     * Helper function used to refresh the Form Meta `validating` flag.
     * Invoked by the validation logic, after an async validation starts or completes.
     *
     * @param silent Optional flag to prevent the function to notify Form and Form Meta subscribers.
     * @returns A boolean value. When true it means the value of `form.meta.validating` has changed.
     */
    function refreshFormMetaValidatingState(silent?: boolean): boolean {
        const validating = globalValidationRunning || Object.values(store.fields).some(field => field.validating);

        log('refreshFormMetaValidatingState');

        if (validating !== store.meta.validating) {
            store.meta = produce(store.meta, draft => {
                draft.validating = validating;
            });

            // TODO: considering that Form level notification are batched the silent flag could be removed
            if (!silent) {
                notifyMetaSubscribers();
                notifyFormSubscribers();
            }

            return true;
        }

        return false;
    }

    /**
     * Helper function used to refresh a Form Field `status`.
     *
     * @param fieldsToValidate Target Field name
     * @param silent Optional flag to prevent the function to notify Form and Field subscribers.
     * @returns A boolean value. When true it means the value of `form.meta.validating` has changed.
     */
    function refreshFieldsStatus(fieldsToValidate: (keyof FIELDS)[], silent?: boolean): boolean {
        log('refreshFieldsStatus', fieldsToValidate);

        // update single fields validity status
        type ReducedErrors = { [key in keyof FIELDS]: true };
        const reducedErrors = Object.values(store.errors).reduce<ReducedErrors>((acc, error) => {
            // update only fields under active validation
            if (fieldsToValidate.includes(error.field)) {
                acc[error.field] ??= true;
            }

            return acc;
        }, {} as ReducedErrors);

        const prevStoreFields = store.fields;

        store.fields = produce(store.fields, draft => {
            for (const [fieldName, field] of Object.entries(draft)) {
                if (fieldsToValidate.includes(fieldName)) {
                    const hasErrors = reducedErrors[fieldName];
                    field.status = hasErrors ? 'invalid' : 'valid';
                }
            }
        });

        let changed = false;
        for (const [fieldName, field] of Object.entries(store.fields)) {
            const prevFieldStatus = prevStoreFields[fieldName].status;

            if (field.status !== prevFieldStatus) {
                changed = true;

                if (!silent) {
                    notifyFieldSubscriber(fieldName);
                }
            }
        }

        // TODO: considering that Form level notification are batched the silent flag could be removed
        if (changed && !silent) {
            notifyFormSubscribers();
        }

        return changed;
    }

    /**
     * Helper function that keep the Form errors updated.
     * It affects both `store.errors` and `errorsByField`.
     *
     * @param validation Validation result as provided by a registered Form Validator.
     */
    function updateStoreErrors(validation: Record<string, ERROR | null>) {
        const affectedFieldNames = new Set<keyof FIELDS>();

        // update the main errors map
        store.errors = produce<Record<string, ERROR>>(store.errors, draft => {
            for (const [errorId, value] of Object.entries(validation)) {
                const currentError = draft[errorId];

                if (value) {
                    affectedFieldNames.add(value.field);
                } else if (currentError && typeof currentError.field === 'string') {
                    affectedFieldNames.add(currentError.field);
                }

                if (value === null) {
                    // remove nullified Errors
                    delete draft[errorId];
                } else {
                    // updated/add still existing errors
                    draft[errorId] = castDraft(value);
                }
            }
        });

        log('updateStoreErrors', affectedFieldNames);

        for (const fieldName of affectedFieldNames) {
            // fetch or initialize the interested Field array of errors
            const errorsList = errorsByField.get(fieldName) ?? [];

            // update the stored array of errors
            errorsByField.set(
                fieldName,
                produce(errorsList, draft => {
                    for (const [errorId, value] of Object.entries(validation)) {
                        // find for a matching existent error instance
                        const index = draft.findIndex(err => err.id === errorId);

                        if (index > -1) {
                            // always remove the previous instance of a given error
                            draft.splice(index, 1);
                        }

                        if (value !== null) {
                            // add the error again if it's not NULL
                            draft.push(castDraft(value));
                        }
                    }

                    // sort the Field's errors by priority
                    draft.sort(({ priority: prev = 0 }, { priority: next = 0 }) => {
                        return next - prev;
                    });
                }),
            );
        }
    }

    /**
     * Helper function used to refresh the Form Meta `status`.
     *
     * @param silent Optional flag to prevent the function to notify Form and Field subscribers.
     * @returns A boolean value. When true it means the value of `form.meta.validating` has changed.
     */
    function refreshFormStatus(silent?: boolean) {
        type Meta = {
            pristine: boolean;
            indeterminate: boolean;
            valid: boolean;
            invalid: boolean;
        };

        // reduce all Form Fields status and flag to a finite set of boolean values
        const meta = Object.values(store.fields).reduce<Meta>(
            (acc, field) => {
                const { pristine, indeterminate, valid, invalid } = acc;

                return {
                    valid: valid && field.status === 'valid',
                    invalid: invalid || field.status === 'invalid',
                    pristine: pristine && field.status === 'pristine',
                    indeterminate: indeterminate || field.status === 'indeterminate',
                };
            },
            {
                pristine: true,
                indeterminate: false,
                valid: true,
                invalid: false,
            },
        );

        // compute the next Form Status
        const nextStatus: FormStatus = meta.pristine
            ? 'pristine'
            : meta.valid
            ? 'valid'
            : meta.invalid
            ? 'invalid'
            : 'indeterminate';

        log('refreshFormStatus', nextStatus);

        if (nextStatus !== store.meta.status) {
            store.meta = produce(store.meta, draft => {
                draft.status = nextStatus;
            });

            // TODO: considering that Form level notification are batched the silent flag could be removed
            if (!silent) {
                notifyMetaSubscribers();
                notifyFormSubscribers();
            }

            return true;
        }

        return false;
    }

    // ---------------------------------------------------
    // ---------------------------------------------------

    /**
     * Return a field or create it if doesn't exist yet.
     *
     * @param fieldName Name of the field to add
     * @param value Optional value for the field
     * @returns the generated Field or the already existent one.
     */
    function tryToAddDynamicField<FIELD_NAME extends keyof FIELDS>(
        fieldName: FIELD_NAME,
        value?: FIELDS[FIELD_NAME]['value'],
    ): Readonly<FIELDS[FIELD_NAME]> {
        if (store.fields[fieldName]) {
            return store.fields[fieldName];
        }

        store.fields = produce(store.fields, draft => {
            const draftFieldName = fieldName as keyof Draft<FIELDS>;

            draft[draftFieldName] = {
                value: value,
                initialValue: undefined,
                visible: true,
                disabled: false,
                required: false,
                validating: false,
                status: 'pristine',
            } as Draft<FIELDS>[keyof Draft<FIELDS>];
        });

        store.values = produce(store.values, draft => {
            Object.assign(draft, { [fieldName]: store.fields[fieldName].value });
        });

        notifyFieldSubscribers([fieldName]);

        refreshFormStatus();

        notifyMetaSubscribers();
        notifyFormSubscribers();

        return store.fields[fieldName];
    }

    // ---------------------------------------------------
    // Main API
    // ---------------------------------------------------

    const api: Readonly<FormApi<FIELDS, ERROR>> = {
        // ------------------------------------------
        // UTILITIES
        // ------------------------------------------

        setDebugMode: mode => {
            debugMode = mode;
        },

        // ------------------------------------------
        // ERRORS
        // ------------------------------------------

        getErrors: () => {
            return store.errors;
        },

        // @ts-expect-error: TS is unable to reconcile ERROR[] with FormError<FIELD_NAME >[]
        getFieldErrors: fieldName => {
            const field = store.fields[fieldName];

            if (!field) {
                if (isStrictMode()) {
                    throw new Error(
                        `Trying to access an unknown field: ${fieldName} with strict mode enabled. This is a no-op.`,
                    );
                }

                // if the field doesn't exists always return a placeholder array
                return emptyErrorArray;
            }

            const fieldErrors = errorsByField.get(fieldName) ?? [];
            errorsByField.set(fieldName, fieldErrors);

            return fieldErrors;
        },

        // ------------------------------------------
        // FIELDS
        // ------------------------------------------

        registerField: (fieldName, field) => {
            if (isStrictMode()) {
                throw new Error(`Trying to register a field: ${fieldName} with strict mode enabled. This is a no-op.`);
            }

            if (store.fields[fieldName]) {
                return false;
            }

            store.fields = produce(store.fields, draft => {
                const draftFieldName = fieldName as keyof Draft<FIELDS>;
                const draftField = castDraft(field) as Draft<FIELDS>[keyof Draft<FIELDS>];

                draft[draftFieldName] = { ...draftField };

                // apply sane default values
                draft[draftFieldName].initialValue ??= field.value;
                draft[draftFieldName].visible ??= true;
                draft[draftFieldName].disabled ??= false;
                draft[draftFieldName].required ??= false;
                draft[draftFieldName].validating ??= false;
                draft[draftFieldName].status ??= 'pristine';
            });

            store.values = produce(store.values, draft => {
                Object.assign(draft, { [fieldName]: store.fields[fieldName].value });
            });

            notifyFieldSubscribers([fieldName]);

            refreshFormStatus();

            notifyMetaSubscribers();
            notifyFormSubscribers();

            return true;
        },

        deregisterField: (fieldName, destroy = false) => {
            if (isStrictMode()) {
                throw new Error(
                    `Trying to deregister a field: ${fieldName} with strict mode enabled. This is a no-op.`,
                );
            }

            if (destroy) {
                // ---------------------------------------
                // clear field subscriptions

                for (const subscription of fieldSubscribers) {
                    const [field] = subscription;

                    if (field === fieldName) {
                        fieldSubscribers.delete(subscription);
                    }
                }

                // ---------------------------------------
                // clear field validations

                const validators = validatorsByField.get(fieldName);
                if (validators) {
                    for (const validator of validators) {
                        const id = validatorIdByFunction.get(validator);

                        if (id) validatorsById.delete(id);
                        validatorIdByFunction.delete(validator);
                    }
                }
                validatorsByField.delete(fieldName);

                // ---------------------------------------
                // clear field-level mutations

                fieldMutations.delete(fieldName);
            }

            // ---------------------------------------
            // Remove all Fields data form the FormData

            store.fields = produce(store.fields, draft => {
                const draftFieldName = fieldName as keyof Draft<FIELDS>;
                delete draft[draftFieldName];
            });

            store.values = produce(store.values, draft => {
                const draftFieldName = fieldName as keyof Draft<FormValues<FIELDS>>;
                delete draft[draftFieldName];
            });

            store.initialValues = produce(store.initialValues, draft => {
                const draftFieldName = fieldName as keyof Draft<FormValues<FIELDS>>;
                delete draft[draftFieldName];
            });

            store.errors = produce(store.errors, draft => {
                for (const [errorId, error] of Object.entries(draft)) {
                    if (error.field === fieldName) {
                        delete draft[errorId];
                    }
                }
            });

            errorsByField.delete(fieldName);

            notifyFieldSubscribers([fieldName]);

            // refresh form status and notify subscribers
            refreshFormStatus();

            notifyMetaSubscribers();
            notifyFormSubscribers();
        },

        getField: fieldName => {
            if (store.fields[fieldName]) {
                return store.fields[fieldName];
            }

            if (isStrictMode()) {
                throw new Error(
                    `Trying to access an unknown field: ${fieldName} with strict mode enabled. This is a no-op.`,
                );
            }

            return tryToAddDynamicField(fieldName);
        },

        getValues: () => {
            return store.values;
        },

        getInitialValues: () => {
            return store.initialValues;
        },

        // ------------------------------------------
        // CHANGE VALUES
        // ------------------------------------------

        change: (fieldName, value) => {
            log('change', fieldName);

            if (isStrictMode() && !store.fields[fieldName]) {
                throw new Error(
                    `Trying to write an unknown field: ${fieldName} with strict mode enabled. This is a no-op.`,
                );
            }

            const initialChangedValues = {
                [fieldName]: value,
            } as Partial<FormValues<FIELDS>>;

            const changedValues = applyMutations(initialChangedValues, fieldName);

            store.fields = produce(store.fields, draft => {
                for (const [targetFieldName, fieldValue] of Object.entries(changedValues)) {
                    // if the field doesn't exist let's add it
                    if (!draft[targetFieldName]) {
                        draft[targetFieldName as keyof Draft<FIELDS>] = {
                            value: fieldValue,
                            initialValue: undefined,
                            visible: true,
                            disabled: false,
                            required: false,
                            validating: false,
                            status: 'indeterminate',
                        } as Draft<FIELDS>[keyof Draft<FIELDS>];
                    } else {
                        draft[targetFieldName].value = fieldValue;
                        draft[targetFieldName].status = 'indeterminate';
                    }
                }
            });

            store.values = produce(store.values, draft => {
                Object.assign(draft, changedValues);
            });

            notifyFieldSubscribers(Object.keys(changedValues));

            refreshFormStatus();
            notifyFormSubscribers();
        },

        batch: fields => {
            log('batch', fields);

            const initialChangedValues: Partial<FormValues<FIELDS>> = {};

            for (const [fieldName, value] of Object.entries(fields)) {
                initialChangedValues[fieldName as keyof FIELDS] = value;

                if (isStrictMode() && !store.fields[fieldName]) {
                    throw new Error(
                        `Trying to write an unknown field: ${fieldName} with strict mode enabled. This is a no-op.`,
                    );
                }
            }

            const changedValues = applyMutations(initialChangedValues);

            store.fields = produce(store.fields, draft => {
                for (const [targetFieldName, fieldValue] of Object.entries(changedValues)) {
                    // if the field doesn't exist let's add it
                    if (!draft[targetFieldName]) {
                        draft[targetFieldName as keyof Draft<FIELDS>] = {
                            value: fieldValue,
                            initialValue: undefined,
                            visible: true,
                            disabled: false,
                            required: false,
                            validating: false,
                            status: 'indeterminate',
                        } as Draft<FIELDS>[keyof Draft<FIELDS>];
                    } else {
                        draft[targetFieldName].value = fieldValue;
                        draft[targetFieldName].status = 'indeterminate';
                    }
                }
            });

            store.values = produce(store.values, draft => {
                Object.assign(draft, changedValues);
            });

            notifyFieldSubscribers(Object.keys(changedValues));

            refreshFormStatus();
            notifyFormSubscribers();
        },

        reset: (mode = 'preserve', initialValues, silent) => {
            log('reset', mode);

            updateFieldInitialValues(mode, initialValues);

            refreshFormStatus(silent);

            if (!silent) {
                notifyFieldSubscribers(Object.keys(store.fields));
                notifyMetaSubscribers();
                notifyFormSubscribers();
            }
        },

        // ------------------------------------------
        // FIELD META STATE
        // ------------------------------------------

        setFieldDisabledState: (fieldName, value) => {
            log('setFieldDisabledState', fieldName, value);

            if (isStrictMode() && !store.fields[fieldName]) {
                throw new Error(
                    `Trying to write the disabled state for an unknown field: ${fieldName} with strict mode enabled. This is a no-op.`,
                );
            }

            if (isDynamicMode()) {
                // ensure the field exist
                tryToAddDynamicField(fieldName);
            }

            const prevDisabled = store.fields[fieldName].disabled;

            store.fields = produce(store.fields, draft => {
                draft[fieldName as keyof Draft<FIELDS>].disabled = value;
            });

            if (prevDisabled !== store.fields[fieldName].disabled) {
                notifyFieldSubscriber(fieldName);
                notifyFormSubscribers();
            }
        },

        setFieldVisibleState: (fieldName, value) => {
            log('setFieldVisibleState', fieldName, value);

            if (isStrictMode() && !store.fields[fieldName]) {
                throw new Error(
                    `Trying to write the visible state for an unknown field: ${fieldName} with strict mode enabled. This is a no-op.`,
                );
            }

            if (isDynamicMode()) {
                // ensure the field exist
                tryToAddDynamicField(fieldName);
            }

            const prevVisible = store.fields[fieldName].visible;

            store.fields = produce(store.fields, draft => {
                draft[fieldName as keyof Draft<FIELDS>].visible = value;
            });

            if (prevVisible !== store.fields[fieldName].visible) {
                notifyFieldSubscriber(fieldName);
                notifyFormSubscribers();
            }
        },

        setFieldRequiredState: (fieldName, value) => {
            log('setFieldRequiredState', fieldName, value);

            if (isStrictMode() && !store.fields[fieldName]) {
                throw new Error(
                    `Trying to write the required state for an unknown field: ${fieldName} with strict mode enabled. This is a no-op.`,
                );
            }

            if (isDynamicMode()) {
                // ensure the field exist
                tryToAddDynamicField(fieldName);
            }

            store.fields = produce(store.fields, draft => {
                draft[fieldName as keyof Draft<FIELDS>].required = value;

                // changing the REQUIRED state will affect the validation of the field. It's marked to be validate again.
                draft[fieldName as keyof Draft<FIELDS>].status = 'indeterminate';
            });

            notifyFieldSubscriber(fieldName);

            refreshFormStatus();
            notifyFormSubscribers();
        },

        // ------------------------------------------
        // MUTATIONS
        // ------------------------------------------

        addFormMutation: (id, mutation) => {
            if (formMutations.has(id)) {
                throw new Error(`Trying to register a Form Mutation with ID: ${id} but it's already registered.`);
            }

            formMutations.set(id, mutation);
        },

        removeFormMutation: id => {
            return formMutations.delete(id);
        },

        addFieldMutation: (fieldName, mutation) => {
            if (isStrictMode() && !store.fields[fieldName]) {
                throw new Error(
                    `Trying to register a field-level mutation for an unknown field: ${fieldName} with strict mode enabled. This is a no-op.`,
                );
            }

            if (fieldMutations.has(fieldName)) {
                console.warn(`Overwriting the Form Mutation registered for the field: ${fieldName}.`);
            }

            fieldMutations.set(fieldName, mutation);
        },

        removeFieldMutation: fieldName => {
            if (isStrictMode() && !store.fields[fieldName]) {
                throw new Error(
                    `Trying to remove a field-level mutation for an unknown field: ${fieldName} with strict mode enabled. This is a no-op.`,
                );
            }

            return fieldMutations.delete(fieldName);
        },

        // ------------------------------------------
        // META STATES
        // ------------------------------------------

        getMeta: () => {
            return store.meta;
        },

        setDisabled: value => {
            log('setDisabled', value);

            store.meta = produce(store.meta, draft => {
                draft.disabled = value;
            });

            notifyFieldSubscribers(Object.keys(store.fields));
            notifyMetaSubscribers();
            notifyFormSubscribers();
        },

        setSubmitting: value => {
            log('setSubmitting', value);

            store.meta = produce(store.meta, draft => {
                draft.submitting = value;
            });

            notifyFieldSubscribers(Object.keys(store.fields));
            notifyMetaSubscribers();
            notifyFormSubscribers();
        },

        // ------------------------------------------
        // VALIDATION
        // ------------------------------------------

        clearValidation: (...userFields) => {
            log('clearValidation', userFields);

            const targetFields: (keyof FIELDS)[] = userFields.length > 0 ? userFields : Object.keys(store.fields);

            store.errors = produce(store.errors, draft => {
                for (const fieldName of Object.keys(draft)) {
                    if (targetFields.includes(fieldName)) {
                        delete draft[fieldName];
                    }
                }
            });

            store.fields = produce(store.fields, draft => {
                for (const [fieldName, field] of Object.entries(draft)) {
                    if (targetFields.includes(fieldName)) {
                        field.status = 'indeterminate';
                        notifyFieldSubscriber(fieldName);
                    }
                }
            });

            refreshFormStatus();
            notifyFormSubscribers();
        },

        validate: (...userFields) => {
            log('validate', userFields);

            // resolve which fields will be validated
            const fieldsToValidate: (keyof FIELDS)[] = userFields.length > 0 ? userFields : Object.keys(store.fields);

            // always add the special FIELD `global` to any validation
            const fieldsToValidateAndGlobal = [...fieldsToValidate, 'global'];

            // Iterate over all fields to validate
            fieldsValidation: for (const fieldName of fieldsToValidateAndGlobal) {
                if (isStrictMode() && !store.fields[fieldName]) {
                    throw new Error(
                        `Trying to validate an unknown field: ${fieldName} with strict mode enabled. This is a no-op.`,
                    );
                }

                // access and/or initialize the list of running validation for the Field
                const runningValidations = runningValidationsByField.get(fieldName) ?? new Set();
                runningValidationsByField.set(fieldName, runningValidations);

                // retrieve all registered validators for the Field
                const validators = validatorsByField.get(fieldName);

                // if no validation exist move to the next Field
                if (!validators) continue fieldsValidation;

                // Unique ticket for async validations
                const validationTicket = performance.now();

                // Iterate over the registered validator functions for the Field
                validationLoop: for (const validator of validators) {
                    // Always pass the name of the Field under validation unless it's `global`
                    const validatorFieldName = fieldName === 'global' ? undefined : fieldName;

                    // Run the validation
                    const validation = validator(api, validatorFieldName);

                    // If the Validation returns `undefined` move to the next validation
                    if (!validation) continue validationLoop;

                    // retrieve the Unique ID used to register the validation
                    const validatorId = validatorIdByFunction.get(validator);
                    if (!validatorId) {
                        throw new Error(`Impossible to retrieve validator ID for function: ${validator.toString()}`);
                    }

                    // If the validation returns a Promise it means it's async, otherwise will be treated as sync.
                    if (validation instanceof Promise) {
                        // -------------------------------------------------
                        // ASYNC VALIDATION
                        // -------------------------------------------------

                        // Record the running validator function with the current unique ticket
                        activeAsyncValidation.set(validator, validationTicket);

                        // Add the validation ID to the list of running validation for the Field
                        runningValidations.add(validatorId);

                        if (fieldName === 'global') {
                            // for global validation set the Global Validation Flag to true
                            globalValidationRunning = true;
                        } else {
                            // For Fields validation set the target Field `validating` flag to true
                            store.fields = produce(store.fields, draft => {
                                draft[fieldName as string].validating = true;
                            });

                            notifyFieldSubscriber(fieldName);
                        }

                        // Recompute the Form validating status
                        refreshFormMetaValidatingState();

                        // wait for the resolution of the async validation
                        validation
                            .then(validationResult => {
                                // retrieve the registered ticket for the validator function
                                const latestValidationTicket = activeAsyncValidation.get(validator);

                                // stop if a more recent validation happened
                                if (latestValidationTicket !== validationTicket) return;

                                if (validationResult) {
                                    // if an errors Map it's returned process it

                                    // Dev warning, the registered Validation ID is a reserved error name, used to expose un-managed exceptions
                                    // during the async validation execution.
                                    if (
                                        process.env.NODE_ENV === 'development' &&
                                        validationResult.hasOwnProperty(validatorId)
                                    ) {
                                        console.warn(
                                            `[Form State] Using a validation function UID as an error ID is a noop. (${validatorId})`,
                                        );
                                    }

                                    // always ensure the reserved validation ID it's cleared after a successful validation
                                    const val = {
                                        ...validationResult,
                                        [validatorId]: null,
                                    } as Record<string, ERROR | null>;

                                    // refresh the Field's stored errors
                                    updateStoreErrors(val);
                                } else {
                                    const val = { [validatorId]: null };

                                    // always ensure the reserved validation ID it's cleared after a successful validation
                                    updateStoreErrors(val);
                                }

                                // remove the validation from the list of running async validations for the Field
                                runningValidations.delete(validatorId);

                                // When the list of running async validation for the Field it's empty
                                // set the Field's validating state to FALSE
                                if (runningValidations.size === 0) {
                                    if (fieldName === 'global') {
                                        globalValidationRunning = false;
                                    } else {
                                        store.fields = produce(store.fields, draft => {
                                            draft[fieldName as string].validating = false;
                                        });
                                    }
                                }

                                // clear the queue of running validations
                                activeAsyncValidation.delete(validator);

                                if (fieldName === 'global') {
                                    // update single fields validity status
                                    refreshFieldsStatus(fieldsToValidate, true);

                                    notifyFieldSubscribers(fieldsToValidate);
                                } else {
                                    // update single fields validity status
                                    refreshFieldsStatus([fieldName], true);

                                    notifyFieldSubscriber(fieldName);
                                }

                                // Recompute the Form validating status
                                refreshFormMetaValidatingState();

                                // Recompute the Form status
                                refreshFormStatus();

                                notifyFormSubscribers();

                                if (!store.meta.validating) {
                                    // after refreshing the form validating state notify pending validation listeners
                                    // if all the async validation for the Fields have completed
                                    resolvePendingValidation(store.meta.status === 'valid');
                                }
                            })
                            .catch(reason => {
                                // retrieve the registered ticket for the validator function
                                const latestValidationTicket = activeAsyncValidation.get(validator);

                                // stop if a more recent validation happened
                                if (latestValidationTicket !== validationTicket) return;

                                // Try to infer the best error message
                                const errorMessage =
                                    typeof reason === 'string'
                                        ? reason
                                        : reason instanceof Error
                                        ? reason.message
                                        : 'unknown';

                                // prepare the Unexpected Error object
                                // using its reserved ID
                                const error = {
                                    id: validatorId,
                                    field: fieldName,
                                    message: errorMessage,
                                } as ERROR;

                                // refresh the Field's stored errors
                                updateStoreErrors({ [validatorId]: error });

                                // remove it from the open queue
                                runningValidations.delete(validatorId);

                                // When the list of running async validation for the Field it's empty
                                // set the Field's validating state to FALSE
                                if (runningValidations.size === 0) {
                                    if (fieldName === 'global') {
                                        globalValidationRunning = false;
                                    } else {
                                        store.fields[fieldName] = produce(store.fields[fieldName], draft => {
                                            draft.validating = false;
                                        });
                                    }
                                }

                                // clear the queue of running validations
                                activeAsyncValidation.delete(validator);

                                if (fieldName === 'global') {
                                    // update single fields validity status
                                    refreshFieldsStatus(fieldsToValidate, true);

                                    notifyFieldSubscribers(fieldsToValidate);
                                } else {
                                    // update single fields validity status
                                    refreshFieldsStatus([fieldName], true);

                                    notifyFieldSubscriber(fieldName);
                                }

                                // Recompute the Form validating status
                                refreshFormMetaValidatingState();

                                // Recompute the Form status
                                refreshFormStatus();

                                notifyFormSubscribers();

                                if (!store.meta.validating) {
                                    // after refreshing the form validating state notify pending validation listeners if the validation has been completed
                                    resolvePendingValidation(store.meta.status === 'valid');
                                }
                            });
                    } else {
                        // -------------------------------------------------
                        // SYNC VALIDATION
                        // -------------------------------------------------

                        // Dev warning, the registered Validation ID is a reserved error name, used to expose un-managed exceptions
                        // during the async validation execution.
                        if (process.env.NODE_ENV === 'development' && validation.hasOwnProperty(validatorId)) {
                            console.warn(
                                `[Form State] Using a validation function UID as an error ID is a noop. (${validatorId})`,
                            );
                        }

                        // refresh the Field's stored errors
                        updateStoreErrors(validation as Record<string, ERROR | null>);

                        if (fieldName === 'global') {
                            notifyFieldSubscribers(fieldsToValidate);
                        } else {
                            notifyFieldSubscriber(fieldName);
                        }
                    }
                }
            }

            // update single fields validity status
            // necessary for sync validations
            refreshFieldsStatus(fieldsToValidate);

            refreshFormStatus();

            notifyFormSubscribers();

            // return a promise that resolve when all running validations (sync and async) finish
            return waitForValidation();
        },

        getValidator: id => {
            return validatorsById.get(id);
        },

        addValidation: (id, validator, fieldName) => {
            if (isStrictMode() && !!fieldName && !store.fields[fieldName]) {
                throw new Error(
                    `Trying to register a field-level validation for an unknown field: ${fieldName} with strict mode enabled. This is a no-op.`,
                );
            }

            if (validatorsById.has(id)) {
                throw new Error(`Trying to register a validator with ID: ${id} but it's already registered.`);
            }

            validatorsById.set(id, validator);
            validatorIdByFunction.set(validator, id);

            if (fieldName === undefined) {
                validatorsByField.set('global', validatorsByField.get('global') ?? new Set());
                validatorsByField.get('global')?.add(validator);
            } else {
                // Add it as validation for a single field
                validatorsByField.set(fieldName, validatorsByField.get(fieldName) ?? new Set());
                validatorsByField.get(fieldName)?.add(validator);
            }
        },

        removeValidation: id => {
            const validator = validatorsById.get(id);
            validatorsById.delete(id);

            if (!validator) return false;

            validatorIdByFunction.delete(validator);

            for (const [, validators] of validatorsByField) {
                validators.delete(validator);
            }

            return true;
        },

        // ------------------------------------------
        // SUBSCRIPTIONS
        // ------------------------------------------

        subscribeToField: (fieldName, subscriber) => {
            if (isStrictMode() && !store.fields[fieldName]) {
                throw new Error(
                    `Trying to subscribe to field events for an unknown field: ${fieldName} with strict mode enabled. This is a no-op.`,
                );
            }

            // FIXME: find a way to properly narrow down the type
            const subscription = [fieldName, subscriber] as any as FieldSubscription;
            fieldSubscribers.add(subscription);

            return () => {
                fieldSubscribers.delete(subscription);
            };
        },

        subscribeToForm: subscriber => {
            formSubscribers.add(subscriber);

            return () => {
                formSubscribers.delete(subscriber);
            };
        },

        subscribeToMeta: subscriber => {
            formMetaSubscribers.add(subscriber);

            return () => {
                formMetaSubscribers.delete(subscriber);
            };
        },

        removeAllListeners: () => {
            formSubscribers.clear();
            fieldSubscribers.clear();
            formMetaSubscribers.clear();
        },
    };

    // ---------------------------------------------------------------------------
    // Custom User Config
    // ---------------------------------------------------------------------------

    if (config?.fieldMutations) {
        for (const { field, mutation } of config.fieldMutations) {
            api.addFieldMutation(field, mutation);
        }
    }

    if (config?.formMutations) {
        for (const { id, mutation } of config.formMutations) {
            api.addFormMutation(id, mutation);
        }
    }

    if (config?.validations) {
        for (const { id, validator, fields } of config.validations) {
            api.addValidation(id, validator, fields);
        }
    }

    // ---------------------------------------------------------------------------
    // Exposed API
    // ---------------------------------------------------------------------------

    return {
        api,
        store,
    };
}
