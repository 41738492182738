import type { ScrollbarOptions, Scrollbar } from 'smooth-scrollbar/interfaces';
import type { Props } from '../types';

import { MutableRefObject, useEffect, useRef } from 'react';

export function useScrollbarOptions(props: Props, scrollbarRef: MutableRefObject<Scrollbar | undefined>) {
    const {
        damping = 1,
        thumbMinSize = 40,
        continuousScrolling,
        delegateTo,
        plugins,
        trackVisible = 'always',
        renderByPixels = true,
    } = props;

    const rOptions = useRef<Partial<ScrollbarOptions>>({
        damping,
        thumbMinSize,
        continuousScrolling,
        delegateTo,
        plugins,
        alwaysShowTracks: trackVisible === 'always',
        // improve performances
        renderByPixels,
    });

    useEffect(() => {
        rOptions.current.damping = damping;
        if (!scrollbarRef.current) return;

        scrollbarRef.current.options.damping = damping;
    }, [damping, scrollbarRef]);

    useEffect(() => {
        rOptions.current.thumbMinSize = thumbMinSize;
        if (!scrollbarRef.current) return;

        scrollbarRef.current.options.thumbMinSize = thumbMinSize;
    }, [thumbMinSize, scrollbarRef]);

    useEffect(() => {
        rOptions.current.continuousScrolling = continuousScrolling;
        if (!scrollbarRef.current) return;

        scrollbarRef.current.options.continuousScrolling = !!continuousScrolling;
    }, [continuousScrolling, scrollbarRef]);

    useEffect(() => {
        rOptions.current.delegateTo = delegateTo;
        if (!scrollbarRef.current) return;

        scrollbarRef.current.options.delegateTo = delegateTo ?? null;
    }, [delegateTo, scrollbarRef]);

    useEffect(() => {
        rOptions.current.renderByPixels = renderByPixels;
        if (!scrollbarRef.current) return;

        scrollbarRef.current.options.renderByPixels = renderByPixels;
    }, [renderByPixels, scrollbarRef]);

    useEffect(() => {
        rOptions.current.plugins = plugins;
        if (!scrollbarRef.current || typeof plugins === 'undefined') return;

        scrollbarRef.current.options.plugins = plugins;

        Object.keys(plugins ?? {}).forEach(pluginName =>
            scrollbarRef.current?.updatePluginOptions(pluginName, plugins[pluginName]),
        );
    }, [plugins, scrollbarRef]);

    useEffect(() => {
        const alwaysShowTracks = trackVisible === 'always';
        rOptions.current.alwaysShowTracks = alwaysShowTracks;
        if (!scrollbarRef.current) return;

        scrollbarRef.current.options.alwaysShowTracks = alwaysShowTracks;
    }, [trackVisible, scrollbarRef]);

    return rOptions.current;
}
