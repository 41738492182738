import { ListHeader } from '@/components/cardList';
import { useTexts } from '../../useTexts';
import { Chips } from './Chips';

interface Props {
    openDrawer: () => void;
}

export function ActiveAgreementsListHeader(props: Props) {
    const { openDrawer } = props;
    const texts = useTexts();

    return <ListHeader title={texts.pageTitle} openDrawer={openDrawer} singleLineOnMobile chips={<Chips />} />;
}
