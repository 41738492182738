import { proxy, subscribe } from 'valtio';
import { AuthenticatedUserModel } from './loginService';

// ----------------------------
// Store state
// ----------------------------

type Session =
    | {
          account?: string;
          authorized: false;
          tenantId?: string;
      }
    | {
          authorized: true;
          userName: string;
          token: string;
          refreshToken: string;
          user: AuthenticatedUserModel;
          tenantId?: string;
          account?: Account.AccountSelect;
          expirationInUtc: Date;
      };

// ----------------------------
// Valtio store initialization
// ----------------------------

export const state = proxy<Session>({ authorized: false });

subscribe(state, () => {
    localStorage.setItem('state', JSON.stringify(state));
});

export function setState(newState: Session) {
    Object.assign(state, newState);
}

// ----------------------------
// setter functions
// ----------------------------

export function setSession(
    token: string,
    refreshToken: string,
    user: AuthenticatedUserModel,
    expirationInUtc: string,
    userName?: string,
) {
    state.authorized = true;

    if (state.authorized) {
        state.userName = userName ?? state.userName;
        state.token = token;
        state.refreshToken = refreshToken;
        state.user = user;
        state.expirationInUtc = new Date(expirationInUtc);
    }
}

export function setAccountId(account: Account.AccountSelect) {
    if (state.authorized) {
        state.account = account;
    }
}

export function getAccount() {
    if (state.authorized) {
        return state.account;
    }
}

export function clearSession() {
    state.authorized = false;

    // @ts-expect-error
    delete state.userName;

    // @ts-expect-error
    delete state.token;

    // @ts-expect-error
    delete state.refreshToken;

    // @ts-expect-error
    delete state.roles;

    // @ts-expect-error
    delete state.user;

    delete state.account;
}

export function loadState() {
    const storedStateString = localStorage.getItem('state');
    const localState = storedStateString ? JSON.parse(storedStateString) : { authorized: false };
    setState(localState);
}

// ----------------------------
// selector functions
// ----------------------------

export function isUserAuthorized() {
    return state.authorized;
}

export function getToken() {
    return state.authorized ? state.token : undefined;
}

export function isTokenValid() {
    if (state.authorized) {
        const expirationDate = state.expirationInUtc ?? new Date(new Date().toISOString());
        const date = new Date(new Date().toISOString());

        return expirationDate > date;
    }

    return true;
}

export function getRefreshToken() {
    return state.authorized ? state.refreshToken : undefined;
}

export function getUser() {
    return state.authorized ? state.user : undefined;
}

export function getDisplayName() {
    return state.authorized ? `${state.user.firstName} ${state.user.lastName}` : undefined;
}

export function getFirstName() {
    return state.authorized ? state.user.firstName : undefined;
}
