import { proxy, useSnapshot } from 'valtio';

// Store state
interface SidebarState {
    isOpen: boolean;
}

// Valtio store initialization
export const state = proxy<SidebarState>({ isOpen: false });

// Setter functions
export function openSidebar() {
    state.isOpen = true;
}

export function closeSidebar() {
    state.isOpen = false;
}

export function toggleSidebar() {
    state.isOpen = !state.isOpen;
}

//Hooks
export function useIsOpen() {
    return useSnapshot(state).isOpen;
}
