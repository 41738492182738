import makeStyles from '@mui/styles/makeStyles';
import Container, { ContainerProps } from '@mui/material/Container';

import { toggleCss } from '@/utils/toggleCss';

const useStyles = makeStyles(theme => ({
    contentContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flex: '0 1 auto',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: 0,
        paddingRight: 0,
    },
}));

export function ContentContainer(props: ContainerProps) {
    const { className, ...other } = props;
    const classes = useStyles();

    return (
        <Container className={toggleCss(classes.contentContainer, className)} maxWidth={false} {...other}>
            {props.children}
        </Container>
    );
}
