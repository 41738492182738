import { AgreementDetails } from '@/common/types/agreements';
import { humanizedInvoiceModes } from '@/common/types/invoiceMode';
import { DefinitionListItem } from '@/components/DefinitionListItem';
import { LongDate } from '@/components/localization';
import { Box, Stack } from '@mui/material';
import { OpenPDFButton } from '@/components/OpenPDFButton';
import { useCallback } from 'react';
import { getAgreementReport } from '@/api/agreements';
import { StatusBadge } from '../components/StatusBadge';
import { AgreementContact } from './listItems/AgreementContact';
import { AgreementAddress } from './listItems/AgreementLocation';
import { AutopayInformation } from './listItems/AutopayInformation';
import { Services } from './listItems/Services';
import { useTexts } from './useTexts';

interface Props {
    details: AgreementDetails;
}

export function DetailListItems(props: Props) {
    const {
        details: {
            agreementId,
            agreementServices,
            autoPaymentAccounts,
            billingContact,
            billingLocation,
            endDate,
            hasValidationErrors,
            invoiceMode,
            name,
            serviceContact,
            serviceLocation,
            startDate,
            status,
        },
    } = props;
    const texts = useTexts();

    const onAgreementReportClick = useCallback(() => {
        return getAgreementReport(agreementId);
    }, [agreementId]);

    return (
        <Stack direction="column" spacing={2}>
            <Box display="flex" alignItems="flex-start">
                <Box flexGrow={1}>
                    <DefinitionListItem primary={texts.agreementName} secondary={name} />
                </Box>
                <StatusBadge status={status} />
            </Box>

            {!!startDate && <DefinitionListItem primary={texts.startDate} secondary={<LongDate value={startDate} />} />}

            {!!endDate && <DefinitionListItem primary={texts.endDate} secondary={<LongDate value={endDate} />} />}

            <DefinitionListItem
                primary={texts.serviceContact}
                secondary={<AgreementContact contact={serviceContact} />}
            />

            <DefinitionListItem
                primary={texts.serviceLocation}
                secondary={<AgreementAddress address={serviceLocation} />}
            />

            <DefinitionListItem
                primary={texts.billingContact}
                secondary={<AgreementContact contact={billingContact} />}
            />

            <DefinitionListItem
                primary={texts.billingLocation}
                secondary={<AgreementAddress address={billingLocation} />}
            />
            <DefinitionListItem primary={texts.services} secondary={<Services services={agreementServices} />} />

            <DefinitionListItem primary={texts.invoicingType} secondary={humanizedInvoiceModes[invoiceMode]} />

            <AutopayInformation
                agreementId={agreementId}
                autoPaymentAccounts={autoPaymentAccounts}
                hasValidationErrors={hasValidationErrors}
                status={status}
            />

            <Box>
                <OpenPDFButton onClick={onAgreementReportClick} errorText={texts.failedToDownloadPDF}>
                    {texts.agreement} {texts.pdf}
                </OpenPDFButton>
            </Box>
        </Stack>
    );
}
