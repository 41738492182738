import type { ReactElement } from 'react';

import { useEffect } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { Box } from '@mui/material';

import { ContentContainer } from '@/components/ContentContainer';
import { Content } from '@/components/Content';
import { ContentPaper } from '@/components/ContentPaper';

import { changeLanguage, useActiveLanguage, selectLanguage } from '@/atoms/intl';
import { IntlManager } from '@/intl';
import { useLocalizationSettings } from '@/common/hooks/useLocalizationSettings';

type Props = {
    children: ReactElement;
};

/**
 * react-intl provider.
 * It loads the preferred language translations when the application mounts.
 */
export function IntlProvider(props: Props) {
    const { children } = props;

    const { messages } = useActiveLanguage();
    const { culture } = useLocalizationSettings();

    useEffect(() => {
        // Load initial language as soon as the provider is mounted
        const language = selectLanguage();
        changeLanguage(language);
    }, []);

    // shows a placeholder content until the i18n translations have been loaded
    if (!messages) {
        return (
            <ContentContainer>
                <Content>
                    <ContentPaper fullWidth>
                        <Box width="100%" height="300px" display="flex" justifyContent="center" alignItems="center">
                            Loading Language Data
                        </Box>
                    </ContentPaper>
                </Content>
            </ContentContainer>
        );
    }

    // wrap the whole components' tree with react-intl provider
    return (
        <ReactIntlProvider defaultLocale="en-US" locale={culture} messages={messages}>
            {/* IntlManager is a workaround necessary to expose react-intl API outside the React component's tree */}
            <IntlManager>{children}</IntlManager>
        </ReactIntlProvider>
    );
}
