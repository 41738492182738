import * as _ from 'lodash';
import { ServiceOffering } from '@/api/services';
import { proxy, useSnapshot } from 'valtio';

export interface AddServicesState {
    all: {
        items: ServiceOffering[];
        page: number;
        take: number;
        totalItems: number;
    };
    selected: ServiceOffering[];
    status: 'error' | 'loading' | 'success';
}

export const initialState: AddServicesState = {
    all: {
        items: [],
        page: 0,
        take: 0,
        totalItems: 0,
    },
    selected: [],
    status: 'loading',
};

// Valtio store initialization
const state = proxy<AddServicesState>(initialState);

// Setter functions
export function setAllServices(services: ServiceOffering[], page: number, take: number, totalItems: number) {
    state.all.items = services;
    state.all.page = page;
    state.all.take = take;
    state.all.totalItems = totalItems;
}

export function clearSelectedServices() {
    state.selected = [];
}

export function setSelectedServices(services: ServiceOffering[]) {
    state.selected = [...services];
}

export function addService(service: ServiceOffering) {
    state.selected.push(service);
}

export function setPage(page: number) {
    state.all.page = page;
}

export function setStatus(status: 'error' | 'loading' | 'success') {
    state.status = status;
}

export function removeService(service: ServiceOffering) {
    const updatedServices = _.cloneDeep(state.selected.filter(s => s.serviceId != service.serviceId));
    state.selected = updatedServices;
}

// Hooks
export function useAddServicesState() {
    return useSnapshot(state);
}

export function useIsServiceSelected(service: ServiceOffering) {
    return useSnapshot(state).selected.filter(s => s.serviceId == service.serviceId).length > 0;
}
