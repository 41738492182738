import { PageWrapper } from '@/components/PageWrapper';
import { Section } from '@/components/Section';
import { SectionHeader } from '@/components/SectionHeader';
import { Stack } from '@mui/material';
import { EmailAddress } from './sections/EmailAddress';
import { Password } from './sections/Password';
import { useTexts } from './useTexts';

export function Profile() {
    const texts = useTexts();
    return (
        <PageWrapper>
            <Section>
                <SectionHeader title={texts.header} />
                <Stack spacing={2}>
                    <EmailAddress />
                    <Password />
                </Stack>
            </Section>
        </PageWrapper>
    );
}
