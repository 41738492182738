import { PaymentStatus } from '@/common/types/payments';
import { getPaymentReceipt, getRefundReceipt, PaymentDetail } from '@/api/payments';
import { DefinitionListItem } from '@/components/DefinitionListItem';
import { Currency, ShortDate } from '@/components/localization';
import { Grid, Stack, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { OpenPDFButton } from '@/components/OpenPDFButton';
import { useTexts } from './useTexts';
import { Badge } from './PaymentCard';

interface Props {
    details: PaymentDetail;
}

const useStyles = makeStyles({
    downloadLink: {
        justifyContent: 'flex-start',
    },
});

export function DetailListItems(props: Props) {
    const {
        details: {
            accountType,
            amount,
            associatedInvoiceNumbers,
            paymentDate,
            status,
            paymentId,
            reference,
            refunds,
            balance,
        },
    } = props;
    const texts = useTexts();

    const associatedInvoiceTitle = useMemo(() => {
        if (associatedInvoiceNumbers.length == 1) {
            return texts.associatedInvoiceNumber;
        } else {
            return texts.associatedInvoiceNumbers;
        }
    }, [associatedInvoiceNumbers, texts.associatedInvoiceNumber, texts.associatedInvoiceNumbers]);

    const associatedInvoiceSubTitle = useMemo(() => {
        if (!associatedInvoiceNumbers.length) {
            return texts.none;
        }
        const formattedNumbers = associatedInvoiceNumbers.map(invoiceNumber => `#${invoiceNumber}`);
        return formattedNumbers.join(', ');
    }, [associatedInvoiceNumbers, texts.none]);

    const onPaymentReceiptReportClick = useCallback(() => {
        return getPaymentReceipt(paymentId);
    }, [paymentId]);

    const onRefundReceiptReportClick = useCallback(() => {
        return getRefundReceipt(paymentId);
    }, [paymentId]);

    const classes = useStyles();

    const isRefund = status == PaymentStatus.PARTIALLY_REFUNDED || status == PaymentStatus.REFUNDED;
    const showReceipt = status == PaymentStatus.SUCCEEDED || isRefund;

    return (
        <Stack direction="column" spacing={2}>
            <DefinitionListItem primary={associatedInvoiceTitle} secondary={associatedInvoiceSubTitle} />
            <DefinitionListItem primary={texts.paymentDate} secondary={<ShortDate value={paymentDate} />} />
            <DefinitionListItem primary={texts.amount} secondary={<Currency value={amount || 0} />} />
            <DefinitionListItem primary={texts.balance} secondary={<Currency value={balance || 0} />} />
            <DefinitionListItem primary={texts.paymentType} secondary={accountType} />
            {accountType == 'Check' && reference && (
                <DefinitionListItem primary={texts.checkNumber} secondary={reference} />
            )}
            <DefinitionListItem primary={texts.status} secondary={<Badge status={status} />} />

            {isRefund && <Typography variant="h4">{texts.refundDetailsTitle}</Typography>}

            {isRefund && (
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Typography variant="h5" style={{ fontWeight: 600 }}>
                            {texts.refundDate}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h5" style={{ fontWeight: 600 }}>
                            {texts.refundAmount}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h5" style={{ fontWeight: 600 }}>
                            {texts.refundPaymentType}
                        </Typography>
                    </Grid>
                    {refunds.map((item, index) => (
                        <Grid key={index} item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Typography>{<ShortDate value={item.refundTime} />}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>{<Currency value={item.amount} />}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>{item.refundMethod}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            )}

            {showReceipt && (
                <OpenPDFButton
                    errorText={texts.paymentReceiptReportFailed}
                    onClick={onPaymentReceiptReportClick}
                    className={classes.downloadLink}
                >
                    {texts.viewReceipt}
                </OpenPDFButton>
            )}

            {isRefund && (
                <OpenPDFButton
                    errorText={texts.refundReceiptReportFailed}
                    onClick={onRefundReceiptReportClick}
                    className={classes.downloadLink}
                >
                    {texts.viewRefundReceipt}
                </OpenPDFButton>
            )}
        </Stack>
    );
}
