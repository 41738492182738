import { clearAuth } from '@/utils/http';
import { resetLoginState } from '../../sections/Login/store';
import { queryClient } from '../../services/queryClient';

import { clearSession } from './store';

type LogoutRejectError = {
    message: string;
    type: 'unknown';
};

export function logout(): true | LogoutRejectError {
    try {
        // clear react query cache
        queryClient.clear();
        resetLoginState();
        // clear HTTP helper auth token
        clearAuth();

        clearSession();
        return true;
    } catch (e) {
        return {
            message: e instanceof Error ? e.message : 'unknown error',
            type: 'unknown',
        };
    }
}
