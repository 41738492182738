import { post } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';

const baseUrl = '/api/opportunities';

export const createOpportunity = async (object: any) => {
    try {
        const { data, status } = await post<any>(`${baseUrl}`, object);

        if (status === 200) {
            return data;
        }

        throw new ServerError(`Failed to request a service.`, status);
    } catch (e) {
        throw e;
    }
};
