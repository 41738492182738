import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faCommentAltSmile, faHistory, faTools } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { List as MuiList } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';

import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { usePaymentsEnabled } from '@/common/hooks/usePaymentsEnabled';
import { ListItem, useTexts } from '../index';
import { ListItemWithChildren } from './ListItemWithChildren';
import { ListProps } from './typings';
import * as SubLists from './SubLists';

export function List(props: ListProps) {
    const { setSubList } = props;
    const texts = useTexts();

    const paymentsEnabled = usePaymentsEnabled();
    const questionsAndCommentsEnabled = useFeatureEnabled('enableQuestionsAndComments');
    const requestServiceEnabled = useFeatureEnabled('enableRequestNewService');
    const serviceHistoryEnabled = useFeatureEnabled('enableServiceHistory');
    const upcomingServicesEnabled = useFeatureEnabled('enableUpcomingServices');
    const viewAgreementsEnabled = useFeatureEnabled('enableViewAgreements');
    const viewBillingHistoryEnabled = useFeatureEnabled('enableBillingHistory');
    const viewPaymentHistoryEnabled = useFeatureEnabled('enableViewPaymentHistory');

    return (
        <MuiList>
            <ListItem
                data-testid="btn-account-overview"
                icon={<DashboardIcon />}
                label={texts.accountOverview}
                path="/account-overview"
            />
            <ListItemWithChildren
                data-testid="btn-your-services"
                hidden={!requestServiceEnabled && !upcomingServicesEnabled && !viewAgreementsEnabled}
                icon={<FontAwesomeIcon icon={faTools} />}
                label={texts.yourServices}
                path="/your-services"
                setSubList={setSubList}
                subList={<SubLists.YourServicesSubList />}
            />
            <ListItem
                data-testid="btn-service-history"
                hidden={!serviceHistoryEnabled && !viewPaymentHistoryEnabled && !viewBillingHistoryEnabled}
                icon={<FontAwesomeIcon icon={faHistory} />}
                label={texts.accountHistory}
                path="/account-history"
            />
            <ListItemWithChildren
                data-testid="btn-payments"
                hidden={!paymentsEnabled}
                icon={<FontAwesomeIcon icon={faCreditCard} />}
                label={texts.makePayments}
                path="/payments"
                setSubList={setSubList}
                subList={<SubLists.PaymentsSubList />}
            />
            <ListItem
                data-testid="btn-questions-and-comments"
                hidden={!questionsAndCommentsEnabled}
                icon={<FontAwesomeIcon icon={faCommentAltSmile} />}
                label={texts.questionsAndComments}
                path="/questions-and-comments"
            />
        </MuiList>
    );
}
