import { ServiceLocation } from '@/api/serviceLocations';
import { ComponentWithTooltip } from '@/components/ComponentWithTooltip';
import { Box, Radio, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useMemo } from 'react';
import { setLocation, useSelectLocationState } from '../store';
import { useTexts } from './useTexts';

interface Props {
    location: ServiceLocation;
}

const useStyles = makeStyles({
    title: {
        fontSize: '16px',
        fontWeight: 700,
    },
    subTitle: {
        fontSize: '16px',
        fontWeight: 400,
    },
    subSubTitle: {
        fontSize: '14px',
    },
    disabledLabel: {
        opacity: 0.5,
    },
});

export const LocationRadioButton = (props: Props) => {
    const { location } = props;
    const { selected } = useSelectLocationState();
    const classes = useStyles();
    const texts = useTexts();

    const handleChange = useCallback(
        (_event: React.ChangeEvent<HTMLInputElement>) => {
            setLocation(location);
        },
        [location],
    );

    const handleLabelClick = useCallback(
        (_event: React.MouseEvent<HTMLElement>) => {
            setLocation(location);
        },
        [location],
    );

    const hasErrors = useMemo(() => {
        return !!location.validationErrors;
    }, [location]);

    return (
        <ComponentWithTooltip showTooltip={hasErrors} tooltipText={texts.locationHasErrors}>
            <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
                <Box flexGrow={1} onClick={handleLabelClick} className={hasErrors ? classes.disabledLabel : undefined}>
                    <Typography className={classes.subTitle}>{`${location.street1}`}</Typography>
                    {location.street2 && <Typography className={classes.subTitle}>{`${location.street2}`}</Typography>}
                    <Typography
                        className={classes.subSubTitle}
                    >{`${location.city}, ${location.stateAbbreviation} ${location.postalCode}`}</Typography>
                </Box>
                <Box>
                    <Radio
                        checked={location.serviceLocationId == selected?.serviceLocationId}
                        color="primary"
                        onChange={handleChange}
                        value={location}
                        disabled={hasErrors}
                    />
                </Box>
            </Box>
        </ComponentWithTooltip>
    );
};

LocationRadioButton.displayName = 'LocationRadioButton';
