import { getAccount } from '@/atoms/session/store';
import { HostedPaymentStatus, TransactionSetupMethod } from '@/common/types/payments';
import { Alert, Box, CircularProgress } from '@mui/material';
import { CreditCardIframe } from './CreditCardIframe';
import { useData } from './useData';
import { useTexts } from './useTexts';

interface CreditCardSaleProps {
    amount: number;
    onCancel: () => void;
    onSuccess: (
        _paymentAccountId: string,
        paymentStatus: HostedPaymentStatus,
        friendlyValue: string,
        transactionId: string,
        transactionReferenceNumber?: string,
        processorTransactionLogId?: string,
        partnerRefId?: string,
    ) => Promise<void>;
    transactionSetupMethod: TransactionSetupMethod.CreditCardSale;
}

interface PaymentAccountCreateProps {
    onCancel: () => void;
    onSuccess: (id: string) => Promise<void>;
    transactionSetupMethod: TransactionSetupMethod.PaymentAccountCreate;
}

type Props = CreditCardSaleProps | PaymentAccountCreateProps;

export function CreditCardEntry(props: Props) {
    const { onCancel, onSuccess, transactionSetupMethod } = props;
    const account = getAccount();
    const texts = useTexts();

    const [data] = useData(
        account?.accountId || '',
        (props as CreditCardSaleProps).amount ?? 0,
        account?.businessUnitId || '',
        transactionSetupMethod,
    );

    return (
        <Box>
            {data.status == 'loading' && (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <CircularProgress />
                </Box>
            )}

            {data.status == 'success' && data && (
                <CreditCardIframe
                    onCancel={onCancel}
                    onSuccess={onSuccess}
                    url={data.payload}
                    transactionSetupMethod={transactionSetupMethod}
                />
            )}

            {data.status == 'error' && <Alert severity="error">{texts.errorLoadingIframe}</Alert>}
        </Box>
    );
}
