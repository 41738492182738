import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.h3.fontSize,
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
}));

interface Props {
    icon?: JSX.Element;
    primaryAction?: JSX.Element;
    secondaryAction?: JSX.Element;
    title: string;
}

export function SectionHeader(props: Props) {
    const { icon, primaryAction, secondaryAction, title } = props;
    const classes = useStyles();

    return (
        <Box pb={2} display="flex" alignItems="center">
            {!!icon && (
                <Box className={classes.icon} mr={1.5}>
                    {icon}
                </Box>
            )}
            <Box display="flex" flexGrow={1}>
                <Typography className={classes.title} variant="h2">
                    {title}
                </Typography>
            </Box>
            {!!primaryAction && <Box ml={2}>{primaryAction}</Box>}
            {!!secondaryAction && <Box ml={2}>{secondaryAction}</Box>}
        </Box>
    );
}

SectionHeader.displayName = 'SectionHeader';
