import { useCallback } from 'react';

import { logout as logoutAction } from '@/atoms/session/logout';

export function useLogoutActions() {
    const logout = useCallback((): boolean => {
        try {
            logoutAction();
            return true;
        } catch (e) {
            // TODO: navigate 404 page
            return false;
        }
    }, []);

    return logout;
}
