interface staggeredPagination {
    page: number;
    take: number;
    skip: number;
}

export function staggerPagination(page: number, take: number, stagger: number): staggeredPagination | undefined {
    const n = (page + 1) * take;

    // The take in this case would be 0, which would throw an error in the API.
    // Add handling in the function that calls this one if this is a possibility.
    if (n <= stagger) {
        return undefined;
    }

    const adjN = n - stagger;
    const adjTake = adjN >= take ? take : adjN;
    const adjPage = Math.floor(adjN / adjTake) - 1;
    const skip = adjN % adjTake;

    return {
        page: adjPage,
        take: adjTake,
        skip: skip,
    };
}
