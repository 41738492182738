import { List as MuiList } from '@mui/material';

import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { ListItem, useTexts } from '../../index';

export function PaymentsSubList() {
    const texts = useTexts();
    const viewAgreementsEnabled = useFeatureEnabled('enableViewAgreements');

    return (
        <MuiList>
            <ListItem data-testid="btn-pay-invoices" label={texts.payInvoices} path="/payments/pay-invoices" />
            <ListItem
                hidden={!viewAgreementsEnabled}
                data-testid="btn-manageAutopay"
                label={texts.manageAutopay}
                path="/payments/manage-autopay"
            />
            <ListItem data-testid="btn-payment-methods" label={texts.paymentMethods} path="/payments/payment-methods" />
        </MuiList>
    );
}
