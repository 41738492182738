import { FormRoot, useCreateForm } from '@/formState';
import { formFields } from './formFields';
import { Form } from './Form';

export function FormProvider() {
    const formState = useCreateForm('update-password-form', formFields);

    return (
        <FormRoot formState={formState}>
            <Form />
        </FormRoot>
    );
}
