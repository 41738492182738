import { getNextScheduledWorkOrder, WorkOrderWidgetData } from '@/api/workOrders';
import { useQuery } from 'react-query';

export const useQueryYourNextService = (accountId: string) =>
    useQuery<WorkOrderWidgetData | null, Error>(
        ['yourNextService', accountId],
        () => getNextScheduledWorkOrder(accountId),
        {
            staleTime: Infinity,
        },
    );
