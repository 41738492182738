import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useCallback } from 'react';
import { formatAddress } from '@/common/utils/formatAddress';
import { ServiceLocation } from '@/api/serviceLocations';
import { useTexts } from '../useTexts';

interface Props {
    locations: ServiceLocation[];
    serviceLocations: string[];
    setServiceLocations: (locations: string[]) => void;
}

export function ServiceLocationFilter(props: Props) {
    const { locations, serviceLocations, setServiceLocations } = props;
    const texts = useTexts();

    const handleAllClicked = useCallback(
        e => {
            setServiceLocations([e.target.dataset.value]);
        },
        [setServiceLocations],
    );

    const handleOptionClicked = useCallback(
        e => {
            const val = e.target.dataset.value;
            if (!serviceLocations.find(x => x === val)) {
                setServiceLocations([...serviceLocations, val].filter(x => !!x));
            } else {
                if (serviceLocations.length === 1) {
                    setServiceLocations(['']);
                } else {
                    setServiceLocations([...serviceLocations].filter(x => x !== val));
                }
            }
        },
        [serviceLocations, setServiceLocations],
    );

    return (
        <Select value={serviceLocations} displayEmpty multiple>
            <MenuItem value="" onClick={handleAllClicked}>
                {texts.showAllServiceLocations}
            </MenuItem>
            {locations?.map((x, i) => (
                <MenuItem key={i} value={x.serviceLocationId} onClick={handleOptionClicked}>
                    {formatAddress(x)}
                </MenuItem>
            ))}
        </Select>
    );
}
