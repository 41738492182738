import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/pro-regular-svg-icons';
import { DesktopListCard } from '@/components/cardList/DesktopListCard';
import { useGetUILayout } from '@/atoms/UILayout';
import { MobileListCard } from '@/components/cardList/MobileListCard';
import { SideDrawerForm } from '@/components/form/SideDrawerForm';
import { useDrawerState } from '@/common/hooks/useDrawerState';
import { useCallback, useMemo } from 'react';
import { useTexts } from '../../useTexts';
import { StatusBadge } from '../../components/StatusBadge';
import { DrawerContent } from '../../drawer/DrawerContent';
import { setSelectedItem, useListState } from '../store';
import { setWizardStep, useAgreementDrawerState, WizardStep, resetStore as resetDrawerStore } from '../../drawer/store';
import { CardProps } from './typings';
import { SecondaryIcon } from './SecondaryIcon';
import { SubTitle } from './SubTitle';
import { useDisableSave } from './hooks/useDisableSave';
import { useAgreementCardActions } from './hooks/useAgreementCardActions';

export const AgreementCard = (props: CardProps) => {
    const { item, disabled } = props;
    const texts = useTexts();
    const layout = useGetUILayout();
    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();
    const { selected } = useListState();
    const { wizardStep } = useAgreementDrawerState();
    const { handleOnSave } = useAgreementCardActions(item);

    const handleOpen = useCallback(() => {
        setSelectedItem(item.agreementId!);
        openDrawer();
    }, [openDrawer, item.agreementId]);

    const handleClose = useCallback(() => {
        setSelectedItem('');
        resetDrawerStore();
        closeDrawer();
    }, [closeDrawer]);

    const handleBackBtnClick = useCallback(() => {
        if (wizardStep > 0) {
            setWizardStep(wizardStep - 1);
        }
    }, [wizardStep]);

    const toolTipText = useMemo(() => {
        return disabled ? texts.locationHasErrors : undefined;
    }, [disabled, texts.locationHasErrors]);

    const disableSave = useDisableSave();

    return (
        <>
            {layout == 'desktop' ? (
                <DesktopListCard
                    badge={<StatusBadge status={item.status} />}
                    icon={<FontAwesomeIcon icon={faAward} />}
                    onClick={handleOpen}
                    secondaryIcon={<SecondaryIcon item={item} />}
                    selected={item.agreementId === selected}
                    subTitle={<SubTitle item={item} />}
                    title={item.name}
                    disabled={disabled}
                    showTooltip={disabled}
                    toolTipText={toolTipText}
                />
            ) : (
                <MobileListCard
                    badge={<StatusBadge status={item.status} />}
                    icon={<FontAwesomeIcon icon={faAward} />}
                    onClick={handleOpen}
                    secondaryIcon={<SecondaryIcon item={item} />}
                    selected={item.agreementId === selected}
                    subTitle={<SubTitle item={item} />}
                    title={item.name}
                    disabled={disabled}
                    showTooltip={disabled}
                    toolTipText={toolTipText}
                />
            )}

            <SideDrawerForm
                isOpen={drawerOpen}
                onClose={handleClose}
                title={texts.drawerTitle}
                onSave={handleOnSave}
                hideFooter={wizardStep === WizardStep.Detail}
                showBackBtn={wizardStep !== WizardStep.Detail}
                disableSave={disableSave}
                onBackBtnClick={handleBackBtnClick}
            >
                <DrawerContent agreementId={item.agreementId} onClose={handleClose} />
            </SideDrawerForm>
        </>
    );
};
