import { PaymentStatus } from '@/common/types/payments';
import { get } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';

export interface AccountHistoryResponse {
    total: number;
    page: number;
    take: number;
    items: AccountHistoryItemModel[];
}

export enum HistoryItemType {
    WORK_ORDER = 'WorkOrder',
    INVOICE = 'Invoice',
    PAYMENT = 'Payment',
}

export enum PaymentMethod {
    ACH = 'ACH',
    CASH = 'Cash',
    CHECK = 'Check',
    CREDIT_CARD = 'CreditCard',
    NONE = 'None',
}

export enum InvoiceStatus {
    OPEN = 'Open',
    PAID = 'Paid',
}

export interface AccountHistoryItemModel {
    itemType: HistoryItemType;
    accountId: string;
    date: Date;
    paymentId: string | null;
    paymentAmount: number | null;
    paymentAccountType: string | null;
    paymentInvoiceNums: string | null;
    paymentMethod: PaymentMethod | null;
    paymentStatus: PaymentStatus | null;
    invoiceId: string | null;
    invoiceNum: number | null;
    invoiceTotal: number | null;
    invoiceStatus: InvoiceStatus | null;
    isPartiallyRefunded: boolean | null;
    workOrderId: string | null;
    workOrderNumber: number | null;
    workOrderInvoiceNumber: number | null;
    workOrderProjectSequence: number | null;
    workOrderSubNumber: number | null;
    reference: string | null;
}

const baseUrl = 'api/Accounts';

function getQueryString(
    page: number,
    take: number,
    search: string,
    type: HistoryItemType | '',
    serviceLocations: string[],
    startDate: Date | null,
    endDate: Date | null,
) {
    const params = new URLSearchParams({
        page: page.toString(),
        take: take.toString(),
    });

    if (!!search) {
        params.append('searchTerm', search);
    }
    if (!!type) {
        params.append('itemType', type);
    }
    if (!!serviceLocations.length) {
        serviceLocations.map(x => !!x && params.append('serviceLocation', x));
    }
    if (!!startDate) {
        const date = new Date(startDate);
        date.setUTCHours(0, 0, 0, 0);
        params.append('startDate', date.toISOString());
    }
    if (!!endDate) {
        const date = new Date(endDate);
        date.setUTCHours(23, 59, 59, 999);
        params.append('endDate', date.toISOString());
    }

    return params;
}

export const getAccountHistory = async (
    accountId: string,
    page: number,
    take: number,
    search: string,
    type: HistoryItemType | '',
    serviceLocations: string[],
    startDate: Date | null,
    endDate: Date | null,
) => {
    try {
        const queryString = getQueryString(page, take, search, type, serviceLocations, startDate, endDate);
        const { status, data } = await get<AccountHistoryResponse>(`${baseUrl}/${accountId}/history?${queryString}`);

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get account history.`, status);
    } catch (e) {
        throw e;
    }
};
