import { Outlet } from 'react-router-dom';

import { AppHeaderDesktop } from '@/components/appHeader';
import { SidebarDrawer } from '@/components/sidebar/SidebarDrawer';
import makeStyles from '@mui/styles/makeStyles';

const drawerWidth = 256;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
    },
}));

export function AppDesktop() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppHeaderDesktop />
            <SidebarDrawer />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Outlet />
            </main>
        </div>
    );
}
