import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';

interface ButtonProps extends MuiButtonProps {
    children?: string;
}

export const Button = (props: ButtonProps) => {
    const { children, ...MuiProps } = props;
    return (
        <MuiButton variant="contained" size="small" {...MuiProps}>
            {children}
        </MuiButton>
    );
};
