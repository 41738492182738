import { proxy, useSnapshot } from 'valtio';

export interface AddNoteState {
    note: string;
}

export const initialState: AddNoteState = {
    note: '',
};

// Valtio store initialization
export const state = proxy<AddNoteState>(initialState);

// Setter functions
export function setNote(note: string) {
    state.note = note;
}

// Hooks
export function useSelectAddNoteState() {
    return useSnapshot(state, { sync: true });
}
