export enum CultureCode {
    en_AU = 'en-AU',
    en_CA = 'en-CA',
    en_GB = 'en-GB',
    en_IE = 'en-IE',
    en_NZ = 'en-NZ',
    en_US = 'en-US',
}

export enum CultureCountry {
    Australia = 'Australia',
    Canada = 'Canada',
    England = 'England',
    Ireland = 'Ireland',
    NewZealand = 'New Zealand',
    NorthernIreland = 'Northern Ireland',
    Scotland = 'Scotland',
    UnitedStates = 'United States',
    Wales = 'Wales',
}

export enum SystemOfMeasurement {
    Imperial = 'imperial',
    Metric = 'metric',
}

export enum UnitOfDistance {
    Kilometer = 'kilometer',
    Mile = 'mile',
}

export enum UnitOfDistanceAbbreviation {
    Kilometer = 'km',
    Mile = 'mi',
}

export interface LocalizationSettings {
    culture: CultureCode;
    cultureCountry: CultureCountry;
    isInternationalTaxType: boolean;
    showCountryCode: boolean;
    systemOfMeasurement: SystemOfMeasurement;
}

export const CultureStrings: Record<CultureCountry, CultureCode> = {
    [CultureCountry.Australia]: CultureCode.en_AU,
    [CultureCountry.Canada]: CultureCode.en_CA,
    [CultureCountry.England]: CultureCode.en_GB,
    [CultureCountry.Ireland]: CultureCode.en_IE,
    [CultureCountry.NewZealand]: CultureCode.en_NZ,
    [CultureCountry.NorthernIreland]: CultureCode.en_GB,
    [CultureCountry.Scotland]: CultureCode.en_GB,
    [CultureCountry.UnitedStates]: CultureCode.en_US,
    [CultureCountry.Wales]: CultureCode.en_GB,
};

export const DefaultSystemOfMeasurement: Record<CultureCountry, SystemOfMeasurement> = {
    [CultureCountry.Australia]: SystemOfMeasurement.Metric,
    [CultureCountry.Canada]: SystemOfMeasurement.Metric,
    [CultureCountry.England]: SystemOfMeasurement.Metric,
    [CultureCountry.Ireland]: SystemOfMeasurement.Metric,
    [CultureCountry.NewZealand]: SystemOfMeasurement.Metric,
    [CultureCountry.NorthernIreland]: SystemOfMeasurement.Metric,
    [CultureCountry.Scotland]: SystemOfMeasurement.Metric,
    [CultureCountry.UnitedStates]: SystemOfMeasurement.Imperial,
    [CultureCountry.Wales]: SystemOfMeasurement.Metric,
};

export const defaultLocalizationSettings: LocalizationSettings = {
    culture: CultureCode.en_US,
    cultureCountry: CultureCountry.UnitedStates,
    isInternationalTaxType: false,
    showCountryCode: false,
    systemOfMeasurement: SystemOfMeasurement.Imperial,
};
