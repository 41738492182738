import { LocalizationSettings } from '@/common/types/localization';
import { get } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';

const baseUrl = `api/Settings`;

export const getLocalizationSettings = async () => {
    try {
        const { data, status } = await get<LocalizationSettings>(`${baseUrl}/localization`);

        if (status === 200) {
            return data;
        }

        throw new ServerError(`Failed to get localization settings.`, status);
    } catch (e) {
        throw e;
    }
};
