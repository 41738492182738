import { SchedulingPlan } from '@/api/services';
import { useTranslate } from '@/intl';
import { useMemo } from 'react';
import { useOrderedDaysOfWeek } from './useOrderedDaysOfWeek';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            every: translate({ id: 'global.schedulingPlans.every' }),
            noRecurrence: translate({ id: 'global.schedulingPlans.noRecurrence' }),
            to: translate({ id: 'global.schedulingPlans.to' }),
        };
    }, [translate]);
}

export const useHumanizedDaysOfWeek = (schedulingPlan: SchedulingPlan | null): string => {
    const texts = useTexts();
    const orderedDaysOfWeek = useOrderedDaysOfWeek();

    if (!schedulingPlan) {
        return '';
    }

    const daysOfWeekBits = schedulingPlan.daysOfWeek.toString();
    const daysOfWeekBitsArray = Array.from(daysOfWeekBits).map(Number);
    const days: number[] = daysOfWeekBitsArray.map((bit, i) => (bit == 1 ? i : -1)).filter(day => day != -1);
    const daysOfWeek = days.filter(d => d >= 0 && d <= 6);

    const groupings = daysOfWeek.reduce((arr: number[][], val: number, i: number, a: number[]) => {
        if (!i || val !== a[i - 1] + 1) arr.push([]);
        arr[arr.length - 1].push(val);
        return arr;
    }, []);

    const stringGroupings: string[] = groupings.map((grouping: number[]) => {
        if (grouping.length == 1) {
            return `${orderedDaysOfWeek[grouping[0]]}`;
        }

        if (grouping.length == 2) {
            return `${orderedDaysOfWeek[grouping[0]]}, ${orderedDaysOfWeek[grouping[1]]}`;
        }

        if (grouping.length > 2) {
            return `${orderedDaysOfWeek[grouping[0]]} ${texts.to} ${orderedDaysOfWeek[grouping[grouping.length - 1]]}`;
        }

        return '';
    });

    const humanizedString = stringGroupings.join(' & ');

    return humanizedString;
};
