import { useFetchCustomerPortalSettings } from '@/common/hooks/query/useFetchCustomerPortalSettings';
import { useCallback } from 'react';
import { useFetchHolidays } from '@/common/hooks/query/useFetchHolidays';
import { DatePicker } from '@/components/DatePicker';
import { useRequestServiceState } from '../../../../../store';
import { setServiceDate, useSelectServiceDetails } from '../../../store';
import { useTexts } from '../../useTexts';

interface Props {
    serviceId: string;
}

export const ServiceDatePicker = (props: Props) => {
    const { serviceId } = props;
    const { serviceLocation } = useRequestServiceState();
    const { data: holidays } = useFetchHolidays(serviceLocation?.businessUnitId || '');
    const { data: customerPortalSettings } = useFetchCustomerPortalSettings(serviceLocation?.businessUnitId || '');
    const serviceDetails = useSelectServiceDetails(serviceId);
    const texts = useTexts();

    const isClosed = useCallback(
        (date: Date) => {
            const dayOfWeek = date.getDay();

            if (dayOfWeek == 0 && customerPortalSettings?.sundayAvailability == 0) {
                return true;
            }

            if (dayOfWeek == 1 && customerPortalSettings?.mondayAvailability == 0) {
                return true;
            }

            if (dayOfWeek == 2 && customerPortalSettings?.tuesdayAvailability == 0) {
                return true;
            }

            if (dayOfWeek == 3 && customerPortalSettings?.wednesdayAvailability == 0) {
                return true;
            }

            if (dayOfWeek == 4 && customerPortalSettings?.thursdayAvailability == 0) {
                return true;
            }

            if (dayOfWeek == 5 && customerPortalSettings?.fridayAvailability == 0) {
                return true;
            }

            if (dayOfWeek == 6 && customerPortalSettings?.saturdayAvailability == 0) {
                return true;
            }
        },
        [customerPortalSettings],
    );

    const isHoliday = useCallback(
        (day: Date) => {
            if (!holidays) {
                return false;
            }

            return !!holidays.find(h => {
                const holidayDate = new Date(h.date);

                if (h.recurring) {
                    const isSameMonth = holidayDate.getMonth() === day.getMonth();
                    const isSameDay = holidayDate.getDate() === day.getDate();
                    return isSameMonth && isSameDay;
                } else {
                    return (
                        holidayDate.getFullYear() === day.getFullYear() &&
                        holidayDate.getMonth() === day.getMonth() &&
                        holidayDate.getDate() === day.getDate()
                    );
                }
            });
        },
        [holidays],
    );

    const shouldDisableDate = useCallback(
        (date: Date | null, _keyboardInputValue?: string | undefined) => {
            if (!date) {
                return false;
            }

            return isClosed(date) || isHoliday(date);
        },
        [isClosed, isHoliday],
    );

    const handleDateChange = useCallback(
        (date: Date | null, _keyboardInputValue?: string | undefined) => {
            setServiceDate(date, serviceId);
        },
        [serviceId],
    );

    return (
        <DatePicker
            disablePast
            label={texts.date}
            onChange={handleDateChange}
            InputProps={{
                required: true,
            }}
            shouldDisableDate={shouldDisableDate}
            value={serviceDetails?.date || null}
        />
    );
};
