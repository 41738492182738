import { InvoiceDetail } from '@/api/invoices';
import { useMemo } from 'react';
import { CardBadge } from '@/components/cardList/CardBadge';
import { DefinitionListItem } from '@/components/DefinitionListItem';
import { useTexts } from '../../useTexts';

interface Props {
    invoiceDetails: InvoiceDetail;
}

export function PaymentStatus(props: Props) {
    const texts = useTexts();

    const {
        invoiceDetails: { balance, total },
    } = props;

    const paidStatusBadge = useMemo(() => {
        if (balance == 0) {
            return <CardBadge severity="success" text={texts.paid} />;
        }

        if (balance != total) {
            return <CardBadge severity="warning" text={texts.partiallyPaid} />;
        }

        return <CardBadge severity="error" text={texts.unpaid} />;
    }, [balance, texts.paid, texts.partiallyPaid, texts.unpaid, total]);

    return <DefinitionListItem primary={texts.paymentStatus} secondary={paidStatusBadge} />;
}
