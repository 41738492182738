import { useCallback, useEffect, useState } from 'react';
import { isServerError } from '@/utils/ServerError';
import { getServices } from '@/api/services';
import { setAllServices, setStatus } from './store';

export const useData = (businessUnitId: string, pageNumber: number) => {
    const [isStale, setIsStale] = useState<boolean>(true);

    useEffect(() => {
        let mounted = true;

        const getSettings = async (businessUnitId: string) => {
            try {
                setStatus('loading');
                const response = await getServices(businessUnitId, pageNumber, 10);
                const { items, page, take, total } = response;

                if (isServerError(response)) {
                    setStatus('error');
                }

                if (mounted && !!response) {
                    setStatus('success');
                    setAllServices(items, page, take, total);
                }
            } catch (error) {
                if (mounted) {
                    setStatus('error');
                }
            } finally {
                setIsStale(false);
            }
        };

        if (isStale && !!businessUnitId) {
            getSettings(businessUnitId);
        }

        return () => {
            mounted = false;
        };
    }, [businessUnitId, pageNumber, isStale]);

    const refreshData = useCallback(async () => {
        setIsStale(true);
    }, []);

    useEffect(() => {
        refreshData();
    }, [businessUnitId, pageNumber, refreshData]);

    return [refreshData] as const;
};
