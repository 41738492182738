import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography } from '@mui/material';
import { useSplitAddress } from '@/common/hooks/useSplitAddress';
import { useBusinessUnitDetails } from '@/common/hooks/useBusinessUnitDetails';
import { PhoneNumber } from '@/components/localization/PhoneNumber';

const useStyles = makeStyles(theme => ({
    phoneNumber: {
        paddingTop: theme.spacing(2),
    },
}));

export function FooterContent() {
    const details = useBusinessUnitDetails();
    const companyName = details ? details.name : '';
    const address = useSplitAddress(details || null);
    const classes = useStyles();

    return (
        <>
            {details && (
                <Typography variant="caption">
                    <Box>{companyName}</Box>
                    <Box>{address[0]}</Box>
                    <Box>{address[1]}</Box>
                    {address[2] && <Box>{address[2]}</Box>}
                    {details && (
                        <Box className={classes.phoneNumber}>
                            <PhoneNumber phone={details.phone} phoneExt={details.phoneExt} />
                        </Box>
                    )}
                </Typography>
            )}
        </>
    );
}
