import { UpcomingServicesModel } from '@/api/upcomingServices';
import { useMemo } from 'react';

export function useAccountHasNoUpcomingServices(
    serviceLocations: string[],
    search: string,
    items: UpcomingServicesModel[],
) {
    return useMemo(
        () => !!serviceLocations.length && !serviceLocations[0] && !search && !items.length,
        [items, search, serviceLocations],
    );
}
