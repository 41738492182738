import Stack from '@mui/material/Stack';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Typography from '@mui/material/Typography';

interface Props {
    issue: string;
}

export function Issue(props: Props) {
    const { issue } = props;

    return (
        <Stack direction="row" spacing={1}>
            <PostAddIcon />
            <Typography>{issue}</Typography>
        </Stack>
    );
}
