import { PaymentAccount, PaymentAccountTypes } from '@/common/types/payments';
import { proxy, useSnapshot } from 'valtio';
import { AchInput } from '../../PaymentMethods/drawers/AddPaymentMethod/Drawer';

export enum WizardStep {
    Detail = 0,
    ManageAutopay = 1,
    AddPaymentMethod = 2,
}

// Store state
export interface WizardState {
    wizardStep: number;
    paymentAccount: PaymentAccount | null;
    paymentAccountType: PaymentAccountTypes;
    achInput: AchInput;
}

export const initialState: WizardState = {
    wizardStep: 0,
    paymentAccount: null,
    paymentAccountType: PaymentAccountTypes.CREDIT_CARD,
    achInput: { accountNumber: '', routingNumber: '' },
};

// Valtio store initialization
export const state = proxy<WizardState>(initialState);

// Setter functions
export function setPaymentAccount(account: PaymentAccount | null) {
    state.paymentAccount = account;
}

export function setWizardStep(step: WizardStep) {
    state.wizardStep = step;
}
export function setPaymentAccountType(type: PaymentAccountTypes) {
    state.paymentAccountType = type;
}

export function setAchInput(input: AchInput) {
    state.achInput = input;
}

export function resetStore() {
    state.wizardStep = initialState.wizardStep;
    state.paymentAccount = initialState.paymentAccount;
    state.paymentAccountType = initialState.paymentAccountType;
    state.achInput = initialState.achInput;
}

//Hooks
export function useAgreementDrawerState() {
    return useSnapshot(state);
}
