import { proxy, useSnapshot } from 'valtio';
import { useMemo } from 'react';

// Store state
export interface ListState {
    page: number;
    take: number;
    selected: string;
    serviceLocations: string[];
}

export const initialState: ListState = {
    page: 0,
    take: 10,
    selected: '',
    serviceLocations: [''],
};

// Valtio store initialization
export const state = proxy<ListState>(initialState);

// Setter functions
export function setPage(page: number) {
    state.page = page;
}

export function setTake(take: number) {
    state.take = take;
}

export function setServiceLocations(locations: string[]) {
    state.serviceLocations = locations;

    if (!state.serviceLocations.length) {
        state.serviceLocations = initialState.serviceLocations;
    }
}

export function clearServiceLocations() {
    state.serviceLocations = initialState.serviceLocations;
}

export function setSelectedItem(id: string) {
    state.selected = id;
}

export function clearSelectedItem() {
    state.selected = '';
}

export function resetFilters() {
    clearServiceLocations();
}

export function resetStore() {
    state.page = initialState.page;
    state.selected = initialState.selected;
    state.serviceLocations = initialState.serviceLocations;
    state.take = initialState.take;
}

//Hooks
export function useListState() {
    return useSnapshot(state);
}

export function useIsListFiltered() {
    const { serviceLocations } = state;

    const isListFiltered = useMemo(() => {
        return !!serviceLocations.length && !!serviceLocations[0];
    }, [serviceLocations]);

    return isListFiltered;
}
