import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            cancelled: translate({ id: 'sections.accountHistory.paymentCard.cancelled' }),
            failed: translate({ id: 'sections.accountHistory.paymentCard.failed' }),
            filters: translate({ id: 'sections.accountHistory.filters' }),
            forInvoice: translate({ id: 'sections.accountHistory.paymentCard.forInvoice' }),
            invoice: translate({ id: 'global.invoice' }),
            noHistoryAlert: translate({ id: 'sections.accountHistory.noHistoryAlert' }),
            open: translate({ id: 'sections.accountHistory.invoiceCard.open' }),
            paid: translate({ id: 'sections.accountHistory.invoiceCard.paid' }),
            payment: translate({ id: 'sections.accountHistory.paymentCard.payment' }),
            partiallyRefunded: translate({ id: 'sections.accountHistory.paymentCard.partiallyRefunded' }),
            pending: translate({ id: 'sections.accountHistory.paymentCard.pending' }),
            refunded: translate({ id: 'sections.accountHistory.paymentCard.refunded' }),
            refundedPayment: translate({ id: 'sections.accountHistory.paymentCard.refundedPayment' }),
            searchPlaceholder: translate({ id: 'sections.accountHistory.searchPlaceholder' }),
            serviceVisit: translate({ id: 'sections.accountHistory.serviceCard.serviceVisit' }),
            succeeded: translate({ id: 'sections.accountHistory.paymentCard.succeeded' }),
            title: translate({ id: 'sections.accountHistory.title' }),
            workOrder: translate({ id: 'global.workOrder' }),
        };
    }, [translate]);
}
