import { Currency } from '@/components/localization';
import { PaymentSubtitleProps } from '../typings';

export const PaymentSubtitle = (props: PaymentSubtitleProps) => {
    const { value, identifier } = props;

    return (
        <>
            <Currency value={value} />
            <> {identifier} </>
        </>
    );
};
