import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            passwordLabel: translate({ id: `sections.resetPassword.passwordLabel` }),
            reEnterPasswordLabel: translate({ id: `sections.resetPassword.reEnterPasswordLabel` }),
            passwordReset: translate({ id: `sections.resetPassword.passwordReset` }),
            enterNew: translate({ id: `sections.resetPassword.enterNew` }),
            changePassword: translate({ id: `sections.resetPassword.changePassword` }),
            success: translate({ id: `sections.resetPassword.success` }),
            succesfullyReset: translate({ id: `sections.resetPassword.succesfullyReset` }),
            invalid: translate({ id: `sections.resetPassword.invalid` }),
            error: translate({ id: `sections.resetPassword.error` }),
            signIn: translate({ id: `sections.login.login` }),
            invalidLink: translate({ id: `sections.resetPassword.invalidLink` }),
            serverError: translate({ id: `sections.resetPassword.serverError` }),
        };
    }, [translate]);
}
