import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            cancel: translate({ id: 'global.cancel' }),
            date: translate({ id: 'global.date' }),
            error: translate({ id: 'sections.requestService.setServiceDetails.drawer.error' }),
            errorLoadingPropertyTraits: translate({
                id: 'sections.requestService.setServiceDetails.drawer.errorLoadingPropertyTraits',
            }),
            header: translate({ id: 'sections.requestService.setServiceDetails.drawer.header' }),
            infoBox: translate({ id: 'sections.requestService.setServiceDetails.drawer.infoBox' }),
            noServices: translate({ id: 'sections.requestService.setServiceDetails.drawer.noServices' }),
            save: translate({ id: 'global.save' }),
        };
    }, [translate]);
}
