import { useQueryInvoiceDetail } from '@/common/hooks/query/useQueryInvoiceDetail';
import { Alert, CircularProgress } from '@mui/material';
import { useTexts } from '../useTexts';
import { DetailListItems } from './DetailListItems';

interface Props {
    invoiceId: string;
}

export function DrawerContent(props: Props) {
    const { invoiceId } = props;
    const texts = useTexts();
    const { data: details, status } = useQueryInvoiceDetail(invoiceId);

    return (
        <>
            {status == 'loading' && <CircularProgress />}
            {status == 'error' && <Alert severity="error">{texts.error}</Alert>}
            {status == 'success' && (
                <>
                    {!details && <Alert severity="error">{texts.error}</Alert>}
                    {details && <DetailListItems details={details} />}
                </>
            )}
        </>
    );
}
