import { SideDrawer } from '@/components/SideDrawer';
import Stack from '@mui/material/Stack';
import { ClearFiltersButton } from '@/components/cardList/filters/ClearFiltersButton';
import { useAccountId } from '@/atoms/session';
import { useFetchServiceLocations } from '@/common/hooks/query/useQueryServiceLocations';
import { ServiceLocationFilter } from '@/components/cardList/filters/ServiceLocationFilter';
import { useListActions } from '../hooks';
import { useListState } from '../store';
import { useTexts } from '../../useTexts';
import { StatusFilter } from './StatusFilter';

interface Props {
    onClose: () => void;
    open: boolean;
}

export function FiltersDrawer(props: Props) {
    const { onClose, open } = props;
    const texts = useTexts();
    const { handleResetFilters, handleSetServiceLocations, handleSetStatus } = useListActions();
    const accountId = useAccountId();
    const { data: locations } = useFetchServiceLocations(accountId || '');
    const { serviceLocations, status } = useListState();

    return (
        <SideDrawer isOpen={open} onClose={onClose} title={texts.filtersDrawerTitle}>
            <Stack direction="column" spacing={2}>
                {!!locations && (
                    <ServiceLocationFilter
                        locations={locations}
                        serviceLocations={serviceLocations}
                        setServiceLocations={handleSetServiceLocations}
                    />
                )}
                <StatusFilter status={status} setStatus={handleSetStatus} />
                <ClearFiltersButton onClick={handleResetFilters} />
            </Stack>
        </SideDrawer>
    );
}
