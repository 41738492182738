import { PaymentAccountTypes } from '@/common/types/payments';
import { useMemo } from 'react';
import { WizardStep, useAgreementDrawerState } from '../../../drawer/store';
import { useIsAchInputValid } from '../../../../PaymentMethods/drawers/AddPaymentMethod/hooks/useIsAchInputValid';

export function useDisableSave() {
    const { achInput, wizardStep, paymentAccount, paymentAccountType } = useAgreementDrawerState();
    const isAchInputValid = useIsAchInputValid(achInput);

    const disableSave = useMemo(() => {
        switch (wizardStep) {
            case WizardStep.ManageAutopay:
                return !paymentAccount;
            case WizardStep.AddPaymentMethod:
                return (
                    paymentAccountType === PaymentAccountTypes.CREDIT_CARD ||
                    (paymentAccountType === PaymentAccountTypes.ACH && !isAchInputValid)
                );
        }
    }, [isAchInputValid, paymentAccount, paymentAccountType, wizardStep]);

    return disableSave;
}
