import { useContext } from 'react';
import { ConfirmationDialogContext, DialogConfig } from '../components/ConfirmationDialog/ConfirmationDialogProvider';

export function useConfirmationDialog() {
    const { openDialog } = useContext(ConfirmationDialogContext);

    const getConfirmation = (options: Pick<DialogConfig, 'title' | 'message' | 'width'>) => {
        return new Promise<boolean>(res => {
            openDialog({ callbackFn: res, ...options });
        });
    };
    return { getConfirmation };
}
