export interface IStorage {
    clear(): IStorage;
    get(request: string, remove?: boolean): string | undefined;
    set(request: string, data: string): IStorage;
    remove(request: string): IStorage;
}

export class ApplicationStorage implements IStorage {
    constructor(private useSession: boolean = false) {}

    private get storage(): Storage {
        return this.useSession ? sessionStorage : localStorage;
    }

    public get(request: string, remove: boolean = false): string | undefined {
        const res = this.storage.getItem(request);
        if (remove) {
            this.remove(request);
        }
        return res ? res : undefined;
    }

    public set(request: string, data: string): IStorage {
        this.storage.setItem(request, data);
        return this;
    }

    public remove(request: string): IStorage {
        this.storage.removeItem(request);
        return this;
    }

    public clear(): IStorage {
        this.storage.clear();
        return this;
    }
}

export const setValue = (request: string, value: string, useSession: boolean = false): IStorage => {
    return new ApplicationStorage(useSession).set(request, value);
};

export const getValue = (request: string, remove: boolean = false, useSession: boolean = false): string | undefined => {
    return new ApplicationStorage(useSession).get(request, remove);
};

export const removeValue = (request: string, useSession: boolean = false): IStorage => {
    return new ApplicationStorage(useSession).remove(request);
};

export const getStorage = (useSession: boolean = false): IStorage => {
    return new ApplicationStorage(useSession);
};
