import { AccountHistoryItemModel, HistoryItemType } from '@/api/accountHistory';
import { InvoiceCard } from './invoices/InvoiceCard';
import { PaymentCard } from './payments/PaymentCard';
import { ServiceCard } from './services/ServiceCard';

interface Props {
    item: AccountHistoryItemModel;
}

export function AccountHistoryCard(props: Props) {
    const { item } = props;

    switch (item.itemType) {
        case HistoryItemType.INVOICE:
            return <InvoiceCard item={item} />;
        case HistoryItemType.PAYMENT:
            return <PaymentCard item={item} />;
        case HistoryItemType.WORK_ORDER:
            return <ServiceCard item={item} />;
    }
}
