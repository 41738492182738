import { DatePickerProps, DesktopDatePicker, MobileDatePicker } from '@mui/lab';
import { useGetUILayout } from '@/atoms/UILayout';
import TextField, { TextFieldProps } from '@mui/material/TextField';

interface Props extends Omit<DatePickerProps<Date>, 'renderInput'> {
    renderInput?: (props: TextFieldProps) => React.ReactElement;
}

export function DatePicker(props: Props) {
    const layout = useGetUILayout();

    return (
        <>
            {layout == 'desktop' ? (
                <DesktopDatePicker
                    {...props}
                    renderInput={props.renderInput ? props.renderInput : params => <TextField {...params} />}
                />
            ) : (
                <MobileDatePicker
                    {...props}
                    clearable
                    renderInput={props.renderInput ? props.renderInput : params => <TextField {...params} />}
                />
            )}
        </>
    );
}
