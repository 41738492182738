import { IntlLoadingScreen } from '@/intl';
import { Provider } from './Provider';
import { Layout } from './Layout';
import { Sections } from './Sections';
import { usePreload } from './usePreload';

/**
 * Application's root component.
 * This component is the static root of the application.
 * It will never unmount and is responsible for rendering providers, layouts, and the main UI
 */
export function Root() {
    const appReady = usePreload();

    return (
        <>
            {appReady.isFetched && (
                <Provider>
                    {/* main layout component. It wraps all rendered UIs */}
                    <Layout>
                        {/* Component used to show a loading screen during a change of language */}
                        <IntlLoadingScreen>
                            {/* URL navigation root and main entry point of the real UI */}
                            <Sections />
                        </IntlLoadingScreen>
                    </Layout>
                </Provider>
            )}
        </>
    );
}
