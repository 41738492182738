import { InvoiceDetail } from '@/api/invoices';
import { useFormattedAddress } from '@/common/hooks/useAddress';
import { DefinitionListItem } from '@/components/DefinitionListItem';
import { useTexts } from '../../useTexts';

interface Props {
    invoiceDetails: InvoiceDetail;
}

export function ServiceLocation(props: Props) {
    const texts = useTexts();

    const {
        invoiceDetails: {
            serviceLocationCity,
            serviceLocationCountryCode,
            serviceLocationPostalCode,
            serviceLocationStateAbbreviation,
            serviceLocationStreet,
            serviceLocationStreet2,
        },
    } = props;

    const serviceAddress = useFormattedAddress(
        serviceLocationStreet,
        serviceLocationStreet2,
        serviceLocationCity,
        serviceLocationStateAbbreviation,
        serviceLocationPostalCode,
        serviceLocationCountryCode,
    );

    return <DefinitionListItem primary={texts.serviceLocation} secondary={serviceAddress} />;
}
