import type { ReactElement } from 'react';

import { forwardRef } from 'react';

import makeStyles from '@mui/styles/makeStyles';

interface Props {
    children: ReactElement;
}

const useClasses = makeStyles({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
    },
});

// FitBox will occupy in absolute position his parent
// doing so `children` will not break any layout rule
export const FitBox = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const classes = useClasses();

    return (
        <div className={classes.root} ref={ref}>
            {props.children}
        </div>
    );
});

FitBox.displayName = 'FitBox';
