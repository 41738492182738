import { ReservationModel, ReservationTimeFrame } from '@/api/serviceRequests';
import { DefinitionListItem } from '@/components/DefinitionListItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { LongDate } from '@/components/localization';
import { useHumanizedSchedulingPlan } from '@/common/hooks/schedulingPlan/useHumanizedSchedulingPlan';
import { useMemo } from 'react';
import { useTexts } from '../useTexts';

interface Props {
    reservation: ReservationModel;
}

export function Reservation(props: Props) {
    const { reservation } = props;
    const { propertyTraits, schedulingPlan, serviceName, startingEligibleDate, timeFrame } = reservation;
    const texts = useTexts();
    const humanizedSchedulingPlan = useHumanizedSchedulingPlan(schedulingPlan);

    const humanizedPropertyTraits = useMemo(() => {
        return propertyTraits.map(x => `${x.amount} ${x.unitOfMeasure}`).join(', ');
    }, [propertyTraits]);

    const translatedTimeFrame = useMemo(() => {
        switch (timeFrame) {
            case ReservationTimeFrame.Afternoon:
                return texts.afternoon;
            case ReservationTimeFrame.Evening:
                return texts.evening;
            case ReservationTimeFrame.Morning:
                return texts.morning;
        }
    }, [timeFrame, texts.afternoon, texts.evening, texts.morning]);

    return (
        <DefinitionListItem
            primary={serviceName}
            secondary={
                <Stack>
                    <Box>
                        {!!startingEligibleDate && (
                            <span>
                                <LongDate value={startingEligibleDate} /> &bull;{' '}
                            </span>
                        )}
                        {translatedTimeFrame}
                    </Box>
                    <Box>{humanizedSchedulingPlan}</Box>
                    <Box>{humanizedPropertyTraits}</Box>
                </Stack>
            }
        />
    );
}
