import { proxy, useSnapshot } from 'valtio';

export interface QuestionCommentState {
    comment: string;
    serviceLocationId: string;
}

export const initialState: QuestionCommentState = {
    comment: '',
    serviceLocationId: '',
};

// Valtio store initialization
export const state = proxy<QuestionCommentState>(initialState);

// Setter functions
export function resetQuestionCommentState() {
    state.comment = '';
    state.serviceLocationId = '';
}

export function setComment(comment: string) {
    state.comment = comment;
}

export function setLocationId(locationId: string) {
    state.serviceLocationId = locationId;
}

// Hooks
export function useSelectQuestionCommentState() {
    return useSnapshot(state, { sync: true });
}
