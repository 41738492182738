import {
    AddAchPaymentAccountInput,
    AddAchPaymentAccountResponse,
    AddCreditCardPaymentAccountInput,
    AddCreditCardPaymentAccountResponse,
    PaymentAccount,
    PaymentStatus,
} from '@/common/types/payments';
import { get, post, remove } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';

export interface PaymentDetail {
    accountType: string;
    amount: number;
    associatedInvoiceNumbers: number[];
    paymentDate: string;
    status: PaymentStatus;
}

const baseUrl = '/api/paymentAccounts';

export const addCreditCardPaymentAccount = async (input: AddCreditCardPaymentAccountInput) => {
    try {
        const { data, status } = await post<AddCreditCardPaymentAccountResponse[]>(`${baseUrl}/creditCard`, input);

        if (status === 200) {
            return data;
        }

        throw new ServerError(`Failed to add credit card.`, status);
    } catch (e) {
        throw e;
    }
};

export const addAchPaymentAccount = async (input: AddAchPaymentAccountInput) => {
    try {
        const { data, status } = await post<AddAchPaymentAccountResponse>(`${baseUrl}/ach`, input);

        if (status === 200) {
            return data;
        }

        throw new ServerError(`Failed to add ACH bank account.`, status);
    } catch (e) {
        throw e;
    }
};

export const getPaymentAccounts = async (accountId: string) => {
    try {
        const { data, status } = await get<PaymentAccount[]>(baseUrl, {
            params: {
                accountId,
            },
        });

        if (status === 200) {
            return data;
        }

        throw new ServerError(`Failed to get payment accounts.`, status);
    } catch (e) {
        throw e;
    }
};

export const deletePaymentAccount = async (businessUnitId: string, paymentAccountId: string) => {
    try {
        const { data, status } = await remove(
            `${baseUrl}?businessUnitId=${businessUnitId}&paymentAccountId=${paymentAccountId}`,
        );

        if (status === 200) {
            return data;
        }

        throw new ServerError(`Failed to delete payment account.`, status);
    } catch (e) {
        throw e;
    }
};
