import { setAccountId } from '@/atoms/session';
import { AccountSwitch } from '@/components/AccountSwitch';
import { toast } from 'react-toastify';
import { useFetchCustomerPortalSettings } from '@/common/hooks/query/useFetchCustomerPortalSettings';
import { getAccount } from '@/atoms/session/store';
import { useTexts } from './useTexts';

export type AccountSwitchListProps = {
    accountsList: Account.AccountSelect[];
    clearSubList: () => void;
};

export const AccountSwitchList = (props: AccountSwitchListProps) => {
    const { accountsList, clearSubList } = props;
    const texts = useTexts();
    const account = getAccount();
    const { refetch } = useFetchCustomerPortalSettings(account?.businessUnitId || '');

    const onAccountSelected = (account: Account.AccountSelect) => {
        clearSubList();
        setAccountId(account);
        refetch();
        toast.success(texts.success);
    };

    return <AccountSwitch title={texts.heading} accountsList={accountsList} onAccountSelected={onAccountSelected} />;
};

AccountSwitchList.displayName = 'AccountSwitchList';
