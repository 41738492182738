import { useQuery } from 'react-query';
import { AccountHistoryResponse, getAccountHistory, HistoryItemType } from '@/api/accountHistory';

export const useQueryAccountHistory = (
    accountId: string,
    page: number,
    take: number,
    search: string,
    type: HistoryItemType | '',
    serviceLocations: string[],
    startDate: Date | null,
    endDate: Date | null,
) =>
    useQuery<AccountHistoryResponse, Error>(
        ['accountHistory', accountId, page, take, search, type, serviceLocations, startDate, endDate],
        () => getAccountHistory(accountId, page, take, search, type, serviceLocations, startDate, endDate),
        {
            keepPreviousData: true,
        },
    );
