import { Typography } from '@mui/material';
import { AgreementAddress as IAgreementAddress } from '@/common/types/agreements';
import { useFormattedAddress } from '@/common/hooks/useAddress';

interface Props {
    address: IAgreementAddress;
}

export function AgreementAddress(props: Props) {
    const {
        address: { city, countryCode, postalCode, stateAbbreviation, street1, street2 },
    } = props;

    const address = useFormattedAddress(street1, street2 || '', city, stateAbbreviation, postalCode, countryCode);

    return <Typography>{address}</Typography>;
}
