import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import * as React from 'react';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { ComponentWithTooltip } from '@/components/ComponentWithTooltip';
import { useTexts } from './useTexts';

interface Props {
    actionBtnText?: string;
    badge?: JSX.Element;
    icon: JSX.Element;
    onClick: () => void;
    secondaryIcon?: JSX.Element;
    selected: boolean;
    styleOverrides?: Partial<{
        container: Pick<React.CSSProperties, 'backgroundColor'>;
    }>;
    subTitle: React.ReactNode;
    title: React.ReactNode;
    disabled?: boolean;
    showTooltip?: boolean;
    toolTipText?: string;
}

const useStyles = (props: Props) =>
    makeStyles(theme => ({
        container: {
            height: '88px',
            marginBottom: theme.spacing(2),
            width: '100%',
            border: props.selected ? `1px solid ${theme.palette.primary.main}` : `1px solid transparent`,
        },
        button: {
            marginBottom: theme.spacing(1),
        },
        subTitle: {
            color: theme.palette.text.secondary,
        },
        contentWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%',
            flex: '1',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            minWidth: 0,
            width: 0,
        },
        right: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginLeft: theme.spacing(1),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        title: {
            lineHeight: '1.5em',
            maxHeight: '3em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
        },
        badgeContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
    }));

export const MobileListCard = (props: Props) => {
    const {
        actionBtnText,
        badge,
        icon,
        title,
        onClick,
        secondaryIcon,
        styleOverrides,
        subTitle,
        disabled,
        showTooltip,
        toolTipText,
    } = props;
    const classes = useStyles(props)();
    const texts = useTexts();

    return (
        <Paper className={classes.container} style={styleOverrides?.container} elevation={1}>
            <Box mx={2} className={classes.contentWrapper}>
                <Box mr={2}>{icon}</Box>
                <Box className={classes.content}>
                    <Typography component="div" className={classes.title}>
                        {title}
                    </Typography>
                    <Typography component="div" noWrap className={classes.subTitle}>
                        {subTitle}
                    </Typography>
                </Box>
                <Box className={classes.right}>
                    <ComponentWithTooltip showTooltip={showTooltip} tooltipText={toolTipText}>
                        <Button
                            className={classes.button}
                            variant="outlined"
                            disabled={disabled}
                            onClick={onClick}
                            size="small"
                        >
                            {actionBtnText ? actionBtnText : texts.view}
                        </Button>
                    </ComponentWithTooltip>
                    <Box className={classes.badgeContainer}>
                        {!!secondaryIcon && secondaryIcon}
                        {!!badge && <Box ml={1}>{badge}</Box>}
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
};
