import { PageHeader } from '@/components/PageHeader';
import { PageWrapper } from '@/components/PageWrapper';
import { Alert, Box, Button, Stack } from '@mui/material';
import { useDrawerState } from '@/common/hooks/useDrawerState';
import { useFetchDefaultBillingLocation } from '@/common/hooks/query/useQueryDefaultBillingLocation';
import { useAccountId } from '@/atoms/session';
import { useMemo } from 'react';
import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { useTexts } from './useTexts';
import { Drawer } from './drawers/AddPaymentMethod/Drawer';
import { PaymentMethodList } from './PaymentMethodList';

export function PaymentMethodsPage() {
    const texts = useTexts();
    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();
    const accountId = useAccountId();
    const { data: billingLocation } = useFetchDefaultBillingLocation(accountId || '');

    const hasLocationErrors = useMemo(() => {
        return !!billingLocation?.validationErrors;
    }, [billingLocation?.validationErrors]);

    const creditCardEnabled = useFeatureEnabled('enableCreditCardPayment');
    const achEnabled = useFeatureEnabled('enableACHPayment');

    return (
        <PageWrapper>
            <Stack spacing={2}>
                {creditCardEnabled && achEnabled && (
                    <PageHeader title={texts.pageTitle} description={texts.pageDescription} />
                )}

                {creditCardEnabled && !achEnabled && (
                    <PageHeader title={texts.pageTitle} description={texts.creditCardPageDescription} />
                )}

                {!creditCardEnabled && achEnabled && (
                    <PageHeader title={texts.pageTitle} description={texts.achPageDescription} />
                )}
                <Box>
                    <Button variant="contained" onClick={openDrawer} disabled={hasLocationErrors}>
                        {texts.addPaymentMethodBtn}
                    </Button>
                </Box>
                {hasLocationErrors && <Alert severity="error">{texts.locationHasErrors}</Alert>}

                <PaymentMethodList />

                <Drawer open={drawerOpen} onClose={closeDrawer} />
            </Stack>
        </PageWrapper>
    );
}
