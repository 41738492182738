import { Stack, TextField } from '@mui/material';
import { useMemo } from 'react';
import { AchInput } from './Drawer';
import { useTexts } from './useTexts';

interface Props {
    achInput: AchInput;
    setAchInput: (input: AchInput) => void;
}

export function AchEntry(props: Props) {
    const {
        achInput: { accountNumber, routingNumber },
        setAchInput,
    } = props;
    const texts = useTexts();

    const isAccountNumberInvalid = useMemo(() => {
        return isNaN(Number(accountNumber));
    }, [accountNumber]);

    const isRoutingNumberInvalid = useMemo(() => {
        return isNaN(Number(routingNumber)) || (!!routingNumber && routingNumber.length != 9);
    }, [routingNumber]);

    return (
        <Stack spacing={2}>
            <TextField
                id="accountNumber"
                autoComplete="off"
                data-testid="account-number"
                error={isAccountNumberInvalid}
                helperText={isAccountNumberInvalid && texts.invalidAccountNumber}
                inputProps={{ maxLength: 20 }}
                label={texts.accountNumber}
                onChange={e => setAchInput({ accountNumber: e.target.value, routingNumber })}
                required
                value={accountNumber}
            />
            <TextField
                id="routingNumber"
                autoComplete="off"
                data-testid="routing-number"
                error={isRoutingNumberInvalid}
                helperText={isRoutingNumberInvalid && texts.invalidRoutingNumber}
                inputProps={{ maxLength: 9 }}
                label={texts.routingNumber}
                onChange={e => setAchInput({ accountNumber, routingNumber: e.target.value })}
                required
                value={routingNumber}
            />
        </Stack>
    );
}
