import { ReactElement } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
    children: ReactElement;
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
    },
}));

export function Layout({ children }: Props) {
    const classes = useStyles();

    return (
        <Box className={classes.root} display="flex" flexDirection="column" width="100%" height="100vh">
            {children}
        </Box>
    );
}
