import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            amount: translate({ id: 'sections.accountOverview.yourNextService.amount' }),
            confirmBtn: translate({ id: 'sections.accountOverview.yourNextService.confirmBtn' }),
            date: translate({ id: 'sections.accountOverview.yourNextService.date' }),
            dateAndTime: translate({ id: 'sections.accountOverview.yourNextService.dateAndTime' }),
            eligibilityDate: translate({ id: 'sections.accountOverview.yourNextService.eligibilityDate' }),
            error: translate({ id: 'sections.accountOverview.yourNextService.error' }),
            eta: translate({ id: 'global.eta' }),
            failedToConfirm: translate({ id: 'sections.upcomingServices.serviceCard.drawer.failedToConfirm' }),
            header: translate({ id: 'sections.accountOverview.yourNextService.header' }),
            noResults: translate({ id: 'sections.accountOverview.yourNextService.noResults' }),
            notScheduled: translate({ id: 'sections.accountOverview.yourNextService.notScheduled' }),
            notSet: translate({ id: 'global.notSet' }),
            serviceLocation: translate({ id: 'sections.accountOverview.yourNextService.serviceLocation' }),
            servicesToBePerformed: translate({ id: 'sections.accountOverview.yourNextService.servicesToBePerformed' }),
            successfullyConfirmed: translate({ id: 'global.successfullyConfirmed' }),
            team: translate({ id: 'global.team' }),
            technician: translate({ id: 'global.technician' }),
            time: translate({ id: 'sections.accountOverview.yourNextService.time' }),
        };
    }, [translate]);
}
