import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            active: translate({ id: 'sections.agreements.status.active' }),
            agreements: translate({ id: 'sections.agreements.agreements' }),
            cancelled: translate({ id: 'sections.agreements.status.cancelled' }),
            completed: translate({ id: 'sections.agreements.status.completed' }),
            drawerTitle: translate({ id: 'sections.agreements.drawer.title' }),
            effectiveDate: translate({ id: 'sections.agreements.effectiveDate' }),
            filtersDrawerTitle: translate({ id: 'sections.agreements.filtersDrawerTitle' }),
            noAgreementsAlert: translate({ id: 'sections.agreements.noAgreementsAlert' }),
            locationHasErrors: translate({ id: 'sections.agreements.locationHasErrors' }),
            notImplementedYet: translate({ id: 'global.notImplementedYet' }),
            pageTitle: translate({ id: 'sections.manageAutopay.pageTitle' }),
            showActiveAgreements: translate({ id: 'sections.agreements.showActiveAgreements' }),
            showAllAgreements: translate({ id: 'sections.agreements.showAllAgreements' }),
            showCancelledAgreements: translate({ id: 'sections.agreements.showCancelledAgreements' }),
            showCompletedAgreements: translate({ id: 'sections.agreements.showCompletedAgreements' }),
        };
    }, [translate]);
}
