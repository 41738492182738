import { HistoryItemType } from '@/api/accountHistory';
import { proxy, useSnapshot } from 'valtio';
import { useMemo } from 'react';

// Store state
export interface ListState {
    page: number;
    take: number;
    search: string;
    type: HistoryItemType | '';
    serviceLocations: string[];
    selected: string;
    startDate: Date | null;
    endDate: Date | null;
}

export const initialState: ListState = {
    page: 0,
    take: 10,
    search: '',
    type: '',
    serviceLocations: [''],
    selected: '',
    startDate: null,
    endDate: null,
};

// Valtio store initialization
export const state = proxy<ListState>(initialState);

// Setter functions
export function setPage(page: number) {
    state.page = page;
}

export function setTake(take: number) {
    state.take = take;
}

export function setSearch(search: string) {
    state.search = search;
}

export function setType(type: HistoryItemType | '') {
    state.type = type;
}

export function clearType() {
    state.type = initialState.type;
}

export function setServiceLocations(locations: string[]) {
    state.serviceLocations = locations;

    if (!state.serviceLocations.length) {
        state.serviceLocations = initialState.serviceLocations;
    }
}

export function clearServiceLocations() {
    state.serviceLocations = initialState.serviceLocations;
}

export function setStartDate(date: Date | null) {
    state.startDate = date;
}

export function setEndDate(date: Date | null) {
    state.endDate = date;
}

export function clearDates() {
    state.startDate = initialState.startDate;
    state.endDate = initialState.endDate;
}

export function setSelectedItem(id: string) {
    state.selected = id;
}

export function clearSelectedItem() {
    state.selected = '';
}

export function resetFilters() {
    clearType();
    clearServiceLocations();
    clearDates();
}

export function resetStore() {
    state.page = initialState.page;
    state.take = initialState.take;
    state.search = initialState.search;
    state.type = initialState.type;
    state.serviceLocations = initialState.serviceLocations;
    state.selected = initialState.selected;
    state.startDate = initialState.startDate;
    state.endDate = initialState.endDate;
}

//Hooks
export function useListState() {
    return useSnapshot(state);
}

export function useIsListFiltered() {
    const { type, startDate, endDate, serviceLocations } = state;

    const isListFiltered = useMemo(() => {
        return !!type || !!startDate || !!endDate || (!!serviceLocations.length && !!serviceLocations[0]);
    }, [type, startDate, endDate, serviceLocations]);

    return isListFiltered;
}
