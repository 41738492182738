import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { IconButton } from '@mui/material';
import { useTexts } from './useTexts';

interface Props {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClear: () => void;
    placeholder?: string;
    value: string;
}

export const SearchBox = (props: Props) => {
    const { onChange, onClear, placeholder, value } = props;
    const texts = useTexts();

    return (
        <TextField
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={onClear} size="small" edge="end">
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            onChange={onChange}
            placeholder={placeholder || texts.search}
            value={value}
        />
    );
};

SearchBox.displayName = 'SearchBox';
