import { PropertyTrait } from '@/api/serviceLocations';
import { ServiceOffering } from '@/api/services';
import { Alert, CircularProgress, Grid, InputAdornment, TextField } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useRequestServiceState } from '../../../../../store';
import { updatePropertyTrait, useSelectServiceDetails } from '../../../store';
import { useGetPropertyTraitsOnMount } from '../utils/useGetPropertyTraitsOnMount';
import { useTexts } from '../../useTexts';

interface Props {
    columnSize: 6 | 12;
    service: ServiceOffering;
}

export const PropertyTraits = (props: Props) => {
    const { columnSize, service } = props;
    const serviceDetails = useSelectServiceDetails(service.serviceId);
    const { serviceLocation } = useRequestServiceState();
    const texts = useTexts();

    const propertyTraits = useMemo(() => {
        return serviceDetails?.propertyTraits || [];
    }, [serviceDetails]);

    const [status] = useGetPropertyTraitsOnMount(service.serviceId, serviceLocation?.serviceLocationId || '');

    const handleChange = useCallback(
        (event: React.ChangeEvent<{ value: unknown }>, propertyTrait: PropertyTrait) => {
            if (!event.target) {
                return;
            }
            const amount = event.target.value as number;
            updatePropertyTrait(service.serviceId, { ...propertyTrait, amount: Number(amount) });
        },
        [service.serviceId],
    );

    return (
        <>
            {status == 'loading' && (
                <Grid item xs={columnSize}>
                    <CircularProgress size={14} />
                </Grid>
            )}
            {status == 'success' && (
                <>
                    {propertyTraits.map((p, i) => (
                        <Grid item key={i} xs={columnSize}>
                            <TextField
                                fullWidth
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                    },
                                    endAdornment: <InputAdornment position="end">{p.unitOfMeasure}</InputAdornment>,
                                }}
                                label={p.name}
                                margin="dense"
                                name={p.name}
                                onChange={e => handleChange(e, p)}
                                required
                                type="number"
                                value={
                                    serviceDetails?.propertyTraits.find(pt => pt.propertyTraitId == p.propertyTraitId)
                                        ?.amount ?? 0
                                }
                            />
                        </Grid>
                    ))}
                </>
            )}
            {status == 'error' && (
                <Grid item xs={columnSize}>
                    <Alert severity="error">{texts.errorLoadingPropertyTraits}</Alert>
                </Grid>
            )}
        </>
    );
};
