import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            title: translate({ id: `sections.unknown.title` }),
            message: translate({ id: `sections.unknown.message` }),
        };
    }, [translate]);
}
