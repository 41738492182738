import { disableAutopayment } from '@/api/autopayments';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { queryClient } from '../../../../services/queryClient';
import { setPaymentAccount, setWizardStep, WizardStep } from '../store';
import { useTexts } from '../useTexts';

export function useRemoveAutoPayActions(agreementId: string) {
    const texts = useTexts();

    const handleRemoveAutopay = useCallback(async () => {
        try {
            await disableAutopayment(agreementId);
            queryClient.invalidateQueries('activeAgreements');
            queryClient.invalidateQueries('agreements');
            queryClient.invalidateQueries('agreementDetails');
            queryClient.invalidateQueries('paymentAccounts');
            setPaymentAccount(null);
            setWizardStep(WizardStep.Detail);
            toast.success(texts.successfullyDisabledAutopay);
        } catch {
            toast.error(texts.errorDisablingAutopay);
        }
    }, [agreementId, texts]);

    return {
        handleRemoveAutopay,
    } as const;
}
