import { get } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';

export interface ServiceLocation {
    accountId: string;
    active: boolean;
    businessUnitId: string;
    city: string;
    country: string;
    countryCode: string;
    county: string;
    defaultBillingLocationId: string;
    defaultContactId: string;
    latitude: number;
    longitude: number;
    manualTaxRateId: string;
    name: string | null;
    number: number;
    postalCode: string;
    serviceLocationId: string;
    serviceMemo: string | null;
    state: string;
    stateAbbreviation: string;
    street1: string;
    street2: string | null;
    taxRate: number;
    taxRateDetail: string;
    timeZoneId: string;
    validationErrors: string;
    zoneId: string;
}

export interface PropertyTrait {
    amount: number;
    name: string;
    propertyTraitId: string;
    serviceLocationId: string;
    serviceLocationPropertyTraitId: string;
    unitOfMeasure: string;
}

const baseUrl = '/api/serviceLocations';

export const getLocations = async (accountId: string) => {
    try {
        const { data, status } = await get<ServiceLocation[]>(`${baseUrl}?accountId=${accountId}`);

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get service locations.`, status);
    } catch (e) {
        throw e;
    }
};

export const getPropertyTraitsByService = async (serviceId: string, serviceLocationId: string) => {
    try {
        const { data, status } = await get<PropertyTrait[]>(
            `${baseUrl}/${serviceLocationId}/ServiceLocationPropertyTraits?serviceId=${serviceId}`,
        );

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get property traits.`, status);
    } catch (e) {
        throw e;
    }
};
