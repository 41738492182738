import { PageWrapper } from '@/components/PageWrapper';
import { useAccountId } from '@/atoms/session';
import { PageErrorState } from '@/components/PageErrorState/PageErrorState';
import { useQueryServiceRequests } from '@/common/hooks/query/useQueryServiceRequests';
import { useEffect } from 'react';
import { LoadingSkeleton } from './LoadingSkeleton';
import { YourRequestsList } from './YourRequestsList';
import { resetStore, useListState } from './YourRequestsList/store';

export function YourRequestsPage() {
    const { page, take } = useListState();
    const accountId = useAccountId();
    const query = useQueryServiceRequests(accountId || '', page, take);

    useEffect(() => {
        resetStore();
    }, [accountId]);

    return (
        <PageWrapper disableScroll>
            {query.status === 'loading' && <LoadingSkeleton />}
            {query.status === 'success' && <YourRequestsList items={query.data.items} total={query.data.total} />}
            {query.status === 'error' && <PageErrorState />}
        </PageWrapper>
    );
}
