import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { useTexts } from '../useTexts';

interface Props {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles({
    button: {
        whiteSpace: 'nowrap',
        minWidth: 'max-content',
    },
});

export function ClearFiltersButton(props: Props) {
    const { onClick } = props;
    const texts = useTexts();
    const classes = useStyles();

    return (
        <Box>
            <Button className={classes.button} variant="text" onClick={onClick}>
                {texts.clearAll}
            </Button>
        </Box>
    );
}
