import { getInvoiceReport, InvoiceDetail } from '@/api/invoices';
import { DefinitionListItem } from '@/components/DefinitionListItem';
import { Currency } from '@/components/localization';
import { LongDate } from '@/components/localization/Dates';
import { Box, Button, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { OpenPDFButton } from '@/components/OpenPDFButton';
import { usePaymentsEnabled } from '@/common/hooks/usePaymentsEnabled';
import { useTexts } from '../useTexts';
import {
    AssociatedWorkOrders,
    BillingLocation,
    Payments,
    PaymentStatus,
    ServiceLocation,
    ServicesPerformed,
} from './listItems';

interface Props {
    details: InvoiceDetail;
}

export function DetailListItems(props: Props) {
    const texts = useTexts();
    const navigate = useNavigate();
    const paymentsEnabled = usePaymentsEnabled();

    const {
        details,
        details: { balance, invoiceDate, invoiceNum, total },
    } = props;

    const showMakePaymentButton = useMemo(() => {
        return paymentsEnabled && balance !== 0;
    }, [balance, paymentsEnabled]);

    const redirectToMakePayment = useCallback(() => {
        navigate('/payments/pay-invoices');
    }, [navigate]);

    const onInvoiceReportClick = useCallback(() => {
        return getInvoiceReport(details.invoiceId);
    }, [details.invoiceId]);

    return (
        <Stack direction="column" spacing={2}>
            <DefinitionListItem primary={`${texts.invoice} #${invoiceNum}`} />

            <DefinitionListItem primary={texts.invoiceDate} secondary={<LongDate value={invoiceDate} />} />

            <ServiceLocation invoiceDetails={details} />

            <BillingLocation invoiceDetails={details} />

            <ServicesPerformed invoiceDetails={details} />

            <AssociatedWorkOrders invoiceDetails={details} />

            <DefinitionListItem primary={texts.total} secondary={<Currency value={total} />} />

            <DefinitionListItem primary={texts.balance} secondary={<Currency value={balance} />} />

            <PaymentStatus invoiceDetails={details} />

            <Payments invoiceDetails={details} />

            {showMakePaymentButton && (
                <Box>
                    <Button variant="contained" onClick={redirectToMakePayment}>
                        {texts.makePayment}
                    </Button>
                </Box>
            )}
            <Box>
                <OpenPDFButton onClick={onInvoiceReportClick} errorText={texts.invoiceReportFailed}>
                    {texts.invoice} #{details.invoiceNum} {texts.pdf}
                </OpenPDFButton>
            </Box>
        </Stack>
    );
}
