import type { Language } from '@/intl';

import { changeLanguageService } from './changeLanguageService';
import { setLanguage } from './store';

type RejectError = {
    message: string;
    type: 'error-loading-locale' | 'action-cancelled';
};

type Result = {
    language: Language;
    messages: Record<string, string>;
};

let changeLanguageController: AbortController | undefined;
export async function changeLanguage(language: Language): Promise<Result | RejectError> {
    if (changeLanguageController) {
        changeLanguageController.abort();
    }
    changeLanguageController = new AbortController();
    const signal = changeLanguageController.signal;

    try {
        const messages = await changeLanguageService(language, signal);

        if (signal.aborted) {
            return {
                message: 'Action Cancelled',
                type: 'action-cancelled',
            };
        }

        changeLanguageController = undefined;
        setLanguage(language, messages);

        return { language, messages };
    } catch (e) {
        changeLanguageController = undefined;

        return {
            message: e instanceof Error ? e.message : 'unknown error',
            type: 'error-loading-locale',
        };
    }
}
