import { PaymentAccount, PaymentAccountTypes } from '@/common/types/payments';
import { Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useMemo } from 'react';
import { CardIcon } from './CardIcon';
import { DeleteButton } from './DeleteButton';
import { PaymentAccountDetails } from './PaymentAccountDetails';

interface Props {
    hideActions?: boolean;
    isSelected?: boolean;
    onClick?: () => void;
    paymentAccount: PaymentAccount;
    selectable?: boolean;
}

const useStyles = (props: Props) =>
    makeStyles(theme => ({
        root: {
            aspectRatio: '4/2.5',
            backgroundColor: props.isSelected ? theme.palette.primary.main : theme.palette.white,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.spacing(0.5),
            color: props.isSelected ? theme.palette.white : 'inherit',
            cursor: props.selectable ? 'pointer' : 'default',
        },
        cardNumber: {
            fontSize: '24px',
            color: props.isSelected ? theme.palette.white : 'inherit',
        },
    }));

export function PaymentAccountCard(props: Props) {
    const { hideActions = false, onClick, paymentAccount, selectable, isSelected } = props;
    const classes = useStyles(props)();

    const isCreditCard = useMemo(() => {
        return paymentAccount.accountType == PaymentAccountTypes.CREDIT_CARD;
    }, [paymentAccount.accountType]);

    const handleClick = useCallback(() => {
        if (selectable && !!onClick) {
            onClick();
        }
    }, [onClick, selectable]);

    const accountNumber = useMemo(() => {
        return isCreditCard ? `**** **** **** ${paymentAccount.ending}` : `*****${paymentAccount.ending}`;
    }, [isCreditCard, paymentAccount.ending]);

    return (
        <Box display="flex" p={2} className={classes.root} onClick={handleClick}>
            <Stack width="100%">
                <Box display="flex" alignItems="center">
                    <Box display="flex" flexGrow={1}>
                        <CardIcon accountName={paymentAccount.friendly} />
                    </Box>
                    {!hideActions && (
                        <Box>
                            <DeleteButton
                                isDisabled={paymentAccount.isAutoPayment || paymentAccount.hasAutoPaymentInstance}
                                paymentAccount={paymentAccount}
                            />
                        </Box>
                    )}
                </Box>
                <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="center">
                    <Typography className={classes.cardNumber}>{accountNumber}</Typography>
                </Box>
                <PaymentAccountDetails
                    isSelected={!!isSelected}
                    expMonth={paymentAccount.month}
                    expYear={paymentAccount.year}
                    friendly={paymentAccount.friendly}
                    isCreditCard={isCreditCard}
                    isExpired={paymentAccount.isExpired}
                />
            </Stack>
        </Box>
    );
}
