import axios from 'axios';
import { post } from '@/utils/http';

export interface LoginPayload {
    token: string;
    refreshToken: string;
    user: AuthenticatedUserModel;
    expirationInUtc: string;
}

export interface AuthenticatedUserModel {
    identityUserId: string;
    contactId: string;
    firstName: string;
    lastName: string;
    email: string;
}

export const loginService = async (userName: string, password: string, abortSignal?: AbortSignal) => {
    const source = axios.CancelToken.source();

    const handleOnAbort = () => source.cancel();
    abortSignal?.addEventListener('abort', handleOnAbort);
    const options = abortSignal ? { cancelToken: source.token } : undefined;

    try {
        const response = await post<LoginPayload>('/api/sessions', { userName, password }, options);
        if (response.status === 200 || response.status === 201) {
            return response.data;
        }

        throw new Error(`${response.status} - ${response.statusText}`);
    } catch (e) {
        throw e;
    } finally {
        abortSignal?.removeEventListener('abort', handleOnAbort);
    }
};
