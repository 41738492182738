import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/pro-regular-svg-icons';
import { DesktopListCard } from '@/components/cardList/DesktopListCard';
import { useGetUILayout } from '@/atoms/UILayout';
import { MobileListCard } from '@/components/cardList/MobileListCard';
import { SideDrawer } from '@/components/SideDrawer';
import { useDrawerState } from '@/common/hooks/useDrawerState';
import { useCallback, useMemo } from 'react';
import { ServiceRequestListItemModel } from '@/api/serviceRequests';
import { useTexts } from '../../useTexts';
import { StatusBadge } from '../../components/StatusBadge';
import { DrawerContent } from '../../drawer/DrawerContent';
import { setSelectedItem, useListState } from '../store';
import { SubTitle } from './SubTitle';

export interface CardProps {
    item: ServiceRequestListItemModel;
}

export const RequestCard = (props: CardProps) => {
    const { item } = props;
    const texts = useTexts();
    const layout = useGetUILayout();
    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();
    const { selected } = useListState();

    const handleOpen = useCallback(() => {
        setSelectedItem(item.protoServiceAgreementId!);
        openDrawer();
    }, [openDrawer, item.protoServiceAgreementId]);

    const handleClose = useCallback(() => {
        setSelectedItem('');
        closeDrawer();
    }, [closeDrawer]);

    const title = useMemo(() => {
        return item.servicesRequested.join(', ');
    }, [item.servicesRequested]);

    return (
        <>
            {layout == 'desktop' ? (
                <DesktopListCard
                    badge={<StatusBadge status={item.status} />}
                    icon={<FontAwesomeIcon icon={faAward} />}
                    onClick={handleOpen}
                    selected={item.protoServiceAgreementId === selected}
                    subTitle={<SubTitle item={item} />}
                    title={title}
                />
            ) : (
                <MobileListCard
                    badge={<StatusBadge status={item.status} />}
                    icon={<FontAwesomeIcon icon={faAward} />}
                    onClick={handleOpen}
                    selected={item.protoServiceAgreementId === selected}
                    subTitle={<SubTitle item={item} />}
                    title={title}
                />
            )}

            <SideDrawer isOpen={drawerOpen} onClose={handleClose} title={texts.drawerTitle}>
                <DrawerContent protoServiceAgreementId={item.protoServiceAgreementId} />
            </SideDrawer>
        </>
    );
};
