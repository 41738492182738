import type { Scrollbar, ScrollIntoViewOptions } from 'smooth-scrollbar/interfaces';

import { MutableRefObject, useEffect } from 'react';

export function useTestActions(
    rScrollbar: MutableRefObject<Scrollbar | undefined>,
    qa?: 'enable' | 'disable',
    testId?: string,
) {
    // ------------------------------------------------------------------------------------
    // TEST ACTIONS
    // ------------------------------------------------------------------------------------
    const qaEnabled = isQaEnabled(qa);
    useEffect(() => {
        // register the test action

        if (!qaEnabled || !testId) return;

        try {
            globalThis.testActions ??= {};
            globalThis.testActions[testId] ??= {};

            // allows QA to programmatically scroll elements into view
            globalThis.testActions[testId].scrollElementIntoView = (
                el: HTMLElement,
                options?: Partial<ScrollIntoViewOptions>,
            ) => {
                if (!el) console.error('Invalid HTMLElement');
                if (!rScrollbar.current) console.error('No scrollbar yet');
                rScrollbar.current?.scrollIntoView(el, options);
            };

            return () => {
                // delete the test action
                if (testId) delete globalThis.testActions?.[testId]?.scrollElementIntoView;
            };
        } catch (e) {
            console.warn(e);
        }
    }, [qaEnabled, testId, rScrollbar]);
}

function isQaEnabled(qa?: 'enable' | 'disable') {
    // if present, the passed prop supersedes the environment variable
    if (qa) return qa === 'enable';

    // allow @ts-ignore usage
    /* eslint-disable @typescript-eslint/prefer-ts-expect-error */

    try {
        // @ts-ignore avoids the developer to add add type for Vite's env variable
        return import.meta.env.VITE_QA === 'enable';
    } catch {
        // @ts-ignore avoids the developer to add add type for the env variable
        return process.env.QA === 'enable';
    }

    /* eslint-enable @typescript-eslint/prefer-ts-expect-error */
}
