import { ServiceRequestStatus } from '@/api/serviceRequests';
import { CardBadge } from '@/components/cardList/CardBadge';
import { useTexts } from '../useTexts';

interface Props {
    status: string;
}

export function StatusBadge(props: Props) {
    const { status } = props;
    const texts = useTexts();

    switch (status) {
        case ServiceRequestStatus.Pending:
            return <CardBadge severity="warning" text={texts.pending} />;
        case ServiceRequestStatus.Processed:
            return <CardBadge severity="success" text={texts.processed} />;
        case ServiceRequestStatus.Declined:
            return <CardBadge severity="error" text={texts.declined} />;
        default:
            return null;
    }
}
