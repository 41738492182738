import { Components } from '@mui/material';
import { MuiDialogTitle } from './MuiDialogTitle';
import { MuiFormControl } from './MuiFormControl';
import { MuiIconButton } from './MuiIconButton';
import { MuiLink } from './MuiLink';
import { MuiPaper } from './MuiPaper';
import { MuiSelect } from './MuiSelect';
import { MuiTableCell } from './MuiTableCell';
import { MuiTableHead } from './MuiTableHead';
import { MuiTableRow } from './MuiTableRow';
import { MuiTextField } from './MuiTextField';
import { MuiTypography } from './MuiTypography';

export const components: Components = {
    MuiDialogTitle,
    MuiFormControl,
    MuiIconButton,
    MuiLink,
    MuiPaper,
    MuiSelect,
    MuiTableCell,
    MuiTableHead,
    MuiTableRow,
    MuiTextField,
    MuiTypography,
};
