import type { ReactElement } from 'react';

import { QueryClientProvider } from 'react-query';

import { queryClient } from './queryClient';

interface Props {
    children: ReactElement;
}

/**
 * react-query provider.
 * Rendered using a custom client cache.
 */
export function ReactQueryProvider(props: Props) {
    const { children } = props;

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
