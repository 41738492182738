import { DefinitionListItem } from '@/components/DefinitionListItem';
import { Grid, Typography } from '@mui/material';
import { WorkOrderWidgetData } from '@/api/workOrders';
import { FormattedTime } from 'react-intl';
import { LongDate } from '@/components/localization';
import { ResourceDisplay } from '@/common/components/ResourceDisplay';
import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { CustomerPortalLastServiceFields } from '@/common/types/services';
import { ServiceAmount } from '../common/ServiceAmount';
import { ServicesList } from '../common/ServicesList';
import { ServiceLocationDetail } from '../common/ServiceLocationDetail';
import { useTexts } from './useTexts';
interface Props {
    data: WorkOrderWidgetData | null;
}

export const Content = (props: Props) => {
    const { data } = props;
    const texts = useTexts();

    const enableLastServiceScheduledDate = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServiceScheduledDate,
    );
    const enableLastServiceScheduledTime = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServiceScheduledTime,
    );
    const enableLastServiceServiceLocation = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServiceServiceLocation,
    );
    const enableLastServiceIncludedServices = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServiceIncludedServices,
    );
    const enableLastServiceTeamTechnicianInfo = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServiceTeamTechnicianInfo,
    );
    const enableLastServicePriceOfServiceVisit = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServicePriceOfServiceVisit,
    );

    return (
        <>
            {!!data && (
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        {enableLastServiceScheduledDate && enableLastServiceScheduledTime && (
                            <DefinitionListItem
                                primary={texts.dateAndTime}
                                secondary={
                                    (
                                        <Typography>
                                            <LongDate value={data.datePerformed || data.estimatedTimeOfArrival} /> at{' '}
                                            <FormattedTime value={data.datePerformed || data.estimatedTimeOfArrival} />
                                        </Typography>
                                    ) || texts.notSet
                                }
                            />
                        )}

                        {enableLastServiceScheduledDate && !enableLastServiceScheduledTime && (
                            <DefinitionListItem
                                primary={texts.date}
                                secondary={
                                    (
                                        <Typography>
                                            <LongDate value={data.datePerformed || data.estimatedTimeOfArrival} />
                                        </Typography>
                                    ) || texts.notSet
                                }
                            />
                        )}
                        {!enableLastServiceScheduledDate && enableLastServiceScheduledTime && (
                            <DefinitionListItem
                                primary={texts.time}
                                secondary={
                                    (
                                        <Typography>
                                            <FormattedTime value={data.datePerformed || data.estimatedTimeOfArrival} />
                                        </Typography>
                                    ) || texts.notSet
                                }
                            />
                        )}
                    </Grid>
                    {enableLastServiceServiceLocation && (
                        <Grid item>
                            <DefinitionListItem
                                primary={texts.serviceLocation}
                                secondary={<ServiceLocationDetail serviceLocation={data.serviceLocation} />}
                            />
                        </Grid>
                    )}

                    <Grid item container>
                        {enableLastServiceIncludedServices && (
                            <Grid item xs={12} sm={6}>
                                <DefinitionListItem
                                    primary={texts.servicesPerformed}
                                    secondary={<ServicesList services={data.services} />}
                                />
                            </Grid>
                        )}
                        {enableLastServicePriceOfServiceVisit && (
                            <Grid item xs={12} sm={6}>
                                <ServiceAmount amount={data.amount || 0} label={texts.amount} />
                            </Grid>
                        )}
                    </Grid>

                    {enableLastServiceTeamTechnicianInfo && (
                        <Grid item>
                            <ResourceDisplay
                                imageId={data.imageId}
                                isCustom={data.isCustomImage}
                                name={data.resource || texts.notSet}
                            />
                        </Grid>
                    )}
                </Grid>
            )}

            {!data && <Typography>{texts.noResults}</Typography>}
        </>
    );
};

Content.displayName = 'Content';
