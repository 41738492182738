import { PaymentMethod } from '@/api/accountHistory';
import { useMemo } from 'react';

export const useFormattedAccountNumber = (
    paymentAccountType: string | null,
    paymentMethod: PaymentMethod | null,
    reference: string | null,
) => {
    const accountNumber = useMemo(() => {
        const splitAccountType = paymentAccountType?.split(' ');
        const type = splitAccountType?.length == 3 && splitAccountType[0];
        const digits = splitAccountType?.length == 3 && splitAccountType[2];

        if (paymentMethod != PaymentMethod.CHECK && type && digits) {
            return paymentMethod == PaymentMethod.CREDIT_CARD
                ? `${type} #**** **** **** ${digits}`
                : `${type} #*****${digits}`;
        } else if (paymentMethod == PaymentMethod.CHECK && paymentMethod && reference) {
            return `${paymentMethod} ${reference}`;
        }

        return null;
    }, [paymentAccountType, paymentMethod, reference]);

    return accountNumber;
};
