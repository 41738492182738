import type { FormState } from '../types';
import type { FormConfig } from '../createForm';
import { useRef, useState, useEffect } from 'react';
import { FormField, FormError } from '../types';

import { createForm } from '../createForm';

/**
 * Helper Hook used to create and maintain a Form State instance.
 *
 * @param id Unique Form identifier.
 * @param baseFields Form Fields Shape.
 * @param config Optional Form configuration
 * @returns A complete Form State
 */
export function useCreateForm<FIELDS extends Record<string, FormField>, ERROR extends FormError<keyof FIELDS>>(
    id: string,
    baseFields?: FIELDS,
    config?: FormConfig<FIELDS, ERROR>,
): FormState<FIELDS, ERROR> {
    const rInitialized = useRef(false);

    const [formState] = useState<FormState<FIELDS, ERROR>>(() => {
        return createForm(id, baseFields, config);
    });

    useEffect(() => {
        if (rInitialized.current && process.env.NODE_ENV === 'development') {
            console.warn(`[useCreateForm] Unsupported action. The form ID changed to: ${id}.`);
        }
    }, [id]);

    useEffect(() => {
        if (rInitialized.current && process.env.NODE_ENV === 'development') {
            console.warn(`[useCreateForm] Unsupported action. The form baseFields changed.`, baseFields);
        }
    }, [baseFields]);

    useEffect(() => {
        if (rInitialized.current && process.env.NODE_ENV === 'development') {
            console.warn(`[useCreateForm] Unsupported action. The form config changed.`, config);
        }
    }, [config]);

    useEffect(() => {
        rInitialized.current = true;
    }, []);

    return formState;
}
