export interface FormattableCreditCardInfo {
    cardType: string;
    lastFourDigits: string;
}

export function formatCreditCardFriendly(l: FormattableCreditCardInfo | null) {
    if (!l) {
        return '';
    }
    return `${l.cardType} - ${l.lastFourDigits}`;
}
