import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            changePassword: translate({ id: 'sections.profile.password.changePassword' }),
            emailLabel: translate({ id: `sections.profile.email.label` }),
            header: translate({ id: `sections.profile.header` }),
            passwordLabel: translate({ id: `sections.profile.password.label` }),
            passwordMask: translate({ id: `sections.profile.password.mask` }),
        };
    }, [translate]);
}
