import { useCallback, useMemo } from 'react';
import { SideDrawerForm } from '@/components/form/SideDrawerForm';
import { Alert, Box } from '@mui/material';
import { setServiceDetails, useRequestServiceState } from '../../../store';
import { getServiceDetail, useSelectServiceDetail } from '../store';
import { areServiceDetailsSet } from '../../YourRequest/validation';
import { useTexts } from './useTexts';
import { ServiceDetailList } from './ServiceDetailList';

interface Props {
    onClose: () => void;
    open: boolean;
}

export const Drawer = (props: Props) => {
    const { onClose, open } = props;
    const texts = useTexts();
    const request = useRequestServiceState();
    const serviceDetails = useSelectServiceDetail();

    const onSave = useCallback(() => {
        const detail = getServiceDetail();
        setServiceDetails(detail);
        onClose();
    }, [onClose]);

    const isSaveDisabled = useMemo(() => {
        if (serviceDetails.length == 0) {
            return true;
        }

        const isValid = areServiceDetailsSet(request, serviceDetails);

        return !isValid;
    }, [request, serviceDetails]);

    return (
        <SideDrawerForm
            disableSave={isSaveDisabled}
            isOpen={open}
            headerSecondary={
                <Box p={2}>
                    <Alert severity="info">{texts.infoBox}</Alert>
                </Box>
            }
            onClose={onClose}
            onSave={onSave}
            title={texts.header}
        >
            <ServiceDetailList />
        </SideDrawerForm>
    );
};

Drawer.displayName = 'Drawer';
