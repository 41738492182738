import { getAccount } from '@/atoms/session/store';
import { useMemo } from 'react';
import { useFetchBusinessUnitDetails } from './query/useFetchBusinessUnitDetails';

export const useBusinessUnitDetails = () => {
    const account = getAccount();
    const { data: details } = useFetchBusinessUnitDetails(account?.businessUnitId || '');

    return useMemo(() => {
        return details ? details : false;
    }, [details]);
};
