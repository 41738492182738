import { useDrawerState } from '@/common/hooks/useDrawerState';
import { Currency } from '@/components/localization';
import { Box, Button, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useMemo } from 'react';
import { Drawer } from './drawer/Drawer';
import { resetInvoicePaymentDetails, usePayInvoicesState } from './store';
import { useTexts } from './useTexts';

interface Props {
    disableSelection: boolean;
}

const useStyles = makeStyles({
    root: {
        fontWeight: 500,
        fontSize: '18px',
    },
});

export function InvoiceTotal(props: Props) {
    const { disableSelection } = props;
    const classes = useStyles();
    const { invoicesSelected, startingBalancePayment } = usePayInvoicesState();
    const { invoiceTotal: texts } = useTexts();

    const total = useMemo(() => {
        return invoicesSelected.reduce((a, b) => a + b.balance || 0, 0) + startingBalancePayment;
    }, [invoicesSelected, startingBalancePayment]);

    const selected = useMemo(() => {
        return invoicesSelected.length + (startingBalancePayment > 0 ? 1 : 0);
    }, [invoicesSelected, startingBalancePayment]);

    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();

    const onClose = useCallback(() => {
        closeDrawer();
        resetInvoicePaymentDetails();
    }, [closeDrawer]);

    return (
        <>
            {selected > 0 && (
                <>
                    <Stack spacing={2}>
                        <Box display="flex" justifyContent="flex-end">
                            <Typography className={classes.root} variant="h5">
                                {texts.paymentTotalFor} {selected}{' '}
                                {selected == 1 ? texts.invoiceSingular : texts.invoicePlural}:{' '}
                                <Currency value={total} />
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="flex-end" pb={2}>
                            <Button onClick={openDrawer} disabled={disableSelection} variant="contained">
                                {texts.selectPaymentMethod}
                            </Button>
                        </Box>
                    </Stack>

                    <Drawer open={drawerOpen} onClose={onClose} />
                </>
            )}
        </>
    );
}
