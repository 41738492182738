import { useMemo } from 'react';
import { splitAddress, SplittableAddress } from '../utils/splitAddress';

export const useSplitAddress = (l: SplittableAddress | null) => {
    const address = useMemo(() => {
        return splitAddress(l);
    }, [l]);

    return address;
};
