import { HistoryItemType } from '@/api/accountHistory';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useCallback } from 'react';
import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { useListState } from '../store';
import { useListActions } from '../hooks';
import { useTexts } from './useTexts';

export function ItemType() {
    const texts = useTexts();
    const { type } = useListState();
    const { handleSetType } = useListActions();
    const serviceHistoryEnabled = useFeatureEnabled('enableServiceHistory');
    const paymentHistoryEnabled = useFeatureEnabled('enableViewPaymentHistory');
    const invoiceHistoryEnabled = useFeatureEnabled('enableBillingHistory');

    const formatMenuItemText = useCallback(
        (type: HistoryItemType) => {
            switch (type) {
                case HistoryItemType.INVOICE:
                    return texts.invoices;
                case HistoryItemType.WORK_ORDER:
                    return texts.serviceVisits;
                case HistoryItemType.PAYMENT:
                    return texts.payments;
            }
        },
        [texts.invoices, texts.serviceVisits, texts.payments],
    );

    const shouldDisableOption = useCallback(
        (type: HistoryItemType) => {
            switch (type) {
                case HistoryItemType.INVOICE:
                    return !invoiceHistoryEnabled;
                case HistoryItemType.WORK_ORDER:
                    return !serviceHistoryEnabled;
                case HistoryItemType.PAYMENT:
                    return !paymentHistoryEnabled;
            }
        },
        [invoiceHistoryEnabled, serviceHistoryEnabled, paymentHistoryEnabled],
    );

    return (
        <Select value={type} onChange={handleSetType} displayEmpty>
            <MenuItem value="">{texts.showCompleteAccountHistory}</MenuItem>
            {Object.values(HistoryItemType).map(
                (key, i) =>
                    !shouldDisableOption(key) && (
                        <MenuItem key={i} value={key}>
                            {formatMenuItemText(key)}
                        </MenuItem>
                    ),
            )}
        </Select>
    );
}
