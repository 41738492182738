import { useCallback, useEffect, useState } from 'react';
import { getPropertyTraitsByService } from '@/api/serviceLocations';
import { isServerError } from '@/utils/ServerError';
import { addPropertyTrait } from '../../../store';

type Status = 'error' | 'loading' | 'success';

export const useGetPropertyTraitsOnMount = (serviceId: string, serviceLocationId: string) => {
    const [status, setStatus] = useState<Status>('loading');
    const [isStale, setIsStale] = useState<boolean>(true);

    useEffect(() => {
        let mounted = true;

        const getData = async (serviceId: string, serviceLocationId: string) => {
            try {
                if (mounted) {
                    setStatus('loading');
                }

                const response = await getPropertyTraitsByService(serviceId, serviceLocationId);

                if (isServerError(response)) {
                    throw new Error(response.message);
                }

                if (mounted && !!response) {
                    setStatus('success');

                    response.forEach(p => {
                        addPropertyTrait(serviceId, p);
                    });
                }
            } catch (error) {
                if (mounted) {
                    setStatus('error');
                }
            } finally {
                if (mounted) {
                    setIsStale(false);
                }
            }
        };

        if (isStale && !!serviceId && !!serviceLocationId) {
            getData(serviceId, serviceLocationId);
        }

        return () => {
            mounted = false;
        };
    }, [isStale, serviceId, serviceLocationId]);

    const refreshData = useCallback(async () => {
        setIsStale(true);
    }, []);

    useEffect(() => {
        refreshData();
    }, [refreshData, serviceId, serviceLocationId]);

    return [status] as const;
};
