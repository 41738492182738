import { ToastContainer } from 'react-toastify';

/**
 * Toast notifications root component
 */
export function ToastNotificationProvider() {
    return (
        <ToastContainer
            limit={6}
            draggable={true}
            autoClose={5000}
            newestOnTop={false}
            hideProgressBar={true}
            position="bottom-right"
            theme="colored"
        />
    );
}
