import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            clearAll: translate({ id: 'sections.accountHistory.filters.clearAll' }),
            title: translate({ id: 'sections.upcomingServices.drawer.title' }),
        };
    }, [translate]);
}
