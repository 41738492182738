import type { Mutation } from 'react-query/types/core/mutation';
import type { Query } from 'react-query/types/core/query';

import { QueryClient, QueryCache, MutationCache } from 'react-query';

import { ServerError } from '@/utils/ServerError';

const manageError = (error: ServerError) => {
    if (error.message.endsWith('401') || error.message.endsWith('403')) {
        // TODO: If the user is unauthorized should the application logout?
    }
};

const handleQueryError = (error: unknown, _query: Query) => {
    manageError(error as ServerError);
};

const handleMutationError = (
    error: unknown,
    _variables: unknown,
    _context: unknown,
    _mutation: Mutation<unknown, unknown, unknown, unknown>,
) => {
    manageError(error as ServerError);
};

const queryCache = new QueryCache({ onError: handleQueryError });

const mutationCache = new MutationCache({ onError: handleMutationError });

/** custom cache for react query */
export const queryClient = new QueryClient({
    queryCache,
    mutationCache,
    defaultOptions: {
        queries: {
            retry: 1,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            staleTime: 60000,
        },
    },
});
