import type { Scrollbar } from 'smooth-scrollbar/interfaces';
import type { Props } from './types';

import { useRef, useImperativeHandle, forwardRef } from 'react';

import { useInitSmoothScrollbar } from './hooks/useInitSmoothScrollbar';
import { useOnScroll } from './hooks/useOnScroll';
import { useTestActions } from './hooks/useTestActions';
import { useOnScrollTo } from './hooks/useOnScrollTo';
import { useOnScrollIntoView } from './hooks/useOnScrollIntoView';
import { useNotifyListeners } from './hooks/useNotifyListeners';
import { useStyles } from './hooks/useStyles';

const defaultScrollbarPosition = {
    position: 'asideContent',
    gap: 20,
} as const;

/**
 * ATTENTION: If the QA mode enabled and a `testId` is provided, a test action is exposed on the
 * global window object. The test action is `window.testActions[testId].scrollElementIntoView` and
 * allows to scroll an element into the view. The QA mode can be enabled
 * - through the `qa` prop (accepted values: 'enable' and 'disable')
 * - through the process.env.QA or the import.meta.env.VITE_QA variables (accepted values: 'enable' and 'disable')
 * Please note that the `qa` pros supersedes the env variable.
 */
export const SmoothScrollbar = forwardRef<
    Scrollbar | undefined,
    Omit<JSX.IntrinsicElements['section'], 'onScroll'> & Props
>((props, forwardedRef) => {
    const {
        children,
        testId,
        scrollbarPosition = defaultScrollbarPosition,
        qa,
        onScroll,
        scrollTo,
        scrollIntoView,
        onChangeScrollbarVisibility,
        onChangeOutOfViewportHeight,
    } = props;
    const rContainer = useRef<HTMLElement | null>(null);
    const rScrollbar = useRef<Scrollbar>();

    // initialize the SmoothScrollbar library
    useInitSmoothScrollbar(props, rScrollbar, rContainer.current);

    // register/unregister onScroll
    useOnScroll(onScroll, rScrollbar);

    // QA test actions
    useTestActions(rScrollbar, qa, testId);

    // Expose scrollbar to the consumers via forwardedRef
    useImperativeHandle(forwardedRef, () => rScrollbar.current);

    // react to scrollTo
    useOnScrollTo(scrollTo, rScrollbar.current);

    // react to scrollIntoView
    useOnScrollIntoView(scrollIntoView, rScrollbar.current);

    // notify listeners that scrollbar has been updated
    useNotifyListeners(rScrollbar.current, onChangeScrollbarVisibility, onChangeOutOfViewportHeight);

    const styles = useStyles(props.width, props.height, scrollbarPosition);

    return (
        <section data-scrollbar ref={rContainer} style={styles} data-testid={testId} data-testActions={testId}>
            <div>{children}</div>
        </section>
    );
});

SmoothScrollbar.displayName = 'SmoothScrollbar';
