import { Box, Link } from '@mui/material';
import { useCallback } from 'react';
import { ListProps } from '../List/typings';
import { AccountSwitchList } from './AccountSwitchList';
import { useTexts } from './useTexts';

type AccountSwitchActionProps = {
    accountsList: Account.AccountSelect[];
    clearSubList: () => void;
} & ListProps;

export const AccountSwitchAction = (props: AccountSwitchActionProps) => {
    const { accountsList, setSubList, clearSubList } = props;
    const texts = useTexts();

    const accountSwitch = AccountSwitchList({ accountsList: accountsList, clearSubList: clearSubList });

    const handleClick = useCallback(() => {
        setSubList(accountSwitch);
    }, [setSubList, accountSwitch]);

    return (
        <Box mt={1}>
            <Link variant="button" onClick={handleClick}>
                {texts.switchLink}
            </Link>
        </Box>
    );
};

AccountSwitchAction.displayName = 'AccountSwitchAction';
