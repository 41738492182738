import type { FormField, FormError, FormValues, FormApi, FormMeta } from '../types';
import { useEffect, useState } from 'react';

import { useFormInternalContext } from '../utils/formContext';

export type UseForm<FIELDS extends Record<string, FormField>, ERROR extends FormError<keyof FIELDS>> = {
    values: Readonly<FormValues<FIELDS>>;
    errors: Readonly<Record<string, ERROR>>;
    meta: Readonly<FormMeta>;
    api: Readonly<FormApi<FIELDS, ERROR>>;
};

/**
 * React Hook connecting to a Form and exposing its API
 * @returns [formValues, formErrors, formMeta, formApi]
 */
export function useForm<FIELDS extends Record<string, FormField>, ERROR extends FormError<keyof FIELDS>>(): UseForm<
    FIELDS,
    ERROR
> {
    const { api } = useFormInternalContext<FIELDS, ERROR>();

    type State = {
        values: ReturnType<typeof api.getValues>;
        errors: ReturnType<typeof api.getErrors>;
        meta: ReturnType<typeof api.getMeta>;
    };

    const [{ values, errors, meta }, setState] = useState<State>(() => {
        return {
            values: api.getValues(),
            errors: api.getErrors(),
            meta: api.getMeta(),
        };
    });

    useEffect(() => {
        let effectCleared = false;

        const unsubscribe = api.subscribeToForm(formApi => {
            if (effectCleared) return;

            setState({
                values: formApi.getValues(),
                errors: formApi.getErrors(),
                meta: formApi.getMeta(),
            });
        });

        return () => {
            effectCleared = true;
            unsubscribe();
        };
    }, [api]);

    return { values, errors, meta, api };
}
