import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';

import { Box, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { ListItemWithChildrenProps } from './typings';

const useStyles = makeStyles({
    icon: {
        fontSize: '24px',
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '24px',
    },
});

export const ListItemWithChildren = (props: ListItemWithChildrenProps) => {
    const { disabled, hidden, icon, label, path, setSubList, subList } = props;
    const classes = useStyles();
    const location = useLocation();

    const handleClick = useCallback(
        () => (_e: React.MouseEvent) => {
            setSubList(subList);
        },
        [setSubList, subList],
    );

    const isSelected = useMemo(() => {
        return location.pathname.startsWith(path);
    }, [location.pathname, path]);

    return hidden ? null : (
        <ListItemButton onClick={handleClick()} disabled={disabled} selected={isSelected}>
            {!!icon && (
                <ListItemIcon className={classes.icon}>
                    <Box className={classes.iconContainer}>{icon}</Box>
                </ListItemIcon>
            )}
            <ListItemText primary={label} />
            <ArrowRightIcon data-testid="btn-arrow-right" />
        </ListItemButton>
    );
};

ListItemWithChildren.displayName = 'ListItemWithChildren';
