import { ButtonWithTooltip } from '@/components/ButtonWithTooltip';
import { createOpportunity } from '@/api/opportunities';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { Box, CircularProgress } from '@mui/material';
import { useMutation } from 'react-query';
import { resetRequestServiceState, useRequestServiceState } from '../../store';
import { queryClient } from '../../../../services/queryClient';
import { useTexts } from './useTexts';
import { areServiceDetailsSet } from './validation';

export const SubmitRequestButton = () => {
    const request = useRequestServiceState();
    const texts = useTexts();
    const navigate = useNavigate();

    const mutation = useMutation(createOpportunity, {
        onError: () => {
            toast.error(texts.submitError);
        },
        onSuccess: async () => {
            toast.success(texts.submitSuccess);
            resetRequestServiceState();
            navigate('/your-services/your-requests');
            return queryClient.invalidateQueries('your-requests');
        },
    });

    const isValidRequest = useMemo(() => {
        const isLocationSelected = !!request.serviceLocation?.serviceLocationId;
        const areServicesSelected = request.services?.length > 0;
        const isDetailSet = areServiceDetailsSet(request, request.serviceDetails);

        return isLocationSelected && areServicesSelected && isDetailSet;
    }, [request]);

    const handleClick = useCallback(async () => {
        mutation.mutate(request);
    }, [mutation, request]);

    return (
        <ButtonWithTooltip
            color="primary"
            disabled={!isValidRequest || mutation.isLoading}
            onClick={handleClick}
            showTooltip={!isValidRequest}
            tooltipText={!isValidRequest ? texts.submitButtonDisabledTooltip : ''}
            variant="contained"
        >
            {mutation.isLoading && (
                <Box mr={1} display="flex">
                    <CircularProgress size={16} />
                </Box>
            )}
            {texts.submitButton}
        </ButtonWithTooltip>
    );
};

SubmitRequestButton.displayName = 'SubmitRequestButton';
