import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            buttonAdd: translate({ id: 'sections.requestService.addNote.buttonAdd' }),
            buttonEdit: translate({ id: 'sections.requestService.addNote.buttonEdit' }),
        };
    }, [translate]);
}
