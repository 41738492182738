import { CommitmentWindowCustomerResponse } from '@/api/workOrderDetails';
import { CardBadge } from '@/components/cardList/CardBadge';
import { useTexts } from '../drawer/useTexts';

interface Props {
    confirmationRequested: boolean;
    status: CommitmentWindowCustomerResponse;
}

export function ConfirmationBadge(props: Props) {
    const { confirmationRequested, status } = props;
    const texts = useTexts();

    switch (status) {
        case CommitmentWindowCustomerResponse.CONFIRMED:
            return <CardBadge severity="success" text={texts.confirmed} />;
        case CommitmentWindowCustomerResponse.DECLINED:
            return <CardBadge severity="error" text={texts.declined} />;
        case CommitmentWindowCustomerResponse.UNKNOWN:
            return confirmationRequested ? <CardBadge severity="warning" text={texts.needsConfirmation} /> : <></>;
    }
}
