import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useCallback, useMemo } from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { AgreementStatus, AutoPaymentAccount, AutoPaymentAccountType } from '@/common/types/agreements';
import { DefinitionListItem } from '@/components/DefinitionListItem';
import Box from '@mui/material/Box';
import { usePaymentsEnabled } from '@/common/hooks/usePaymentsEnabled';
import { ComponentWithTooltip } from '@/components/ComponentWithTooltip';
import { useTexts } from '../useTexts';
import { setWizardStep, WizardStep } from '../store';
import { PaymentAccountDetails } from '../../../PaymentMethods/PaymentMethodCard/PaymentAccountDetails';
import { useRemoveAutoPayActions } from '../hooks/useRemoveAutoPayActions';

interface Props {
    agreementId: string;
    autoPaymentAccounts: AutoPaymentAccount[];
    status: AgreementStatus;
    hasValidationErrors: boolean;
}

export function AutopayInformation(props: Props) {
    const { agreementId, autoPaymentAccounts, status, hasValidationErrors } = props;
    const texts = useTexts();
    const paymentsEnabled = usePaymentsEnabled();
    const { handleRemoveAutopay } = useRemoveAutoPayActions(agreementId);

    const handleManageClick = useCallback(() => {
        setWizardStep(WizardStep.ManageAutopay);
    }, []);

    const details = useMemo(() => {
        if (!autoPaymentAccounts.length || !autoPaymentAccounts[0]) {
            return undefined;
        }
        const account = autoPaymentAccounts[0];

        return {
            expMonth: account.expirationMonth,
            expYear: account.expirationYear,
            friendly: account.friendly,
            isCreditCard: account.accountType == AutoPaymentAccountType.CREDIT_CARD,
            isExpired: account.isExpired,
            isSelected: false,
            styles: { color: 'inherit' },
        };
    }, [autoPaymentAccounts]);

    return (
        <Stack spacing={1} direction="column">
            {!!autoPaymentAccounts.length && !!autoPaymentAccounts[0] ? (
                <DefinitionListItem
                    primary={texts.autopayIsEnabled}
                    secondary={!!details ? <PaymentAccountDetails {...details} /> : undefined}
                />
            ) : (
                <DefinitionListItem primary={texts.autopayIsDisabled} />
            )}
            {paymentsEnabled && status === AgreementStatus.ACTIVE && (
                <Box>
                    <ComponentWithTooltip showTooltip={hasValidationErrors} tooltipText={texts.locationHasErrors}>
                        <Button
                            variant="outlined"
                            disabled={hasValidationErrors}
                            onClick={handleManageClick}
                            startIcon={<AutorenewIcon />}
                        >
                            {texts.manageAutopay}
                        </Button>
                    </ComponentWithTooltip>
                </Box>
            )}
            {paymentsEnabled && status !== AgreementStatus.ACTIVE && !!autoPaymentAccounts[0] && (
                <Box>
                    <Button variant="outlined" onClick={handleRemoveAutopay}>
                        {texts.disableAutopay}
                    </Button>
                </Box>
            )}
        </Stack>
    );
}
