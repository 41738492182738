import { Alert, Box, CircularProgress } from '@mui/material';
import { useAccountId } from '@/atoms/session';
import { useData } from '../useData';
import { useSelectLocationState } from '../store';
import { LocationList } from './LocationList';
import { useTexts } from './useTexts';

export const DrawerContent = () => {
    const { status } = useSelectLocationState();
    const texts = useTexts();
    const accountId = useAccountId();

    useData(accountId || '');

    return (
        <Box>
            {status == 'loading' && (
                <Box display="flex" p={2} justifyContent="center">
                    <CircularProgress />
                </Box>
            )}
            {status == 'success' && <LocationList />}
            {status == 'error' && <Alert severity="error">{texts.error}</Alert>}
        </Box>
    );
};

DrawerContent.displayName = 'DrawerContent';
