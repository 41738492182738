import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            clear: translate({ id: 'sections.questionsAndComments.clear' }),
            description: translate({ id: 'sections.questionsAndComments.description' }),
            error: translate({ id: 'sections.questionsAndComments.error' }),
            header: translate({ id: 'sections.questionsAndComments.header' }),
            questionComment: translate({ id: 'sections.questionsAndComments.questionComment' }),
            send: translate({ id: 'sections.questionsAndComments.send' }),
            serviceLocation: translate({ id: 'sections.questionsAndComments.serviceLocation' }),
            successToast: translate({ id: 'sections.questionsAndComments.successToast' }),
        };
    }, [translate]);
}
