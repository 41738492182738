import { useDrawerState } from '@/common/hooks/useDrawerState';
import { DefinitionListItem } from '@/components/DefinitionListItem';
import { Box, Button } from '@mui/material';
import { Drawer } from '../drawer';
import { useTexts } from '../useTexts';

export function Password() {
    const [closeDrawer, isOpen, openDrawer] = useDrawerState();
    const texts = useTexts();

    return (
        <>
            <Drawer onClose={closeDrawer} open={isOpen} />
            <Box display="flex" flexDirection="row">
                <Box flexGrow={1}>
                    <DefinitionListItem primary={texts.passwordLabel} secondary={texts.passwordMask} />
                </Box>
                <Box>
                    <Button variant="contained" onClick={openDrawer}>
                        {texts.changePassword}
                    </Button>
                </Box>
            </Box>
        </>
    );
}
