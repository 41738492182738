import type { FormField, FormError } from '../types';
import { useFormInternalContext } from '../utils/formContext';

/**
 * React Hook exposing a form instance API
 * @returns a formApi instance
 */
export function useFormApi<FIELDS extends Record<string, FormField>, ERROR extends FormError<keyof FIELDS>>() {
    const { api } = useFormInternalContext<FIELDS, ERROR>();
    return api;
}
