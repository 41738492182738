import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/pro-regular-svg-icons';
import { useCallback } from 'react';
import { Currency, ShortDate } from '@/components/localization';
import { DesktopListCard } from '@/components/cardList/DesktopListCard';
import { MobileListCard } from '@/components/cardList/MobileListCard';
import { InvoiceListItem } from '@/common/types/invoices';
import { useGetUILayout } from '@/atoms/UILayout';
import { Box, Typography } from '@mui/material';
import { useTexts } from './useTexts';
import { addInvoice, removeInvoice, useIsInvoiceSelected } from './store';

interface TitleProps {
    date: Date;
    invoiceNumber: string;
}

function Title(props: TitleProps) {
    const { date, invoiceNumber } = props;
    const texts = useTexts();

    return (
        <Box display="flex" flexDirection="row">
            <Typography noWrap>
                <ShortDate value={date} />
                {` ${texts.invoice} #${invoiceNumber}`}
            </Typography>
        </Box>
    );
}

interface Props {
    invoice: InvoiceListItem;
    disableSelection: boolean;
}

export function InvoiceCard(props: Props) {
    const { invoice, disableSelection } = props;
    const texts = useTexts();
    const layout = useGetUILayout();

    const isSelected = useIsInvoiceSelected(invoice);

    const handleClick = useCallback(() => {
        isSelected ? removeInvoice(invoice) : addInvoice(invoice);
    }, [invoice, isSelected]);

    return (
        <>
            {layout == 'desktop' ? (
                <DesktopListCard
                    actionBtnText={isSelected ? texts.deselect : texts.select}
                    icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
                    onClick={handleClick}
                    selected={isSelected}
                    subTitle={<Currency value={invoice.balance || 0} />}
                    title={<Title date={invoice.invoiceDate} invoiceNumber={invoice.invoiceNum} />}
                    disabled={disableSelection}
                    showTooltip={disableSelection}
                    toolTipText={texts.locationHasErrors}
                />
            ) : (
                <MobileListCard
                    actionBtnText={isSelected ? texts.deselect : texts.select}
                    icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
                    onClick={handleClick}
                    selected={isSelected}
                    subTitle={<Currency value={invoice.balance || 0} />}
                    title={<Title date={invoice.invoiceDate} invoiceNumber={invoice.invoiceNum} />}
                    disabled={disableSelection}
                    showTooltip={disableSelection}
                    toolTipText={texts.locationHasErrors}
                />
            )}
        </>
    );
}
