import { Box, Grid, Alert } from '@mui/material';
import { FunctionComponent } from 'react';

interface CardProps<T> {
    item: T;
    disabled?: boolean;
}

interface Props<T> {
    card: FunctionComponent<CardProps<T>>;
    items: T[];
    emptyListAlertText: string;
    disabled?: boolean;
}

export function ListBody<T>(props: Props<T>) {
    const { card: Card, emptyListAlertText, items, disabled } = props;

    return (
        <Box pr={2} display="flex" flexDirection="column" flexGrow={1} style={{ overflowY: 'auto' }}>
            <Grid container item direction="column">
                {!!items.length &&
                    items.map((x, i) => (
                        <Grid item key={i}>
                            <Card item={x} disabled={disabled} />
                        </Grid>
                    ))}
                {!items.length && <Alert severity="info">{emptyListAlertText}</Alert>}
            </Grid>
        </Box>
    );
}
