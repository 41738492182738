import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            pageHeader: translate({ id: 'sections.requestService.pageHeader' }),
            pageDescription: translate({ id: 'sections.requestService.pageDescription' }),
        };
    }, [translate]);
}
