import { PageWrapper } from '@/components/PageWrapper';
import { useAccountId } from '@/atoms/session';
import { PageErrorState } from '@/components/PageErrorState/PageErrorState';
import { useEffect, useMemo } from 'react';
import { useQueryActiveAgreements } from '@/common/hooks/query/useQueryActiveAgreements';
import { Alert, Stack } from '@mui/material';
import { LoadingSkeleton } from './LoadingSkeleton';
import { ActiveAgreementsList } from './ActiveAgreementsList';
import { resetStore, useListState } from './ActiveAgreementsList/store';
import { useTexts } from './useTexts';

export function ManageAutopayPage() {
    const { page, take, serviceLocations } = useListState();
    const accountId = useAccountId();
    const query = useQueryActiveAgreements(accountId || '', page, take, serviceLocations);
    const texts = useTexts();

    const hasValidationErrors = useMemo(() => {
        return query.isSuccess && !!query.data.items && query.data.items[0].hasValidationErrors;
    }, [query]);

    useEffect(() => {
        resetStore();
    }, [accountId]);

    return (
        <PageWrapper disableScroll>
            <Stack direction="column" spacing={2} pb={2}>
                {query.status === 'loading' && <LoadingSkeleton />}
                {query.status === 'success' && (
                    <ActiveAgreementsList
                        items={query.data.items}
                        total={query.data.total}
                        disabled={hasValidationErrors}
                    />
                )}
                {query.status === 'error' && <PageErrorState />}
                {hasValidationErrors && <Alert severity="error">{texts.locationHasErrors}</Alert>}
            </Stack>
        </PageWrapper>
    );
}
