import { ReactElement } from 'react';

// Switch to HashRouter if the server doesn't support URL-rewrites
// see: https://github.com/ReactTraining/react-router/blob/dev/docs/api-reference.md#browserrouter
import { BrowserRouter as Router } from 'react-router-dom';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { CssBaseline } from '@mui/material';
import { IntlProvider } from '@/intl';
import { ThemeProvider } from '@mui/material/styles';
import { ToastNotificationProvider } from '@/utils/ToastNotificationProvider';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { LocalizationProvider } from '@mui/lab';
import { useCustomTheme } from '@/common/hooks/useCustomTheme';
import enAULocale from 'date-fns/locale/en-AU';
import enCALocale from 'date-fns/locale/en-CA';
import enGBLocale from 'date-fns/locale/en-GB';
import enNZLocale from 'date-fns/locale/en-NZ';
import enUSLocale from 'date-fns/locale/en-US';
import { CultureCode } from '@/common/types/localization';
import { useLocalizationSettings } from '@/common/hooks/useLocalizationSettings';
import { ConfirmationDialogProvider } from '@/common/components/ConfirmationDialog/ConfirmationDialogProvider';

interface Props {
    children: ReactElement;
}

const localeMap = {
    [CultureCode.en_AU]: enAULocale,
    [CultureCode.en_CA]: enCALocale,
    [CultureCode.en_GB]: enGBLocale,
    [CultureCode.en_IE]: enGBLocale,
    [CultureCode.en_NZ]: enNZLocale,
    [CultureCode.en_US]: enUSLocale,
};

/**
 * Component responsible to instantiate and configure all required providers.
 */
export function Provider(props: Props) {
    const { children } = props;
    const customTheme = useCustomTheme();
    const { culture } = useLocalizationSettings();

    return (
        <StyledEngineProvider injectFirst>
            {/* MUI CSS reset */}
            <CssBaseline />

            {/* MUI theme provider, mounted with the application's custom theme */}
            <ThemeProvider theme={customTheme}>
                {/* Localization provider for date and time pickers */}
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[culture]}>
                    {/* react-router provider, enabling the URL routing */}
                    <Router>
                        {/* 18n provider. It starts react-intl engine */}
                        <IntlProvider>
                            {/* Confirmation dialog provider */}
                            <ConfirmationDialogProvider>{children}</ConfirmationDialogProvider>
                        </IntlProvider>
                    </Router>
                </LocalizationProvider>
            </ThemeProvider>

            {/* Root component configuring and rendering the toast notification root */}
            <ToastNotificationProvider />
        </StyledEngineProvider>
    );
}
