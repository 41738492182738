import { Box } from '@mui/material';
import { getUser } from '@/atoms/session/store';
import { DefinitionListItem } from '@/components/DefinitionListItem';
import { useTexts } from '../useTexts';

export function EmailAddress() {
    const user = getUser();
    const texts = useTexts();

    return (
        <Box display="flex" width={1}>
            <DefinitionListItem primary={texts.emailLabel} secondary={user?.email} />
        </Box>
    );
}
