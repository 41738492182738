import { useCallback, useMemo } from 'react';
import { useAccountId } from '@/atoms/session';
import {
    Alert,
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { useQueryPaymentAccounts } from '@/common/hooks/query/useQueryPaymentAccounts';
import { PaymentAccount, PaymentAccountTypes } from '@/common/types/payments';
import { isCardExpired } from '@/common/utils/isCardExpired';
import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { CardIcon } from '../../PaymentMethods/PaymentMethodCard/CardIcon';
import { useTexts } from './useTexts';

interface Props {
    onSelect: (paymentAccount: PaymentAccount) => void;
    selected: PaymentAccount | null;
}

export function PaymentMethodSelect(props: Props) {
    const { onSelect, selected } = props;
    const { select: texts } = useTexts();
    const accountId = useAccountId();

    const creditCardEnabled = useFeatureEnabled('enableCreditCardPayment');
    const achEnabled = useFeatureEnabled('enableACHPayment');

    const { data: paymentMethods, status } = useQueryPaymentAccounts(accountId || '');

    const selectPaymentMethod = useCallback(
        (e: SelectChangeEvent<string>) => {
            const selectedPaymentMethod = paymentMethods?.find(p => p.paymentAccountId == e.target.value);

            if (selectedPaymentMethod) {
                onSelect(selectedPaymentMethod);
            }
        },
        [onSelect, paymentMethods],
    );

    const noSavedPaymentMethodsText = useMemo(() => {
        if (creditCardEnabled && achEnabled) {
            return texts.noSavedPaymentMethods;
        } else if (creditCardEnabled && !achEnabled) {
            return texts.noSavedPaymentMethodsCreditCard;
        } else if (!creditCardEnabled && achEnabled) {
            return texts.noSavedPaymentMethodsACH;
        } else {
            return texts.noSavedPaymentMethods;
        }
    }, [creditCardEnabled, achEnabled, texts]);

    return (
        <>
            {status == 'loading' && <CircularProgress />}
            {status == 'error' && <Alert severity="error">{texts.failedToLoadPaymentMethods}</Alert>}
            {status == 'success' && (
                <>
                    {!paymentMethods && <Alert severity="info">{noSavedPaymentMethodsText}</Alert>}

                    {!!paymentMethods && (
                        <>
                            {paymentMethods.length == 0 && <Alert severity="info">{noSavedPaymentMethodsText}</Alert>}

                            {paymentMethods.length > 0 && (
                                <FormControl fullWidth>
                                    <InputLabel id="payment-method-select-label">{texts.label}</InputLabel>
                                    <Select
                                        labelId="payment-method-select-label"
                                        id="payment-method-select"
                                        value={selected?.paymentAccountId || ''}
                                        label={texts.label}
                                        onChange={selectPaymentMethod}
                                    >
                                        {paymentMethods.map((p, i) => (
                                            <MenuItem
                                                key={i}
                                                value={p.paymentAccountId}
                                                style={{ width: '100%' }}
                                                disabled={
                                                    p.accountType == PaymentAccountTypes.CREDIT_CARD &&
                                                    isCardExpired(p.month, p.year)
                                                }
                                            >
                                                <Box display="flex" flexGrow={1}>
                                                    <Box display="flex" pr={2}>
                                                        <CardIcon accountName={p.friendly} size="medium" />
                                                    </Box>
                                                    <Box
                                                        display="flex"
                                                        flexGrow={1}
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                    >
                                                        <Typography>{p.friendly} </Typography>
                                                        {p.month && p.year && (
                                                            <Typography variant="caption">
                                                                {texts.expires} {p.month.toString().padStart(2, '0')}/
                                                                {p.year}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
}
