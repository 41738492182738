import type { MutableRefObject } from 'react';
import type { Scrollbar } from 'smooth-scrollbar/interfaces';
import type { InternalProps as Props } from '../types';

import { useEffect } from 'react';

import CoreSmoothScrollbar from 'smooth-scrollbar';

import { useScrollbarOptions } from './useScrollbarOptions';

export function useInitSmoothScrollbar(
    props: Props,
    scrollbarRef: MutableRefObject<Scrollbar | undefined>,
    container: HTMLElement | null,
) {
    const options = useScrollbarOptions(props, scrollbarRef);

    // initialize the Scrollbar
    useEffect(() => {
        if (!container) return;
        scrollbarRef.current = CoreSmoothScrollbar.init(container, options);

        return () => {
            scrollbarRef.current?.destroy();
            scrollbarRef.current = undefined;
        };
    }, [container, options, scrollbarRef]);
}
