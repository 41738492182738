import { AgreementModel } from '@/api/agreements';
import { useDrawerState } from '@/common/hooks/useDrawerState';
import { CardList, ListBody, ListFooter } from '@/components/cardList';
import { useTexts } from '../useTexts';
import { AgreementCard } from '../../Agreements/AgreementsList/cards';
import { ActiveAgreementsListHeader } from './header/ActiveAgreementsListHeader';
import { useListState } from './store';
import { useListActions } from './hooks';
import { FiltersDrawer } from './filters/FiltersDrawer';

interface Props {
    items: AgreementModel[];
    total: number;
    disabled?: boolean;
}

export function ActiveAgreementsList(props: Props) {
    const { items, total, disabled } = props;
    const { page, take } = useListState();
    const texts = useTexts();
    const { handleSetPage } = useListActions();
    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();

    return (
        <>
            <CardList
                header={<ActiveAgreementsListHeader openDrawer={openDrawer} />}
                body={
                    <ListBody
                        card={AgreementCard}
                        emptyListAlertText={texts.noAgreementsAlert}
                        items={items}
                        disabled={disabled}
                    />
                }
                footer={<ListFooter count={Math.ceil(total / take)} page={page + 1} onChange={handleSetPage} />}
            />
            <FiltersDrawer open={drawerOpen} onClose={closeDrawer} />
        </>
    );
}
