import type { Scrollbar } from 'smooth-scrollbar/interfaces';
import type { ScrollTo } from '../types';

import { useEffect } from 'react';

export function useOnScrollTo(scrollTo: ScrollTo | undefined, scrollbar: Scrollbar | undefined) {
    // the scrollTo has not been spread in the deps because otherwise we could fall into a case like this
    // - the code logic call programmatically scrollTo({0, 100}) (duration and options are constant so you can remove it from the logic)
    // - the user then scroll the content
    // - the code logic will trigger again with {0, 100} but the effect will ignore this case and we fall into a unwanted behavior
    // using the Scrollbars component however more expressive api are exposed via context
    useEffect(() => {
        if (!scrollbar || !scrollTo) return;
        const { x, y, duration, options } = scrollTo;
        scrollbar.scrollTo(x, y, duration, options);
    }, [scrollTo, scrollbar]);
}
