import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            afternoon: translate({ id: 'sections.yourRequests.timeFrame.afternoon' }),
            error: translate({ id: 'sections.yourRequests.drawer.error' }),
            evening: translate({ id: 'sections.yourRequests.timeFrame.evening' }),
            morning: translate({ id: 'sections.yourRequests.timeFrame.morning' }),
            serviceAddress: translate({ id: 'sections.yourRequests.serviceAddress' }),
            serviceContact: translate({ id: 'sections.yourRequests.serviceContact' }),
        };
    }, [translate]);
}
