import { useQueryAccountHistory } from '@/common/hooks/query/useQueryAccountHistory';
import { PageWrapper } from '@/components/PageWrapper';
import { useAccountId } from '@/atoms/session';
import { PageErrorState } from '@/components/PageErrorState/PageErrorState';
import { useEffect } from 'react';
import { AccountHistoryList } from './AccountHistoryList';
import { resetStore, useListState } from './AccountHistoryList/store';
import { LoadingSkeleton } from './LoadingSkeleton';
import { useLocation } from 'react-router';

export interface AccountHistoryState {
    isStale: boolean;
}

export const AccountHistory = () => {
    const { page, take, search, type, serviceLocations, startDate, endDate } = useListState();
    const accountId = useAccountId();
    const query = useQueryAccountHistory(
        accountId || '',
        page,
        take,
        search,
        type,
        serviceLocations,
        startDate,
        endDate,
    );

    const location = useLocation();
    const state = location.state as AccountHistoryState;

    useEffect(() => {
        if (!!state && state.isStale) {
            query.refetch();
        }
    }, [])

    useEffect(() => {
        resetStore();
    }, [accountId]);

    return (
        <PageWrapper disableScroll>
            {query.status === 'loading' && <LoadingSkeleton />}
            {query.status === 'success' && <AccountHistoryList items={query.data.items} total={query.data.total} />}
            {query.status === 'error' && <PageErrorState />}
        </PageWrapper>
    );
};

AccountHistory.displayName = 'AccountHistory';
