import { CommitmentWindowCustomerResponse, WorkOrderDetails, WorkOrderStatus } from '@/api/workOrderDetails';
import ListItemText from '@mui/material/ListItemText';
import { useFormattedWorkOrderNumber } from '@/common/hooks/useFormattedWorkOrderNumber';
import { Alert, Box, Stack, Typography } from '@mui/material';
import { ResourceDisplay } from '@/common/components/ResourceDisplay';
import { Currency, ShortDate, LongDateTime } from '@/components/localization';
import { useFormattedAddress } from '@/common/hooks/useAddress';
import { DefinitionListItem } from '@/components/DefinitionListItem';
import { useCallback, useMemo } from 'react';
import { ShortTime } from '@/components/localization/Times';
import { setCommitmentWindowCustomerResponse } from '@/api/workOrders';
import { useAccountId } from '@/atoms/session';
import { useQueryUpcomingServices } from '@/common/hooks/query/useQueryUpcomingServices';
import { useQueryWorkOrderDetails } from '@/common/hooks/query/useQueryWorkOrderDetails';
import { useQueryYourNextService } from '@/common/hooks/query/useQueryYourNextService';
import { useToast } from '@/utils/useToast';
import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { DateOnly, Time } from '@/components/localization/Dates';
import { useListState } from '../UpcomingServicesList/store';
import { ConfirmationBadge } from '../components/ConfirmationBadge';
import { ServicesPerformedSecondary } from '../../AccountHistory/AccountHistoryList/cards/services/drawer/DetailListItems';
import { ConfirmationActions } from './ConfirmationActions';
import { useTexts } from './useTexts';

interface Props {
    details: WorkOrderDetails;
    workOrderId: string;
}

export function DetailListItems(props: Props) {
    const {
        details,
        details: { resource },
        workOrderId,
    } = props;
    const texts = useTexts();
    const isScheduled = useMemo(() => !!details.estimatedStartTime, [details]);
    const { page, take, search, serviceLocations } = useListState();
    const accountId = useAccountId();
    const { refetch: refetchCards } = useQueryUpcomingServices(accountId || '', page, take, search, serviceLocations);
    const { refetch: refetchDetails } = useQueryWorkOrderDetails(workOrderId);
    const { refetch: refetchYourNextService } = useQueryYourNextService(accountId || '');
    const toast = useToast();

    const enableWorkOrderNumber = useFeatureEnabled('enableWorkOrderNumber');
    const enableScheduledDate = useFeatureEnabled('enableScheduledDate');
    const enableScheduledTime = useFeatureEnabled('enableScheduledTime');
    const enableServiceLocation = useFeatureEnabled('enableServiceLocation');
    const enableIncludedServices = useFeatureEnabled('enableIncludedServices');
    const enableTeamTechnicianInfo = useFeatureEnabled('enableTeamTechnicianInfo');
    const enablePriceOfServiceVisit = useFeatureEnabled('enablePriceOfServiceVisit');

    const formattedWorkOrderNumber = useFormattedWorkOrderNumber(
        details.number,
        details.projectSequence,
        details.subNumber,
    );

    const serviceLocationAddress = useFormattedAddress(
        details.serviceLocation.street1,
        details.serviceLocation.street2,
        details.serviceLocation.city,
        details.serviceLocation.stateAbbreviation,
        details.serviceLocation.postalCode,
        details.serviceLocation.countryCode,
    );

    const refetchData = useCallback(() => {
        refetchDetails();
        refetchCards();
        refetchYourNextService();
    }, [refetchCards, refetchDetails, refetchYourNextService]);

    const confirmVisit = useCallback(async () => {
        try {
            await setCommitmentWindowCustomerResponse(workOrderId, CommitmentWindowCustomerResponse.CONFIRMED);
            toast.success(texts.successfullyConfirmed);
            refetchData();
        } catch (e) {
            toast.error(texts.failedToConfirm);
        }
    }, [refetchData, texts.failedToConfirm, texts.successfullyConfirmed, toast, workOrderId]);

    const declineVisit = useCallback(async () => {
        try {
            await setCommitmentWindowCustomerResponse(workOrderId, CommitmentWindowCustomerResponse.DECLINED);
            toast.success(texts.successfullyDeclined);
            refetchData();
        } catch (e) {
            toast.error(texts.failedToDecline);
        }
    }, [refetchData, texts.failedToDecline, texts.successfullyDeclined, toast, workOrderId]);

    return (
        <Stack direction="column" spacing={2}>
            <Box display="flex" alignItems="center">
                {enableWorkOrderNumber && (
                    <>
                        <Box flexGrow={1}>
                            <ListItemText
                                primary={formattedWorkOrderNumber}
                                primaryTypographyProps={{ fontWeight: 'bold' }}
                            />
                        </Box>
                    </>
                )}
                {isScheduled && (
                    <Box>
                        <ConfirmationBadge
                            confirmationRequested={details.commitmentWindowConfirmationRequested}
                            status={details.commitmentWindowCustomerResponse}
                        />
                    </Box>
                )}
            </Box>
            {isScheduled && enableScheduledDate && enableScheduledTime && (
                <DefinitionListItem
                    primary={texts.scheduledDateAndTime}
                    secondary={<LongDateTime value={details.estimatedStartTime as Date} />}
                />
            )}

            {isScheduled && !enableScheduledDate && enableScheduledTime && (
                <DefinitionListItem
                    primary={texts.scheduledTime}
                    secondary={<Time value={details.estimatedStartTime as Date} />}
                />
            )}

            {isScheduled && enableScheduledDate && !enableScheduledTime && (
                <DefinitionListItem
                    primary={texts.scheduledDate}
                    secondary={<DateOnly value={details.estimatedStartTime as Date} />}
                />
            )}

            {isScheduled && (
                <DefinitionListItem
                    primary={texts.eligibilityDate}
                    secondary={
                        <>
                            <ShortDate value={details.startingEligibleDate as Date} />
                            {` - `}
                            <ShortDate value={details.endingEligibleDate as Date} />
                        </>
                    }
                />
            )}
            {details.commitmentWindowConfirmationRequested &&
                isScheduled &&
                details.startOfCommitmentWindow &&
                details.endOfCommitmentWindow && (
                    <DefinitionListItem
                        primary={texts.commitmentWindow}
                        secondary={
                            <span>
                                <ShortTime value={details.startOfCommitmentWindow} />
                                {' - '}
                                <ShortTime value={details.endOfCommitmentWindow} />
                            </span>
                        }
                    />
                )}
            {!isScheduled && enableServiceLocation && <Alert severity="info">{texts.notYetScheduled}</Alert>}

            {enableServiceLocation && (
                <DefinitionListItem primary={texts.serviceLocation} secondary={serviceLocationAddress} />
            )}

            {enableIncludedServices && (
                <DefinitionListItem
                    primary={texts.includedServices}
                    secondary={<ServicesPerformedSecondary services={details.services} />}
                />
            )}
            {isScheduled && !!resource && enableTeamTechnicianInfo && (
                <ResourceDisplay imageId={resource.imageId} isCustom={resource.isCustomImage} name={resource.name} />
            )}
            {enablePriceOfServiceVisit && (
                <DefinitionListItem
                    primary={texts.price}
                    secondary={
                        <Box>
                            <Box>
                                <Currency value={details.totals.subTotal} />
                            </Box>
                            <Box sx={{ fontStyle: 'italic' }}>
                                <Typography variant="caption">{texts.doesNotIncludeTax}</Typography>
                            </Box>
                        </Box>
                    }
                />
            )}
            {details.commitmentWindowConfirmationRequested &&
                isScheduled &&
                details.status !== WorkOrderStatus.IN_PROGRESS && (
                    <ConfirmationActions
                        commitmentWindowCustomerResponse={details.commitmentWindowCustomerResponse}
                        onConfirm={confirmVisit}
                        onDecline={declineVisit}
                    />
                )}
        </Stack>
    );
}
