import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            title: translate({ id: 'sections.register.title' }),
            createAccount: translate({ id: 'sections.register.createAccount' }),
            registrationFailed: translate({ id: 'sections.register.registrationFailed' }),
            passwordFieldLabel: translate({ id: 'sections.register.passwordFieldLabel' }),
            confirmPasswordFieldLabel: translate({ id: 'sections.register.confirmPasswordFieldLabel' }),
            invalidRegistrationLink: translate({ id: 'sections.register.invalidRegistrationLink' }),
            userAlreadyRegistered: translate({ id: 'sections.register.userAlreadyRegistered' }),
            signIn: translate({ id: 'sections.register.signIn' }),
        };
    }, [translate]);
}
