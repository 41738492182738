export interface FormattableAddress {
    city: string;
    postalCode: string;
    stateAbbreviation: string;
    street1: string;
    street2: string | null;
}

export function formatAddress(l: FormattableAddress | null) {
    if (!l) {
        return '';
    }
    return `${l.street1},${l.street2 ? ` ${l.street2},` : ''} ${l.city}, ${l.stateAbbreviation} ${l.postalCode}`;
}
