import { useGetUILayout } from '@/atoms/UILayout';
import { AppDesktop } from './AppDesktop';
import { AppMobile } from './AppMobile';

export function App() {
    const layout = useGetUILayout();

    switch (layout) {
        case 'desktop':
            return <AppDesktop />;

        case 'mobile':
            return <AppMobile />;
    }
}
