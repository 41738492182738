import { useFetchTenantId } from '@/common/hooks/query/useQueryLoadTenant';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

export const useCheckSubdomain = () => {
    const result = useFetchTenantId();
    const navigate = useNavigate();
    const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');

    useEffect(() => {
        let unmounted = false;
        if (unmounted) return;

        if (result.isFetched) {
            if (result.isSuccess) {
                setStatus('success');
            } else {
                setStatus('error');
                navigate('/unknown');
            }
        } else {
            setStatus('loading');
        }
        return () => void (unmounted = true);
    }, [navigate, result]);

    return status;
};
