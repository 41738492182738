import { FormattedDate } from 'react-intl';

interface Props {
    value: string | number | Date | undefined;
}

export function ShortTime(props: Props) {
    const { value } = props;
    return <FormattedDate value={value} hour="numeric" minute="2-digit" dayPeriod="short" />;
}
