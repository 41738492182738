import { get } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';

const baseUrl = '/api/Avatars';

export const getCustomAvatarUrl = async (imageId: string) => {
    try {
        const response = await get<string>(`${baseUrl}/${imageId}/custom`, {
            responseType: 'arraybuffer',
        });
        if (response.status === 200) {
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            return URL.createObjectURL(blob);
        }

        throw new ServerError(`Failed to get avatar.`, response.status);
    } catch (e) {
        throw e;
    }
};

export const getStockAvatarUrl = async (imageId: string) => {
    try {
        const response = await get<string>(`${baseUrl}/${imageId}/stock`, {
            responseType: 'arraybuffer',
        });
        if (response.status === 200) {
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            return URL.createObjectURL(blob);
        }

        throw new ServerError(`Failed to get avatar.`, response.status);
    } catch (e) {
        throw e;
    }
};
