import { deletePaymentAccount } from '@/api/paymentAccounts';
import { useAccountId } from '@/atoms/session';
import { useQueryPaymentAccounts } from '@/common/hooks/query/useQueryPaymentAccounts';
import { useConfirmationDialog } from '@/common/hooks/useConfirmationDialog';
import { PaymentAccount, PaymentAccountTypes } from '@/common/types/payments';
import { IconButtonWithTooltip } from '@/components/IconButtonWithTooltip';
import { useToast } from '@/utils/useToast';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useTexts } from '../useTexts';

interface Props {
    isDisabled: boolean;
    paymentAccount: PaymentAccount;
}

const useStyles = makeStyles({
    tooltipLink: {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
});

export function DeleteButton(props: Props) {
    const accountId = useAccountId();
    const classes = useStyles();
    const navigate = useNavigate();
    const texts = useTexts();
    const toast = useToast();

    const { getConfirmation } = useConfirmationDialog();
    const { isDisabled, paymentAccount } = props;
    const { refetch } = useQueryPaymentAccounts(accountId || '');

    const isCreditCard = useMemo(() => {
        return paymentAccount.accountType == PaymentAccountTypes.CREDIT_CARD;
    }, [paymentAccount.accountType]);

    const handleDelete = useCallback(async () => {
        const confirmed = await getConfirmation({
            title: texts.deleteConfirmation.title,
            message: `${texts.deleteConfirmation.body} ${paymentAccount.friendly}?`,
        });

        if (!confirmed) {
            return;
        }

        try {
            await deletePaymentAccount(paymentAccount.businessUnitId, paymentAccount.paymentAccountId);
            toast.success(isCreditCard ? texts.card.deleteSuccess : texts.ach.deleteSuccess);
            refetch();
        } catch {
            toast.error(isCreditCard ? texts.card.deleteError : texts.ach.deleteError);
        }
    }, [getConfirmation, isCreditCard, paymentAccount, refetch, toast, texts]);

    const redirectToAutoPay = useCallback(() => {
        navigate('/payments/manage-autopay');
    }, [navigate]);

    return (
        <IconButtonWithTooltip
            disabled={isDisabled}
            onClick={handleDelete}
            showTooltip={isDisabled}
            tooltipText={
                <>
                    {paymentAccount.isAutoPayment && texts.deletePaymentAccountAutoPayTooltip}{' '}
                    {paymentAccount.isAutoPayment && (
                        <span className={classes.tooltipLink} onClick={redirectToAutoPay}>
                            {texts.moreInfo}
                            <br />
                        </span>
                    )}
                    {paymentAccount.hasAutoPaymentInstance && texts.deletePaymentAccountAutoPayInstanceTooltip}{' '}
                </>
            }
        >
            <DeleteIcon fontSize="small" />
        </IconButtonWithTooltip>
    );
}
