import { useQueryWorkOrderDetails } from '@/common/hooks/query/useQueryWorkOrderDetails';
import { Alert, CircularProgress } from '@mui/material';
import { DetailListItems } from './DetailListItems';
import { useTexts } from './useTexts';

interface Props {
    workOrderId: string;
}

export function DrawerContent(props: Props) {
    const { workOrderId } = props;
    const texts = useTexts();
    const { data: details, status } = useQueryWorkOrderDetails(workOrderId);

    return (
        <>
            {status == 'loading' && <CircularProgress />}
            {status == 'error' && <Alert severity="error">{texts.error}</Alert>}
            {status == 'success' && (
                <>
                    {!details && <Alert severity="error">{texts.error}</Alert>}
                    {details && <DetailListItems details={details} />}
                </>
            )}
        </>
    );
}
