import { getAccount } from '@/atoms/session/store';
import { blue } from '@mui/material/colors';
import { useMemo } from 'react';
import { useFetchCustomerPortalSettings } from './query/useFetchCustomerPortalSettings';

export const useThemingKey = () => {
    const account = getAccount();
    const { data: settings } = useFetchCustomerPortalSettings(account?.businessUnitId || '');

    return useMemo(() => {
        return settings ? settings.themingKey : blue[800];
    }, [settings]);
};
