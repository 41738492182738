import { Box, Stack, Typography } from '@mui/material';
import { Avatar } from './Avatar';
import { AvatarPlaceholder } from './AvatarPlaceholder';
import { useTexts } from './useTexts';

interface Props {
    imageId: string | null;
    isCustom: boolean;
    name: string;
}

export function ResourceDisplay(props: Props) {
    const { imageId, isCustom, name } = props;
    const texts = useTexts();

    return (
        <>
            <Box display="flex" alignItems="center">
                <Box mr={1} display="flex">
                    {!!imageId ? <Avatar imageId={imageId} isCustom={isCustom} name={name} /> : <AvatarPlaceholder />}
                </Box>
                <Stack>
                    <Typography display="block" variant="overline">
                        {texts.team} / {texts.technician}
                    </Typography>
                    <Typography display="block" variant="h4">
                        {name}
                    </Typography>
                </Stack>
            </Box>
        </>
    );
}
