import Box from '@mui/material/Box';
import { DatePicker } from '@/components/DatePicker';
import { useListState } from '../store';
import { useListActions } from '../hooks';
import { useTexts } from './useTexts';

export function StartDate() {
    const { startDate } = useListState();
    const texts = useTexts();
    const { handleSetStartDate } = useListActions();

    return (
        <Box flexGrow={1}>
            <DatePicker label={texts.startDateLabel} onChange={handleSetStartDate} value={startDate} disableFuture />
        </Box>
    );
}
