import { Box, Paper, Stack } from '@mui/material';
import { CommentField } from './fields/CommentField';
import { ServiceLocationSelect } from './fields/ServiceLocationSelect';
import { FormFooter } from './FormFooter';

export function Form() {
    return (
        <Paper>
            <Box p={2}>
                <Stack direction="column" spacing={2}>
                    <ServiceLocationSelect />
                    <CommentField />
                    <FormFooter />
                </Stack>
            </Box>
        </Paper>
    );
}
