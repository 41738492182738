import { proxy, useSnapshot } from 'valtio';

// Store state
export interface ListState {
    page: number;
    take: number;
    selected: string;
}

export const initialState: ListState = {
    page: 0,
    take: 10,
    selected: '',
};

// Valtio store initialization
export const state = proxy<ListState>(initialState);

// Setter functions
export function setPage(page: number) {
    state.page = page;
}

export function setTake(take: number) {
    state.take = take;
}

export function setSelectedItem(id: string) {
    state.selected = id;
}

export function clearSelectedItem() {
    state.selected = '';
}

export function resetStore() {
    state.page = initialState.page;
    state.selected = initialState.selected;
    state.take = initialState.take;
}

//Hooks
export function useListState() {
    return useSnapshot(state);
}
