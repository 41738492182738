import { post } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';

const baseUrl = '/api/customerPortalUsers';

export const createUser = async (customerPortalUserId: string, password: string) => {
    try {
        const { status } = await post(baseUrl, { customerPortalUserId, password });

        if (status === 200) {
            return true;
        }

        throw new ServerError('Failed to create user.', status);
    } catch (e) {
        throw e;
    }
};
