import { Box, Typography } from '@mui/material';
import { WorkOrderWidgetServiceLocation } from '@/api/workOrders';

interface Props {
    serviceLocation: WorkOrderWidgetServiceLocation;
}

export const ServiceLocationDetail = (props: Props) => {
    const { serviceLocation } = props;

    return (
        <Box>
            {serviceLocation.serviceLocationName && <Typography>{serviceLocation.serviceLocationName}</Typography>}
            {serviceLocation.street1 && <Typography>{serviceLocation.street1}</Typography>}
            {serviceLocation.street2 && <Typography>{serviceLocation.street2}</Typography>}
            <Typography>
                {serviceLocation.city}, {serviceLocation.state} {serviceLocation.zipCode}, {serviceLocation.countryCode}{' '}
            </Typography>
        </Box>
    );
};

ServiceLocationDetail.displayName = 'ServiceLocationDetail';
