import { DateOnly, LongDateTime, Time } from '@/components/localization/Dates';
import { Currency } from '@/components/localization';
import { useFormattedAddress } from '@/common/hooks/useAddress';
import { useFormattedWorkOrderNumber } from '@/common/hooks/useFormattedWorkOrderNumber';
import { ResourceDisplay } from '@/common/components/ResourceDisplay';
import { getServiceReport, WorkOrderDetails, WorkOrderDetailsService } from '@/api/workOrderDetails';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { DefinitionListItem } from '@/components/DefinitionListItem';
import { OpenPDFButton } from '@/components/OpenPDFButton';
import { useCallback, useMemo } from 'react';
import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { CustomerPortalLastServiceFields } from '@/common/types/services';
import { useTexts } from './useTexts';

interface Props {
    details: WorkOrderDetails;
}

export function ServicesPerformedSecondary(props: { services: WorkOrderDetailsService[] }) {
    const { services } = props;
    return (
        <>
            {services.map((x, i) => (
                <span style={{ display: 'block' }} key={i}>{`${i + 1}. ${x.name}`}</span>
            ))}
        </>
    );
}
export function DetailListItems(props: Props) {
    const {
        details,
        details: {
            serviceLocation: { city, countryCode, postalCode, stateAbbreviation, street1, street2 },
        },
    } = props;
    const resource = props.details.resource;

    const texts = useTexts();
    const formattedWorkOrderNumber = useFormattedWorkOrderNumber(
        details.number,
        details.projectSequence,
        details.subNumber,
    );
    const serviceAddress = useFormattedAddress(street1, street2, city, stateAbbreviation, postalCode, countryCode);

    const onServiceReportClick = useCallback(() => {
        return getServiceReport(details.workOrderId);
    }, [details.workOrderId]);

    const enableLastServiceWorkOrderNumber = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServiceWorkOrderNumber,
    );
    const enableLastServiceScheduledDate = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServiceScheduledDate,
    );
    const enableLastServiceScheduledTime = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServiceScheduledTime,
    );
    const enableLastServiceServiceLocation = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServiceServiceLocation,
    );
    const enableLastServiceIncludedServices = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServiceIncludedServices,
    );
    const enableLastServiceTeamTechnicianInfo = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServiceTeamTechnicianInfo,
    );
    const enableLastServicePriceOfServiceVisit = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServicePriceOfServiceVisit,
    );
    const enableLastServiceServiceReport = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServiceServiceReport,
    );

    const completedDateAndTimeOutput = useMemo(() => {
        const correctedCompletedDateTime = !!details.actualCompletedTime
            ? details.actualCompletedTime
            : (details.estimatedCompletedTime as Date);
        if (enableLastServiceScheduledDate && !enableLastServiceScheduledTime) {
            return <DateOnly value={correctedCompletedDateTime} />;
        } else if (!enableLastServiceScheduledDate && enableLastServiceScheduledTime) {
            return <Time value={correctedCompletedDateTime} />;
        } else {
            return <LongDateTime value={correctedCompletedDateTime} />;
        }
    }, [details, enableLastServiceScheduledDate, enableLastServiceScheduledTime]);

    const completedDateAndTimeOutputText = useMemo(() => {
        if (enableLastServiceScheduledDate && !enableLastServiceScheduledTime) {
            return texts.dateCompleted;
        } else if (!enableLastServiceScheduledDate && enableLastServiceScheduledTime) {
            return texts.timeCompleted;
        } else {
            return texts.dateAndTimeCompleted;
        }
    }, [texts, enableLastServiceScheduledDate, enableLastServiceScheduledTime]);

    return (
        <Stack direction="column" spacing={2}>
            {enableLastServiceWorkOrderNumber && <DefinitionListItem primary={formattedWorkOrderNumber} />}
            {(!!details.actualCompletedTime || !!details.estimatedCompletedTime) &&
                (enableLastServiceScheduledDate || enableLastServiceScheduledTime) && (
                    <DefinitionListItem
                        primary={`${completedDateAndTimeOutputText}`}
                        secondary={completedDateAndTimeOutput}
                    />
                )}
            {enableLastServiceServiceLocation && (
                <DefinitionListItem primary={texts.serviceLocation} secondary={serviceAddress} />
            )}
            {enableLastServiceIncludedServices && (
                <DefinitionListItem
                    primary={texts.servicesPerformed}
                    secondary={<ServicesPerformedSecondary services={details.services} />}
                />
            )}
            <Box display="flex" flexDirection="row">
                {!!resource && enableLastServiceTeamTechnicianInfo && (
                    <Box flexGrow={1} pl={0}>
                        <ResourceDisplay
                            imageId={resource.imageId}
                            isCustom={resource.isCustomImage}
                            name={resource.name}
                        />
                    </Box>
                )}

                {enableLastServicePriceOfServiceVisit && (
                    <Box flexGrow={0} pr={2}>
                        <DefinitionListItem primary={texts.amount} secondary={<Currency value={details.subTotal} />} />
                    </Box>
                )}
            </Box>
            {enableLastServiceServiceReport && (
                <Box>
                    <OpenPDFButton onClick={onServiceReportClick} errorText={texts.failedToDownloadPDF}>
                        {texts.service} {texts.pdf}
                    </OpenPDFButton>
                </Box>
            )}
        </Stack>
    );
}
