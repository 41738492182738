import { PageWrapper } from '@/components/PageWrapper';
import { Box, Stack, Typography } from '@mui/material';
import { Form } from './Form/Form';
import { useTexts } from './useTexts';

export function QuestionsAndCommentsPage() {
    const texts = useTexts();

    return (
        <PageWrapper>
            <Stack spacing={2}>
                <Box>
                    <Typography variant="h2">{texts.header}</Typography>
                    <Typography>{texts.description}</Typography>
                </Box>

                <Form />
            </Stack>
        </PageWrapper>
    );
}
