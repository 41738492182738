import * as sections from '@/sections';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '@/router';

export function Sections() {
    return (
        // All URL driven sections must be nested under `RouterSections`
        <Routes>
            {/* Login screens */}
            <Route path="login" element={<sections.Login />} />
            <Route path="register" element={<sections.Register />} />
            <Route path="forgot-password" element={<sections.ForgotPassword />} />
            <Route path="reset-password" element={<sections.ResetPassword />} />

            <Route path="/" element={<PrivateRoute element={<sections.App />} />}>
                <Route path="/" element={<PrivateRoute element={<sections.AccountOverview />} />} />
                <Route path="account-history" element={<PrivateRoute element={<sections.AccountHistory />} />} />
                <Route path="account-overview" element={<PrivateRoute element={<sections.AccountOverview />} />} />
                <Route path="payments/pay-invoices" element={<PrivateRoute element={<sections.PayInvoicesPage />} />} />
                <Route
                    path="payments/payment-methods"
                    element={<PrivateRoute element={<sections.PaymentMethodsPage />} />}
                />
                <Route
                    path="payments/manage-autopay"
                    element={<PrivateRoute element={<sections.ManageAutopayPage />} />}
                />
                <Route path="profile" element={<PrivateRoute element={<sections.Profile />} />} />
                <Route
                    path="questions-and-comments"
                    element={<PrivateRoute element={<sections.QuestionsAndCommentsPage />} />}
                />
                <Route
                    path="your-services/agreements"
                    element={<PrivateRoute element={<sections.AgreementsPage />} />}
                />
                <Route
                    path="your-services/request-service"
                    element={<PrivateRoute element={<sections.RequestService />} />}
                />
                <Route
                    path="your-services/upcoming-services"
                    element={<PrivateRoute element={<sections.UpcomingServicesPage />} />}
                />
                <Route
                    path="your-services/your-requests"
                    element={<PrivateRoute element={<sections.YourRequestsPage />} />}
                />

                <Route path="*" element={<PrivateRoute element={<sections.Unknown />} />} />
            </Route>

            <Route path="forbidden" element={<sections.Forbidden />} />
            <Route path="unknown" element={<sections.Unknown />} />
            <Route path="*" element={<sections.Unknown />} />
        </Routes>
    );
}
