import { ShortDate } from '@/components/localization';
import Chip from '@mui/material/Chip';
import { useMemo } from 'react';
import { useTexts } from '../useTexts';

type Props = {
    startDate: Date | null;
    endDate: Date | null;
    onDelete: React.MouseEventHandler;
};

export function DateFilterChip(props: Props) {
    const { onDelete, startDate, endDate } = props;
    const texts = useTexts();

    const label = useMemo(() => {
        if (!startDate) {
            return (
                <span>
                    {`${texts.onOrBefore} `}
                    {!!endDate && <ShortDate value={endDate} />}
                </span>
            );
        }
        if (!endDate) {
            return (
                <span>
                    {`${texts.onOrAfter} `}
                    {!!startDate && <ShortDate value={startDate} />}
                </span>
            );
        }
        if (!!startDate && !!endDate) {
            return (
                <span>
                    <ShortDate value={startDate} />
                    {' - '}
                    <ShortDate value={endDate} />
                </span>
            );
        }
    }, [startDate, endDate, texts.onOrAfter, texts.onOrBefore]);

    return <Chip size="small" color="primary" variant="outlined" label={label} onDelete={onDelete} />;
}
