import { CustomerPortalFeatures } from '@/api/businessUnitSettings';
import { getAccount } from '@/atoms/session/store';
import { useMemo } from 'react';
import { useFetchCustomerPortalSettings } from './query/useFetchCustomerPortalSettings';

export const useFeatureEnabled = (feature: keyof CustomerPortalFeatures) => {
    const account = getAccount();
    const { data: settings } = useFetchCustomerPortalSettings(account?.businessUnitId || '');

    return useMemo(() => {
        return settings ? settings[feature] : false;
    }, [feature, settings]);
};
