import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/pro-regular-svg-icons';
import { FormattedDate } from 'react-intl';
import Box from '@mui/material/Box';
import { useDrawerState } from '@/common/hooks/useDrawerState';
import { DesktopListCard } from '@/components/cardList/DesktopListCard';
import { useFormattedWorkOrderNumber } from '@/common/hooks/useFormattedWorkOrderNumber';
import { SideDrawer } from '@/components/SideDrawer';
import { theme } from '@/theme';
import { useGetUILayout } from '@/atoms/UILayout';
import { MobileListCard } from '@/components/cardList/MobileListCard';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { useTexts } from '../../useTexts';
import { DrawerContent } from '../../drawer/DrawerContent';
import { ConfirmationBadge } from '../../components/ConfirmationBadge';
import { setSelectedItem, useListState } from '../store';
import { CardProps } from './typings';

const Title = (props: { date: Date | null | undefined; services: string[] }) => {
    const { date, services } = props;
    const texts = useTexts();

    return (
        <Box display="flex" flexDirection="row">
            <Typography noWrap>
                {!!date && date != undefined && (
                    <FormattedDate value={date} day="2-digit" month="2-digit" year="2-digit" />
                )}{' '}
                {!date && date != undefined && `${texts.unscheduled} | `}
                {services.join(', ')}
            </Typography>
        </Box>
    );
};

export const ServiceCard = (props: CardProps) => {
    const { item } = props;
    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();
    const texts = useTexts();
    const layout = useGetUILayout();
    const enableWorkOrderNumber = useFeatureEnabled('enableWorkOrderNumber');
    const enableIncludedServices = useFeatureEnabled('enableIncludedServices');
    const enableScheduledDate = useFeatureEnabled('enableScheduledDate');

    const workOrderNumber = useFormattedWorkOrderNumber(
        item.number?.toString() || '',
        item.projectSequence,
        item.subNumber,
    );
    const formattedWorkOrderNumber = enableWorkOrderNumber ? workOrderNumber : '';
    const { selected } = useListState();

    const handleOpen = useCallback(() => {
        setSelectedItem(item.workOrderId!);
        openDrawer();
    }, [openDrawer, item.workOrderId]);

    const handleClose = useCallback(() => {
        setSelectedItem('');
        closeDrawer();
    }, [closeDrawer]);

    return (
        <>
            {layout == 'desktop' ? (
                <DesktopListCard
                    badge={
                        !!item.estimatedTimeOfArrival ? (
                            <ConfirmationBadge
                                confirmationRequested={item.commitmentWindowConfirmationRequested}
                                status={item.commitmentWindowCustomerResponse}
                            />
                        ) : undefined
                    }
                    icon={<FontAwesomeIcon icon={faTools} />}
                    onClick={handleOpen}
                    selected={item.workOrderId === selected}
                    styleOverrides={{
                        container: {
                            backgroundColor: !item.estimatedTimeOfArrival
                                ? theme.palette.custom.lightGrey
                                : theme.palette.white,
                        },
                    }}
                    subTitle={formattedWorkOrderNumber}
                    title={
                        <Title
                            date={enableScheduledDate ? item.estimatedTimeOfArrival : undefined}
                            services={enableIncludedServices ? item.workOrderServices : []}
                        />
                    }
                />
            ) : (
                <MobileListCard
                    badge={
                        !!item.estimatedTimeOfArrival ? (
                            <ConfirmationBadge
                                confirmationRequested={item.commitmentWindowConfirmationRequested}
                                status={item.commitmentWindowCustomerResponse}
                            />
                        ) : undefined
                    }
                    icon={<FontAwesomeIcon icon={faTools} />}
                    onClick={handleOpen}
                    selected={item.workOrderId === selected}
                    styleOverrides={{
                        container: {
                            backgroundColor: !item.estimatedTimeOfArrival
                                ? theme.palette.custom.lightGrey
                                : theme.palette.white,
                        },
                    }}
                    subTitle={formattedWorkOrderNumber}
                    title={<Title date={item.estimatedTimeOfArrival} services={item.workOrderServices} />}
                />
            )}

            <SideDrawer isOpen={drawerOpen} onClose={handleClose} title={texts.title}>
                <DrawerContent workOrderId={item.workOrderId} />
            </SideDrawer>
        </>
    );
};
