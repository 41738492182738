import { useCallback, useEffect, useState } from 'react';
import { isServerError } from '@/utils/ServerError';
import { getPaymentTransactionsIframeUrl } from '@/api/paymentTransactions';
import { TransactionSetupMethod } from '@/common/types/payments';

interface ErrorData {
    status: 'error';
}

interface LoadingData {
    status: 'loading';
}

interface SuccessData {
    payload: string;
    status: 'success';
}

type Data = ErrorData | LoadingData | SuccessData;

export const useData = (
    accountId: string,
    amount: number,
    businessUnitId: string,
    transactionSetupMethod: TransactionSetupMethod,
) => {
    const [data, setData] = useState<Data>({ status: 'loading' });
    const [isStale, setIsStale] = useState<boolean>(true);

    useEffect(() => {
        let mounted = true;

        const getSettings = async (accountId: string, businessUnitId: string) => {
            try {
                setData({ status: 'loading' });
                const response = await getPaymentTransactionsIframeUrl(
                    accountId,
                    amount,
                    businessUnitId,
                    transactionSetupMethod,
                );

                if (isServerError(response)) {
                    throw new Error(response.message);
                }

                if (mounted && !!response) {
                    setData({ status: 'success', payload: response });
                }
            } catch (error) {
                console.log(error);
                if (mounted) {
                    setData({ status: 'error' });
                }
            } finally {
                setIsStale(false);
            }
        };

        if (isStale && !!accountId && !!businessUnitId) {
            getSettings(accountId, businessUnitId);
        }

        return () => {
            mounted = false;
        };
    }, [accountId, amount, businessUnitId, isStale, transactionSetupMethod]);

    const refreshData = useCallback(async () => {
        setIsStale(true);
    }, []);

    useEffect(() => {
        refreshData();
    }, [accountId, refreshData]);

    return [data, refreshData] as const;
};
