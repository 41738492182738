import { proxy } from 'valtio';

import { Language } from '@/intl';
import * as storage from '@/utils/ApplicationStorage';

// ----------------------------
// Store state
// ----------------------------

type IntlState = {
    language: Language;
    messages: Record<string, string> | null;
    loadingLanguage: 'ready' | 'pending' | 'error';
};

// ----------------------------
// INITIAL STATE WITH LOCAL/SESSION STORAGE SUPPORT

const getDefaultInitialState = (): IntlState => ({
    messages: null,
    language: 'en',
    loadingLanguage: 'pending',
});

let recoveredState: IntlState | undefined;
const getInitialState = () => recoveredState ?? getDefaultInitialState();

const clearRecoveredState = () => {
    storage.removeValue('ww/store/intl', false);
    recoveredState = undefined;
};

const tryToRecoverInitialState = () => {
    try {
        const stored = storage.getValue('ww/store/intl', false, false);

        if (stored) {
            const tmp = JSON.parse(stored) as IntlState;

            recoveredState = {
                messages: {},
                language: tmp.language,
                loadingLanguage: 'pending',
            };
        }
    } catch (e) {}
};
tryToRecoverInitialState();

// ----------------------------
// Valtio store initialization
// ----------------------------

export const state = proxy<IntlState>(getInitialState());

// ----------------------------
// setter functions
// ----------------------------

export function setLoadingLanguage(loadingLanguage: IntlState['loadingLanguage']) {
    state.loadingLanguage = loadingLanguage;
}

export function setLanguage(language: Language, messages: IntlState['messages']) {
    state.language = language;
    state.messages = messages;
    state.loadingLanguage = 'ready';
}

export function resetLanguage() {
    clearRecoveredState();
    Object.assign(state, getInitialState());
}

// ----------------------------
// selector functions
// ----------------------------

export function selectLanguage() {
    return state.language;
}

export function selectLanguageMessages() {
    return state.messages;
}

export function selectLanguageLoading() {
    return state.loadingLanguage;
}
