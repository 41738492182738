import { avatarSize } from '@/common/constants';
import { useFetchAvatarUrl } from '@/common/hooks/query/useQueryAvatar';
import makeStyles from '@mui/styles/makeStyles';
import { AvatarPlaceholder } from './AvatarPlaceholder';

interface Props {
    imageId: string;
    isCustom: boolean;
    name: string;
}

const useStyles = makeStyles({
    img: {
        borderRadius: '50%',
        height: `${avatarSize}px`,
        width: `${avatarSize}px`,
    },
});

export function Avatar(props: Props) {
    const { imageId, isCustom, name } = props;
    const classes = useStyles();

    const { data, status } = useFetchAvatarUrl(imageId, isCustom);

    return (
        <>
            {status == 'success' ? (
                <img className={classes.img} src={data} alt={name} title={name} />
            ) : (
                <AvatarPlaceholder />
            )}
        </>
    );
}
