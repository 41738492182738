import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

import { AppHeaderMobile } from '@/components/appHeader';
import makeStyles from '@mui/styles/makeStyles';
import { mobileAppbarHeight } from '@/common/constants';

const useStyles = makeStyles({
    content: {
        height: `calc(100vh - ${mobileAppbarHeight}px)`,
        marginTop: `${mobileAppbarHeight}px`,
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
});

export function AppMobile() {
    const classes = useStyles();

    return (
        <Fragment>
            <AppHeaderMobile />
            <div className={classes.content}>
                <Outlet />
            </div>
        </Fragment>
    );
}
