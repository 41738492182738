import { AgreementModel } from '@/api/agreements';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Tooltip } from '@mui/material';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { useTexts } from '../../useTexts';

interface Props {
    item: AgreementModel;
}

export function SecondaryIcon(props: Props) {
    const {
        item: { hasAutoPayment, hasExpiredPaymentMethod },
    } = props;
    const texts = useTexts();

    return hasExpiredPaymentMethod ? (
        <Tooltip title={texts.autopayMethodIsExpired}>
            <span>
                <WarningAmberRoundedIcon color="error" />
            </span>
        </Tooltip>
    ) : (
        <Tooltip title={hasAutoPayment ? texts.autopayIsEnabled : texts.autopayIsDisabled}>
            <span>
                <AutorenewIcon color={hasAutoPayment ? 'info' : 'inherit'} />
            </span>
        </Tooltip>
    );
}
