import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

export function LoadingSkeleton() {
    return (
        <Grid container spacing={2} direction="column" item sm={12} lg={8}>
            <Grid item>
                <Skeleton variant="text" />
            </Grid>
            <Grid item>
                <Skeleton variant="rectangular" height={65} />
            </Grid>
            <Grid item>
                <Skeleton variant="rectangular" height={65} />
            </Grid>
            <Grid item>
                <Skeleton variant="rectangular" height={65} />
            </Grid>
            <Grid item>
                <Skeleton variant="rectangular" height={65} />
            </Grid>
        </Grid>
    );
}
