import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTexts } from './useTexts';

export function PageErrorState() {
    const texts = useTexts();

    return (
        <Grid container item direction="column" xs={12} lg={8}>
            <Grid item>
                <Alert severity="error">
                    <Typography>{texts.errorMessage}</Typography>
                </Alert>
            </Grid>
        </Grid>
    );
}
