import { get } from '@/utils/http';
import { openReport } from '@/utils/openReport';
import { ServerError } from '@/utils/ServerError';
import { PaymentRefund } from './payments';

export interface InvoiceDetailWorkOrder {
    number: number;
    services: {
        serviceName: string;
    }[];
}
export interface InvoiceDetail {
    balance: number;
    billingCity: string;
    billingCountry: string;
    billingCountryCode: string;
    billingPostalCode: string;
    billingState: string;
    billingStateAbbreviation: string;
    billingStreet2: string;
    billingStreet: string;
    invoiceDate: string;
    invoiceId: string;
    invoiceNum: string;
    payments: {
        amount: number;
        paymentDate: string;
        status: number;
        refunds: PaymentRefund[];
    }[];
    serviceLocationCity: string;
    serviceLocationCountry: string;
    serviceLocationCountryCode: string;
    serviceLocationPostalCode: string;
    serviceLocationState: string;
    serviceLocationStateAbbreviation: string;
    serviceLocationStreet2: string;
    serviceLocationStreet: string;
    total: number;
    workOrders: InvoiceDetailWorkOrder[];
}

const baseUrl = '/api/invoice';

export const getInvoiceDetails = async (invoiceId: string) => {
    try {
        const { data, status } = await get<InvoiceDetail>(`${baseUrl}/${invoiceId}/details`);

        if (status === 200) {
            return data;
        }

        throw new ServerError(`Failed to get invoice details.`, status);
    } catch (e) {
        throw e;
    }
};

export const getInvoiceReport = async (invoiceId: string) => {
    await openReport(`${baseUrl}/${invoiceId}/report`);
};
