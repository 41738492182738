import type { TextFieldProps as MuiTextFieldProps } from '@mui/material';

import type { ValidateOn } from './types';
import type { FormError, FormField, NarrowFieldsByValueType, UseFormFieldOptions } from '@/formState';

import { useCallback } from 'react';
import { TextField as MuiTextField, FormControl } from '@mui/material';

import { useFormField } from '@/formState';

import { FieldMessage } from '../../FieldMessage';
import { useDebounceText } from '../hooks/useDebounceText';
import { useValidateOn } from '../hooks/useValidateOn';

type RequiredFormField = string;

type Props<
    FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
    FIELDS extends Record<string, FormField>,
    ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
> = {
    label: string;
    name: FIELD_NAME;
    validateOn: ValidateOn;
    helperText?: string;
    placeholder?: string;
    autoFocus?: boolean;
    debounce?: number;
    // custom MUI properties
    // TODO: it should oOmit all props not allowed to configure
    // TODO: or it could ONLY expose the props we want to be customizable
    textField?: {
        type?: 'text' | 'password' | 'email';
        fullWidth?: boolean;
        variant?: 'standard' | 'outlined' | 'filled';
    };
    // ATTENTION: use it carefully
    UNSTABLE_textField?: Partial<MuiTextFieldProps>;
} & UseFormFieldOptions<FIELDS, ERROR>;

export function TextField<
    FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
    FIELDS extends Record<string, FormField>,
    ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
>(props: Props<FIELD_NAME, FIELDS, ERROR>) {
    const {
        placeholder,
        helperText,
        validateOn,
        autoFocus,
        label,
        name,
        debounce,
        textField = {},
        UNSTABLE_textField = {},
        ...options
    } = props;

    // internal type used to resolve the connected Form Field to a `string` instead of a dynamically derived type,
    // not resolved inside the reusable component
    type PartialForm = Record<string, FormField<RequiredFormField>>;

    const {
        field,
        errors,
        fieldApi: { change: apiChange, validate },
        formApi,
    } = useFormField<FIELD_NAME, PartialForm, FormError<FIELD_NAME>>(
        name,
        options as UseFormFieldOptions<PartialForm, FormError<FIELD_NAME>>,
    );

    const { required, status, disabled, visible } = field;

    const { validateOnBlur, validateOnChange, validateOnFocus } = useValidateOn(validate, validateOn);

    const changeAndValidate = useCallback(
        (value: string) => {
            apiChange(value);
            validateOnChange();
        },
        [validateOnChange, apiChange],
    );

    const [value, onChange] = useDebounceText(field.value, changeAndValidate, debounce);

    const onBlur = useCallback(() => {
        validateOnBlur();
    }, [validateOnBlur]);

    const onFocus = useCallback(() => {
        validateOnFocus();
    }, [validateOnFocus]);

    // Resolve the field meta states to improve code readability
    const fieldInvalid = status === 'invalid';
    const fieldIndeterminate = status === 'indeterminate';
    const fieldHasError = fieldInvalid || (fieldIndeterminate && errors.length > 0);

    const errorText = fieldHasError ? errors[0]?.message ?? 'Unknown Error' : undefined;

    // Disable the field also while the form is submitting
    const fieldDisabled = disabled || formApi.getMeta().submitting || formApi.getMeta().disabled;

    if (!visible) return null;

    return (
        <FormControl error={fieldHasError} fullWidth={textField?.fullWidth}>
            <MuiTextField
                // spread it first to avoid overwriting necessary props managed by the Field
                fullWidth={false}
                {...UNSTABLE_textField}
                {...textField}
                name={name}
                label={label}
                required={required}
                error={fieldHasError}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                disabled={fieldDisabled}
                autoFocus={autoFocus}
            />
            <FieldMessage error={fieldHasError} helperText={helperText} errorText={errorText} />
        </FormControl>
    );
}
