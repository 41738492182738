import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            useGreeting: {
                afternoon: translate({ id: 'toolbar.greeting.afternoon' }),
                evening: translate({ id: 'toolbar.greeting.evening' }),
                good: translate({ id: 'toolbar.greeting.good' }),
                howCanWeServeYou: translate({ id: 'toolbar.greeting.howCanWeServeYou' }),
                morning: translate({ id: 'toolbar.greeting.morning' }),
                thankYou: translate({ id: 'toolbar.greeting.thankYou' }),
            },
        };
    }, [translate]);
}
