import Box from '@mui/material/Box';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormMeta, FormError, FormValidator } from '@/formState';
import makeStyles from '@mui/styles/makeStyles';
import { Section } from '@/components/Section';
import { SectionHeader } from '@/components/SectionHeader';
import Grid from '@mui/material/Grid';
import { TextField } from '@/formUI';
import { PasswordRequirements } from '@/components/PasswordRequirements/PasswordRequirements';
import { getPasswordErrors } from '@/components/PasswordRequirements/utils';
import { useTexts as useErrorsTexts } from '@/components/PasswordRequirements/useTexts';
import { toast } from 'react-toastify';
import { useRegistrationActions } from '../useRegisterActions';
import { useTexts } from '../useTexts';
import { Footer } from './Footer';
import { InvalidRegistrationFooter } from './InvalidRegistrationFooter';
import { FormFields, FormErrors } from './formFields';
import { useCustomerPortalUserId } from './hooks/useCustomerPortalUserId';
import { useFetchUserRegistration } from './hooks/useFetchUserRegistration';
import { Typography } from '@mui/material';

const useStyles = makeStyles({
    section: {
        width: '350px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
});

export const Form = () => {
    const texts = useTexts();
    const { api } = useFormMeta<FormFields, FormError<keyof FormFields>>();
    const classes = useStyles();
    const customerPortalUserId = useCustomerPortalUserId();
    const userRegistration = useFetchUserRegistration(customerPortalUserId);
    const { register, afterRegistrationRedirect, notifyError } = useRegistrationActions();
    const errorsTexts = useErrorsTexts();

    const validateForm: FormValidator<FormFields, FormErrors> = formApi => {
        const pwField = formApi.getField('password');
        const confirmPwField = formApi.getField('confirmPassword');

        const errors = getPasswordErrors(pwField, confirmPwField, errorsTexts);
        return errors;
    };

    useEffect(() => {
        if (userRegistration.isError)
        {
            api.setDisabled(true);
            toast.error(texts.invalidRegistrationLink);
        }
    }, [userRegistration, customerPortalUserId, api, texts]);

    const userRegistrationNotCompletedYet = useMemo(() => userRegistration.status == 'success' && userRegistration.data.identityUserId == null,[userRegistration]);
    const userRegistrationMessage = useMemo(() => {
        let message;
        if (userRegistration.isError)
            message = texts.invalidRegistrationLink;
        if (userRegistration.isSuccess && userRegistration.data.identityUserId != null)
            message = texts.userAlreadyRegistered;
        return message;
    }, [userRegistration, texts]);

    useEffect(() => {
        if (!api.getValidator('checkPassword')) {
            api.addValidation('checkPassword', validateForm);
        }
    });

    const handleOnSubmit = useCallback(async () => {
        try {
            api.setSubmitting(true);

            const valid = await api.validate();
            if (!valid) {
                return;
            }

            const values = api.getValues();
            const response = await register(customerPortalUserId, values.password);

            if (response == true) {
                afterRegistrationRedirect();
            } else {
                notifyError(response.message || texts.registrationFailed);
            }
        } catch (e) {
        } finally {
            api.setSubmitting(false);
        }
    }, [api, customerPortalUserId, afterRegistrationRedirect, register, notifyError, texts]);

    return (
        <Box className={classes.container}>
            <Section className={classes.section}>
                <form onChange={() => api.validate()}>
                    <SectionHeader title={texts.title} />
                    {userRegistrationNotCompletedYet &&
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <TextField<'password', FormFields, FormErrors>
                                    name="password"
                                    label={texts.passwordFieldLabel}
                                    validateOn="change blur"
                                    textField={{
                                        'type': 'password',
                                        'fullWidth': true,
                                    }}
                                    UNSTABLE_textField={{ 'autoComplete': 'new-password' }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField<'confirmPassword', FormFields, FormErrors>
                                    name="confirmPassword"
                                    label={texts.confirmPasswordFieldLabel}
                                    validateOn="change blur"
                                    textField={{
                                        'type': 'password',
                                        'fullWidth': true,
                                    }}
                                    UNSTABLE_textField={{ 'autoComplete': 'new-password' }}
                                />
                            </Grid>
                            <Grid item>
                                <PasswordRequirements errors={api.getErrors()} />
                            </Grid>
                            <Grid item>
                                <Footer handleOnSubmit={handleOnSubmit} />
                            </Grid>
                        </Grid>
                    }
                    {!userRegistrationNotCompletedYet &&
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography>
                                    <i>{userRegistrationMessage}</i>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <InvalidRegistrationFooter/>
                            </Grid>
                        </Grid>
                    }
                </form>
            </Section>
        </Box>
    );
};
