import { proxy, useSnapshot } from 'valtio';

export interface LoginState {
    accountSelect: Account.AccountSelect[];
    error: string;
}

export const initialState: LoginState = {
    accountSelect: [],
    error: '',
};

// Valtio store initialization
const state = proxy<LoginState>(initialState);

// Setter functions
export function setAccountSelect(accounts: Account.AccountSelect[]) {
    state.accountSelect = accounts;
}

export function setError(error: string) {
    state.error = error;
}

export function resetLoginState() {
    state.accountSelect = [];
    state.error = '';
}

// Hooks
export function useLoginState() {
    return useSnapshot(state);
}
