import { Section } from '@/components/Section';
import { SectionHeader } from '@/components/SectionHeader';
import SettingsIcon from '@mui/icons-material/Settings';
import { useDrawerState } from '@/common/hooks/useDrawerState';
import { ButtonWithTooltip } from '@/components/ButtonWithTooltip';
import { useServiceRequirementComplete } from '../../store';
import { Drawer } from './Drawer/Drawer';
import { useTexts } from './useTexts';

export const SetServiceDetails = () => {
    const texts = useTexts();
    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();
    const serviceRequirementsComplete = useServiceRequirementComplete();

    return (
        <>
            <Section>
                <SectionHeader title={texts.header} />
                <ButtonWithTooltip
                    color="primary"
                    disabled={!serviceRequirementsComplete}
                    onClick={openDrawer}
                    showTooltip={!serviceRequirementsComplete}
                    startIcon={<SettingsIcon />}
                    tooltipText={serviceRequirementsComplete ? '' : texts.actionBtnDisabled}
                    variant="contained"
                >
                    {texts.actionBtn}
                </ButtonWithTooltip>
            </Section>

            <Drawer open={drawerOpen} onClose={closeDrawer} />
        </>
    );
};

SetServiceDetails.displayName = 'SetServiceDetails';
