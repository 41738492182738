import { get } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';

export interface CustomerPortalUserRegistrationViewModel {
    customerPortalUserId: string;
    identityUserId: string | null;
}

export const getCustomerPortalUserRegistration = async (customerPortalUserId: string) => {
    try {
        const { status, data } = await get<CustomerPortalUserRegistrationViewModel>(
            `api/customerPortalUsers/${customerPortalUserId}`,
        );

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get customer portal user.`, status);
    } catch (e) {
        throw e;
    }
};
