import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            confirmationWarning: translate({ id: 'components.confirmationDialog.confirmationWarning' }),
            defaultTitle: translate({ id: 'components.confirmationDialog.defaultTitle' }),
            no: translate({ id: 'global.no' }),
            yes: translate({ id: 'global.yes' }),
        };
    }, [translate]);
}
