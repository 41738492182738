import type { Scrollbar, ScrollbarSize } from 'smooth-scrollbar/interfaces';
import type { Props } from '../types';

import { useEffect, useRef } from 'react';

const emptyScrollbarSize = {
    container: { width: 0, height: 0 },
    content: { width: 0, height: 0 },
} as const;

export function useNotifyListeners(
    scrollbar: Scrollbar | undefined,
    onChangeScrollbarVisibility?: Props['onChangeScrollbarVisibility'],
    onChangeOutOfViewportHeight?: Props['onChangeOutOfViewportHeight'],
) {
    const rScrollbarSize = useRef<ScrollbarSize>(emptyScrollbarSize);

    // notify listeners when scroll viewport updates
    useEffect(() => {
        let sizeChanged: boolean;
        let prevVisible: boolean;
        let timerStart: number;
        let rafId: number;

        // bail out if no callback are provided
        if (!onChangeOutOfViewportHeight && !onChangeScrollbarVisibility) return;

        function tick(timestamp: number) {
            // if no scrollbar or no callback to be called bail out immediately
            if (!scrollbar) return;

            timerStart ??= timestamp;

            const elapsed = timestamp - timerStart;

            scrollbar?.update();
            const size = scrollbar?.getSize() ?? emptyScrollbarSize;
            sizeChanged = isScrollbarSizeChanged(rScrollbarSize.current, size);
            prevVisible = isScrollbarVisible(rScrollbarSize.current);
            rScrollbarSize.current = size;

            if (sizeChanged) {
                onChangeOutOfViewportHeight?.(getOutOfViewportHeight(size), size);
            }

            const visible = isScrollbarVisible(size);
            if (visible !== prevVisible) {
                onChangeScrollbarVisibility?.(visible);
            }

            if (elapsed > 200) {
                rafId = globalThis.requestAnimationFrame(tick);
            }
        }

        rafId = globalThis.requestAnimationFrame(tick);

        return () => {
            globalThis.cancelAnimationFrame(rafId);
        };
    }, [scrollbar, onChangeOutOfViewportHeight, onChangeScrollbarVisibility]);
}

function isScrollbarSizeChanged(prevSize: ScrollbarSize, nextSize: ScrollbarSize) {
    return (
        prevSize.container.width !== nextSize.container.width ||
        prevSize.container.height !== nextSize.container.height ||
        prevSize.content.width !== nextSize.content.width ||
        prevSize.content.height !== nextSize.content.height
    );
}

function isScrollbarVisible(size: ScrollbarSize) {
    return size.container.width < size.content.width || size.container.height < size.content.height;
}

function getOutOfViewportHeight(size: ScrollbarSize) {
    return size.content.height - size.container.height;
}
