import { TextField } from '@mui/material';
import { useCallback } from 'react';
import { useTexts } from '../../useTexts';
import { setComment, useSelectQuestionCommentState } from '../../store';

export function CommentField() {
    const texts = useTexts();
    const { comment } = useSelectQuestionCommentState();

    const onChangeComment = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
    }, []);

    return (
        <TextField
            autoComplete="off"
            data-testid="comment"
            id="comment"
            label={texts.questionComment}
            minRows={5}
            multiline
            onChange={onChangeComment}
            required
            value={comment}
        />
    );
}
