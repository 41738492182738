import { useCallback } from 'react';
import { setPage, setTake } from '../store';

export function useListActions() {
    const handleSetPage = useCallback((_e, page: number) => {
        setPage(page - 1);
    }, []);

    const handleSetTake = useCallback((take: number) => {
        setTake(take);
    }, []);

    return {
        handleSetPage,
        handleSetTake,
    } as const;
}
