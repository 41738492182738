import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            welcome: translate({ id: `sections.login.welcome` }),
            header: translate({ id: `sections.login.header` }),
            login: translate({ id: `sections.login.login` }),
            loginFailed: translate({ id: `sections.login.loginFailed` }),
            rememberMe: translate({ id: `sections.login.rememberMe` }),
            forgotPassword: translate({ id: `sections.login.forgotPassword` }),
            emailLabel: translate({ id: `sections.login.emailLabel` }),
            passwordLabel: translate({ id: `sections.login.passwordLabel` }),
            selectAnAccount: translate({ id: `sections.login.selectAnAccount` }),
        };
    }, [translate]);
}
