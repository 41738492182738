import { useQueryAgreementDetails } from '@/common/hooks/query/useQueryAgreementDetail';
import { Alert, CircularProgress } from '@mui/material';
import { AddPaymentMethod } from './AddPaymentMethod';
import { DetailListItems } from './DetailListItems';
import { ManageAutopay } from './ManageAutopay';
import { useAgreementDrawerState, WizardStep } from './store';
import { useTexts } from './useTexts';

interface Props {
    agreementId: string;
    onClose: () => void;
}

export function DrawerContent(props: Props) {
    const { agreementId, onClose } = props;
    const texts = useTexts();
    const { data: details, status } = useQueryAgreementDetails(agreementId);
    const { wizardStep } = useAgreementDrawerState();

    return (
        <>
            {status == 'loading' && <CircularProgress />}
            {status == 'error' && <Alert severity="error">{texts.error}</Alert>}
            {status == 'success' && (
                <>
                    {!details && <Alert severity="error">{texts.error}</Alert>}
                    {details && wizardStep === WizardStep.Detail && <DetailListItems details={details} />}
                    {details && wizardStep === WizardStep.ManageAutopay && (
                        <ManageAutopay id={agreementId} details={details} />
                    )}
                    {details && wizardStep === WizardStep.AddPaymentMethod && <AddPaymentMethod onClose={onClose} />}
                </>
            )}
        </>
    );
}
