import type { Language } from '@/intl';
import axios from 'axios';

type Messages = Record<string, string>;

export const changeLanguageService = async (language: Language, abortSignal?: AbortSignal) => {
    const source = axios.CancelToken.source();

    const handleOnAbort = () => source.cancel();
    abortSignal?.addEventListener('abort', handleOnAbort);
    const options = abortSignal ? { cancelToken: source.token } : undefined;

    try {
        // see: https://vitejs.dev/guide/assets.html#the-public-directory
        // Vite expects all dynamic import of static assets inside the public folder to start with `/`
        const folder = `/locales`;
        const fileName = `${language}.json`;
        const preventCache = `v=${Math.random()}`;
        const fileUrl = `${folder}/${fileName}?${preventCache}`;

        const response = await axios.get(fileUrl, options);

        if (response.status === 200) {
            return response.data as Messages;
        }

        throw new Error(`${response.status} - ${response.statusText}`);
    } catch (e) {
        throw e;
    } finally {
        abortSignal?.removeEventListener('abort', handleOnAbort);
    }
};
