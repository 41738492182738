import { AccountSwitch } from '@/components/AccountSwitch';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { Section } from '@/components/Section';
import { SectionHeader } from '@/components/SectionHeader';
import Grid from '@mui/material/Grid';
import { TextField } from '@/formUI';
import { useLoginActions } from '../hooks/useLoginActions';
import { useTexts } from '../hooks/useTexts';
import { useLoginState } from '../store';
import { Footer } from './Footer';
import { FormErrors, FormFields } from './formFields';
import { ForgotPasswordButton } from './ForgotPasswordButton';

const useStyles = makeStyles({
    section: {
        width: '350px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
});

export const Form = () => {
    const texts = useTexts();
    const { accountSelect, error } = useLoginState();
    const { login, handleOnAccountSelect, handleOnForgotPasswordClick } = useLoginActions();
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Section className={classes.section}>
                {!accountSelect.length && (
                    <form>
                        <SectionHeader title={texts.welcome} />
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography>{texts.header}</Typography>
                            </Grid>
                            <Grid item>
                                <TextField<'email', FormFields, FormErrors>
                                    name="email"
                                    label={texts.emailLabel}
                                    validateOn="change blur"
                                    textField={{
                                        'type': 'email',
                                    }}
                                    UNSTABLE_textField={{
                                        'autoComplete': 'username',
                                        'inputProps': { 'data-testid': 'textField-email' },
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField<'password', FormFields, FormErrors>
                                    name="password"
                                    label={texts.passwordLabel}
                                    validateOn="change blur"
                                    textField={{
                                        'type': 'password',
                                    }}
                                    UNSTABLE_textField={{
                                        'autoComplete': 'current-password',
                                        'inputProps': { 'data-testid': 'textField-password' },
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Footer handleOnSubmit={login} />
                            </Grid>
                            <Grid item>
                                <Box display="flex" flexDirection="row-reverse" alignItems="center">
                                    <ForgotPasswordButton handleClick={handleOnForgotPasswordClick} />
                                </Box>
                            </Grid>
                            {!!error && (
                                <Grid item>
                                    <Typography color="error">{error}</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </form>
                )}

                {!!accountSelect.length && (
                    <AccountSwitch
                        title={texts.selectAnAccount}
                        onAccountSelected={handleOnAccountSelect}
                        accountsList={accountSelect}
                        wrappingContainer={true}
                    />
                )}
            </Section>
        </Box>
    );
};
