import { useState, useRef, useEffect, useCallback } from 'react';

import { resetPassword as resetPasswordAction, validateLinkHash as validateLinkHashAction } from '@/services';
import { useToast } from '@/utils/useToast';

export function useResetPasswordActions() {
    const toast = useToast();

    // --------------------------------------
    // Mounted state ref for async callbacks

    const unmounted = useRef(false);
    useEffect(() => () => void (unmounted.current = true));

    // --------------------------------------
    // local state to track state of the login request

    const [submitting, setSubmitting] = useState(false);

    // --------------------------------------
    // callbacks
    const resetPassword = useCallback(async (linkHash: string, newPassword: string): Promise<boolean> => {
        try {
            setSubmitting(true);

            await resetPasswordAction(linkHash, newPassword);

            if (!unmounted.current) setSubmitting(false);

            return true;
        } catch (e) {
            // TODO: navigate 404 page
            return false;
        } finally {
            setSubmitting(false);
        }
    }, []);

    const validateLinkHash = useCallback(async (linkHash: string): Promise<boolean> => {
        return await validateLinkHashAction(linkHash);
    }, []);

    const notifyError = useCallback((message: string) => toast.error(message), [toast]);

    const notifySuccess = useCallback((message: string) => toast.success(message), [toast]);

    // --------------------------------------
    // --------------------------------------
    return {
        submitting,

        resetPassword,
        validateLinkHash,
        notifyError,
        notifySuccess,
    } as const;
}
