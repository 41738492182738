import { useMemo } from 'react';
import { formatPhoneNumber, formatPhoneNumberTelLink, FormattablePhoneNumber } from '../utils/formatPhoneNumber';
import { useLocalizationSettings } from './useLocalizationSettings';

export const usePhoneNumber = (p: FormattablePhoneNumber) => {
    const { showCountryCode } = useLocalizationSettings();

    const phoneNumber = useMemo(() => {
        return formatPhoneNumber(p, showCountryCode);
    }, [p, showCountryCode]);

    return phoneNumber;
};

export const usePhoneNumberTelLink = (p: FormattablePhoneNumber) => {
    const phoneNumber = useMemo(() => {
        return formatPhoneNumberTelLink(p);
    }, [p]);

    return phoneNumber;
};
