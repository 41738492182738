import { AgreementModel } from '@/api/agreements';
import { FormattedDate } from 'react-intl';
import { useTexts } from '../../useTexts';

interface Props {
    item: AgreementModel;
}

export function SubTitle(props: Props) {
    const { item } = props;
    const { agreementDate } = item;
    const texts = useTexts();

    return (
        <span>
            <>
                {`${texts.effectiveDate} `}
                <FormattedDate value={agreementDate as Date} day="2-digit" month="2-digit" year="2-digit" />
            </>
        </span>
    );
}
