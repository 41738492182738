import { Box } from '@mui/material';

interface Props {
    body: JSX.Element;
    footer: JSX.Element;
    header: JSX.Element;
}

export function CardList(props: Props) {
    const { body, footer, header } = props;

    return (
        <Box display="flex" flexDirection="column" height={1}>
            {header}
            {body}
            {footer}
        </Box>
    );
}
