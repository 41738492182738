import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    AddCreditCardCallback,
    HostedPaymentStatus,
    isCancelledCreditCardCallback,
    isSuccessfulCreditCardCallback,
    TransactionSetupMethod,
} from '@/common/types/payments';
import { useToast } from '@/utils/useToast';
import { addCreditCardPaymentAccount } from '@/api/paymentAccounts';
import { getAccount } from '@/atoms/session/store';
import { useGetUILayout } from '@/atoms/UILayout';
import { formatCreditCardFriendly } from '@/common/utils/formatCreditCardFriendly';
import { useTexts } from './useTexts';

interface Props {
    onCancel: () => void;
    onSuccess: (
        _paymentAccountId: string,
        paymentStatus: HostedPaymentStatus,
        friendlyValue: string,
        transactionId: string,
        transactionReferenceNumber?: string,
        processorTransactionLogId?: string,
        partnerRefId?: string,
    ) => Promise<void>;
    transactionSetupMethod: TransactionSetupMethod;
    url: string;
}

export function CreditCardIframe(props: Props) {
    const { onCancel, onSuccess, transactionSetupMethod, url } = props;
    const account = getAccount();
    const layout = useGetUILayout();
    const texts = useTexts();
    const toast = useToast();

    const [isListening, setIsListening] = useState<boolean>(false);

    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    const height = useMemo(() => {
        return layout == 'desktop' ? '230px' : '530px';
    }, [layout]);

    const hostedPaymentsCallback = useCallback(
        async (event: MessageEvent) => {
            if (!mounted.current) return;

            if (typeof event.data !== 'string') {
                return;
            }

            const data: AddCreditCardCallback = JSON.parse(event.data);
            if (isCancelledCreditCardCallback(data)) {
                onCancel();
            }

            if (isSuccessfulCreditCardCallback(data) && !!account?.businessUnitId) {
                switch (transactionSetupMethod) {
                    case TransactionSetupMethod.PaymentAccountCreate: {
                        try {
                            await addCreditCardPaymentAccount({
                                accountId: data.accountId,
                                businessUnitId: account.businessUnitId,
                                cardType: data.cardLogo,
                                lastFour: data.lastFour,
                                month: data.expirationMonth.toString(),
                                processorPaymentAccountId: data.paymentAccountId,
                                year: data.expirationYear.toString(),
                            });
                            await onSuccess(
                                data.paymentAccountId,
                                data.hostedPaymentStatus,
                                formatCreditCardFriendly({
                                    cardType: data.cardLogo,
                                    lastFourDigits: data.lastFour,
                                }),
                                data.transactionId,
                                data.approvalNumber || undefined,
                                data.partnerRefId,
                            );
                            break;
                        } catch (e) {
                            toast.error(texts.errorAddingCreditCard);
                        }
                    }
                    case TransactionSetupMethod.CreditCardSale: {
                        try {
                            await onSuccess(
                                data.paymentAccountId,
                                data.hostedPaymentStatus,
                                formatCreditCardFriendly({
                                    cardType: data.cardLogo,
                                    lastFourDigits: data.lastFour,
                                }),
                                data.transactionId,
                                data.approvalNumber || undefined,
                                data.processorTransactionLogId,
                                data.partnerRefId,
                            );
                        } catch {
                            toast.error(texts.errorMakingPayment);
                        }
                        break;
                    }
                    default: {
                        return;
                    }
                }
            }
        },
        [account, onCancel, onSuccess, texts, toast, transactionSetupMethod],
    );

    useEffect(() => {
        if (mounted.current && !isListening) {
            window.addEventListener('message', hostedPaymentsCallback, false);
            setIsListening(true);
        }

        return () => {
            if (!mounted.current) {
                window.removeEventListener('message', hostedPaymentsCallback);
            }
        };
    }, [hostedPaymentsCallback, isListening]);

    return <iframe src={url} height={height} width="100%" frameBorder="0"></iframe>;
}
