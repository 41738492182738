import { useMemo } from 'react';
import { AchInput } from '../Drawer';

export function useIsAchInputValid(achInput: AchInput) {
    return useMemo(() => {
        return !Boolean(
            !achInput.accountNumber ||
                isNaN(Number(achInput.accountNumber)) ||
                !achInput.routingNumber ||
                isNaN(Number(achInput.routingNumber)) ||
                achInput.routingNumber.length != 9,
        );
    }, [achInput]);
}
