import { get } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';

export interface BusinessUnitDetails {
    name: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    postalCode: string;
    phone: string;
    phoneExt: string;
}

const baseUrl = '/api/BusinessUnit';

export const getBusinessUnitDetails = async (businessUnitId: string) => {
    try {
        const { data, status } = await get<BusinessUnitDetails>(`${baseUrl}/${businessUnitId}/details`);

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get Business Unit details.`, status);
    } catch (e) {
        throw e;
    }
};
