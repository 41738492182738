import { useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio';
import { state } from './store';
import { tenantService } from './tenant';

export function useIsUserAuthorized() {
    return useSnapshot(state).authorized;
}

export function useAccountId() {
    const snapshot = useSnapshot(state);
    return snapshot.authorized ? snapshot.account?.accountId : undefined;
}

export function useUserToken() {
    const snapshot = useSnapshot(state);
    return snapshot.authorized ? snapshot.token : undefined;
}

export const useTenantInformation = () => {
    const loading = useRef(true);

    useEffect(() => {
        const fetchTenantInformation = async () => {
            loading.current = true;
            await tenantService();
            loading.current = false;
        };
        fetchTenantInformation();
    }, []);

    return loading.current;
};
