import { getToken } from '@/atoms/session/store';
import { logout } from '@/atoms/session';
import { rootPath, requestNewToken } from './http';

export const openReport = async (url: string) => {
    try {
        // Get a new token to make sure the current one isn't expiring
        await requestNewToken()
            .then(() => {
                const token = getToken();
                window.open(`${rootPath}${url}?tempToken=${token}`);
            })
            .catch(() => {
                // If the access token could not be refreshed, the session is broken.
                logout();
            });
    } catch (e) {
        throw e;
    }
};
