import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            clearAll: translate({ id: 'sections.accountHistory.filters.clearAll' }),
            emptyState_getStarted: translate({ id: 'sections.upcomingServices.emptyState.getStarted' }),
            emptyState_newRequestsText: translate({ id: 'sections.upcomingServices.emptyState.newRequestsText' }),
            emptyState_noUpcomingServicesText: translate({
                id: 'sections.upcomingServices.emptyState.noUpcomingServicesText',
            }),
            emptyState_pleaseContact: translate({ id: 'sections.upcomingServices.emptyState.pleaseContact' }),
            emptyState_requestService: translate({ id: 'sections.upcomingServices.emptyState.requestService' }),
            emptyState_toSchedule: translate({ id: 'sections.upcomingServices.emptyState.toSchedule' }),
            filters: translate({ id: 'sections.upcomingServices.filters' }),
            noHistoryAlert: translate({ id: 'sections.upcomingServices.noHistoryAlert' }),
            searchPlaceholder: translate({ id: 'global.search' }),
            serviceEligible: translate({ id: 'sections.upcomingServices.serviceEligible' }),
            title: translate({ id: 'sections.upcomingServices.title' }),
            unscheduled: translate({ id: 'sections.upcomingServices.unscheduled' }),
            workOrder: translate({ id: 'global.workOrder' }),
        };
    }, [translate]);
}
