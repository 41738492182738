import { useSnapshot } from 'valtio';
import { state } from './store';

export function useActiveLanguage() {
    return useSnapshot(state);
}

export function useLanguageLoading() {
    return useSnapshot(state).loadingLanguage;
}
