import type { ReactElement } from 'react';

import { Box } from '@mui/material';

import { ContentContainer } from '@/components/ContentContainer';
import { Content } from '@/components/Content';
import { ContentPaper } from '@/components/ContentPaper';

import { useLanguageLoading } from '@/atoms/intl';

import { useTexts } from './useTexts';

type Props = {
    children: ReactElement;
};

export function IntlLoadingScreen(props: Props) {
    const { children } = props;

    const texts = useTexts();
    const loading = useLanguageLoading();

    switch (loading) {
        case 'pending':
            return (
                <ContentContainer>
                    <Content>
                        <ContentPaper fullWidth>
                            <Box width="100%" height="300px" display="flex" justifyContent="center" alignItems="center">
                                {texts.loading}
                            </Box>
                        </ContentPaper>
                    </Content>
                </ContentContainer>
            );

        case 'error':
            return (
                <ContentContainer>
                    <Content>
                        <ContentPaper fullWidth>
                            <Box width="100%" height="300px" display="flex" justifyContent="center" alignItems="center">
                                {texts.error}
                            </Box>
                        </ContentPaper>
                    </Content>
                </ContentContainer>
            );

        default:
            return children;
    }
}
