import { Section } from '@/components/Section';
import { SectionHeader } from '@/components/SectionHeader';
import { Button } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import { useDrawerState } from '@/common/hooks/useDrawerState';
import { Drawer } from './Drawer/Drawer';
import { useTexts } from './useTexts';

export const SelectLocation = () => {
    const texts = useTexts();
    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();

    return (
        <>
            <Section>
                <SectionHeader title={texts.header} />
                <Button color="primary" startIcon={<RoomIcon />} variant="contained" onClick={openDrawer}>
                    {texts.actionBtn}
                </Button>
            </Section>

            <Drawer open={drawerOpen} onClose={closeDrawer} />
        </>
    );
};

SelectLocation.displayName = 'SelectLocation';
