import { InvoiceDetail } from '@/api/invoices';
import { useFormattedAddress } from '@/common/hooks/useAddress';
import { DefinitionListItem } from '@/components/DefinitionListItem';
import { useTexts } from '../../useTexts';

interface Props {
    invoiceDetails: InvoiceDetail;
}

export function BillingLocation(props: Props) {
    const texts = useTexts();

    const {
        invoiceDetails: {
            billingCity,
            billingCountryCode,
            billingPostalCode,
            billingStateAbbreviation,
            billingStreet,
            billingStreet2,
        },
    } = props;

    const billingAddress = useFormattedAddress(
        billingStreet,
        billingStreet2,
        billingCity,
        billingStateAbbreviation,
        billingPostalCode,
        billingCountryCode,
    );

    return <DefinitionListItem primary={texts.billingLocation} secondary={billingAddress} />;
}
