import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            clearAll: translate({ id: 'sections.accountHistory.filters.clearAll' }),
            filter: translate({ id: 'sections.accountHistory.filters' }),
            invoices: translate({ id: 'sections.accountHistory.filters.invoices' }),
            onOrAfter: translate({ id: 'sections.accountHistory.filters.onOrAfter' }),
            onOrBefore: translate({ id: 'sections.accountHistory.filters.onOrBefore' }),
            payments: translate({ id: 'sections.accountHistory.filters.payments' }),
            serviceVisits: translate({ id: 'sections.accountHistory.filters.serviceVisits' }),
            showAllServiceLocations: translate({ id: 'sections.accountHistory.filters.showAllServiceLocations' }),
        };
    }, [translate]);
}
