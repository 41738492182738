import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useDrawerState } from '@/common/hooks/useDrawerState';
import { useGetNote } from '../../store';
import { useTexts } from './useTexts';
import { Drawer } from './Drawer/Drawer';

export const AddNote = () => {
    const note = useGetNote();
    const texts = useTexts();
    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();

    return (
        <>
            <Button startIcon={note ? <EditIcon /> : <PostAddIcon />} variant="text" onClick={openDrawer}>
                {note ? texts.buttonEdit : texts.buttonAdd}
            </Button>

            <Drawer open={drawerOpen} onClose={closeDrawer} />
        </>
    );
};

AddNote.displayName = 'AddNote';
