import { useGetUILayout } from '@/atoms/UILayout';
import { Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { makeStyles } from '@mui/styles';
import { toggleSidebar } from './store';

interface Props {
    content: React.ReactNode;
}

const useStyles = makeStyles({
    content: {
        cursor: 'pointer',
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
    },
});

export const Header = (props: Props) => {
    const { content } = props;
    const layout = useGetUILayout();
    const navigate = useNavigate();
    const classes = useStyles();

    const handleContentClick = useCallback(() => {
        navigate('/account-overview');
    }, [navigate]);

    return (
        <Box p={2} display="flex">
            {layout == 'mobile' && (
                <Box pr={3}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={toggleSidebar}
                        data-testid="btn-toggle-sidebar"
                        size="large"
                    >
                        <MenuIcon />
                    </IconButton>
                </Box>
            )}

            <Box className={classes.content} data-testid="logo" onClick={handleContentClick}>
                {content}
            </Box>
        </Box>
    );
};

Header.displayName = 'Header';
