import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            passwordMust: translate({ id: 'sections.passwordRequirements.passwordsMust' }),
            minLength: translate({ id: 'sections.passwordRequirements.minLength' }),
            includeLower: translate({ id: 'sections.passwordRequirements.includeLower' }),
            includeUpper: translate({ id: 'sections.passwordRequirements.includeUpper' }),
            includeNumber: translate({ id: 'sections.passwordRequirements.includeNumber' }),
            includeSpecial: translate({ id: 'sections.passwordRequirements.includeSpecial' }),
            mustMatch: translate({ id: 'sections.passwordRequirements.mustMatch' }),
        };
    }, [translate]);
}
