import { FormRoot, useCreateForm } from '@/formState';
import { formFields } from './formFields';
import { FormWrapper } from './FormWrapper';

interface Props {
    onClose: () => void;
    open: boolean;
}

export function Drawer(props: Props) {
    const { onClose, open } = props;
    const formState = useCreateForm('change-password-form', formFields);

    return (
        <FormRoot formState={formState}>
            <FormWrapper onClose={onClose} open={open}></FormWrapper>
        </FormRoot>
    );
}
