import type { ValidateOn } from '../Generic/types';

import { useMemo } from 'react';

const noop = () => undefined;

export function useValidateOn(validate: (force?: boolean) => void | Promise<void>, validateOn: ValidateOn) {
    return useMemo(() => {
        return {
            validateOnBlur: validateOn.includes('blur') ? validate : noop,
            validateOnFocus: validateOn.includes('focus') ? validate : noop,
            validateOnChange: validateOn.includes('change') ? validate : noop,
        };
    }, [validate, validateOn]);
}
