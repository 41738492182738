import { UpcomingServicesModel } from '@/api/upcomingServices';
import { useDrawerState } from '@/common/hooks/useDrawerState';
import { CardList, ListBody, ListFooter } from '@/components/cardList';
import { useTexts } from '../useTexts';
import { NoUpcomingServicesSection } from '../NoUpcomingServicesSection';
import { UpcomingServicesListHeader } from './header/UpcomingServicesListHeader';
import { ServiceCard } from './cards';
import { useListState } from './store';
import { useListActions } from './hooks';
import { FiltersDrawer } from './filters/FiltersDrawer';
import { useAccountHasNoUpcomingServices } from './hooks/useAccountHasNoUpcomingServices';

interface Props {
    items: UpcomingServicesModel[];
    total: number;
}

export function UpcomingServicesList(props: Props) {
    const { items, total } = props;
    const { page, take, search, serviceLocations } = useListState();
    const texts = useTexts();
    const { handleSetPage } = useListActions();
    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();
    const noUpcomingServices = useAccountHasNoUpcomingServices(serviceLocations, search, items);

    return (
        <>
            {!noUpcomingServices && (
                <>
                    <CardList
                        header={<UpcomingServicesListHeader openDrawer={openDrawer} />}
                        body={<ListBody card={ServiceCard} emptyListAlertText={texts.noHistoryAlert} items={items} />}
                        footer={<ListFooter count={Math.ceil(total / take)} page={page + 1} onChange={handleSetPage} />}
                    />
                    <FiltersDrawer open={drawerOpen} onClose={closeDrawer} />
                </>
            )}
            {noUpcomingServices && <NoUpcomingServicesSection />}
        </>
    );
}
