import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            unauthorized: translate({ id: `sections.forbidden.unauthorized` }),
        };
    }, [translate]);
}
