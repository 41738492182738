import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { FormError, FormValidator, useForm } from '@/formState';
import { TextField } from '@/formUI';
import { PasswordRequirements } from '@/components/PasswordRequirements/PasswordRequirements';
import { useTexts as useErrorsTexts } from '@/components/PasswordRequirements/useTexts';
import { getPasswordErrors } from '@/components/PasswordRequirements/utils';
import { RequiredFieldValidation } from '@/utils/requiredValidator';
import { FormErrors, FormFields } from './formFields';
import { useTexts } from './useTexts';

export function Form() {
    const texts = useTexts();
    const errorsTexts = useErrorsTexts();

    const { api } = useForm<FormFields, FormError<keyof FormFields>>();

    const validateForm: FormValidator<FormFields, FormErrors> = formApi => {
        const pwField = formApi.getField('password');
        const confirmPwField = formApi.getField('confirmPassword');

        const errors = getPasswordErrors(pwField, confirmPwField, errorsTexts);

        return errors;
    };

    useEffect(() => {
        if (!api.getValidator('checkPassword')) {
            api.addValidation('checkPassword', validateForm);
            api.addValidation(
                'oldPassword',
                RequiredFieldValidation<FormFields, FormErrors>('oldPassword', 'oldPasswordRequired'),
            );
        }
    });

    return (
        <form onChange={() => api.validate()}>
            <Box width="100%">
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <TextField<'oldPassword', FormFields, FormErrors>
                            textField={{
                                type: 'password',
                                fullWidth: true,
                            }}
                            validateOn="change blur"
                            label={texts.currentPassword}
                            name="oldPassword"
                        />
                    </Grid>
                    <Grid item>
                        <TextField<'password', FormFields, FormErrors>
                            textField={{
                                type: 'password',
                                fullWidth: true,
                            }}
                            validateOn="change blur"
                            label={texts.newPassword}
                            name="password"
                        />
                    </Grid>
                    <Grid item>
                        <TextField<'confirmPassword', FormFields, FormErrors>
                            textField={{
                                type: 'password',
                                fullWidth: true,
                            }}
                            validateOn="change blur"
                            label={texts.confirmPasswordFieldLabel}
                            name="confirmPassword"
                        />
                    </Grid>
                    <Grid item>
                        <PasswordRequirements errors={api.getErrors()} />
                    </Grid>
                    <Grid item>
                        <Typography>{texts.sessionClearWarning}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
}
