import type { FC } from 'react';
import type { WrappedComponentProps, IntlShape } from 'react-intl';
import type { Translate } from './types';

import { ReactElement, useEffect, memo } from 'react';
import { injectIntl } from 'react-intl';

type IntlAPI = IntlShape | null;
let intlAPI: IntlAPI = null;

const getIntlAPI = (): IntlAPI => {
    if (!intlAPI) {
        throw new Error(`Intl Manager doesn't have references to intl API`);
    }
    return intlAPI;
};

type Props = WrappedComponentProps & { children: ReactElement | null };

const IntlManagerComp: FC<Props> = memo(
    props => {
        const { intl } = props;

        if (!intlAPI) {
            intlAPI = intl;
        }

        useEffect(() => {
            intlAPI = intl;
        }, [intl]);

        return props.children;
    },
    (prevProps: Props, nextProps: Props) => {
        const { intl: prevIntl } = prevProps;
        const { intl } = nextProps;

        return prevIntl === intl;
    },
);
IntlManagerComp.displayName = 'IntlManagerComp';

export const IntlManager = injectIntl(IntlManagerComp);

/**
 * Expose the Intl APIs outside the components tree.
 */
export const translate: Translate = options => {
    return getIntlAPI()?.formatMessage(options, options.values) || '';
};
