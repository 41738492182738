import { useGetUILayout } from '@/atoms/UILayout';
import { ClickAwayListener, IconButton } from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';

export interface Props extends IconButtonProps {
    showTooltip?: boolean;
    spanClassName?: string;
    tooltipText?: any;
}

export function IconButtonWithTooltip(props: Props) {
    const { tooltipText, showTooltip, spanClassName, ...buttonProps } = props;
    const [open, setOpen] = useState<boolean>(false);
    const layout = useGetUILayout();

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <>
            {showTooltip && (
                <>
                    {layout == 'desktop' && (
                        <>
                            <Tooltip enterTouchDelay={0} title={tooltipText}>
                                <span className={spanClassName}>
                                    <IconButton {...buttonProps}>{props.children}</IconButton>
                                </span>
                            </Tooltip>
                        </>
                    )}

                    {layout == 'mobile' && (
                        <>
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                <div>
                                    <Tooltip
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        onClose={handleTooltipClose}
                                        open={open}
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        title={tooltipText}
                                    >
                                        <span className={spanClassName} onClick={handleTooltipOpen}>
                                            <IconButton {...buttonProps}>{props.children}</IconButton>
                                        </span>
                                    </Tooltip>
                                </div>
                            </ClickAwayListener>
                        </>
                    )}
                </>
            )}

            {!showTooltip && <IconButton {...buttonProps}>{props.children}</IconButton>}
        </>
    );
}
