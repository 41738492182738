import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            account: translate({ id: `sections.accountSwitcher.account` }),
            success: translate({ id: `sections.accountSwitcher.success` }),
            switchLink: translate({ id: `sections.accountSwitcher.switchLink` }),
            heading: translate({ id: `sections.accountSwitcher.heading` }),
        };
    }, [translate]);
}
