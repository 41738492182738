import { Section } from '@/components/Section';
import { SectionHeader } from '@/components/SectionHeader';
import AddIcon from '@mui/icons-material/Add';
import { useDrawerState } from '@/common/hooks/useDrawerState';
import { ButtonWithTooltip } from '@/components/ButtonWithTooltip';
import { useLocationRequirementComplete } from '../../store';
import { useTexts } from './useTexts';
import { Drawer } from './Drawer/Drawer';

export const AddServices = () => {
    const texts = useTexts();
    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();

    const locationRequirementsComplete = useLocationRequirementComplete();

    return (
        <>
            <Section>
                <SectionHeader title={texts.header} />
                <ButtonWithTooltip
                    color="primary"
                    disabled={!locationRequirementsComplete}
                    onClick={openDrawer}
                    showTooltip={!locationRequirementsComplete}
                    startIcon={<AddIcon />}
                    tooltipText={locationRequirementsComplete ? '' : texts.actionBtnDisabled}
                    variant="contained"
                >
                    {texts.actionBtn}
                </ButtonWithTooltip>
            </Section>

            <Drawer open={drawerOpen} onClose={closeDrawer} />
        </>
    );
};

AddServices.displayName = 'AddServices';
