import { AgreementStatus } from '@/common/types/agreements';
import Chip from '@mui/material/Chip';
import { useMemo } from 'react';
import { useTexts } from '../../../useTexts';

type Props = {
    onDelete: React.MouseEventHandler;
    status: AgreementStatus;
};

export function StatusFilterChip(props: Props) {
    const { onDelete, status } = props;
    const texts = useTexts();

    const label = useMemo(() => {
        switch (status) {
            case AgreementStatus.ACTIVE:
                return `${texts.active} ${texts.agreements}`;
            case AgreementStatus.CANCELLED:
                return `${texts.cancelled} ${texts.agreements}`;
            case AgreementStatus.COMPLETED:
                return `${texts.completed} ${texts.agreements}`;
        }
    }, [status, texts.active, texts.cancelled, texts.completed, texts.agreements]);

    return <Chip size="small" color="primary" variant="outlined" label={label} onDelete={onDelete} />;
}
