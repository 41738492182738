import { get } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';
import { CommitmentWindowCustomerResponse } from './workOrderDetails';

export interface UpcomingServicesResponse {
    total: number;
    page: number;
    take: number;
    items: UpcomingServicesModel[];
}

export interface UpcomingServicesModel {
    commitmentWindowConfirmationRequested: boolean;
    commitmentWindowCustomerResponse: CommitmentWindowCustomerResponse;
    endingEligibleDate: Date | null;
    estimatedTimeOfArrival: Date | null;
    number: number | null;
    projectSequence: number | null;
    startingEligibleDate: Date | null;
    subNumber: number | null;
    workOrderId: string;
    workOrderServices: string[];
}

const baseUrl = 'api/Accounts';

function getQueryString(page: number, take: number, search: string, serviceLocations: string[]) {
    const params = new URLSearchParams({
        page: page.toString(),
        take: take.toString(),
    });

    if (!!search) {
        params.append('searchTerm', search);
    }
    if (!!serviceLocations.length) {
        serviceLocations.map(x => !!x && params.append('serviceLocation', x));
    }

    return params;
}

export const getUpcomingServices = async (
    accountId: string,
    page: number,
    take: number,
    search: string,
    serviceLocations: string[],
) => {
    try {
        const queryString = getQueryString(page, take, search, serviceLocations);
        const { status, data } = await get<UpcomingServicesResponse>(
            `${baseUrl}/${accountId}/upcomingServices?${queryString}`,
        );

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get upcoming services.`, status);
    } catch (e) {
        throw e;
    }
};
