import { LoadingButton } from '@mui/lab';
import { ReactNode, useCallback } from 'react';
import { useMutation } from 'react-query';
import { useToast } from '@/utils/useToast';
import { PDF } from '../sections/AccountHistory/AccountHistoryList/cards/icons/PDF';

interface Props {
    children: ReactNode;
    onClick: () => Promise<void>;
    errorText: string;
    className?: string;
}

export function OpenPDFButton(props: Props) {
    const toast = useToast();

    const { children, onClick, errorText, className } = props;

    const mutation = useMutation(onClick, {
        onError: () => {
            toast.error(errorText);
        },
        onSuccess: async () => {},
    });

    const handleClick = useCallback(async () => {
        mutation.mutate();
    }, [mutation]);

    return (
        <LoadingButton
            variant="text"
            startIcon={<PDF />}
            loadingPosition="start"
            onClick={handleClick}
            loading={mutation.isLoading}
            className={className}
        >
            {children}
        </LoadingButton>
    );
}
