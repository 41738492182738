import { Alert, Stack } from '@mui/material';
import { useMemo } from 'react';
import { useSelectLocationState } from '../store';
import { LocationRadioButton } from './LocationRadioButton';
import { useTexts } from './useTexts';

export const LocationList = () => {
    const texts = useTexts();
    const { all: locations, searchQuery } = useSelectLocationState();

    const filteredLocations = useMemo(() => {
        return locations.filter(l => {
            const addressString = `${l.name} ${l.street1} ${l.street2} ${l.city} ${l.state} ${l.stateAbbreviation} ${l.country} ${l.countryCode} ${l.postalCode}`;
            return addressString.toLowerCase().includes(searchQuery.toLowerCase());
        });
    }, [locations, searchQuery]);

    return (
        <>
            {locations.length == 0 && <Alert severity="info">{texts.noLocations}</Alert>}

            {locations.length > 0 && (
                <>
                    {filteredLocations.length == 0 && <Alert severity="info">{texts.noLocationsMatchQuery}</Alert>}
                    {filteredLocations.length > 0 && (
                        <Stack direction="column" spacing={2}>
                            {filteredLocations.map((location, i) => (
                                <LocationRadioButton key={i} location={location} />
                            ))}
                        </Stack>
                    )}
                </>
            )}
        </>
    );
};
