import { useCallback, useState } from 'react';

export const useDrawerState = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const openDrawer = useCallback(() => {
        setIsOpen(true);
    }, []);

    const closeDrawer = useCallback(() => {
        setIsOpen(false);
    }, []);

    return [closeDrawer, isOpen, openDrawer] as const;
};
