import { createContext, useState } from 'react';
import { ConfirmationDialog } from './ConfirmationDialog';
import { useTexts } from './useTexts';

export const ConfirmationDialogContext = createContext({} as { openDialog: (config: DialogConfig) => void });

export interface DialogConfig {
    callbackFn: (value: boolean | PromiseLike<boolean>) => void;
    message: string;
    title: string;
    width?: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
}

interface Props {
    children: React.ReactNode;
}

export function ConfirmationDialogProvider(props: Props) {
    const { children } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogConfig, setDialogConfig] = useState<DialogConfig | null>();
    const texts = useTexts();

    const openDialog = (config: DialogConfig) => {
        setDialogOpen(true);
        setDialogConfig(config);
    };

    const resetDialog = () => {
        if (!dialogConfig) return;
        setDialogOpen(false);
    };

    const onConfirm = () => {
        if (!dialogConfig) return;
        resetDialog();
        dialogConfig.callbackFn(true);
    };

    const onDismiss = () => {
        if (!dialogConfig) return;
        resetDialog();
        dialogConfig.callbackFn(false);
    };

    return (
        <ConfirmationDialogContext.Provider value={{ openDialog }}>
            <ConfirmationDialog
                open={dialogOpen}
                title={dialogConfig?.title || texts.defaultTitle}
                message={dialogConfig?.message || texts.confirmationWarning}
                width={dialogConfig?.width || 'sm'}
                onConfirm={onConfirm}
                onDismiss={onDismiss}
            />
            {children}
        </ConfirmationDialogContext.Provider>
    );
}
