import { DefinitionListItem } from '@/components/DefinitionListItem';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Currency } from '@/components/localization';
import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { CustomerPortalLastServiceFields } from '@/common/types/services';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        marginLeft: theme.spacing(1),
        textAlign: 'right',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(0),
            marginTop: theme.spacing(2),
        },
    },
}));

const servicesHiddenStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(0),
        marginTop: theme.spacing(0),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            marginLeft: theme.spacing(0),
            marginTop: theme.spacing(2),
        },
    },
}));

interface Props {
    amount: number;
    label: string;
}

export const ServiceAmount = (props: Props) => {
    const defaultClass = useStyles();
    const servicesHiddenClass = servicesHiddenStyles();
    const { amount, label } = props;

    const enableLastServiceIncludedServices = useFeatureEnabled(
        CustomerPortalLastServiceFields.EnableLastServiceIncludedServices,
    );

    return (
        <Box className={enableLastServiceIncludedServices ? defaultClass.root : servicesHiddenClass.root}>
            <DefinitionListItem
                primary={label}
                secondary={<Typography>{<Currency value={amount || 0} />}</Typography>}
            />
        </Box>
    );
};

ServiceAmount.displayName = 'ServiceAmount';
