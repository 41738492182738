import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Box, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { closeSidebar } from '../store';
import { ListItemProps } from './typings';

const useStyles = makeStyles({
    icon: {
        fontSize: '24px',
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '24px',
    },
});

export const ListItem = (props: ListItemProps) => {
    const { disabled, hidden, icon, label, path } = props;
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = useCallback(
        () => (_e: React.MouseEvent) => {
            navigate(path);
            closeSidebar();
        },
        [navigate, path],
    );

    const isSelected = useMemo(() => {
        return location.pathname == path;
    }, [location.pathname, path]);

    return hidden ? null : (
        <ListItemButton onClick={handleClick()} disabled={disabled} selected={isSelected}>
            {!!icon && (
                <ListItemIcon className={classes.icon}>
                    <Box className={classes.iconContainer}>{icon}</Box>
                </ListItemIcon>
            )}
            <ListItemText primary={label} />
        </ListItemButton>
    );
};

ListItem.displayName = 'ListItem';
