import { CustomerPortalSettings, getCustomerPortalSettings } from '@/api/businessUnitSettings';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

export function useFetchCustomerPortalSettings(businessUnitId: string) {
    const isQueryEnabled = useMemo(() => {
        return !!businessUnitId;
    }, [businessUnitId]);

    return useQuery<CustomerPortalSettings, Error>(
        ['customerPortalSettings', businessUnitId],
        () => getCustomerPortalSettings(businessUnitId),
        {
            enabled: isQueryEnabled,
            staleTime: Infinity,
        },
    );
}
