import { useTranslate } from '@/intl';
import { PaymentAccountTypes } from '../types/payments';

export function useHumanizedPaymentAccountTypes() {
    const translate = useTranslate();

    return {
        [PaymentAccountTypes.ACH]: translate({ id: 'global.ach' }),
        [PaymentAccountTypes.CREDIT_CARD]: translate({ id: 'global.creditCard' }),
    };
}
