import { blue, blueGrey, green, grey, orange, red } from '@mui/material/colors';

const black = '#000000';
const white = '#FFFFFF';

export const palette = {
    background: {
        default: '#F4F6F8',
        paper: white,
        secondary: '#ECECEE',
    },
    badges: {
        error: '#E9C9C9',
        info: '#1257A573',
        success: '#E2F1CB',
        warning: '#FCF1CA',
    },
    black,
    custom: {
        lightGrey: '#ECECEE',
    },
    divider: grey[200],
    error: {
        contrastText: white,
        dark: red[900],
        main: red[600],
        light: red[400],
    },
    icon: blueGrey[600],
    info: {
        contrastText: white,
        dark: blue[900],
        main: blue[600],
        light: blue[400],
    },
    primary: {
        main: blue[800],
    },
    secondary: {
        contrastText: white,
        dark: grey[900],
        main: grey[600],
        light: grey[400],
    },
    success: {
        contrastText: white,
        dark: green[900],
        main: green[600],
        light: green[400],
    },
    text: {
        primary: blueGrey[900],
        secondary: blueGrey[600],
        disabled: grey[600],
    },
    warning: {
        contrastText: white,
        dark: orange[900],
        main: orange[600],
        light: orange[400],
    },
    white,
};
