// see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#custom_error_types

import type { AxiosError } from 'axios';
export class ServerError extends Error {
    public readonly name = 'ServerError';

    constructor(public readonly message: string, public readonly errorCode: number) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(message);

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ServerError);
        }
    }
}

export const prepareServerError = (e: any): ServerError => {
    if (e instanceof ServerError) {
        return e;
    } else if (typeof e === 'object' && e.isAxiosError === true) {
        const axiosError = e as AxiosError;
        const errorCode = axiosError.code
            ? parseInt(axiosError.code)
            : axiosError.response?.status
            ? axiosError.response?.status
            : -1;
        return new ServerError(axiosError.message, errorCode);
    } else if (e instanceof Error) {
        return new ServerError(e.message, -1);
    } else {
        return new ServerError(`Unknown error`, -2);
    }
};

export const isServerError = (entity: unknown): entity is ServerError => {
    const e = entity as ServerError;
    return !!e.message;
};
