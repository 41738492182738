import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            greeting: {
                tagLine: translate({ id: 'toolbar.greeting.howCanWeServeYou' }),
            },
            logout: translate({ id: `components.avatar.logout` }),
            viewProfileSettings: translate({ id: `components.avatar.viewProfileSettings` }),
        };
    }, [translate]);
}
