import 'react-toastify/dist/ReactToastify.css';
import './main.css';

import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';

import { Root } from './Root';
import { reportWebVitals } from './reportWebVitals';
import { printHostEnvironmentInfo } from './utils/hostEnvironmentInfo';
import { ReactQueryProvider } from './services';

async function main() {
    if (import.meta.env.DEV && import.meta.env.VITE_ENABLE_MOCK_SERVER == 'true') {
        // ------------------------------
        // conditional import executed only in DEV mode
        // the production build will not bundle or execute the mock server

        const { makeServer } = await import('./server');
        makeServer({ environment: 'development' });
    }

    // ------------------------------
    // mount the React Root component

    ReactDOM.render(
        <StrictMode>
            <ReactQueryProvider>
                <Root />
            </ReactQueryProvider>
        </StrictMode>,
        document.getElementById('root'),
    );

    // ------------------------------
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint.
    reportWebVitals();
}

main();

if (import.meta.env.DEV) {
    // print useful information on the host environment
    printHostEnvironmentInfo();
}
