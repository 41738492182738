import { remove, post } from '@/utils/http';

const baseUrl = '/api/autoPayments';

export const enableAutopayment = async (agreementId: string, paymentAccountId: string) => {
    try {
        const { status } = await post(baseUrl, { agreementId, paymentAccountId });

        if (status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        throw e;
    }
};

export const disableAutopayment = async (agreementId: string) => {
    try {
        const { status } = await remove(baseUrl, {
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(agreementId),
        });

        if (status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        throw e;
    }
};
