import { SchedulingPlan } from '@/api/services';
import { useMemo } from 'react';
import { useTranslate } from '@/intl';
import { Frequencies } from '@/common/types/schedulingPlan';
import { emptyGuid } from '@/common/constants';
import { useHumanizedDaysOfWeek } from './useHumanizedDaysOfWeek';
import { usePluralFrequencies } from './usePluralFrequencies';
import { useSingularFrequencies } from './useSingularFrequencies';
import { useHumanizedIntervalUnits } from './useHumanizedIntervalUnits';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            every: translate({ id: 'global.schedulingPlans.every' }),
            noRecurrence: translate({ id: 'global.schedulingPlans.noRecurrence' }),
            to: translate({ id: 'global.schedulingPlans.to' }),
        };
    }, [translate]);
}

export const useHumanizedSchedulingPlan = (plan: SchedulingPlan | null): string => {
    const texts = useTexts();
    const humanizedDaysOfWeek = useHumanizedDaysOfWeek(plan);
    const singularFrequencies = useSingularFrequencies();
    const pluralFrequencies = usePluralFrequencies();
    const humanizedIntervalUnits = useHumanizedIntervalUnits();

    if (!plan || !plan.intervalUnit || plan.schedulingPlanId == emptyGuid) {
        return '';
    }

    const interval = plan.interval ? Number(plan.interval.substring(1, plan.interval.length - 1)) : 0;

    if (plan.intervalUnit == Frequencies.OneTime) {
        return texts.noRecurrence;
    }

    if (plan.intervalUnit == Frequencies.MultiDaysPerWeek) {
        return humanizedDaysOfWeek;
    }

    if (plan.intervalUnit == Frequencies.OnDemand) {
        return humanizedIntervalUnits[plan.intervalUnit];
    }

    if (interval == 1) {
        return humanizedIntervalUnits[plan.intervalUnit];
    }

    if (plan.intervalUnit == Frequencies.Quarterly && plan.interval == 'P3M' && plan.tolerance == 'P15D') {
        return humanizedIntervalUnits[Frequencies.Quarterly];
    }

    const intervalUnit = pluralFrequencies[plan.intervalUnit];
    const tolerance = plan.tolerance.match(/\d/g)?.join('');
    const toleranceUnit =
        tolerance == '1' ? singularFrequencies[Frequencies.Daily] : pluralFrequencies[Frequencies.Daily];
    const toleranceStr = tolerance && toleranceUnit ? ` \u00b1 ${tolerance} ${toleranceUnit}` : '';

    return `${texts.every} ${interval} ${intervalUnit} ${toleranceStr}`;
};
