import { useServiceLocationAddress } from '@/common/hooks/useAddress';
import { useDrawerState } from '@/common/hooks/useDrawerState';
import { PaddedDivider } from '@/components/PaddedDivider';
import { Section } from '@/components/Section';
import { SectionHeader } from '@/components/SectionHeader';
import { Grid, Typography } from '@mui/material';
import { useRequestServiceState } from '../../store';
import { Drawer } from '../SetServiceDetails/Drawer/Drawer';
import { Service } from './Service';
import { SubmitRequestButton } from './SubmitRequestButton';
import { useTexts } from './useTexts';

export const YourRequest = () => {
    const texts = useTexts();
    const { note, serviceLocation, services } = useRequestServiceState();
    const address = useServiceLocationAddress(serviceLocation);
    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();

    return (
        <>
            <Section>
                <SectionHeader title={texts.header} primaryAction={<SubmitRequestButton />} />
                {!!serviceLocation && (
                    <>
                        {serviceLocation.name && (
                            <Typography>
                                <b>{serviceLocation.name}</b>
                            </Typography>
                        )}
                        <Typography>{address}</Typography>
                        <PaddedDivider />
                    </>
                )}

                {services.length > 0 ? (
                    <Grid container direction="column" spacing={2}>
                        {services.map((service, i) => (
                            <Grid item key={i}>
                                <Service service={service} onEditDetails={openDrawer} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Typography>
                        <i>{texts.noServicesSelected}</i>
                    </Typography>
                )}
                {!!note && (
                    <>
                        <PaddedDivider />
                        <Typography gutterBottom>
                            <b>Note:</b>
                        </Typography>
                        <Typography style={{ whiteSpace: 'pre-line' }}>{note}</Typography>
                    </>
                )}
            </Section>

            <Drawer open={drawerOpen} onClose={closeDrawer} />
        </>
    );
};

YourRequest.displayName = 'YourRequest';
