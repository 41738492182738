import { PaymentProcessors } from '@/api/paymentProcessor';
import { getAccount } from '@/atoms/session/store';
import { useMemo } from 'react';
import { useFetchBusinessUnitPaymentProcessor } from './query/useFetchBusinessUnitPaymentProcessor';
import { useFetchCustomerPortalSettings } from './query/useFetchCustomerPortalSettings';

export const usePaymentsEnabled = () => {
    const account = getAccount();
    const { data: settings } = useFetchCustomerPortalSettings(account?.businessUnitId || '');
    const { data: paymentProcessor } = useFetchBusinessUnitPaymentProcessor(account?.businessUnitId || '');
    const hasWWP = useMemo(() => {
        return paymentProcessor == PaymentProcessors.WorkWave;
    }, [paymentProcessor]);

    return useMemo(() => {
        return !!(settings?.enableMakePayments && hasWWP);
    }, [settings, hasWWP]);
};
