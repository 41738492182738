import { Button, CircularProgress, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Section } from '@/components/Section';
import { SectionHeader } from '@/components/SectionHeader';
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router';
import { useCallback } from 'react';
import { useFetchAccountSummary } from '@/common/hooks/query/useFetchAccountSummary';
import { usePaymentsEnabled } from '@/common/hooks/usePaymentsEnabled';
import { useTexts } from './useTexts';
import { Content } from './Content';

interface Props {
    accountId: string;
    hidePrimaryAction?: boolean;
}

export function AccountSummary(props: Props) {
    const { accountId, hidePrimaryAction = false } = props;
    const { data, status } = useFetchAccountSummary(accountId);
    const texts = useTexts();
    const paymentsEnabled = usePaymentsEnabled();
    const navigate = useNavigate();

    const navigateToPayInvoices = useCallback(() => {
        navigate('/payments/pay-invoices');
    }, [navigate]);

    return (
        <Section>
            <SectionHeader
                icon={<FontAwesomeIcon icon={faDollarSign} />}
                title={texts.header}
                primaryAction={
                    paymentsEnabled && !hidePrimaryAction ? (
                        <Button variant="contained" color="primary" onClick={navigateToPayInvoices}>
                            {texts.makePayment}
                        </Button>
                    ) : (
                        <></>
                    )
                }
            />
            {status == 'error' && <Typography>{texts.error}</Typography>}
            {status == 'loading' && <CircularProgress />}
            {status == 'success' && !!data && <Content data={data} />}
        </Section>
    );
}
