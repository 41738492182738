import { AccountHistoryItemModel } from '@/api/accountHistory';
import { useDrawerState } from '@/common/hooks/useDrawerState';
import { CardList, ListBody, ListFooter } from '@/components/cardList';
import { useTexts } from '../useTexts';
import { AccountHistoryListHeader } from './header/AccountHistoryListHeader';
import { useListState } from './store';
import { useListActions } from './hooks';
import { FiltersDrawer } from './filters/FiltersDrawer';
import { AccountHistoryCard } from './cards';

interface Props {
    items: AccountHistoryItemModel[];
    total: number;
}

export const AccountHistoryList = (props: Props) => {
    const { items, total } = props;
    const { page, take } = useListState();
    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();
    const texts = useTexts();
    const { handleSetPage } = useListActions();

    return (
        <>
            <CardList
                header={<AccountHistoryListHeader openDrawer={openDrawer} />}
                body={<ListBody card={AccountHistoryCard} emptyListAlertText={texts.noHistoryAlert} items={items} />}
                footer={<ListFooter count={Math.ceil(total / take)} page={page + 1} onChange={handleSetPage} />}
            />
            <FiltersDrawer open={drawerOpen} onClose={closeDrawer} />
        </>
    );
};
