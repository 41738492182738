import Box from '@mui/material/Box';

import { ContentContainer } from '@/components/ContentContainer';
import { ContentPaper } from '@/components/ContentPaper';

import { useTexts } from './useTexts';

export function Forbidden() {
    const texts = useTexts();

    return (
        <ContentContainer>
            <ContentPaper fullWidth>
                <Box width="100%" height="300px" display="flex" justifyContent="center" alignItems="center">
                    {texts.unauthorized}
                </Box>
            </ContentPaper>
        </ContentContainer>
    );
}
