import type { FormError, FormField, FormState } from '../types';

function throwError() {
    if (process.env.NODE_ENV === 'development') {
        throw new Error(`You cannot use a FormState Context without mounting a FormRoot component as an ancestor.`);
    }
}

const safeNoop = () => void throwError();
const safeNoopBool = () => {
    throwError();
    return false;
};

/**
 * @private
 *
 * Helper function to create an empty placeholder for a Form State.
 * Used internally by the `Form React Context` as default value.
 *
 * @returns A valid Form State placeholder.
 */
export function createEmptyFormState(): FormState<Record<string, FormField>, FormError<string>> {
    return {
        api: {
            setDebugMode: safeNoop,

            getErrors: () => {
                throwError();
                return {};
            },
            getField: () => {
                throwError();
                return { value: '', status: 'pristine' };
            },
            registerField: () => {
                throwError();
                return false;
            },
            deregisterField: () => {
                throwError();
            },
            getFieldErrors: () => {
                throwError();
                return [];
            },
            getMeta: () => {
                throwError();
                return {
                    disabled: false,
                    submitting: false,
                    validating: false,
                    status: 'pristine',
                };
            },
            getValues: () => {
                throwError();
                return {};
            },
            getInitialValues: () => {
                throwError();
                return {};
            },

            reset: safeNoop,
            change: safeNoop,
            batch: safeNoop,

            setFieldVisibleState: safeNoop,
            setFieldDisabledState: safeNoop,
            setFieldRequiredState: safeNoop,

            addFormMutation: safeNoop,
            removeFormMutation: safeNoopBool,
            addFieldMutation: safeNoop,
            removeFieldMutation: safeNoopBool,

            setDisabled: safeNoop,
            setSubmitting: safeNoop,

            clearValidation: safeNoop,
            validate: async () => {
                throwError();
                return false;
            },
            addValidation: safeNoop,
            getValidator: safeNoop,
            removeValidation: safeNoopBool,

            subscribeToForm: () => safeNoop,
            subscribeToMeta: () => safeNoop,
            subscribeToField: () => safeNoop,

            removeAllListeners: safeNoop,
        },

        store: {
            id: '_____defaultEmptyState_____',
            initialValues: {},
            values: {},
            errors: {},
            fields: {},
            meta: {
                disabled: false,
                submitting: false,
                validating: false,

                status: 'pristine',
            },
        },
    };
}
