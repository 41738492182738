import { TransactionSetupMethod } from '@/common/types/payments';
import { PaymentTransactionsInput } from '@/common/types/paymentTransactions';
import { post } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';

const baseUrl = '/api/paymentTransactions';

export const getPaymentTransactionsIframeUrl = async (
    accountId: string,
    amount: number,
    businessUnitId: string,
    transactionSetupMethod: TransactionSetupMethod,
) => {
    try {
        const input: PaymentTransactionsInput = {
            accountId,
            amount,
            businessUnitId,
            returnUrl: `${import.meta.env.VITE_HTTP_HOST}:${import.meta.env.VITE_HTTP_PORT}/api/paymentTransactions`,
            saveToAccount: transactionSetupMethod == TransactionSetupMethod.PaymentAccountCreate,
            transactionSetupMethod,
        };

        const { data, status } = await post<string>(baseUrl, input);

        if (status === 200) {
            return data;
        }

        throw new ServerError(`Failed to get create payment transaction details.`, status);
    } catch (e) {
        throw e;
    }
};
