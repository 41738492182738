import { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
    children: ReactElement | ReactElement[];
}

const useClasses = makeStyles(theme => ({
    fieldsContainer: {
        // Spacer that allow the label to not be cut (outlined label will overflow the container)
        paddingTop: '5px',
        // this rule will remove the spacing in the last field
        // this because of the "scrolling" footer that requires some padding
        // if the last field has this padding too the result will be doubling the space
        '& > *:last-child': {
            paddingBottom: theme.spacing(0),
        },
    },
}));

export function FieldsContainer(props: Props) {
    const classes = useClasses();

    return <div className={classes.fieldsContainer}>{props.children}</div>;
}
