import { get, put } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';
import { CommitmentWindowCustomerResponse, WorkOrderStatus } from './workOrderDetails';

export interface WorkOrderWidgetService {
    serviceName: string;
}
export interface WorkOrderWidgetServiceLocation {
    city: string;
    countryCode: string;
    serviceLocationName: string;
    state: string;
    street1: string;
    street2: string;
    zipCode: string;
}

export interface WorkOrderWidgetData {
    amount: number;
    commitmentWindowConfirmationRequested: boolean;
    commitmentWindowCustomerResponse: CommitmentWindowCustomerResponse;
    datePerformed: string;
    endingEligibleDate: Date;
    estimatedTimeOfArrival: string;
    imageId: string | null;
    isCustomImage: boolean;
    resource: string;
    serviceLocation: WorkOrderWidgetServiceLocation;
    services: WorkOrderWidgetService[];
    startingEligibleDate: Date;
    workOrderId: string;
    status: WorkOrderStatus;
}

export interface CommitmentWindowCustomerRequest {
    workOrderId: string;
    customerResponse: CommitmentWindowCustomerResponse;
}

const baseUrl = '/api/workOrders';

export const getLastServicePerformedWidget = async (accountId: string) => {
    try {
        const { data, status } = await get<WorkOrderWidgetData | null>(
            `${baseUrl}/lastPerformedWidget?accountId=${accountId}`,
        );

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to fetch last service performed.`, status);
    } catch (e) {
        throw e;
    }
};

export const getNextScheduledWorkOrder = async (accountId: string) => {
    try {
        const { data, status } = await get<WorkOrderWidgetData | null>(
            `${baseUrl}/nextScheduledWidget?accountId=${accountId}`,
        );

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to fetch next scheduled service.`, status);
    } catch (e) {
        throw e;
    }
};

export const setCommitmentWindowCustomerResponse = async (
    workOrderId: string,
    customerResponse: CommitmentWindowCustomerResponse,
) => {
    try {
        const { data, status } = await put<CommitmentWindowCustomerResponse>(
            `${baseUrl}/${workOrderId}/commitmentWindowCustomerResponse?response=${customerResponse}`,
        );

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to update Work Order confirmation status.`, status);
    } catch (e) {
        throw e;
    }
};
