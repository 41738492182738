import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import Stack from '@mui/material/Stack';
import { useGetUILayout } from '@/atoms/UILayout';
import { useTexts } from '../useTexts';

interface Props {
    chips?: JSX.Element;
    hideFilters?: boolean;
    openDrawer?: () => void;
    searchBox?: React.ReactNode;
    singleLineOnMobile?: boolean;
    title: string;
}

export function ListHeader(props: Props) {
    const { chips, hideFilters, openDrawer, searchBox, singleLineOnMobile, title } = props;
    const layout = useGetUILayout();
    const texts = useTexts();

    return (
        <Box mb={2}>
            <Stack direction="column" spacing={2}>
                {layout == 'desktop' && (
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Box flexGrow={1}>
                            <Typography variant="h2">{title}</Typography>
                        </Box>
                        {!hideFilters && (
                            <>
                                <Box flexGrow={0}>{searchBox}</Box>
                                <Button variant="outlined" onClick={openDrawer} startIcon={<FilterListIcon />}>
                                    {texts.filter}
                                </Button>
                            </>
                        )}
                    </Stack>
                )}

                {layout == 'mobile' && (
                    <Stack
                        direction={singleLineOnMobile ? 'row' : 'column'}
                        spacing={2}
                        justifyContent={singleLineOnMobile ? 'space-between' : undefined}
                    >
                        <Box>
                            <Typography variant="h2">{title}</Typography>
                        </Box>
                        {!hideFilters && (
                            <Box display="flex">
                                <Box flexGrow={1} pr={2}>
                                    {searchBox}
                                </Box>
                                <Button variant="outlined" onClick={openDrawer} startIcon={<FilterListIcon />}>
                                    {texts.filter}
                                </Button>
                            </Box>
                        )}
                    </Stack>
                )}

                {!!chips && chips}
            </Stack>
        </Box>
    );
}
