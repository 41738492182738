import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useTexts } from '../useTexts';

export function InvalidRegistrationFooter() {
    const texts = useTexts();
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => {
        navigate('/login');
    }, [navigate]);

    return (
        <Box width="100%">
            <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleOnClick}
                disabled={false}
                size="small"
                type="submit"
                data-testid="btn-signIn"
            >
                <Box ml={1}>{texts.signIn}</Box>
            </Button>
        </Box>
    );
}