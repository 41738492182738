import { HistoryItemType } from '@/api/accountHistory';
import Chip from '@mui/material/Chip';
import { useMemo } from 'react';
import { useTexts } from '../useTexts';

type Props = {
    type: HistoryItemType;
    onDelete: React.MouseEventHandler;
};

export function HistoryTypeFilterChip(props: Props) {
    const { onDelete, type } = props;
    const texts = useTexts();

    const label = useMemo(() => {
        switch (type) {
            case HistoryItemType.INVOICE:
                return texts.invoices;
            case HistoryItemType.WORK_ORDER:
                return texts.serviceVisits;
            case HistoryItemType.PAYMENT:
                return texts.payments;
        }
    }, [type, texts.invoices, texts.serviceVisits, texts.payments]);

    return <Chip size="small" color="primary" variant="outlined" label={label} onDelete={onDelete} />;
}
