import type { Option } from './types';

import { Box, FormHelperText, Radio, FormControlLabel } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useClasses = makeStyles({
    root: {
        alignItems: 'flex-start',
    },
});

interface Props<VALUE extends string> {
    option: Option<VALUE>;
}

export function RadioGroupOption<VALUE extends string>(props: Props<VALUE>) {
    const { value, disabled, label, description } = props.option;

    const classes = useClasses();

    return (
        <FormControlLabel
            control={<Radio />}
            value={value}
            disabled={disabled}
            className={classes.root}
            label={
                <>
                    <Box paddingTop="9px">{label}</Box>
                    {description && <FormHelperText>{description}</FormHelperText>}
                </>
            }
        />
    );
}
