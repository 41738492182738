import { Box, Divider } from '@mui/material';

export const PaddedDivider = () => {
    return (
        <Box my={2}>
            <Divider />
        </Box>
    );
};

PaddedDivider.displayName = 'PaddedDivider';
