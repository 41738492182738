import type { AxiosInstance } from 'axios';
import type { LoginPayload } from '@/atoms/session/loginService';
import { logout } from '@/atoms/session';
import { isTokenValid, setSession, getRefreshToken, loadState } from '@/atoms/session/store';

import axios from 'axios';

export const rootPath =
    import.meta.env.VITE_HTTP_HOST && import.meta.env.VITE_HTTP_PORT
        ? `${import.meta.env.VITE_HTTP_HOST}:${import.meta.env.VITE_HTTP_PORT}`
        : ``;
const extraPath = import.meta.env.VITE_HTTP_EXTRA_PATH ? `${import.meta.env.VITE_HTTP_EXTRA_PATH}/` : '';

// see: https://github.com/axios/axios#request-config
const axiosInstance = axios.create({
    baseURL: `${rootPath}/${extraPath}`,
    withCredentials: false,
    headers: {},
});

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const originalRequest = error.config;
        if (error.response.status != 401 || isTokenValid()) {
            return Promise.reject(error); // Non-auth server failures aren't retried
        }

        if (originalRequest.url.startsWith('/api/sessions')) {
            // Never retry sessions requests (loop)
            return Promise.reject(error);
        }

        if (originalRequest._skipRetry) {
            // Avoid infinite loop if refresh token is bad
            logout();
            return Promise.reject(error);
        }

        return new Promise(function (_resolve, reject) {
            requestNewToken()
                .then(token => {
                    originalRequest._skipRetry = true;
                    originalRequest.headers['Authorization'] = `Bearer ${token}`; // The defaults aren't reloaded, so this must be set manually
                    _resolve(axiosInstance(originalRequest));
                })
                .catch(() => {
                    logout();
                    reject(error);
                });
        });
    },
);

export const requestNewToken = async () => {
    loadState(); // Refresh local storage (the refresh token may have changed in another tab)
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
        throw new Error('No refresh token available');
    }

    const response = await post<LoginPayload>(`/api/sessions/${refreshToken}/refresh`);
    if (response.status === 200 || response.status === 201) {
        const loginResults = response.data;
        setAuth(loginResults.token);
        setSession(loginResults.token, loginResults.refreshToken, loginResults.user, loginResults.expirationInUtc);
        return loginResults.token;
    }

    throw new Error(`${response.status} - ${response.statusText}`);
};

// TODO: make it configurable. It should support multiple headers.
export const setAuth = (token: string) => {
    // see: https://tools.ietf.org/html/rfc6750
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const setTenantId = (tenantId: string) => {
    axiosInstance.defaults.headers.common['tenantId'] = tenantId;
};

export const clearAuth = () => {
    delete axiosInstance.defaults.headers.common['Authorization'];
};

export const http = () => axiosInstance;

export const get: AxiosInstance['get'] = (url, config) => {
    return axiosInstance.get(url, config);
};

export const remove: AxiosInstance['delete'] = (url, config) => {
    return axiosInstance.delete(url, config);
};
export const head: AxiosInstance['head'] = (url, config) => {
    return axiosInstance.head(url, config);
};
export const options: AxiosInstance['options'] = (url, config) => {
    return axiosInstance.options(url, config);
};
export const post: AxiosInstance['post'] = (url, data, config) => {
    return axiosInstance.post(url, data, config);
};
export const put: AxiosInstance['put'] = (url, data, config) => {
    return axiosInstance.put(url, data, config);
};
export const patch: AxiosInstance['patch'] = (url, data, config) => {
    return axiosInstance.patch(url, data, config);
};
