import type { FormError, FormField } from '@/formState';

const oldPasswordField: FormField<string> = { value: '', required: true };
const passwordField: FormField<string> = { value: '', required: true };
const confirmPasswordField: FormField<string> = { value: '', required: true };

export const formFields = {
    oldPassword: oldPasswordField,
    password: passwordField,
    confirmPassword: confirmPasswordField,
};

export type FormFields = typeof formFields;
export type FormErrors = FormError<keyof FormFields>;
