import { ServiceRequestListItemModel } from '@/api/serviceRequests';
import { CardList, ListBody, ListFooter } from '@/components/cardList';
import { useTexts } from '../useTexts';
import { YourRequestsListHeader } from './header/YourRequestsListHeader';
import { RequestCard } from './cards';
import { useListState } from './store';
import { useListActions } from './hooks';

interface Props {
    items: ServiceRequestListItemModel[];
    total: number;
}

export function YourRequestsList(props: Props) {
    const { items, total } = props;
    const { page, take } = useListState();
    const texts = useTexts();
    const { handleSetPage } = useListActions();

    return (
        <CardList
            header={<YourRequestsListHeader />}
            body={<ListBody card={RequestCard} emptyListAlertText={texts.noServiceRequestsAlert} items={items} />}
            footer={<ListFooter count={Math.ceil(total / take)} page={page + 1} onChange={handleSetPage} />}
        />
    );
}
