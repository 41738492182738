import { DefinitionListItem } from '@/components/DefinitionListItem';
import { useTexts } from '../useTexts';

interface Props {
    contactName: string;
}

export function ServiceContact(props: Props) {
    const { contactName } = props;
    const texts = useTexts();

    return <DefinitionListItem primary={texts.serviceContact} secondary={contactName} />;
}
