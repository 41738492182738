import { ServiceLocation } from '@/api/serviceLocations';
import Chip from '@mui/material/Chip';
import { useMemo } from 'react';

type Props = {
    id: string;
    onDelete: React.MouseEventHandler;
    serviceLocations: ServiceLocation[];
};

export function ServiceLocationFilterChip(props: Props) {
    const { onDelete, id, serviceLocations } = props;

    const label = useMemo(() => {
        const location = serviceLocations.find(x => x.serviceLocationId === id);

        if (!location) {
            return '';
        }

        return `${location.street1}${!!location.street2 ? `, ${location.street2}` : ''}`;
    }, [id, serviceLocations]);

    return <Chip size="small" color="primary" variant="outlined" label={label} onDelete={onDelete} />;
}
