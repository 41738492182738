import { getBusinessUnitPaymentProcessor } from '@/api/paymentProcessor';
import { useQuery } from 'react-query';

export const useFetchBusinessUnitPaymentProcessor = (businessUnitId: string) =>
    useQuery<string, Error>(
        ['paymentProcessor', businessUnitId],
        () => getBusinessUnitPaymentProcessor(businessUnitId),
        {
            staleTime: Infinity,
        },
    );
