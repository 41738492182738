import type { MutableRefObject } from 'react';
import type { Scrollbar, ScrollStatus } from 'smooth-scrollbar/interfaces';
import type { InternalProps as Props } from '../types';

import { useEffect } from 'react';

export function useOnScroll(onScroll: Props['onScroll'], scrollbarRef: MutableRefObject<Scrollbar | undefined>) {
    // update the onScroll callback
    useEffect(() => {
        const handleOnScroll = (status: ScrollStatus) => {
            onScroll?.(status, scrollbarRef.current);
        };

        const scrollbar = scrollbarRef.current;
        scrollbar?.addListener(handleOnScroll);

        return () => {
            scrollbar?.removeListener(handleOnScroll);
        };
    }, [scrollbarRef, onScroll]);
}
