import type { Scrollbar } from 'smooth-scrollbar/interfaces';
import type { ScrollIntoView } from '../types';

import { useEffect } from 'react';

export function useOnScrollIntoView(scrollIntoView: ScrollIntoView | undefined, scrollbar: Scrollbar | undefined) {
    // the scrollIntoView has not been spread in the deps because otherwise we could fall into the case described into useOnScrollTo
    // using the Scrollbars component however more expressive api are exposed via context
    useEffect(() => {
        if (!scrollbar || !scrollIntoView) return;
        const { elem, options } = scrollIntoView;
        scrollbar.scrollIntoView(elem, options);
    }, [scrollIntoView, scrollbar]);
}
