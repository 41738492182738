import { getDefaultBillingLocation } from '@/api/accounts';
import { BillingLocation } from '@/api/billingLocations';
import { useQuery } from 'react-query';

export const useFetchDefaultBillingLocation = (accountId: string) =>
    useQuery<BillingLocation, Error>(
        [accountId, 'defaultBillingLocation'],
        () => getDefaultBillingLocation(accountId),
        {
            staleTime: Infinity,
        },
    );
