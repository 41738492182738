import { useLocalizationSettings } from '@/common/hooks/useLocalizationSettings';
import { DefaultCurrencyCode } from '@/common/types/currency';
import { FormattedNumber } from 'react-intl';

interface Props {
    value: number;
}

export function Currency(props: Props) {
    const { value } = props;
    const { culture } = useLocalizationSettings();

    return (
        <FormattedNumber
            currency={DefaultCurrencyCode[culture]}
            currencyDisplay="narrowSymbol"
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            style="currency"
            value={value || 0}
        />
    );
}
