import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            ach: {
                deleteError: translate({ id: 'sections.paymentMethods.ach.deleteError' }),
                deleteSuccess: translate({ id: 'sections.paymentMethods.ach.deleteSuccess' }),
            },
            achName: translate({ id: 'sections.paymentMethods.ach.accountName' }),
            achPageDescription: translate({ id: 'sections.paymentMethods.pageDescriptionACH' }),
            addPaymentMethodBtn: translate({ id: 'sections.paymentMethods.addPaymentMethodBtn' }),
            card: {
                deleteError: translate({ id: 'sections.paymentMethods.card.deleteError' }),
                deleteSuccess: translate({ id: 'sections.paymentMethods.card.deleteSuccess' }),
            },
            cardHolder: translate({ id: 'sections.paymentMethods.card.cardHolder' }),
            cardName: translate({ id: 'sections.paymentMethods.card.cardName' }),
            creditCardPageDescription: translate({ id: 'sections.paymentMethods.pageDescriptionCreditCard' }),
            deleteConfirmation: {
                body: translate({ id: 'sections.paymentMethods.deleteConfirmation.body' }),
                title: translate({ id: 'sections.paymentMethods.deleteConfirmation.title' }),
            },
            deletePaymentAccountAutoPayTooltip: translate({
                id: 'sections.paymentMethods.deletePaymentAccountAutoPayTooltip',
            }),
            deletePaymentAccountAutoPayInstanceTooltip: translate({
                id: 'sections.paymentMethods.deletePaymentAccountAutoPayInstanceTooltip',
            }),
            expired: translate({ id: 'sections.paymentMethods.card.expired' }),
            expires: translate({ id: 'sections.paymentMethods.card.expires' }),
            locationHasErrors: translate({ id: 'sections.paymentMethods.locationHasErrors' }),
            moreInfo: translate({ id: 'sections.paymentMethods.moreInfo' }),
            noPaymentMethodsAlert: translate({ id: 'sections.paymentMethods.noPaymentMethodsAlert' }),
            noPaymentMethodsAlertACH: translate({ id: 'sections.paymentMethods.noPaymentMethodsAlertACH' }),
            noPaymentMethodsAlertCreditCard: translate({
                id: 'sections.paymentMethods.noPaymentMethodsAlertCreditCard',
            }),
            notImplementedYet: translate({ id: 'global.notImplementedYet' }),
            pageDescription: translate({ id: 'sections.paymentMethods.pageDescription' }),
            pageTitle: translate({ id: 'sections.paymentMethods.pageTitle' }),
        };
    }, [translate]);
}
