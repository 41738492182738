import { InvoiceDetail } from '@/api/invoices';
import { DefinitionListItem } from '@/components/DefinitionListItem';
import { useMemo } from 'react';
import { useTexts } from '../../useTexts';

interface Props {
    invoiceDetails: InvoiceDetail;
}

export function AssociatedWorkOrders(props: Props) {
    const texts = useTexts();

    const {
        invoiceDetails: { workOrders },
    } = props;

    const associatedWorkOrders = useMemo(() => {
        if (!workOrders.length) {
            return texts.none;
        }
        const formattedNumbers = workOrders.map(workOrder => `#${workOrder.number}`);
        return formattedNumbers.join(', ');
    }, [workOrders, texts.none]);

    return <DefinitionListItem primary={texts.associatedWorkOrders} secondary={associatedWorkOrders} />;
}
