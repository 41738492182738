import type { FormField, FormError, FormValidator, FormFieldMutation, FormApi } from '../types';

/**
 * Field level API.
 *
 * Set of API exposed to simplify actions on a single Form Field.
 */
export type FormFieldApi<
    FIELD_NAME extends keyof FIELDS,
    FIELDS extends Record<string, FormField>,
    ERROR extends FormError<keyof FIELDS>,
> = {
    /**
     * @returns the Field controlled by the Field API instance
     */
    getField: () => Readonly<FIELDS[FIELD_NAME]>;

    /**
     * Change the value of the Field.
     *
     * @param value The new Field value.
     */
    change: (value: FIELDS[FIELD_NAME]['value']) => void;

    /**
     * Change the value of the Field.
     *
     * @param force Optional flag used to run the validation also when it shouldn't be necessary.
     * @returns A Promise resolving when all validations are complete.
     */
    validate: (force?: boolean) => Promise<void>;

    /**
     * Set the `required` state for the Field.
     *
     * @param value The new `required` state.
     */
    setRequired: (value: boolean) => void;

    /**
     * Set the `visible` state for the Field.
     *
     * @param value The new `visible` state.
     */
    setVisible: (value: boolean) => void;

    /**
     * Set the `disabled` state for the Field.
     *
     * @param value The new `disabled` state.
     */
    setDisabled: (value: boolean) => void;

    /**
     * Clear all errors for the Field.
     */
    clearValidation: () => void;

    /**
     * Register a new Validation for the Field.
     *
     * @param id Unique identifier of the validation.
     * @param validator Validator function.
     */
    addValidation: (id: string, validator: FormValidator<FIELDS, ERROR>) => void;

    /**
     * Unregister a Validator function.
     *
     * @param id Unique identifier of the validation.
     * @returns A boolean value indicating if the Validation was registered and it's been removed (`true`) or if it was not registered (`false`)
     */
    removeValidation: (id: string) => void;

    /**
     * Register a Mutation for the Field.
     *
     * @param mutation The Mutation function.
     */
    addMutation: (mutation: FormFieldMutation<FIELDS, ERROR>) => void;

    /**
     * Unregister the Field Mutation.
     *
     * @returns A boolean value indicating if the Mutation was registered and it's been removed (`true`) or if it was not registered (`false`)
     */
    removeMutation: () => boolean;
};

/**
 *
 * @param formApi Full Form API
 * @param fieldName The target Field name.
 * @returns A Form Field API connected to the provided Field.
 */
export function createFieldApi<
    FIELD_NAME extends keyof FIELDS,
    FIELDS extends Record<string, FormField>,
    ERROR extends FormError<keyof FIELDS>,
>(formApi: Readonly<FormApi<FIELDS, ERROR>>, fieldName: FIELD_NAME): Readonly<FormFieldApi<FIELD_NAME, FIELDS, ERROR>> {
    return {
        getField: () => {
            return formApi.getField(fieldName);
        },

        change: (value: FIELDS[FIELD_NAME]['value']) => {
            formApi.change(fieldName, value);
        },

        validate: (force?: boolean) => {
            const targetField = formApi.getField(fieldName);

            if (force || targetField.status === 'indeterminate') {
                return formApi.validate(fieldName).then();
            }

            return Promise.resolve();
        },

        setDisabled: (value: boolean) => {
            formApi.setFieldDisabledState(fieldName, value);
        },

        setVisible: (value: boolean) => {
            formApi.setFieldVisibleState(fieldName, value);
        },

        setRequired: (value: boolean) => {
            formApi.setFieldRequiredState(fieldName, value);
        },

        clearValidation: () => {
            formApi.clearValidation(fieldName);
        },

        addValidation: (id: string, validator: FormValidator<FIELDS, ERROR>) => {
            formApi.addValidation(id, validator, fieldName);
        },

        removeValidation: (id: string) => {
            formApi.removeValidation(id);
        },

        addMutation: (mutation: FormFieldMutation<FIELDS, ERROR>) => {
            formApi.addFieldMutation(fieldName, mutation);
        },

        removeMutation: () => {
            return formApi.removeFieldMutation(fieldName);
        },
    };
}
