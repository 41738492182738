// --------------------------------------------------------
// Log helper
// --------------------------------------------------------

const prefixStyles = [
    'color: lightCoral',
    'font-size: 12px',
    'font-weight: bolder',
    'padding-left: 10px',
    'padding-right: 5px',
].join(';');

const contextStyles = ['color: goldenRod', 'font-size: 12px', 'font-weight: bolder', 'padding-right: 10px'].join(';');

function log(main: string, value: string, ...args: any[]) {
    console.log(`%c[${main}] %c${value}`, prefixStyles, contextStyles, ...args);
}

// --------------------------------------------------------
// retrieve system info
// --------------------------------------------------------

export type EnvironmentInfo = {
    mobile: boolean;
    touchEnabled: boolean;
    width: number;
    height: number;
    pixelRatio: number;
};

export function getHostEnvironmentInfo(): EnvironmentInfo {
    // log('NAVIGATOR', navigator.userAgent);
    const mobile = !!navigator.userAgent.match(/Mobi/gm);
    const touchEnabled = navigator.maxTouchPoints > 0;

    return {
        mobile,
        touchEnabled,
        width: window.screen.availWidth,
        height: window.screen.availHeight,
        pixelRatio: window.devicePixelRatio,
    };
}

// --------------------------------------------------------
// check and subscribe to the device orientation
// --------------------------------------------------------

export type DeviceOrientation = 'portrait' | 'landscape';
type OnChangeOrientationEventHandler = (orientation: DeviceOrientation) => void;

export function subscribeToDeviceOrientation(listener: OnChangeOrientationEventHandler) {
    const query = '(orientation: portrait)';
    const mq = window.matchMedia(query);

    const onChange = (e: MediaQueryListEvent) => {
        listener(e.matches ? 'portrait' : 'landscape');
    };

    mq.addEventListener('change', onChange);

    return () => {
        mq.removeEventListener('change', onChange);
    };
}

export function getDeviceOrientation(): DeviceOrientation {
    const query = '(orientation: portrait)';
    const mq = window.matchMedia(query);

    return mq.matches ? 'portrait' : 'landscape';
}

// --------------------------------------------------------
// utility to print system info in console
// --------------------------------------------------------

export function printHostEnvironmentInfo() {
    log('Device orientation', getDeviceOrientation());

    const info = getHostEnvironmentInfo();
    log('Device Info', JSON.stringify(info, null, 2));
}
