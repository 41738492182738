import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            heading: translate({ id: `sections.changePassword.heading` }),
            drawerButtonLabel: translate({ id: 'sections.changePassword.drawerButtonLabel' }),
            currentPassword: translate({ id: `sections.changePassword.currentPassword` }),
            passwordFieldLabel: translate({ id: 'sections.register.passwordFieldLabel' }),
            confirmPasswordFieldLabel: translate({ id: 'sections.register.confirmPasswordFieldLabel' }),
            newPassword: translate({ id: `sections.changePassword.newPassword` }),
            success: translate({ id: `sections.changePassword.success` }),
            error: translate({ id: `sections.changePassword.error` }),
            sessionClearWarning: translate({ id: `sections.changePassword.sessionClearWarning` }),
        };
    }, [translate]);
}
