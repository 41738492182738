import { getAccount } from '@/atoms/session/store';
import { useQueryPaymentAccounts } from '@/common/hooks/query/useQueryPaymentAccounts';
import { AgreementDetails } from '@/common/types/agreements';
import { PaymentAccount } from '@/common/types/payments';
import { Alert, Box, Button, Link, Stack, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';

import { PaymentMethodSelect } from '../../PayInvoices/drawer/PaymentMethodSelect';
import { setPaymentAccount, setWizardStep, useAgreementDrawerState, WizardStep } from './store';
import { useTexts } from './useTexts';
import { useRemoveAutoPayActions } from './hooks/useRemoveAutoPayActions';

interface Props {
    id: string;
    details: AgreementDetails;
}

export function ManageAutopay(props: Props) {
    const { id, details } = props;
    const { paymentAccount } = useAgreementDrawerState();
    const account = getAccount();
    const { data: paymentAccounts } = useQueryPaymentAccounts(account?.accountId || '');
    const texts = useTexts();
    const { handleRemoveAutopay } = useRemoveAutoPayActions(id);

    useEffect(() => {
        if (!details.autoPaymentAccounts.length || !details.autoPaymentAccounts[0]) {
            return;
        }
        const selectedPaymentAccount = paymentAccounts?.find(
            x => x.processorPaymentAccountId === details.autoPaymentAccounts[0].processorPaymentAccountId,
        );
        setPaymentAccount(selectedPaymentAccount || null);
    }, [id, details, paymentAccounts]);

    const handleOnSelect = useCallback((selected: PaymentAccount) => {
        setPaymentAccount(selected);
    }, []);

    const goToAddPaymentMethodStep = useCallback(() => {
        setWizardStep(WizardStep.AddPaymentMethod);
    }, []);

    return (
        <Stack direction="column" spacing={2}>
            {details.hasAutoPaymentInstance && <Alert severity="info">{texts.hasAutoPayInstance}</Alert>}
            <Typography gutterBottom>
                {texts.useSavedPaymentMethod}{' '}
                <Link onClick={goToAddPaymentMethodStep}>{texts.addNewPaymentMethod}</Link>
            </Typography>
            <PaymentMethodSelect onSelect={handleOnSelect} selected={paymentAccount} />
            {!!details.autoPaymentAccounts.length && !!details.autoPaymentAccounts[0] && (
                <Box>
                    <Button variant="outlined" onClick={handleRemoveAutopay}>
                        {texts.disableAutopay}
                    </Button>
                </Box>
            )}
        </Stack>
    );
}
