import { PageWrapper } from '@/components/PageWrapper';
import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { Box, Grid, Typography } from '@mui/material';
import { useAccountId } from '@/atoms/session';
import { useEffect } from 'react';
import { Unknown } from '../../sections/Unknown/Unknown';
import { AddNote } from './sections/AddNote/AddNote';
import { AddServices } from './sections/AddServices/AddServices';
import { SelectLocation } from './sections/SelectLocation/SelectLocation';
import { SetServiceDetails } from './sections/SetServiceDetails/SetServiceDetails';
import { YourRequest } from './sections/YourRequest/YourRequest';
import { useTexts } from './useTexts';
import { resetRequestServiceState } from './store';

export const RequestService = () => {
    const texts = useTexts();
    const featureEnabled = useFeatureEnabled('enableRequestNewService');
    const accountId = useAccountId();

    useEffect(() => {
        resetRequestServiceState();
    }, [accountId]);

    return (
        <PageWrapper fullWidth>
            {featureEnabled && (
                <>
                    <Box mb={2}>
                        <Typography variant="h2" gutterBottom>
                            {texts.pageHeader}
                        </Typography>
                        <Typography>{texts.pageDescription}</Typography>
                    </Box>
                    <Grid container direction="row" spacing={2}>
                        <Grid item container direction="column" spacing={2} xs={12} md={8}>
                            <Grid item>
                                <SelectLocation />
                            </Grid>
                            <Grid item>
                                <AddServices />
                            </Grid>
                            <Grid item>
                                <SetServiceDetails />
                            </Grid>
                            <Grid item>
                                <AddNote />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <YourRequest />
                        </Grid>
                    </Grid>
                </>
            )}
            {!featureEnabled && <Unknown />}
        </PageWrapper>
    );
};

RequestService.displayName = 'RequestService';
