import type { FormField, FormError, FormState } from '../types';
import { ReactElement, useContext, createContext } from 'react';

import { createEmptyFormState } from './createEmptyFormState';

const formContext = createContext<FormState<Record<string, FormField>, FormError<string>>>(createEmptyFormState());

type FormRootProps<FIELDS extends Record<string, FormField>, ERROR extends FormError<keyof FIELDS>> = {
    children: ReactElement;
    formState: FormState<FIELDS, ERROR>;
};

/**
 * Root component exposing a `React Context` to all its descendant.
 *
 * Required to be able to use the Form React hooks.
 */
export function FormRoot<FIELDS extends Record<string, FormField>, ERROR extends FormError<keyof FIELDS>>(
    props: FormRootProps<FIELDS, ERROR>,
) {
    const { children, formState } = props;

    // TODO: FIX GENERIC cast issue
    return <formContext.Provider value={formState as any}>{children}</formContext.Provider>;
}

/**
 * @private
 */
export function useFormInternalContext<
    FIELDS extends Record<string, FormField>,
    ERROR extends FormError<keyof FIELDS>,
>() {
    // TODO: FIX GENERIC cast issue
    const ctx = useContext(formContext) as any as FormState<FIELDS, ERROR>;

    if (process.env.NODE_ENV === 'development' && ctx.store.id === '_____defaultEmptyState_____') {
        throw new Error(`You cannot use a FormState Context without mounting a FormRoot component as an ancestor.`);
    }

    return ctx;
}
