import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { SpacingProps } from '@mui/system';

interface Props extends SpacingProps {
    children?: React.ReactNode;
    className?: string;
}

export const Section = (props: Props) => {
    const { children, className, ...other } = props;

    return (
        <Paper elevation={2}>
            <Box p={other.p ?? 2} className={className} {...other}>
                {children}
            </Box>
        </Paper>
    );
};

Section.displayName = 'Section';
