import { debounce } from 'lodash';
import { useState, useMemo, useEffect } from 'react';

export function useDebounce<T>(value: T, delay: number = 500) {
    const [debounced, setDebounced] = useState<T>(value);

    const update = useMemo(() => debounce((v: T) => setDebounced(v), delay), [delay]);

    useEffect(() => {
        update(value);

        return () => {
            update.cancel();
        };
    }, [update, value]);

    return debounced;
}
