import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            dateAndTimeRequested: translate({ id: 'sections.yourRequests.dateAndTimeRequested' }),
            declined: translate({ id: 'sections.yourRequests.declined' }),
            drawerTitle: translate({ id: 'sections.yourRequests.drawer.title' }),
            noServiceRequestsAlert: translate({ id: 'sections.yourRequests.noServiceRequestsAlert' }),
            pageTitle: translate({ id: 'sections.yourRequests.pageTitle' }),
            pending: translate({ id: 'sections.yourRequests.pending' }),
            processed: translate({ id: 'sections.yourRequests.processed' }),
        };
    }, [translate]);
}
