import { useMemo } from 'react';
import { useTranslate } from '@/intl';
import { Frequencies } from '@/common/types/schedulingPlan';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            days: translate({ id: 'global.pluralFrequencies.days' }),
            months: translate({ id: 'global.pluralFrequencies.months' }),
            multiDaysPerWeek: translate({ id: 'global.pluralFrequencies.multiDaysPerWeek' }),
            onDemand: translate({ id: 'global.pluralFrequencies.onDemand' }),
            oneTime: translate({ id: 'global.pluralFrequencies.oneTime' }),
            quarterly: translate({ id: 'global.pluralFrequencies.quarterly' }),
            weeks: translate({ id: 'global.pluralFrequencies.weeks' }),
        };
    }, [translate]);
}

export function usePluralFrequencies() {
    const texts = useTexts();
    return useMemo(() => {
        return {
            [Frequencies.Daily]: texts.days,
            [Frequencies.Monthly]: texts.months,
            [Frequencies.MultiDaysPerWeek]: texts.multiDaysPerWeek,
            [Frequencies.OnDemand]: texts.onDemand,
            [Frequencies.OneTime]: texts.oneTime,
            [Frequencies.Quarterly]: texts.quarterly,
            [Frequencies.Weekly]: texts.weeks,
        };
    }, [texts]);
}
