import { InvoiceDetail } from '@/api/invoices';
import { DefinitionListItem } from '@/components/DefinitionListItem';
import { useTexts } from '../../useTexts';

interface Props {
    invoiceDetails: InvoiceDetail;
}

export function ServicesPerformed(props: Props) {
    const texts = useTexts();

    const {
        invoiceDetails: { workOrders },
    } = props;

    return (
        <DefinitionListItem
            primary={texts.servicesPerformed}
            secondary={
                <>
                    {workOrders.map((w, i) => (
                        <div key={i}>
                            {w.services.map((s, j) => (
                                <div key={j}>
                                    {s.serviceName} ({texts.workOrder} #{w.number})
                                </div>
                            ))}
                        </div>
                    ))}
                </>
            }
        />
    );
}
