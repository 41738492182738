import { Box, Typography } from '@mui/material';
import { WorkOrderWidgetService } from '@/api/workOrders';

interface Props {
    services: WorkOrderWidgetService[];
}

export const ServicesList = (props: Props) => {
    const { services } = props;

    return (
        <>
            {services.length > 0 && (
                <Box>
                    {services.map((service, i) => (
                        <Typography key={i}>
                            {i + 1}. {service.serviceName}
                        </Typography>
                    ))}
                </Box>
            )}

            {services.length == 0 && <Typography>None</Typography>}
        </>
    );
};

ServicesList.displayName = 'ServicesList';
