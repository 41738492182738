export enum Frequencies {
    Daily = 'Daily',
    Monthly = 'Monthly',
    MultiDaysPerWeek = 'MultiDaysPerWeek',
    OnDemand = 'OnDemand',
    OneTime = 'OneTime',
    Quarterly = 'Quarterly',
    Weekly = 'Weekly',
}

export const humanizedIntervalUnits = {
    [Frequencies.Daily]: 'Daily',
    [Frequencies.Monthly]: 'Monthly',
    [Frequencies.MultiDaysPerWeek]: 'Multiple Days Per Week',
    [Frequencies.OnDemand]: 'On Demand',
    [Frequencies.OneTime]: 'One Time',
    [Frequencies.Quarterly]: 'Quarterly',
    [Frequencies.Weekly]: 'Weekly',
};
