import { CircularProgress, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Section } from '@/components/Section';
import { SectionHeader } from '@/components/SectionHeader';
import { faHistory } from '@fortawesome/pro-regular-svg-icons';
import { useData } from './useData';
import { useTexts } from './useTexts';
import { Content } from './Content';
interface Props {
    accountId: string;
}

export const YourLastService = (props: Props) => {
    const { accountId } = props;
    const [data] = useData(accountId);
    const texts = useTexts();

    return (
        <Section>
            <SectionHeader icon={<FontAwesomeIcon icon={faHistory} />} title={texts.header} />
            {data.status == 'error' && <Typography>{texts.error}</Typography>}
            {data.status == 'loading' && <CircularProgress />}
            {data.status == 'success' && <Content data={data.payload} />}
        </Section>
    );
};

YourLastService.displayName = 'YourLastService';
