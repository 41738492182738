import { AgreementStatus } from '@/common/types/agreements';
import { useCallback } from 'react';
import { clearServiceLocations, resetFilters, setPage, setServiceLocations, setStatus, setTake } from '../store';

export function useListActions() {
    const handleClearServiceLocations = useCallback(() => {
        setPage(0);
        clearServiceLocations();
    }, []);

    const handleDeleteServiceLocationFilter = useCallback(
        (id: string, serviceLocations: string[]) => (_e: React.MouseEvent<HTMLElement>) => {
            setPage(0);
            setServiceLocations(serviceLocations.filter(x => x !== id));
        },
        [],
    );

    const handleResetFilters = useCallback(() => {
        setPage(0);
        resetFilters();
    }, []);

    const handleSetPage = useCallback((_e, page: number) => {
        setPage(page - 1);
    }, []);

    const handleSetServiceLocations = useCallback((locations: string[]) => {
        setPage(0);
        setServiceLocations(locations);
    }, []);

    const handleSetTake = useCallback((take: number) => {
        setTake(take);
    }, []);

    const handleSetStatus = useCallback((status: AgreementStatus | null) => {
        setPage(0);
        setStatus(status);
    }, []);

    const handleDeleteStatus = useCallback(() => {
        setPage(0);
        setStatus(null);
    }, []);

    return {
        handleClearServiceLocations,
        handleDeleteServiceLocationFilter,
        handleResetFilters,
        handleSetPage,
        handleSetServiceLocations,
        handleSetTake,
        handleSetStatus,
        handleDeleteStatus,
    } as const;
}
