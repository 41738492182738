import { Alert, Stack } from '@mui/material';
import { useEffect } from 'react';

import { useRequestServiceState } from '../../../store';
import { resetServiceDetails } from '../store';
import { ServiceDetailForm } from './Form/ServiceDetailForm';
import { useTexts } from './useTexts';

export const ServiceDetailList = () => {
    const texts = useTexts();
    const { services, serviceDetails } = useRequestServiceState();

    useEffect(() => {
        resetServiceDetails(serviceDetails);
    }, [serviceDetails]);

    return (
        <>
            {services.length == 0 && <Alert severity="info">{texts.noServices}</Alert>}

            {services.length > 0 && (
                <Stack direction="column" spacing={2}>
                    {services.map((s, i) => (
                        <ServiceDetailForm key={i} service={s} />
                    ))}
                </Stack>
            )}
        </>
    );
};
