export enum InvoiceMode {
    NoInvoice = 'NoInvoice',
    NotSet = 'NotSet',
    OnCompletion = 'OnCompletion',
    OnDemand = 'OnDemand',
    Recurring = 'Recurring',
}

export const humanizedInvoiceModes = {
    [InvoiceMode.NoInvoice]: 'Do Not Invoice',
    [InvoiceMode.NotSet]: 'Not Set',
    [InvoiceMode.OnCompletion]: 'On Completion',
    [InvoiceMode.OnDemand]: 'On Demand',
    [InvoiceMode.Recurring]: 'Recurring',
};
