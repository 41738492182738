import { ServiceRequestDetails } from '@/api/serviceRequests';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Fragment } from 'react';
import { StatusBadge } from '../components/StatusBadge';
import { Issue } from './listItems/Issue';
import { Reservation } from './listItems/Reservation';
import { ServiceAddress } from './listItems/ServiceAddress';
import { ServiceContact } from './listItems/ServiceContact';

interface Props {
    details: ServiceRequestDetails;
}

export function DetailListItems(props: Props) {
    const { details } = props;
    const {
        issue,
        status,
        reservations,
        serviceLocationContactName,
        serviceLocationAddress,
        serviceLocationCity,
        serviceLocationCountry,
        serviceLocationPostalCode,
        serviceLocationStateAbbreviation,
    } = details;

    return (
        <Stack direction="column" spacing={2}>
            <Box display="flex" alignItems="flex-start">
                <Box flexGrow={1}>
                    <Reservation reservation={reservations[0]} />
                </Box>
                <StatusBadge status={status} />
            </Box>

            {reservations?.map((r, i) => (
                <Fragment key={i}>{!!i && <Reservation reservation={r} />}</Fragment>
            ))}

            <ServiceContact contactName={serviceLocationContactName} />

            <ServiceAddress
                city={serviceLocationCity}
                country={serviceLocationCountry}
                postalCode={serviceLocationPostalCode}
                state={serviceLocationStateAbbreviation}
                street={serviceLocationAddress}
            />

            {issue && <Issue issue={issue} />}
        </Stack>
    );
}
