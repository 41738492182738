import { useCallback, useEffect } from 'react';
import { SideDrawerForm } from '@/components/form/SideDrawerForm';
import { setServices, useRequestServiceState } from '../../../store';
import { setPage, setSelectedServices, useAddServicesState } from '../store';
import { useTexts } from './useTexts';
import { DrawerContent } from './DrawerContent';
import { Pagination } from './Pagination';

interface Props {
    onClose: () => void;
    open: boolean;
}

export function Drawer(props: Props) {
    const { onClose, open } = props;
    const texts = useTexts();
    const { selected: selectedServices } = useAddServicesState();
    const request = useRequestServiceState();

    const onSave = useCallback(() => {
        setServices(selectedServices);
        onClose();
    }, [onClose, selectedServices]);

    useEffect(() => {
        if (open) {
            setPage(0);
            setSelectedServices(request.services);
        }
    }, [open, request.services]);

    return (
        <SideDrawerForm
            disableSave={selectedServices.length == 0}
            footerSecondary={<Pagination />}
            isOpen={open}
            onClose={onClose}
            onSave={onSave}
            title={texts.header}
        >
            <DrawerContent />
        </SideDrawerForm>
    );
}
