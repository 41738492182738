import { useBusinessUnitDetails } from '@/common/hooks/useBusinessUnitDetails';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useFetchLogoUrl } from '../../hooks/query/useFetchLogoUrl';
import { useCompanyLogoExists } from './useCompanyLogoExists';

const useStyles = makeStyles({
    logoImg: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
    },
});

export function LogoDisplay() {
    const classes = useStyles();
    const businessUnitDetails = useBusinessUnitDetails();
    const companyLogoExists = useCompanyLogoExists();
    const { status, data: logoUrl } = useFetchLogoUrl();

    return (
        <>
            {!companyLogoExists && !!businessUnitDetails && (
                <Typography variant="h4">{businessUnitDetails.name}</Typography>
            )}

            {companyLogoExists && status == 'success' && <img src={logoUrl} className={classes.logoImg}></img>}
        </>
    );
}
