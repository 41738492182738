import Box from '@mui/material/Box';
import { useCallback } from 'react';
import { DatePicker } from '@/components/DatePicker';
import { useListState } from '../store';
import { useListActions } from '../hooks';
import { useTexts } from './useTexts';

export function EndDate() {
    const { startDate, endDate } = useListState();
    const texts = useTexts();
    const { handleSetEndDate } = useListActions();

    const shouldDisableDate = useCallback(
        (date: Date | null, _keyboardInputValue?: string | undefined) => {
            if (!date) {
                return false;
            }

            return !!startDate && date < startDate;
        },
        [startDate],
    );

    return (
        <Box ml={2} flexGrow={1}>
            <DatePicker
                label={texts.endDateLabel}
                onChange={handleSetEndDate}
                value={endDate}
                shouldDisableDate={shouldDisableDate}
                disableFuture
            />
        </Box>
    );
}
