import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            cancel: translate({ id: 'global.cancel' }),
            error: translate({ id: 'sections.requestService.selectLocation.drawer.error' }),
            header: translate({ id: 'sections.requestService.selectLocation.drawer.header' }),
            locationRequired: translate({ id: 'sections.requestService.selectLocation.drawer.locationRequired' }),
            locationHasErrors: translate({ id: 'sections.requestService.selectLocation.drawer.locationHasErrors' }),
            locations: translate({ id: 'sections.requestService.selectLocation.drawer.locations' }),
            noLocations: translate({ id: 'sections.requestService.selectLocation.drawer.noLocations' }),
            noLocationsMatchQuery: translate({
                id: 'sections.requestService.selectLocation.drawer.noLocationsMatchQuery',
            }),
            save: translate({ id: 'global.save' }),
        };
    }, [translate]);
}
