import type { FormError, FormField } from '@/formState';

const passwordField: FormField<string> = { value: '', required: true };
const reEnterPasswordField: FormField<string> = { value: '', required: true };

export const formFields = {
    password: passwordField,
    reEnterPassword: reEnterPasswordField,
};

export type FormFields = typeof formFields;
export type FormErrors = FormError<keyof FormFields>;
