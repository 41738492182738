import * as _ from 'lodash';
import { PropertyTrait, ServiceLocation } from '@/api/serviceLocations';
import { ServiceOffering } from '@/api/services';
import { proxy, useSnapshot } from 'valtio';
import { ServiceDetails } from './sections/SetServiceDetails/store';

// Store state
export interface RequestServiceState {
    note: string;
    serviceDetails: ServiceDetails[];
    serviceLocation: ServiceLocation | null;
    services: ServiceOffering[];
    serviceLocationId: string;
    schedulingPlanId: string;
}

export const initialState: RequestServiceState = {
    note: '',
    serviceDetails: [],
    serviceLocation: null,
    services: [],
    serviceLocationId: '',
    schedulingPlanId: '',
};

// Valtio store initialization
const state = proxy<RequestServiceState>(initialState);

// Setter functions
export function resetRequestServiceState() {
    state.note = '';
    state.schedulingPlanId = '';
    state.serviceDetails = [];
    state.serviceLocation = null;
    state.serviceLocationId = '';
    state.services = [];
}

export function setNote(note: string) {
    state.note = note;
}

export function setServices(services: ServiceOffering[]) {
    state.services = services.map(service => {
        const existingService = state.services.find(s => s.serviceId == service.serviceId);

        if (existingService) {
            return existingService;
        } else {
            return { propertyTraits: [], ...service };
        }
    });

    state.serviceDetails = services.map(service => {
        const existingDetails = state.serviceDetails.find(detail => detail.serviceId == service.serviceId);

        if (existingDetails) {
            return existingDetails;
        } else {
            return {
                date: null,
                propertyTraits: [],
                schedulingPlan: null,
                serviceId: service.serviceId,
                timeFrame: null,
                schedulingPlanId: service.schedulingPlanId,
            };
        }
    });
}

export function setPropertyTraits(propertyTraits: PropertyTrait[], serviceId: string) {
    const service = state.services.find(s => s.serviceId == serviceId);

    if (service) {
        service.servicePropertyTraits = [...propertyTraits];
    }
}

export function setServiceDetails(serviceDetails: ServiceDetails[]) {
    state.serviceDetails = serviceDetails;
}

export function addServiceOffering(service: ServiceOffering) {
    state.services.push(service);
}

export function removeServiceOffering(service: ServiceOffering) {
    state.services = _.cloneDeep(state.services.filter(s => s.serviceId != service.serviceId));
    state.serviceDetails = state.serviceDetails.filter(s => s.serviceId != service.serviceId);
}

export function setServiceLocation(serviceLocation: ServiceLocation | null) {
    state.serviceLocation = serviceLocation;
    state.serviceDetails = [];
    state.services = [];
    state.serviceLocationId = serviceLocation?.serviceLocationId || '';
}

//Hooks
export function useRequestServiceState() {
    return useSnapshot(state);
}

export function useGetNote() {
    return useSnapshot(state).note;
}

export function useGetServices() {
    return useSnapshot(state).services;
}

export function useGetServiceDetails() {
    return useSnapshot(state).services;
}

export function useGetServiceById(serviceId: string) {
    return useSnapshot(state).services.find(s => s.serviceId == serviceId);
}

export function useGetServiceLocation() {
    return useSnapshot(state).serviceLocation;
}

export function useLocationRequirementComplete() {
    return useSnapshot(state).serviceLocation != null;
}

export function useServiceRequirementComplete() {
    return useSnapshot(state).services.length > 0;
}
