import { useMemo } from 'react';
import { useTexts } from './useTexts';

export const useFormattedWorkOrderNumber = (
    number: string,
    projectSequence: number | null,
    subNumber: number | null,
) => {
    const texts = useTexts();
    const formattedNumber = useMemo(() => {
        let str = `${texts.workOrder} #${number}`;

        if (!!projectSequence) {
            str += `-${projectSequence.toString().padStart(2, '0')}`;
        }
        if (!!subNumber) {
            str += `-${subNumber.toString().padStart(2, '0')}`;
        }

        return str;
    }, [number, projectSequence, subNumber, texts.workOrder]);
    return formattedNumber;
};
