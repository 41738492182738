import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTexts } from './useTexts';

const useStyles = makeStyles(theme => ({
    actions: {
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
}));

interface Props {
    message: string;
    onConfirm: () => void;
    onDismiss: () => void;
    open: boolean;
    title: string;
    width?: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
}

export function ConfirmationDialog(props: Props) {
    const { open, title, message, onConfirm, onDismiss, width } = props;
    const classes = useStyles();
    const texts = useTexts();

    return (
        <Dialog open={open} maxWidth={width ? width : 'sm'} onClose={onDismiss}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography>{message}</Typography>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button onClick={onDismiss} data-testid="btn-no" autoFocus>
                    {texts.no}
                </Button>
                <Button onClick={onConfirm} data-testid="btn-yes">
                    {texts.yes}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
