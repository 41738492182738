import { ServiceOffering } from '@/api/services';
import { RequestServiceState } from '../../store';
import { ServiceDetails } from '../SetServiceDetails/store';

const arePropertyTraitsSet = (service: ServiceOffering, detail: ServiceDetails) => {
    let isValid = false;
    const propertyTraits = service.servicePropertyTraits || [];

    if (propertyTraits.length) {
        isValid = propertyTraits.every(p => {
            const trait = detail.propertyTraits.find(dp => dp.propertyTraitId == p.propertyTraitId);
            return !!trait && !!trait.serviceLocationPropertyTraitId && !!trait.amount;
        });
    } else {
        isValid = true;
    }

    return isValid;
};

const isSchedulingPlanSet = (service: ServiceOffering, detail: ServiceDetails) => {
    return service.serviceSchedulingPlans?.length > 0 ? !!detail.schedulingPlan?.schedulingPlanId : true;
};

export const areServiceDetailsSet = (request: RequestServiceState, details: ServiceDetails[]) => {
    return request.services.every(s => {
        const detail = details.find(d => d.serviceId == s.serviceId);

        if (!!detail) {
            const dateIsSet = !!detail.date;
            const dateHasNotPassed =
                !!detail.date && new Date().setHours(0, 0, 0, 0) - detail.date.setHours(0, 0, 0, 0) <= 0;
            const propertyTraitsAreSet = arePropertyTraitsSet(s, detail);
            const schedulingPlanIsSet = isSchedulingPlanSet(s, detail);
            const timeFrameIsSet = !!detail.timeFrame;
            return dateIsSet && dateHasNotPassed && propertyTraitsAreSet && schedulingPlanIsSet && timeFrameIsSet;
        } else {
            return false;
        }
    });
};
