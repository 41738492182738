import { Box, Stack, Typography } from '@mui/material';
import { AgreementService } from '@/common/types/agreements';
import { Service } from './Service';

interface Props {
    services: AgreementService[];
}

export function Services(props: Props) {
    const { services } = props;

    return (
        <Stack direction="column" spacing={1}>
            {services.length > 0 && (
                <Box>
                    {services.map((s, i) => (
                        <Service index={i} key={i} service={s} />
                    ))}
                </Box>
            )}

            {services.length == 0 && <Typography>None</Typography>}
        </Stack>
    );
}
