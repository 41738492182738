import { AgreementStatus } from '@/common/types/agreements';
import { CardBadge } from '@/components/cardList/CardBadge';
import { useTexts } from '../useTexts';

interface Props {
    status: AgreementStatus;
}

export function StatusBadge(props: Props) {
    const { status } = props;
    const texts = useTexts();

    switch (status) {
        case AgreementStatus.ACTIVE:
            return <CardBadge severity="success" text={texts.active} />;
        case AgreementStatus.CANCELLED:
            return <CardBadge severity="error" text={texts.cancelled} />;
        case AgreementStatus.COMPLETED:
            return <CardBadge severity="info" text={texts.completed} />;
        default:
            return null;
    }
}
