import type { EnvironmentInfo, DeviceOrientation } from '@/utils/hostEnvironmentInfo';

import { proxy, useSnapshot } from 'valtio';

import {
    getHostEnvironmentInfo,
    getDeviceOrientation,
    subscribeToDeviceOrientation,
} from '@/utils/hostEnvironmentInfo';

// ----------------------------
// Store state
// ----------------------------

type Layout = 'mobile' | 'desktop';
type UILayout = {
    env: EnvironmentInfo;
    orientation: DeviceOrientation;
    layout: Layout;
};

// ----------------------------
// Utilities
// ----------------------------

function determineLayout(env: EnvironmentInfo): Layout {
    // if is not mobile-ish just opt-in for desktop
    // return 'mobile';
    if (!env.mobile || !env.touchEnabled) return 'desktop';

    // on mobile
    const targetSideSize = env.width < env.height ? env.width : env.height;
    const sideSize = targetSideSize * env.pixelRatio;

    // iPhone smaller side is 1125px when you consider device pixels ratio
    // iPad smaller side is 1536px when you consider device pixels ratio
    return sideSize >= 1250 ? 'desktop' : 'mobile';
}

// ----------------------------
// Valtio store initialization
// ----------------------------

function getInitialState(): UILayout {
    const env = getHostEnvironmentInfo();
    const orientation = getDeviceOrientation();
    const layout = determineLayout(env);

    console.log('-------');
    console.log(`Active Layout: ${layout}`);
    console.log('-------');

    return {
        env,
        layout,
        orientation,
    };
}

const state = proxy<UILayout>(getInitialState());

// ----------------------------
// Monitor the device orientation
// ----------------------------

subscribeToDeviceOrientation(orientation => {
    state.orientation = orientation;
});

// ----------------------------
// selector functions
// ----------------------------

export function getUILayout() {
    return state.layout;
}

export function getUILayoutInfo() {
    return state.env;
}

export function getUILayoutOrientation() {
    return state.env;
}

// ----------------------------
// React hooks
// ----------------------------

export function useGetUILayout() {
    return useSnapshot(state).layout;
}

export function useGetUILayoutInfo() {
    return useSnapshot(state).env;
}

export function useGetUILayoutOrientation() {
    return useSnapshot(state).orientation;
}
