import { get } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';

export interface CustomerPortalSettings extends CustomerPortalFeatures {
    businessUnitId: string;
    companyLogoImageId: string;
    defaultEmailForRequests?: boolean;
    fridayAvailability?: number;
    mondayAvailability?: number;
    saturdayAvailability?: number;
    settingsId: string;
    sundayAvailability?: number;
    themingKey: string;
    thursdayAvailability?: number;
    tuesdayAvailability?: number;
    wednesdayAvailability?: number;
}

export interface CustomerPortalFeatures {
    enableBillingHistory?: boolean;
    enableMakePayments?: boolean;
    enableQuestionsAndComments?: boolean;
    enableRequestNewService?: boolean;
    enableServiceHistory?: boolean;
    enableUpcomingServices?: boolean;
    enableViewAgreements?: boolean;
    enableViewPaymentHistory?: boolean;
    enableCreditCardPayment?: boolean;
    enableACHPayment?: boolean;
    enableWorkOrderNumber?: boolean;
    enableScheduledDate?: boolean;
    enableScheduledTime?: boolean;
    enableServiceLocation?: boolean;
    enableIncludedServices?: boolean;
    enableTeamTechnicianInfo?: boolean;
    enablePriceOfServiceVisit?: boolean;
    enableLastServiceWorkOrderNumber?: boolean;
    enableLastServiceScheduledDate?: boolean;
    enableLastServiceScheduledTime?: boolean;
    enableLastServiceServiceLocation?: boolean;
    enableLastServiceIncludedServices?: boolean;
    enableLastServiceTeamTechnicianInfo?: boolean;
    enableLastServicePriceOfServiceVisit?: boolean;
    enableLastServiceServiceReport?: boolean;
}

const baseUrl = '/api/CustomerPortalSettingBusinessUnit';

export const getCustomerPortalSettings = async (businessUnitId: string) => {
    try {
        const { data, status } = await get<CustomerPortalSettings>(`${baseUrl}/${businessUnitId}`);

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get Customer Portal settings.`, status);
    } catch (e) {
        throw e;
    }
};
