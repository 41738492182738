import { DefinitionListItem } from '@/components/DefinitionListItem';
import { Alert, Grid, Stack, Typography } from '@mui/material';
import { WorkOrderWidgetData } from '@/api/workOrders';
import { FormattedTime } from 'react-intl';
import { Currency, LongDate, ShortDate } from '@/components/localization';
import { ResourceDisplay } from '@/common/components/ResourceDisplay';
import { useFeatureEnabled } from '@/common/hooks/useFeatureEnabled';
import { useMemo } from 'react';
import { ServiceAmount } from '../common/ServiceAmount';
import { ServicesList } from '../common/ServicesList';
import { ServiceLocationDetail } from '../common/ServiceLocationDetail';
import { useTexts } from './useTexts';

interface Props {
    data?: WorkOrderWidgetData | null;
}

export const Content = (props: Props) => {
    const { data } = props;
    const texts = useTexts();

    const enableScheduledDate = useFeatureEnabled('enableScheduledDate');
    const enableScheduledTime = useFeatureEnabled('enableScheduledTime');
    const enableServiceLocation = useFeatureEnabled('enableServiceLocation');
    const enableIncludedServices = useFeatureEnabled('enableIncludedServices');
    const enableTeamTechnicianInfo = useFeatureEnabled('enableTeamTechnicianInfo');
    const enablePriceOfServiceVisit = useFeatureEnabled('enablePriceOfServiceVisit');

    const scheduledDateText = useMemo(() => {
        if (enableScheduledDate && enableScheduledTime === false) {
            return texts.date;
        } else if (enableScheduledDate === false && enableScheduledTime) {
            return texts.time;
        } else {
            return texts.dateAndTime;
        }
    }, [enableScheduledDate, enableScheduledTime, texts.date, texts.time, texts.dateAndTime]);

    return (
        <>
            {!!data && (
                <Stack direction="column" spacing={2}>
                    {!!data.estimatedTimeOfArrival && (enableScheduledDate || enableScheduledTime) && (
                        <DefinitionListItem
                            primary={scheduledDateText}
                            secondary={
                                (
                                    <Typography>
                                        {enableScheduledDate && (
                                            <>
                                                <LongDate value={data.estimatedTimeOfArrival} />{' '}
                                                {enableScheduledTime && <> - {texts.eta} </>}
                                            </>
                                        )}
                                        {enableScheduledTime && <FormattedTime value={data.estimatedTimeOfArrival} />}
                                    </Typography>
                                ) || texts.notSet
                            }
                        />
                    )}
                    {!data.estimatedTimeOfArrival && (
                        <>
                            <DefinitionListItem
                                primary={texts.eligibilityDate}
                                secondary={
                                    (
                                        <Typography>
                                            <ShortDate value={data.startingEligibleDate} />
                                            {' - '}
                                            <ShortDate value={data.endingEligibleDate} />
                                        </Typography>
                                    ) || texts.notSet
                                }
                            />
                            <Alert severity="info">
                                <Typography>{texts.notScheduled}</Typography>
                            </Alert>
                        </>
                    )}

                    {enableServiceLocation && (
                        <DefinitionListItem
                            primary={texts.serviceLocation}
                            secondary={<ServiceLocationDetail serviceLocation={data.serviceLocation} />}
                        />
                    )}

                    {enablePriceOfServiceVisit && enableIncludedServices === false && (
                        <DefinitionListItem
                            primary={texts.amount}
                            secondary={<Typography>{<Currency value={data.amount || 0} />}</Typography>}
                        />
                    )}

                    <Grid item container>
                        {enableIncludedServices && (
                            <Grid item xs={12} sm={6}>
                                <DefinitionListItem
                                    primary={texts.servicesToBePerformed}
                                    secondary={<ServicesList services={data.services} />}
                                />
                            </Grid>
                        )}
                        {enablePriceOfServiceVisit && enableIncludedServices && (
                            <Grid item xs={12} sm={6}>
                                <ServiceAmount label={texts.amount} amount={data.amount || 0} />
                            </Grid>
                        )}
                    </Grid>

                    {!!data.resource && enableTeamTechnicianInfo && (
                        <ResourceDisplay
                            imageId={data.imageId}
                            isCustom={data.isCustomImage}
                            name={data.resource || texts.notSet}
                        />
                    )}
                </Stack>
            )}

            {!data && <Typography>{texts.noResults}</Typography>}
        </>
    );
};

Content.displayName = 'Content';
