import { Link } from '@mui/material';

interface Props {
    email: string;
}

export function EmailLink(props: Props) {
    const { email } = props;

    return <Link href={`mailto:${email}`}>{email}</Link>;
}
