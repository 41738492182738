import { useGetUILayout } from '@/atoms/UILayout';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { useCallback, useMemo } from 'react';

interface Props {
    count: number;
    page: number;
    onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

export function ListFooter(props: Props) {
    const { count, page, onChange } = props;
    const layout = useGetUILayout();

    const siblingCount = useMemo(() => {
        return layout == 'mobile' ? 0 : 1;
    }, [layout]);

    const handlePageChange = useCallback(
        (event: React.ChangeEvent<unknown>, page: number | null) => {
            if (!!page) {
                onChange(event, page);
            }
        },
        [onChange],
    );

    return (
        <Box display="flex" flexDirection="row-reverse" pt={2}>
            <Pagination
                color="primary"
                count={count}
                onChange={handlePageChange}
                page={page}
                siblingCount={siblingCount}
            />
        </Box>
    );
}
