import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            commitmentWindow: translate({ id: 'sections.upcomingServices.serviceCard.drawer.commitmentWindow' }),
            confirmed: translate({ id: 'sections.upcomingServices.serviceCard.badge.confirmed' }),
            confirmVisit: translate({ id: 'sections.upcomingServices.serviceCard.drawer.confirmVisit' }),
            declined: translate({ id: 'sections.upcomingServices.serviceCard.badge.declined' }),
            declineVisit: translate({ id: 'sections.upcomingServices.serviceCard.drawer.declineVisit' }),
            doesNotIncludeTax: translate({ id: 'sections.upcomingServices.serviceCard.drawer.doesNotIncludeTax' }),
            eligibilityDate: translate({ id: 'sections.upcomingServices.serviceCard.drawer.eligibilityDate' }),
            error: translate({ id: 'sections.upcomingServices.serviceCard.drawer.error' }),
            failedToConfirm: translate({ id: 'sections.upcomingServices.serviceCard.drawer.failedToConfirm' }),
            failedToDecline: translate({ id: 'sections.upcomingServices.serviceCard.drawer.failedToDecline' }),
            includedServices: translate({ id: 'sections.upcomingServices.serviceCard.drawer.includedServices' }),
            needsConfirmation: translate({ id: 'sections.upcomingServices.serviceCard.badge.needsConfirmation' }),
            notYetScheduled: translate({ id: 'sections.upcomingServices.serviceCard.drawer.notYetScheduled' }),
            price: translate({ id: 'sections.upcomingServices.serviceCard.drawer.price' }),
            scheduledDate: translate({
                id: 'sections.upcomingServices.serviceCard.drawer.scheduledDate',
            }),
            scheduledDateAndTime: translate({
                id: 'sections.upcomingServices.serviceCard.drawer.scheduledDateAndTime',
            }),
            scheduledTime: translate({
                id: 'sections.upcomingServices.serviceCard.drawer.scheduledTime',
            }),
            serviceLocation: translate({ id: 'global.serviceLocation' }),
            successfullyConfirmed: translate({ id: 'global.successfullyConfirmed' }),
            successfullyDeclined: translate({ id: 'global.successfullyDeclined' }),
            title: translate({ id: 'sections.upcomingServices.serviceCard.drawer.title' }),
        };
    }, [translate]);
}
