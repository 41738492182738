import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ContentContainer } from '@/components/ContentContainer';
import { ContentPaper } from '@/components/ContentPaper';

import { useTexts } from './useTexts';

export function Unknown() {
    const texts = useTexts();

    return (
        <ContentContainer>
            <ContentPaper fullWidth>
                <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography variant="h1">{texts.title}</Typography>
                    <Typography>{texts.message}</Typography>
                </Box>
            </ContentPaper>
        </ContentContainer>
    );
}
