import { getAccount } from '@/atoms/session/store';
import { useFetchCustomerPortalSettings } from '@/common/hooks/query/useFetchCustomerPortalSettings';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { setServiceTimeFrame, useSelectServiceDetails } from '../../../store';

export enum TimeFrames {
    Morning = 'morning',
    Afternoon = 'afternoon',
    Evening = 'evening',
}

const humanizedTimeFrames = {
    [TimeFrames.Morning]: 'Morning',
    [TimeFrames.Afternoon]: 'Afternoon',
    [TimeFrames.Evening]: 'Evening',
};

interface Props {
    serviceId: string;
}

export const TimeFrame = (props: Props) => {
    const { serviceId } = props;
    const serviceDetails = useSelectServiceDetails(serviceId);
    const account = getAccount();
    const { data: settings } = useFetchCustomerPortalSettings(account?.businessUnitId || '');

    const handleChange = useCallback(
        (event: SelectChangeEvent<TimeFrames | ''>) => {
            if (!event.target) {
                return;
            }

            if (event.target.value == '') {
                return;
            }

            setServiceTimeFrame(serviceId, event.target.value as TimeFrames);
        },
        [serviceId],
    );

    const getAvailability = useCallback(
        (date: Date) => {
            const day = date.getDay();

            switch (day) {
                case 0:
                    return settings?.sundayAvailability || 0;
                case 1:
                    return settings?.mondayAvailability || 0;
                case 2:
                    return settings?.tuesdayAvailability || 0;
                case 3:
                    return settings?.wednesdayAvailability || 0;
                case 4:
                    return settings?.thursdayAvailability || 0;
                case 5:
                    return settings?.fridayAvailability || 0;
                case 6:
                    return settings?.saturdayAvailability || 0;
                default:
                    return 0;
            }
        },
        [settings],
    );

    const timeFrames = useMemo(() => {
        const availableTimeFrames: TimeFrames[] = [];
        if (serviceDetails?.date) {
            const availability = getAvailability(serviceDetails.date);

            if (availability & 1) {
                availableTimeFrames.push(TimeFrames.Morning);
            }

            if (availability & 2) {
                availableTimeFrames.push(TimeFrames.Afternoon);
            }

            if (availability & 4) {
                availableTimeFrames.push(TimeFrames.Evening);
            }
        }

        return availableTimeFrames;
    }, [getAvailability, serviceDetails]);

    const isDisabled = useMemo(() => {
        return !serviceDetails?.date;
    }, [serviceDetails?.date]);

    return (
        <FormControl fullWidth variant="outlined" margin="dense" required>
            <InputLabel id="timeframe">Timeframe</InputLabel>
            <Select<TimeFrames | ''>
                defaultValue={''}
                disabled={isDisabled}
                id="timeframe"
                label="Timeframe"
                labelId="timeframe"
                onChange={handleChange}
                required
                value={serviceDetails?.timeFrame || ''}
            >
                {timeFrames.map((t, i) => (
                    <MenuItem key={i} value={t}>
                        {humanizedTimeFrames[t]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
