import makeStyles from '@mui/styles/makeStyles';
import Container, { ContainerProps } from '@mui/material/Container';

import { toggleCss } from '@/utils/toggleCss';

const useStyles = makeStyles(() => ({
    contentContainer: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },
}));

export function Content(props: ContainerProps) {
    const { className, ...other } = props;
    const classes = useStyles();

    return (
        <Container className={toggleCss(classes.contentContainer, className)} maxWidth="xl" {...other}>
            {props.children}
        </Container>
    );
}
