import { Box, Typography } from '@mui/material';

interface Props {
    description?: string;
    title: string;
}

export function PageHeader(props: Props) {
    const { description, title } = props;

    return (
        <Box>
            <Typography variant="h2" gutterBottom>
                {title}
            </Typography>
            {!!description && <Typography>{description}</Typography>}
        </Box>
    );
}
