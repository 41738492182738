import { usePhoneNumber, usePhoneNumberTelLink } from '@/common/hooks/usePhoneNumber';
import { Link } from '@mui/material';

interface Props {
    callingCode?: string;
    phone: string;
    phoneExt?: string;
}

export function PhoneNumber(props: Props) {
    const { callingCode = '', phone, phoneExt = '' } = props;

    const formattedPhoneNumber = usePhoneNumber({ callingCode, phone, phoneExt });
    const telLink = usePhoneNumberTelLink({ callingCode, phone, phoneExt });

    return <Link href={telLink}>{formattedPhoneNumber}</Link>;
}
