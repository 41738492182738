import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/pro-regular-svg-icons';

import Box from '@mui/material/Box';
import { InvoiceStatus } from '@/api/accountHistory';
import { useDrawerState } from '@/common/hooks/useDrawerState';
import { SideDrawer } from '@/components/SideDrawer';
import { Currency, ShortDate } from '@/components/localization';
import { DesktopListCard } from '@/components/cardList/DesktopListCard';
import { CardBadge } from '@/components/cardList/CardBadge';
import { MobileListCard } from '@/components/cardList/MobileListCard';
import { useGetUILayout } from '@/atoms/UILayout';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { CardProps } from '../typings';
import { setSelectedItem, useListState } from '../../store';
import { DrawerContent } from './drawer/DrawerContent';
import { useTexts } from './useTexts';

const Title = (props: { date: Date; invoiceNumber: number | null }) => {
    const { date, invoiceNumber } = props;
    const texts = useTexts();

    return (
        <Box display="flex" flexDirection="row">
            <Typography noWrap>
                <ShortDate value={date} />
                {` ${texts.invoice} #${invoiceNumber}`}
            </Typography>
        </Box>
    );
};

const Badge = (props: { status: InvoiceStatus | null }) => {
    const { status } = props;
    const texts = useTexts();

    switch (status) {
        case InvoiceStatus.OPEN:
            return <CardBadge severity="error" text={texts.open} />;
        case InvoiceStatus.PAID:
            return <CardBadge severity="success" text={texts.paid} />;
        default:
            return <></>;
    }
};

export const InvoiceCard = (props: CardProps) => {
    const { item } = props;
    const texts = useTexts();
    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();
    const { selected } = useListState();
    const layout = useGetUILayout();

    const handleOpen = useCallback(() => {
        setSelectedItem(item.invoiceId!);
        openDrawer();
    }, [openDrawer, item.invoiceId]);

    const handleClose = useCallback(() => {
        setSelectedItem('');
        closeDrawer();
    }, [closeDrawer]);

    return (
        <>
            {layout == 'desktop' ? (
                <DesktopListCard
                    badge={<Badge status={item.invoiceStatus} />}
                    icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
                    onClick={handleOpen}
                    selected={item.invoiceId === selected}
                    subTitle={<Currency value={item.invoiceTotal || 0} />}
                    title={<Title date={item.date} invoiceNumber={item.invoiceNum} />}
                />
            ) : (
                <MobileListCard
                    badge={<Badge status={item.invoiceStatus} />}
                    icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
                    onClick={handleOpen}
                    selected={item.invoiceId === selected}
                    subTitle={<Currency value={item.invoiceTotal || 0} />}
                    title={<Title date={item.date} invoiceNumber={item.invoiceNum} />}
                />
            )}

            <SideDrawer isOpen={drawerOpen} onClose={handleClose} title={texts.title}>
                <DrawerContent invoiceId={item.invoiceId || ''} />
            </SideDrawer>
        </>
    );
};
