export enum CustomerPortalLastServiceFields {
    EnableLastServiceWorkOrderNumber = 'enableLastServiceWorkOrderNumber',
    EnableLastServiceScheduledDate = 'enableLastServiceScheduledDate',
    EnableLastServiceScheduledTime = 'enableLastServiceScheduledTime',
    EnableLastServiceServiceLocation = 'enableLastServiceServiceLocation',
    EnableLastServiceIncludedServices = 'enableLastServiceIncludedServices',
    EnableLastServiceTeamTechnicianInfo = 'enableLastServiceTeamTechnicianInfo',
    EnableLastServicePriceOfServiceVisit = 'enableLastServicePriceOfServiceVisit',
    EnableLastServiceServiceReport = 'enableLastServiceServiceReport',
}
