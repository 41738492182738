import { get } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';

export interface CompanyHoliday {
    businessUnitId: string;
    date: string;
    generatedFrom: string;
    id: string;
    name: string;
    recurring: boolean;
}

const baseUrl = '/api/CompanyHoliday';

export const getCompanyHolidays = async (businessUnitId: string) => {
    try {
        const { data, status } = await get<CompanyHoliday[]>(`${baseUrl}/${businessUnitId}/CompanyHolidays`);

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get company holidays.`, status);
    } catch (e) {
        throw e;
    }
};
