import { setAuth } from '@/utils/http';

import { loginService } from './loginService';
import { setSession } from './store';

type RejectError = {
    message: string;
    type: 'invalid-login' | 'action-cancelled';
};

let loginController: AbortController | undefined;
export async function login(userName: string, password: string): Promise<true | RejectError> {
    if (loginController) {
        loginController.abort();
    }
    loginController = new AbortController();
    const signal = loginController.signal;

    try {
        const loginResults = await loginService(userName, password, signal);

        if (signal.aborted) {
            return {
                message: 'Action Cancelled',
                type: 'action-cancelled',
            };
        }

        // set HTTP helper auth token
        setAuth(loginResults.token);

        loginController = undefined;
        setSession(
            loginResults.token,
            loginResults.refreshToken,
            loginResults.user,
            loginResults.expirationInUtc,
            userName,
        );

        return true;
    } catch (e) {
        loginController = undefined;

        return {
            message: e instanceof Error ? e.message : 'unknown error',
            type: 'invalid-login',
        };
    }
}
