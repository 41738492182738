import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            header: translate({ id: 'sections.requestService.yourRequest.header' }),
            noServicesSelected: translate({ id: 'sections.requestService.yourRequest.noServicesSelected' }),
            removeService: translate({ id: 'sections.requestService.yourRequest.removeService' }),
            setServiceDetails: translate({ id: 'sections.requestService.yourRequest.setServiceDetails' }),
            submitButton: translate({ id: 'sections.requestService.yourRequest.submitButton' }),
            submitButtonDisabledTooltip: translate({
                id: 'sections.requestService.yourRequest.submitButtonDisabledTooltip',
            }),
            submitError: translate({ id: 'sections.requestService.yourRequest.submitServiceRequestError' }),
            submitSuccess: translate({ id: 'sections.requestService.yourRequest.submitServiceRequestSuccess' }),
        };
    }, [translate]);
}
