import { getAccount } from '@/atoms/session/store';
import { useQueryPaymentAccounts } from '@/common/hooks/query/useQueryPaymentAccounts';
import { useCallback } from 'react';
import { DrawerContent as AddPaymentMethodDrawerContent } from '../../PaymentMethods/drawers/AddPaymentMethod/DrawerContent';
import {
    setAchInput,
    setPaymentAccount,
    setPaymentAccountType,
    setWizardStep,
    useAgreementDrawerState,
    WizardStep,
} from './store';

interface Props {
    onClose: () => void;
}

export function AddPaymentMethod(props: Props) {
    const { onClose } = props;
    const { achInput, paymentAccountType } = useAgreementDrawerState();
    const account = getAccount();
    const { refetch } = useQueryPaymentAccounts(account?.accountId || '');

    const onAddCreditCardSuccess = useCallback(
        async (id: string) => {
            const response = await refetch();

            if (!!response.data && response.data.length) {
                const newPaymentMethod = response.data.find(p => p.processorPaymentAccountId == id);
                if (newPaymentMethod) {
                    setPaymentAccount(newPaymentMethod);
                }
            }

            setWizardStep(WizardStep.ManageAutopay);
        },
        [refetch],
    );

    const onCancelCreditCardEntry = useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        <AddPaymentMethodDrawerContent
            achInput={achInput}
            onAddCreditCardSuccess={onAddCreditCardSuccess}
            onCancelCreditCardEntry={onCancelCreditCardEntry}
            paymentType={paymentAccountType}
            setAchInput={setAchInput}
            setPaymentType={setPaymentAccountType}
        />
    );
}
