import { ServiceOffering } from '@/api/services';
import { useGetUILayout } from '@/atoms/UILayout';
import { Box, Grid, Typography } from '@mui/material';
import { useMemo } from 'react';
import { ServiceDatePicker } from './fields/ServiceDatePicker';
import { Frequency } from './fields/Frequency';
import { PropertyTraits } from './fields/PropertyTraits';
import { TimeFrame } from './fields/Timeframe';

interface Props {
    service: ServiceOffering;
}

export const ServiceDetailForm = (props: Props) => {
    const { service } = props;

    const layout = useGetUILayout();
    const columnSize = useMemo(() => {
        return layout == 'desktop' ? 6 : 12;
    }, [layout]);

    return (
        <Box>
            <Typography gutterBottom>
                <b>{service.serviceName}</b>
            </Typography>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={columnSize}>
                    <ServiceDatePicker serviceId={service.serviceId} />
                </Grid>

                <Grid item xs={columnSize}>
                    <TimeFrame serviceId={service.serviceId} />
                </Grid>

                {service.serviceSchedulingPlans && service.serviceSchedulingPlans.length > 0 && (
                    <Grid item xs={columnSize}>
                        <Frequency service={service} />
                    </Grid>
                )}

                <PropertyTraits columnSize={columnSize} service={service} />
            </Grid>
        </Box>
    );
};
