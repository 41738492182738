import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            associatedWorkOrders: translate({ id: 'sections.accountHistory.invoiceCard.drawer.associatedWorkOrders' }),
            balance: translate({ id: 'sections.accountHistory.invoiceCard.drawer.balance' }),
            billingLocation: translate({ id: 'sections.accountHistory.invoiceCard.drawer.billingLocation' }),
            error: translate({ id: 'sections.accountHistory.invoiceCard.drawer.error' }),
            invoice: translate({ id: 'global.invoice' }),
            invoiceDate: translate({ id: 'sections.accountHistory.invoiceCard.drawer.invoiceDate' }),
            invoiceReportFailed: translate({ id: 'sections.accountHistory.invoiceCard.drawer.invoiceReportFailed' }),
            makePayment: translate({ id: 'sections.accountHistory.invoiceCard.drawer.makePayment' }),
            none: translate({ id: 'global.none' }),
            open: translate({ id: 'sections.accountHistory.invoiceCard.open' }),
            paid: translate({ id: 'sections.accountHistory.invoiceCard.drawer.paid' }),
            partiallyPaid: translate({ id: 'sections.accountHistory.invoiceCard.drawer.partiallyPaid' }),
            paymentMadeOn: translate({ id: 'sections.accountHistory.invoiceCard.drawer.paymentMadeOn' }),
            payments: translate({ id: 'sections.accountHistory.invoiceCard.drawer.payments' }),
            paymentStatus: translate({ id: 'sections.accountHistory.invoiceCard.drawer.paymentStatus' }),
            pdf: translate({ id: 'global.pdf' }),
            serviceLocation: translate({ id: 'sections.accountHistory.invoiceCard.drawer.serviceLocation' }),
            servicesPerformed: translate({ id: 'sections.accountHistory.invoiceCard.drawer.servicesPerformed' }),
            title: translate({ id: 'sections.accountHistory.invoiceCard.drawer.title' }),
            total: translate({ id: 'sections.accountHistory.invoiceCard.drawer.total' }),
            unpaid: translate({ id: 'sections.accountHistory.invoiceCard.drawer.unpaid' }),
            workOrder: translate({ id: 'global.workOrder' }),
        };
    }, [translate]);
}
