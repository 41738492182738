import { BusinessUnitDetails, getBusinessUnitDetails } from '@/api/businessUnitDetails';
import { useQuery } from 'react-query';

export const useFetchBusinessUnitDetails = (businessUnitId: string) =>
    useQuery<BusinessUnitDetails, Error>(
        ['businessUnitDetails', businessUnitId],
        () => getBusinessUnitDetails(businessUnitId),
        {
            staleTime: Infinity,
        },
    );
