import { useCallback } from 'react';
import { clearServiceLocations, resetFilters, setPage, setSearch, setServiceLocations, setTake } from '../store';

export function useListActions() {
    const handleClearServiceLocations = useCallback(() => {
        setPage(0);
        clearServiceLocations();
    }, []);

    const handleDeleteServiceLocationFilter = useCallback(
        (id: string, serviceLocations: string[]) => (_e: React.MouseEvent<HTMLElement>) => {
            setPage(0);
            setServiceLocations(serviceLocations.filter(x => x !== id));
        },
        [],
    );

    const handleResetFilters = useCallback(() => {
        setPage(0);
        resetFilters();
    }, []);

    const handleSetPage = useCallback((_e, page: number) => {
        setPage(page - 1);
    }, []);

    const handleSetSearch = useCallback((search: string) => {
        setPage(0);
        setSearch(search);
    }, []);

    const handleSetServiceLocations = useCallback((locations: string[]) => {
        setPage(0);
        setServiceLocations(locations);
    }, []);

    const handleSetTake = useCallback((take: number) => {
        setTake(take);
    }, []);

    return {
        handleClearServiceLocations,
        handleDeleteServiceLocationFilter,
        handleResetFilters,
        handleSetPage,
        handleSetSearch,
        handleSetServiceLocations,
        handleSetTake,
    } as const;
}
