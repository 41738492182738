import { SvgIcon } from '@mui/material';

export function ACH(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M22.8 20.7896H22.4V17.991C22.4 17.3286 21.8628 16.7916 21.2 16.7916H20.4V9.1954H18V16.7916H15.6V9.1954H13.2V16.7916H10.8V9.1954H8.4V16.7916H6V9.1954H3.6V16.7916H2.8C2.13725 16.7916 1.6 17.3286 1.6 17.991V20.7896H1.2C0.53725 20.7896 1.89924e-09 21.3266 1.89924e-09 21.989V22.7886C1.89924e-09 22.8946 0.0421427 22.9963 0.117157 23.0713C0.192172 23.1463 0.293913 23.1884 0.4 23.1884H23.6C23.7061 23.1884 23.8078 23.1463 23.8828 23.0713C23.9579 22.9963 24 22.8946 24 22.7886V21.989C24 21.3266 23.4627 20.7896 22.8 20.7896ZM20 20.7896H4V19.1904H20V20.7896ZM23.6133 4.65058L12.7589 0.123089C12.2663 -0.0410296 11.7337 -0.0410296 11.241 0.123089L0.38665 4.65058C0.272944 4.69381 0.175073 4.77056 0.106019 4.87067C0.0369646 4.97078 -9.67562e-06 5.0895 1.89924e-09 5.21109V6.5967C1.89924e-09 6.92789 0.26865 7.1964 0.6 7.1964H1.6V7.7961C1.6 8.12729 1.86865 8.3958 2.2 8.3958H21.8C22.1313 8.3958 22.4 8.12729 22.4 7.7961V7.1964H23.4C23.7313 7.1964 24 6.92789 24 6.5967V5.21109C24 4.96212 23.8461 4.73908 23.6133 4.65058ZM2.4 5.997L12 2.3988L21.6 5.997H2.4Z"
                    fill="#696969"
                />
            </svg>
        </SvgIcon>
    );
}
