import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            addCard: {
                creditCardAdded: translate({ id: 'sections.paymentMethods.drawer.successfullyAddedCreditCard' }),
                infoBox: translate({ id: 'sections.payInvoices.drawer.addCard.infoBox' }),
                infoBoxPayment: translate({ id: 'sections.payInvoices.drawer.addCard.infoBoxPayment' }),
                noOption: translate({ id: 'sections.payInvoices.drawer.addCard.noOption' }),
                paymentFailed: translate({ id: 'sections.payInvoices.drawer.addCard.paymentFailed' }),
                paymentSuccessful: translate({ id: 'sections.payInvoices.drawer.addCard.paymentSuccessful' }),
                prompt: translate({ id: 'sections.payInvoices.drawer.addCard.prompt' }),
                yesOption: translate({ id: 'sections.payInvoices.drawer.addCard.yesOption' }),
            },
            addNewPaymentMethod: translate({ id: 'sections.payInvoices.drawer.paymentMethod.addNewPaymentMethod' }),
            chargePaymentMethod: {
                failed: translate({ id: 'sections.payInvoices.drawer.paymentMethod.chargePaymentMethodFailed' }),
                success: translate({ id: 'sections.payInvoices.drawer.paymentMethod.chargePaymentMethodSuccess' }),
                badRequest: translate({ id: 'sections.payInvoices.drawer.paymentMethod.chargePaymentMethodBadRequest' }),
            },
            confirmationAlert: {
                payment: translate({ id: 'sections.payInvoices.drawer.confirmationAlert.payment' }),
                toMakeA: translate({ id: 'sections.payInvoices.drawer.confirmationAlert.toMakeA' }),
                youAreUsing: translate({ id: 'sections.payInvoices.drawer.confirmationAlert.youAreUsing' }),
            },
            failedToLoadPaymentMethods: translate({
                id: 'sections.payInvoices.drawer.paymentMethod.failedToLoadPaymentMethods',
            }),
            footer: {
                processPayment: translate({ id: 'sections.payInvoices.drawer.paymentMethod.footer.processPayment' }),
            },
            header: {
                addCreditCard: translate({ id: 'sections.payInvoices.drawer.paymentMethod.header.addCreditCard' }),
                selectPaymentMethod: translate({
                    id: 'sections.payInvoices.drawer.paymentMethod.header.selectPaymentMethod',
                }),
            },
            noPaymentMethodsAlert: translate({ id: 'sections.payInvoices.drawer.paymentMethod.noPaymentMethodsAlert' }),
            noPaymentMethodsAlertACH: translate({
                id: 'sections.payInvoices.drawer.paymentMethod.noPaymentMethodsAlertACH',
            }),
            noPaymentMethodsAlertCreditCard: translate({
                id: 'sections.payInvoices.drawer.paymentMethod.noPaymentMethodsAlertCreditCard',
            }),
            processingPaymentAlert: translate({
                id: 'sections.payInvoices.drawer.paymentMethod.processingPaymentAlert',
            }),
            select: {
                expires: translate({ id: 'sections.paymentMethods.dropdown.expires' }),
                failedToLoadPaymentMethods: translate({
                    id: 'sections.payInvoices.drawer.paymentMethod.failedToLoadPaymentMethods',
                }),
                label: translate({ id: 'sections.paymentMethods.dropdown.label' }),
                noSavedPaymentMethods: translate({
                    id: 'sections.paymentMethods.dropdown.noSavedPaymentMethods',
                }),
                noSavedPaymentMethodsACH: translate({
                    id: 'sections.paymentMethods.dropdown.noSavedPaymentMethodsACH',
                }),
                noSavedPaymentMethodsCreditCard: translate({
                    id: 'sections.paymentMethods.dropdown.noSavedPaymentMethodsCreditCard',
                }),
            },
            useOtherPaymentMethod: translate({ id: 'sections.payInvoices.drawer.paymentMethod.useOtherPaymentMethod' }),
            useSavedPaymentMethod: translate({ id: 'sections.payInvoices.drawer.paymentMethod.useSavedPaymentMethod' }),
        };
    }, [translate]);
}
