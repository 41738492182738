import { SideDrawerForm } from '@/components/form/SideDrawerForm';
import { useCallback, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import { useRequestServiceState, setNote as setRequestNote } from '../../../store';
import { setNote, useSelectAddNoteState } from '../store';
import { useTexts } from './useTexts';

interface Props {
    onClose: () => void;
    open: boolean;
}

export const Drawer = (props: Props) => {
    const { onClose, open } = props;
    const texts = useTexts();
    const { note: requestNote } = useRequestServiceState();
    const { note } = useSelectAddNoteState();

    useEffect(() => {
        if (open) {
            setNote(requestNote);
        }
    }, [requestNote, open]);

    const onSave = useCallback(() => {
        setRequestNote(note);
        onClose();
    }, [note, onClose]);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setNote(event.target.value);
    }, []);

    return (
        <SideDrawerForm isOpen={open} onClose={onClose} onSave={onSave} title={texts.header}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <TextField
                        id="note"
                        autoComplete="off"
                        data-testid="note"
                        label={texts.noteFieldLabel}
                        multiline
                        onChange={handleChange}
                        onFocus={e =>
                            e.currentTarget.setSelectionRange(
                                e.currentTarget.value.length,
                                e.currentTarget.value.length,
                            )
                        }
                        minRows={5}
                        value={note}
                        autoFocus
                    />
                </Grid>
            </Grid>
        </SideDrawerForm>
    );
};

Drawer.displayName = 'Drawer';
