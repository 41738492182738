import { useAccountId } from '@/atoms/session';
import { useFetchServiceLocations } from '@/common/hooks/query/useQueryServiceLocations';
import { DateFilterChip, HistoryTypeFilterChip, ServiceLocationFilterChip } from '@/components/cardList/chips';
import { ClearFiltersButton } from '@/components/cardList/filters';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useListActions } from '../hooks';
import { useIsListFiltered, useListState } from '../store';

export function Chips() {
    const { startDate, endDate, type, serviceLocations } = useListState();
    const isListFiltered = useIsListFiltered();
    const accountId = useAccountId();
    const { data: locations } = useFetchServiceLocations(accountId || '');
    const { handleClearDates, handleClearType, handleResetFilters, handleDeleteServiceLocationFilter } =
        useListActions();

    return (
        <>
            {!!locations?.length && (
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box flexGrow={1}>
                        <Stack direction="row-reverse" spacing={1} flexWrap="wrap" gap={1}>
                            {!!type && <HistoryTypeFilterChip type={type} onDelete={handleClearType} />}
                            {(!!startDate || !!endDate) && (
                                <DateFilterChip startDate={startDate} endDate={endDate} onDelete={handleClearDates} />
                            )}
                            {!!serviceLocations.length &&
                                serviceLocations.map(
                                    x =>
                                        !!x && (
                                            <ServiceLocationFilterChip
                                                key={x}
                                                id={x}
                                                onDelete={handleDeleteServiceLocationFilter(x, serviceLocations)}
                                                serviceLocations={locations}
                                            />
                                        ),
                                )}
                        </Stack>
                    </Box>

                    <Box ml={2} flexGrow={0}>
                        {isListFiltered && <ClearFiltersButton onClick={handleResetFilters} />}
                    </Box>
                </Box>
            )}
        </>
    );
}
