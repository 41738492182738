import { Typography } from '@mui/material';
import { AgreementService } from '@/common/types/agreements';
import { useHumanizedSchedulingPlan } from '@/common/hooks/schedulingPlan/useHumanizedSchedulingPlan';

interface Props {
    index: number;
    service: AgreementService;
}

export function Service(props: Props) {
    const {
        index,
        service: { interval, intervalUnit, name, schedulingPlanDaysOfWeek, tolerance },
    } = props;

    const schedulingPlan = useHumanizedSchedulingPlan({
        daysOfWeek: schedulingPlanDaysOfWeek,
        interval: interval,
        intervalUnit: intervalUnit,
        schedulingPlanId: '',
        tolerance: tolerance,
    });

    return (
        <>
            <Typography>
                {index + 1}. {name}
            </Typography>
            <Typography variant="caption" marginLeft={2}>
                {schedulingPlan}
            </Typography>
        </>
    );
}
