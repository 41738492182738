import { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUser } from '@/api/registration';
import { useToast } from '@/utils/useToast';

type RejectError = {
    message: string;
    type: 'invalid-registration' | 'unknown';
};

export function useRegistrationActions() {
    const navigate = useNavigate();
    const toast = useToast();

    const unmounted = useRef(false);
    useEffect(() => () => void (unmounted.current = true));

    const [submitting, setSubmitting] = useState(false);

    const afterRegistrationRedirect = useCallback(() => {
        navigate('/login');
    }, [navigate]);

    const register = useCallback(
        async (customerPortalUserId: string, password: string): Promise<true | RejectError> => {
            try {
                setSubmitting(true);

                const response = await createUser(customerPortalUserId, password);

                if (!unmounted.current) setSubmitting(false);

                if (response === true) {
                    return true;
                } else {
                    return {
                        message: 'Error during registration',
                        type: 'unknown',
                    };
                }
            } catch (e) {
                const message = e instanceof Error ? e.message : 'unknown error';

                return {
                    message: message || 'Error during registration',
                    type: 'invalid-registration',
                };
            } finally {
                setSubmitting(false);
            }
        },
        [],
    );

    const notifyError = useCallback((message: string) => toast.error(message), [toast]);

    return {
        submitting,
        register,
        afterRegistrationRedirect,
        notifyError,
    } as const;
}
