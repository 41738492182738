import { SvgIcon } from '@mui/material';

export function Discover(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 40 40">
            <svg viewBox="0 0 40 6.83">
                <g id="layer1">
                    <path
                        id="path22"
                        fill="#201d1c"
                        d="M3.3,21.59a2.4,2.4,0,0,1-1.71.51H1.25V17.86h.34a2.3,2.3,0,0,1,1.71.52A2.15,2.15,0,0,1,4,20a2.22,2.22,0,0,1-.69,1.62ZM1.84,16.77H0v6.42H1.83a3.31,3.31,0,0,0,2.29-.75,3.21,3.21,0,0,0-2.28-5.67"
                        transform="translate(0 -16.59)"
                    />
                    <path id="path24" fill="#201d1c" d="M5.86,23.19H7.11V16.77H5.86Z" transform="translate(0 -16.59)" />
                    <path
                        id="path26"
                        fill="#201d1c"
                        d="M10.17,19.23c-.75-.28-1-.46-1-.81s.39-.71.93-.71a1.33,1.33,0,0,1,1,.52l.65-.85a2.77,2.77,0,0,0-1.88-.72,1.88,1.88,0,0,0-2,1.84c0,.89.4,1.34,1.57,1.76a4.71,4.71,0,0,1,.87.37.75.75,0,0,1,.37.66.92.92,0,0,1-1,.91,1.48,1.48,0,0,1-1.36-.86l-.81.78A2.51,2.51,0,0,0,9.8,23.34,2.07,2.07,0,0,0,12,21.23c0-1-.42-1.48-1.84-2"
                        transform="translate(0 -16.59)"
                    />
                    <path
                        id="path28"
                        fill="#201d1c"
                        d="M12.41,20a3.32,3.32,0,0,0,3.38,3.35A3.56,3.56,0,0,0,17.36,23V21.48a2,2,0,0,1-1.51.71A2.1,2.1,0,0,1,13.7,20a2.13,2.13,0,0,1,2.09-2.2,2.09,2.09,0,0,1,1.57.72V17a3.17,3.17,0,0,0-1.54-.39A3.37,3.37,0,0,0,12.41,20"
                        transform="translate(0 -16.59)"
                    />
                    <path
                        id="path30"
                        fill="#201d1c"
                        d="M27.28,21.08l-1.71-4.31H24.21l2.72,6.58h.67l2.77-6.58H29l-1.74,4.31"
                        transform="translate(0 -16.59)"
                    />
                    <path
                        id="path32"
                        fill="#201d1c"
                        d="M30.94,23.19h3.55V22.1h-2.3V20.37H34.4V19.28H32.19V17.86h2.3V16.77H30.94v6.42"
                        transform="translate(0 -16.59)"
                    />
                    <path
                        id="path34"
                        fill="#201d1c"
                        d="M36.93,19.72h-.36V17.78H37c.78,0,1.2.33,1.2.95S37.73,19.72,36.93,19.72Zm2.51-1.06c0-1.2-.83-1.89-2.27-1.89H35.32v6.42h1.25V20.61h.16l1.73,2.58H40l-2-2.71a1.7,1.7,0,0,0,1.46-1.82"
                        transform="translate(0 -16.59)"
                    />
                    <path
                        id="path78"
                        fill="#e66e26"
                        d="M17.77,20h0a3.42,3.42,0,0,1,6.83,0h0a3.42,3.42,0,0,1-6.83,0"
                        transform="translate(0 -16.59)"
                    />
                </g>
            </svg>
        </SvgIcon>
    );
}
