import { ServiceOffering } from '@/api/services';
import { Box, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import { useCallback, useMemo } from 'react';
import { IconButtonWithTooltip } from '@/components/IconButtonWithTooltip';
import { removeServiceOffering, useRequestServiceState } from '../../store';
import { useTexts } from './useTexts';
import { ServiceDetails } from './ServiceDetails';

interface Props {
    onEditDetails: () => void;
    service: ServiceOffering;
}

export const Service = (props: Props) => {
    const { onEditDetails, service } = props;
    const texts = useTexts();

    const { serviceDetails } = useRequestServiceState();

    const details = useMemo(() => {
        return serviceDetails.find(d => d.serviceId == service.serviceId);
    }, [service.serviceId, serviceDetails]);

    const removeService = useCallback(() => {
        removeServiceOffering(service);
    }, [service]);

    return (
        <Box>
            <Grid container direction="column">
                <Grid item>
                    <Box display="flex" alignItems="center">
                        <Box display="flex" flexGrow={1}>
                            <Typography>
                                <b>{service.serviceName}</b>
                            </Typography>
                        </Box>
                        <Box>
                            <IconButtonWithTooltip
                                edge="end"
                                onClick={onEditDetails}
                                showTooltip={true}
                                size="small"
                                tooltipText={texts.setServiceDetails}
                            >
                                <SettingsIcon fontSize="small" />
                            </IconButtonWithTooltip>

                            <IconButtonWithTooltip
                                edge="end"
                                onClick={removeService}
                                showTooltip={true}
                                size="small"
                                tooltipText={texts.removeService}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButtonWithTooltip>
                        </Box>
                    </Box>

                    <Typography>{service.serviceDescription}</Typography>

                    {details && <ServiceDetails details={details} />}
                </Grid>
            </Grid>
        </Box>
    );
};

Service.displayName = 'Service';
