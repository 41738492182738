import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faUniversity, faSackDollar, faMoneyCheck, faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { useCallback, useMemo } from 'react';
import { PaymentMethod } from '@/api/accountHistory';
import { PaymentStatus } from '@/common/types/payments';
import { SideDrawer } from '@/components/SideDrawer';
import { useDrawerState } from '@/common/hooks/useDrawerState';
import { ShortDate } from '@/components/localization';
import { DesktopListCard } from '@/components/cardList/DesktopListCard';
import { CardBadge } from '@/components/cardList/CardBadge';
import { useGetUILayout } from '@/atoms/UILayout';
import { MobileListCard } from '@/components/cardList/MobileListCard';
import { Typography } from '@mui/material';
import { CardProps } from '../typings';
import { useTexts } from '../../../useTexts';
import { setSelectedItem, useListState } from '../../store';
import { useFormattedAccountNumber } from '../../hooks/useFormattedAccountType';
import { useTexts as useDrawerTexts } from './useTexts';
import { DrawerContent } from './DrawerContent';
import { PaymentSubtitle } from './PaymentSubtitle';

const Title = (props: { date: Date; invoiceNums: string | null }) => {
    const { date, invoiceNums } = props;
    const texts = useTexts();

    const invoiceNumsStr = useMemo(() => {
        if (!invoiceNums) return '';
        const nums = invoiceNums.split(',').map(x => x.trim());
        const joined = nums.join(', #');
        return `#${joined}`;
    }, [invoiceNums]);

    return (
        <Typography noWrap>
            <ShortDate value={date} />
            {` ${texts.payment}`} {!!invoiceNums && ` ${texts.forInvoice} ${invoiceNumsStr}`}
        </Typography>
    );
};

export const Badge = (props: { status: PaymentStatus | null }) => {
    const { status } = props;
    const texts = useTexts();

    switch (status) {
        case PaymentStatus.PENDING:
            return <CardBadge severity="warning" text={texts.pending} />;
        case PaymentStatus.SUCCEEDED:
            return <CardBadge severity="success" text={texts.succeeded} />;
        case PaymentStatus.REFUNDED:
            return <CardBadge severity="error" text={texts.refunded} />;
        case PaymentStatus.PARTIALLY_REFUNDED:
            return <CardBadge severity="error" text={texts.partiallyRefunded} />;
        case PaymentStatus.CANCELLED:
            return <CardBadge severity="error" text={texts.cancelled} />;
        case PaymentStatus.FAILED:
            return <CardBadge severity="error" text={texts.failed} />;
        default:
            return <></>;
    }
};

const Icon = (props: { type: PaymentMethod | null }) => {
    const { type } = props;
    switch (type) {
        case PaymentMethod.CASH:
            return <FontAwesomeIcon icon={faSackDollar} />;
        case PaymentMethod.CREDIT_CARD:
            return <FontAwesomeIcon icon={faCreditCard} />;
        case PaymentMethod.CHECK:
            return <FontAwesomeIcon icon={faMoneyCheck} />;
        case PaymentMethod.ACH:
            return <FontAwesomeIcon icon={faUniversity} />;
        default:
            return <FontAwesomeIcon icon={faDollarSign} />;
    }
};

export const PaymentCard = (props: CardProps) => {
    const { item } = props;
    const [closeDrawer, drawerOpen, openDrawer] = useDrawerState();
    const texts = useDrawerTexts();
    const { selected } = useListState();
    const layout = useGetUILayout();

    const handleOpen = useCallback(() => {
        setSelectedItem(item.paymentId!);
        openDrawer();
    }, [openDrawer, item.paymentId]);

    const handleClose = useCallback(() => {
        setSelectedItem('');
        closeDrawer();
    }, [closeDrawer]);

    const formattedAccountType = useFormattedAccountNumber(item.paymentAccountType, item.paymentMethod, item.reference);

    const itemPaymentStatus = item.isPartiallyRefunded ? PaymentStatus.PARTIALLY_REFUNDED : item.paymentStatus;

    return (
        <>
            {layout == 'desktop' ? (
                <DesktopListCard
                    badge={<Badge status={itemPaymentStatus} />}
                    icon={<Icon type={item.paymentMethod} />}
                    onClick={handleOpen}
                    selected={item.paymentId === selected}
                    subTitle={<PaymentSubtitle value={item.paymentAmount || 0} identifier={formattedAccountType} />}
                    title={<Title date={item.date} invoiceNums={item.paymentInvoiceNums} />}
                />
            ) : (
                <MobileListCard
                    badge={<Badge status={itemPaymentStatus} />}
                    icon={<Icon type={item.paymentMethod} />}
                    onClick={handleOpen}
                    selected={item.paymentId === selected}
                    subTitle={<PaymentSubtitle value={item.paymentAmount || 0} identifier={formattedAccountType} />}
                    title={<Title date={item.date} invoiceNums={item.paymentInvoiceNums} />}
                />
            )}

            <SideDrawer isOpen={drawerOpen} onClose={handleClose} title={texts.title}>
                <DrawerContent paymentId={item.paymentId || ''} />
            </SideDrawer>
        </>
    );
};
