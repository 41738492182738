import { get } from '@/utils/http';
import { ServerError } from '@/utils/ServerError';
import { PropertyTrait } from './serviceLocations';

export interface SchedulingPlan {
    schedulingPlanId: string;
    daysOfWeek: string;
    intervalUnit: string;
    interval: string;
    tolerance: string;
}

export interface ServiceOffering {
    businessUnitId: string;
    serviceDescription: string;
    serviceId: string;
    serviceName: string;
    servicePropertyTraits: PropertyTrait[];
    serviceSchedulingPlans: SchedulingPlan[];
    schedulingPlanId: string;
}

export interface GetServicesResponse {
    items: ServiceOffering[];
    page: number;
    take: number;
    total: number;
}

const baseUrl = '/api/BusinessUnits';

export const getServices = async (businessUnitId: string, page: number, take: number) => {
    try {
        const { data, status } = await get<GetServicesResponse>(
            `${baseUrl}/${businessUnitId}/Services?page=${page}&take=${take}`,
        );

        if (status === 200 || status == 204) {
            return data;
        }

        throw new ServerError(`Failed to get services.`, status);
    } catch (e) {
        throw e;
    }
};
