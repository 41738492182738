import { ServiceOffering } from '@/api/services';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ReactNode, useCallback, useMemo } from 'react';
import { setServiceSchedulingPlan, useSelectServiceDetails } from '../../../store';
import { FrequencyMenuItemText } from './FrequencyMenuItemText';

interface Props {
    service: ServiceOffering;
}

export const Frequency = (props: Props) => {
    const { service } = props;
    const serviceDetail = useSelectServiceDetails(service.serviceId);

    const frequencies = useMemo(() => {
        return service.serviceSchedulingPlans || [];
    }, [service]);

    const handleChange = useCallback(
        (event: SelectChangeEvent<string>, _child: ReactNode) => {
            if (!event.target || !service.serviceId) {
                return;
            }

            if (event.target.value == '') {
                return;
            }

            const schedulingPlanId = event.target.value as string;
            const selectedPlan = service.serviceSchedulingPlans.find(s => s.schedulingPlanId == schedulingPlanId);

            if (!!selectedPlan) {
                setServiceSchedulingPlan(selectedPlan, service.serviceId);
            }
        },
        [service.serviceId, service.serviceSchedulingPlans],
    );

    const isDisabled = useMemo(() => {
        return !serviceDetail?.date;
    }, [serviceDetail?.date]);

    return (
        <FormControl fullWidth variant="outlined" margin="dense" required>
            <InputLabel id="frequency">Frequency</InputLabel>
            <Select<string>
                defaultValue={''}
                disabled={isDisabled}
                id="frequency"
                label="Frequency"
                labelId="frequency"
                onChange={handleChange}
                required
                value={serviceDetail?.schedulingPlan?.schedulingPlanId || ''}
            >
                {frequencies.map((f, i) => (
                    <MenuItem key={i} value={f.schedulingPlanId}>
                        <FrequencyMenuItemText frequency={f} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
