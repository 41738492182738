import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Drawer, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useGetUILayout } from '@/atoms/UILayout';

type Props = {
    children?: React.ReactNode;
    footer?: React.ReactNode;
    footerSecondary?: React.ReactNode;
    headerSecondary?: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
    title: string;
};

export const SideDrawer = (props: Props) => {
    const layout = useGetUILayout();

    const useFormStyles = makeStyles({
        container: {
            overflowY: 'auto',
            width: '100%',
            height: '100%',
        },
        drawer: {
            flexShrink: 0,
            width: layout == 'mobile' ? 350 : 550,
            maxWidth: '90%',
        },
    });

    const { children, footer, footerSecondary, headerSecondary, isOpen, title, onClose } = props;
    const classes = useFormStyles();

    return (
        <Drawer
            anchor="right"
            className={classes.drawer}
            classes={{
                paper: classes.drawer,
            }}
            data-testid="sidebar-drawer"
            onClose={onClose}
            open={isOpen}
        >
            <Box display="flex" flexDirection="column" height="100%">
                <Box display="flex" flexDirection="row" p={2} justifyContent="space-between" alignItems="center">
                    <Typography variant="h4">{title}</Typography>

                    {layout == 'desktop' && (
                        <IconButton size="small" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    )}
                </Box>

                <Divider />

                {!!headerSecondary && <>{headerSecondary}</>}

                <Box className={classes.container} p={2}>
                    {children}
                </Box>

                {!!footerSecondary && (
                    <>
                        <Divider />
                        {footerSecondary}
                    </>
                )}

                {!!footer && (
                    <>
                        <Divider />
                        {footer}
                    </>
                )}
            </Box>
        </Drawer>
    );
};

SideDrawer.displayName = 'SideDrawer';
