import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            amount: translate({ id: 'sections.accountHistory.serviceCard.drawer.amount' }),
            dateAndTimeCompleted: translate({ id: 'sections.accountHistory.serviceCard.drawer.dateAndTimeCompleted' }),
            dateCompleted: translate({ id: 'sections.accountHistory.serviceCard.drawer.dateCompleted' }),
            error: translate({ id: 'sections.accountHistory.serviceCard.drawer.error' }),
            failedToDownloadPDF: translate({ id: 'sections.accountHistory.serviceCard.drawer.failedToDownloadPDF' }),
            pdf: translate({ id: 'global.pdf' }),
            service: translate({ id: 'sections.accountHistory.serviceCard.serviceReport' }),
            serviceLocation: translate({ id: 'sections.accountHistory.serviceCard.drawer.serviceLocation' }),
            servicesPerformed: translate({ id: 'sections.accountHistory.serviceCard.drawer.servicesPerformed' }),
            timeCompleted: translate({ id: 'sections.accountHistory.serviceCard.drawer.timeCompleted' }),
            title: translate({ id: 'sections.accountHistory.serviceCard.drawer.title' }),
            technician: translate({ id: 'sections.accountHistory.serviceCard.drawer.technician' }),
            workOrder: translate({ id: 'global.workOrder' }),
        };
    }, [translate]);
}
