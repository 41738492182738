import { Box, Pagination as MuiPagination } from '@mui/material';
import { useMemo } from 'react';
import { useAddServicesState, setPage } from '../store';

export const Pagination = () => {
    const {
        all: { page, take, totalItems },
    } = useAddServicesState();

    const pageCount = useMemo(() => {
        return Math.ceil(totalItems / take);
    }, [take, totalItems]);

    return (
        <>
            {pageCount > 1 && (
                <Box p={1}>
                    <MuiPagination
                        color="primary"
                        count={pageCount}
                        page={page + 1}
                        onChange={(_e, page) => {
                            setPage(page - 1);
                        }}
                    />
                </Box>
            )}
        </>
    );
};
