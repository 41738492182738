import { useCallback, useMemo, useState } from 'react';
import { PaymentAccountTypes } from '@/common/types/payments';
import { SideDrawerForm } from '@/components/form/SideDrawerForm';
import { addAchPaymentAccount } from '@/api/paymentAccounts';
import { useToast } from '@/utils/useToast';
import { getAccount } from '@/atoms/session/store';
import { useQueryPaymentAccounts } from '@/common/hooks/query/useQueryPaymentAccounts';
import { DrawerContent } from './DrawerContent';
import { useIsAchInputValid } from './hooks/useIsAchInputValid';
import { useTexts } from './useTexts';

interface Props {
    onClose: () => void;
    open: boolean;
}

export interface AchInput {
    accountNumber: string;
    routingNumber: string;
}

export function Drawer(props: Props) {
    const account = getAccount();
    const [achInput, setAchInput] = useState<AchInput>({ accountNumber: '', routingNumber: '' });
    const [paymentType, setPaymentType] = useState<PaymentAccountTypes>(PaymentAccountTypes.CREDIT_CARD);
    const { refetch } = useQueryPaymentAccounts(account?.accountId || '');

    const { onClose, open } = props;
    const texts = useTexts();
    const toast = useToast();

    const closeDrawer = useCallback(() => {
        setAchInput({ accountNumber: '', routingNumber: '' });
        setPaymentType(PaymentAccountTypes.CREDIT_CARD);
        onClose();
    }, [onClose, setAchInput]);

    const hideFooter = useMemo(() => {
        return paymentType == PaymentAccountTypes.CREDIT_CARD;
    }, [paymentType]);

    const isSaveDisabled = !useIsAchInputValid(achInput);

    const onSave = useCallback(async () => {
        if (!account) return;

        try {
            await addAchPaymentAccount({
                accountId: account.accountId,
                accountNumber: achInput.accountNumber,
                businessUnitId: account.businessUnitId,
                processorPaymentAccountId: '',
                routingNumber: achInput.routingNumber,
            });

            toast.success(texts.successfullyAddedAch);
            refetch();
            closeDrawer();
        } catch {
            toast.error(texts.errorAddingAchAccountNumber);
        }
    }, [account, achInput, closeDrawer, refetch, texts, toast]);

    const onAddCreditCardSuccess = useCallback(async () => {
        toast.success(texts.successfullyAddedCreditCard);

        try {
            await refetch();
            closeDrawer();
        } catch {
            toast.error(texts.errorAddingCreditCard);
        }
    }, [closeDrawer, refetch, texts, toast]);

    return (
        <SideDrawerForm
            isOpen={open}
            onClose={closeDrawer}
            onSave={onSave}
            disableSave={isSaveDisabled}
            hideFooter={hideFooter}
            title={texts.title}
        >
            <DrawerContent
                achInput={achInput}
                onAddCreditCardSuccess={onAddCreditCardSuccess}
                onCancelCreditCardEntry={onClose}
                paymentType={paymentType}
                setAchInput={setAchInput}
                setPaymentType={setPaymentType}
            />
        </SideDrawerForm>
    );
}
