import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Section } from '@/components/Section';
import { SectionHeader } from '@/components/SectionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { useQueryYourNextService } from '@/common/hooks/query/useQueryYourNextService';
import { useCallback, useMemo, useState } from 'react';
import { useToast } from '@/utils/useToast';
import { setCommitmentWindowCustomerResponse } from '@/api/workOrders';
import { CommitmentWindowCustomerResponse, WorkOrderStatus } from '@/api/workOrderDetails';
import LoadingButton from '@mui/lab/LoadingButton';
import { queryClient } from '../../../../services/queryClient';
import { ConfirmationBadge } from '../../../UpcomingServices/components/ConfirmationBadge';
import { useTexts } from './useTexts';
import { Content } from './Content';

interface Props {
    accountId: string;
}

export function YourNextService(props: Props) {
    const { accountId } = props;
    const { status, data } = useQueryYourNextService(accountId);
    const texts = useTexts();
    const toast = useToast();
    const [loading, setLoading] = useState(false);

    const confirmVisit = useCallback(async () => {
        try {
            setLoading(true);
            await setCommitmentWindowCustomerResponse(
                data?.workOrderId || '',
                CommitmentWindowCustomerResponse.CONFIRMED,
            );
            queryClient.invalidateQueries('yourNextService');
            queryClient.invalidateQueries('workOrderDetails');
            queryClient.invalidateQueries('upcomingServices');
            toast.success(texts.successfullyConfirmed);
        } catch {
            toast.error(texts.failedToConfirm);
        } finally {
            setLoading(false);
        }
    }, [data?.workOrderId, texts, toast]);

    const showBadge = useMemo(() => {
        return (
            !!data?.estimatedTimeOfArrival &&
            data?.commitmentWindowCustomerResponse !== CommitmentWindowCustomerResponse.UNKNOWN
        );
    }, [data?.commitmentWindowCustomerResponse, data?.estimatedTimeOfArrival]);

    const showButton = useMemo(() => {
        return (
            !!data?.estimatedTimeOfArrival &&
            data?.commitmentWindowConfirmationRequested &&
            data?.commitmentWindowCustomerResponse === CommitmentWindowCustomerResponse.UNKNOWN &&
            data?.status !== WorkOrderStatus.IN_PROGRESS
        );
    }, [
        data?.commitmentWindowConfirmationRequested,
        data?.commitmentWindowCustomerResponse,
        data?.estimatedTimeOfArrival,
        data?.status,
    ]);

    return (
        <Section>
            <SectionHeader
                icon={<FontAwesomeIcon icon={faClock} />}
                primaryAction={
                    <>
                        {showBadge && (
                            <ConfirmationBadge
                                status={data!.commitmentWindowCustomerResponse}
                                confirmationRequested={data!.commitmentWindowConfirmationRequested}
                            />
                        )}
                        {showButton && (
                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                sx={{ pl: loading ? 4.5 : 2 }}
                                onClick={confirmVisit}
                                color="primary"
                                variant="contained"
                                disabled={data == null}
                            >
                                {texts.confirmBtn}
                            </LoadingButton>
                        )}
                    </>
                }
                title={texts.header}
            />
            {status == 'error' && <Typography>{texts.error}</Typography>}
            {status == 'loading' && <CircularProgress />}
            {status == 'success' && <Content data={data} />}
        </Section>
    );
}
