import { Button } from '@/components/Button';
import makeStyles from '@mui/styles/makeStyles';
import { useTexts } from '../hooks/useTexts';

const useStyles = makeStyles({
    button: {
        textTransform: 'none',
    },
});

interface Props {
    handleClick: React.MouseEventHandler;
}

export const ForgotPasswordButton = (props: Props) => {
    const { handleClick } = props;
    const classes = useStyles();
    const texts = useTexts();
    return (
        <Button variant="text" color="primary" className={classes.button} onClick={handleClick}>
            {texts.forgotPassword}
        </Button>
    );
};
