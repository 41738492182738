import { useCallback } from 'react';
import { SideDrawerForm } from '@/components/form/SideDrawerForm';
import { setServiceLocation } from '../../../store';
import { useSelectLocationState } from '../store';
import { useTexts } from './useTexts';
import { DrawerContent } from './DrawerContent';
import { LocationSearch } from './LocationSearch';

interface Props {
    onClose: () => void;
    open: boolean;
}

export const Drawer = (props: Props) => {
    const { onClose, open } = props;
    const texts = useTexts();
    const { selected } = useSelectLocationState();

    const onSave = useCallback(() => {
        setServiceLocation(selected);
        onClose();
    }, [onClose, selected]);

    return (
        <SideDrawerForm
            disableSave={!selected}
            headerSecondary={<LocationSearch />}
            isOpen={open}
            onClose={onClose}
            onSave={onSave}
            title={texts.header}
        >
            <DrawerContent />
        </SideDrawerForm>
    );
};

Drawer.displayName = 'Drawer';
