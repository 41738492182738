import { getAccount, getUser } from '@/atoms/session/store';
import { useFetchAccountByContactId } from '@/common/hooks/query/useQueryAccounts';
import { Typography, Box } from '@mui/material';
import { ListProps } from '../List/typings';
import { AccountSwitchAction } from './AccountSwitchAction';
import { useTexts } from './useTexts';

type SwitchAccountViewProps = {
    clearSubList: () => void;
} & ListProps;

export const SwitchAccountView = (props: SwitchAccountViewProps) => {
    const { setSubList, clearSubList } = props;
    const texts = useTexts();
    const contactId = getUser()?.contactId ?? '';

    const accountFetchResults = useFetchAccountByContactId(contactId);

    return (
        <Box pt={2} pl={2} pr={2}>
            <Typography>{getAccount()?.name}</Typography>
            <Typography variant="body2">
                {texts.account} #{getAccount()?.number}
            </Typography>
            {accountFetchResults.isSuccess && accountFetchResults.data.length > 1 && (
                <AccountSwitchAction
                    setSubList={setSubList}
                    clearSubList={clearSubList}
                    accountsList={accountFetchResults.data}
                />
            )}
        </Box>
    );
};

SwitchAccountView.displayName = 'SwitchAccountView';
