import { get, setTenantId, requestNewToken } from '@/utils/http';
import * as storage from '@/utils/ApplicationStorage';
import { clearSession } from './store';

const getDomain = () => {
    const url = window.location;
    const hostname = url.hostname;
    const domain = hostname.split('.')[0];
    return domain;
};

interface TenantIdModel {
    tenantId: string;
}

const getTenantId = async (domain: string) => {
    try {
        const response = await get<TenantIdModel>(`/api/tenants?domain=${domain}`);
        return response.data.tenantId;
    } catch (e) {
        throw e;
    }
};

export const tenantService = async () => {
    const domain = getDomain();
    const tenantId = await getTenantId(domain);

    setTenantId(tenantId);
    storage.setValue('tenantId', tenantId);

    // Refresh credentials after loading tenant but before anything else
    // Consider refactoring
    try {
        await requestNewToken();
    } catch {
        clearSession(); // Clear the session values so the user can login
    }

    return tenantId;
};
