import { InvoiceMode } from '@/common/types/invoiceMode';
import { MethodOfContact } from './contacts';
import { Frequencies } from './schedulingPlan';

export interface AgreementContactEmail {
    email: string;
    name: string;
}

export interface AgreementContactOther {
    contactValue: string;
    name: string;
}

export interface AgreementContactPhone {
    callingCode: string;
    countryCode: string;
    extension: string;
    name: string;
    number: string;
}

export interface AgreementContact {
    email: AgreementContactEmail | null;
    firstName: string;
    lastName: string;
    middleName: string;
    other: AgreementContactOther | null;
    phone: AgreementContactPhone | null;
    preferredMethodOfContact: MethodOfContact;
}

export interface AgreementAddress {
    city: string;
    country: string;
    countryCode: string;
    county: string;
    postalCode: string;
    state: string;
    stateAbbreviation: string;
    street1: string;
    street2: string | null;
}

export interface AgreementService {
    interval: string;
    intervalUnit: Frequencies;
    name: string;
    schedulingPlanDaysOfWeek: string;
    tolerance: string;
}

export enum AgreementStatus {
    ACTIVE = 'Active',
    CANCELLED = 'Cancelled',
    COMPLETED = 'Completed',
}

export enum AutoPaymentAccountType {
    CREDIT_CARD,
    ACH,
}
export interface AutoPaymentAccount {
    accountId: string;
    accountType: AutoPaymentAccountType;
    businessUnitId: string;
    createdOn: Date;
    ending: string;
    expirationMonth: string;
    expirationYear: string;
    friendly: string;
    id: string;
    isExpired: boolean;
    processorPaymentAccountId: string;
}

export interface AgreementDetails {
    agreementId: string;
    agreementServices: AgreementService[];
    autoPaymentAccounts: AutoPaymentAccount[];
    billingContact: AgreementContact;
    billingLocation: AgreementAddress;
    endDate: string | null;
    hasValidationErrors: boolean;
    invoiceMode: InvoiceMode;
    name: string;
    serviceContact: AgreementContact;
    serviceLocation: AgreementAddress;
    startDate: string | null;
    status: AgreementStatus;
    upfrontInvoicingDaysAhead: string | null;
    upfrontInvoicingTimeToCreateInvoice: string | null;
    hasAutoPaymentInstance: boolean;
}
