import { ServiceRequestListItemModel } from '@/api/serviceRequests';
import { FormattedDate, FormattedTime } from 'react-intl';
import { useTexts } from '../../useTexts';

interface Props {
    item: ServiceRequestListItemModel;
}

export function SubTitle(props: Props) {
    const { item } = props;
    const { dateTimeCreated } = item;
    const texts = useTexts();

    return (
        <span>
            <>
                {`${texts.dateAndTimeRequested} `}
                <FormattedDate value={dateTimeCreated} day="2-digit" month="2-digit" year="2-digit" />{' '}
                <FormattedTime value={dateTimeCreated} />
            </>
        </span>
    );
}
