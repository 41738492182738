import { PropertyTrait } from '@/api/serviceLocations';
import { SchedulingPlan } from '@/api/services';
import { proxy, snapshot, useSnapshot } from 'valtio';
import * as _ from 'lodash';
import { TimeFrames } from './Drawer/Form/fields/Timeframe';

export interface ServiceDetails {
    date: Date | null;
    propertyTraits: PropertyTrait[];
    schedulingPlan: SchedulingPlan | null;
    serviceId: string;
    timeFrame: TimeFrames | null;
    schedulingPlanId: string;
}
export interface SetServiceDetailsState {
    propertyTraits: PropertyTrait[];
    serviceDetails: ServiceDetails[];
}

export const initialState: SetServiceDetailsState = {
    propertyTraits: [],
    serviceDetails: [],
};

// Valtio store initialization
const state = proxy<SetServiceDetailsState>(initialState);

// Getter functions
export function getServiceDetail() {
    return snapshot(state.serviceDetails);
}

// Setter functions
export function setServiceDate(date: Date | null, serviceId: string) {
    const existingService = state.serviceDetails.find(s => s.serviceId == serviceId);

    if (existingService) {
        existingService.date = date;
        existingService.timeFrame = null;
        if (!date) {
            existingService.schedulingPlan = null;
        }
    } else {
        state.serviceDetails.push({
            date,
            propertyTraits: [],
            serviceId,
            schedulingPlan: null,
            timeFrame: null,
            schedulingPlanId: '',
        });
    }
}

export function setServiceSchedulingPlan(schedulingPlan: SchedulingPlan, serviceId: string) {
    const existingService = state.serviceDetails.find(s => s.serviceId == serviceId);

    if (existingService) {
        existingService.schedulingPlan = schedulingPlan;
        existingService.schedulingPlanId = schedulingPlan.schedulingPlanId;
    }
}

export function setServiceTimeFrame(serviceId: string, timeFrame: TimeFrames) {
    const existingService = state.serviceDetails.find(s => s.serviceId == serviceId);

    if (existingService) {
        existingService.timeFrame = timeFrame;
    }
}

export function addPropertyTrait(serviceId: string, propertyTrait: PropertyTrait) {
    const existingService = state.serviceDetails.find(s => s.serviceId == serviceId);

    if (existingService) {
        const existingPropertyTrait = existingService.propertyTraits.find(
            p => p.propertyTraitId == propertyTrait.propertyTraitId,
        );

        if (existingPropertyTrait) {
            return;
        } else {
            existingService.propertyTraits.push(propertyTrait);
        }
    }
}

export function updatePropertyTrait(serviceId: string, propertyTrait: PropertyTrait) {
    const existingServiceIndex = state.serviceDetails.findIndex(s => s.serviceId == serviceId);

    if (existingServiceIndex != -1) {
        const existingPropertyTraitIndex = state.serviceDetails[existingServiceIndex].propertyTraits.findIndex(
            p => p.propertyTraitId == propertyTrait.propertyTraitId,
        );

        if (existingPropertyTraitIndex != -1) {
            state.serviceDetails[existingServiceIndex].propertyTraits[existingPropertyTraitIndex].amount =
                propertyTrait.amount;
        } else {
            return;
        }
    }
}

export function removeServiceDetailsById(serviceId: string) {
    state.serviceDetails = _.cloneDeep(state.serviceDetails.filter(s => serviceId != s.serviceId));
}

export function resetServiceDetails(serviceDetails: ServiceDetails[]) {
    state.serviceDetails = _.cloneDeep(serviceDetails);
}

// Hooks
export function useSelectServiceDetailState() {
    return useSnapshot(state);
}

export function useSelectServiceDetail() {
    return useSnapshot(state).serviceDetails;
}

export function useSelectServiceDetails(serviceId: string) {
    return useSnapshot(state).serviceDetails.find(s => s.serviceId == serviceId) || null;
}
