import { theme } from '@/theme';
import { useCallback } from 'react';
import { List, ListItem, Typography, ListItemText, Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export type Props = {
    accountsList: Account.AccountSelect[];
    onAccountSelected: (account: Account.AccountSelect) => void;
    title: string;
    wrappingContainer?: boolean;
};

const useStyles = (props: Props) =>
    makeStyles({
        container: {
            maxHeight: props.wrappingContainer ? '350px' : 'auto',
            overflowY: 'auto',
        },
        list: {
            minWidth: '250px',
        },
        title: {
            paddingLeft: theme.spacing(2),
        },
    });

export function AccountSwitch(props: Props) {
    const { accountsList, onAccountSelected, title } = props;
    const classes = useStyles(props)();

    const handleClick = useCallback(
        (account: Account.AccountSelect) => (_e: React.MouseEvent<HTMLElement>) => {
            onAccountSelected(account);
        },
        [onAccountSelected],
    );

    return (
        <>
            <Typography className={classes.title} color="primary" variant="body2">
                {title}
            </Typography>
            <Box className={classes.container}>
                <List className={classes.list}>
                    {accountsList.map((value, index) => (
                        <ListItem
                            dense
                            button
                            divider={index < accountsList.length - 1}
                            key={index}
                            onClick={handleClick(value)}
                        >
                            <ListItemText
                                primary={
                                    <Typography>
                                        <b>{value.name}</b>
                                    </Typography>
                                }
                                secondary={<Typography>Account #{value.number}</Typography>}
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>
    );
}
