import { useMemo } from 'react';
import { useTranslate } from '@/intl';

export function useTexts() {
    const translate = useTranslate();

    return useMemo(() => {
        return {
            header: translate({ id: 'sections.requestService.addNote.drawer.header' }),
            noteFieldLabel: translate({ id: 'sections.requestService.addNote.drawer.noteFieldLabel' }),
        };
    }, [translate]);
}
